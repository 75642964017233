import * as React from 'react'
const IncidenceContext = React.createContext();

function bookReducer(state, action) {
    switch (action.type) {
        case 'add_incidence': {
            return { ...state, incidences: [...state.incidences, action.value] }
        }
        case 'remove_incidence': {
            const newIncidences = state.incidences.filter((incidenceData) => incidenceData.id !== action.value.id)
            return { ...state, incidences: newIncidences }
        }
        case 'clear_incidences': {
            const newIncidences = []
            return { ...state, incidences: newIncidences }
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function IncidenceProvider({ children }) {
    const [state, dispatch] = React.useReducer(bookReducer, { incidences: [] })
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch }
    return <IncidenceContext.Provider value={value}>{children}</IncidenceContext.Provider>
}

const addIncidence = (value) => {
    return { type: "add_incidence", value }
}

const removeIncidence = (value) => {
    return { type: "remove_incidence", value }
}

const clearIncidences = (value) => {
    return { type: "clear_incidences", value }
}

const actions = { addIncidence, removeIncidence, clearIncidences };

function useIncidence() {
    const context = React.useContext(IncidenceContext)
    if (context === undefined) {
        throw new Error('useIncidence must be used within a IncidenceProvider')
    }
    return { ...context, ...actions };
}

export { IncidenceProvider, useIncidence, actions }