import React from 'react';
import { Formik, Form } from "formik";
import * as yup from "yup";
import ButtonComponent from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import './ManagePestLibraryModal.scss';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import ImagePicker from 'components/elements/ImagePicker/ImagePicker';
import { base64ToFile } from 'services/uploadFile';

const ManagePestLibraryModalComponent = (props) => {
    const {
        isOpen,
        toggleModal,
        loading,
        bugData,
        submitBug
    } = props

    const preventiveTreatments = bugData?.preventiveTreatments.join('\n');

    const initialValues = {
        dangerLevel: bugData?.dangerLevel ? [bugData?.dangerLevel] : [dangerLevelOptions[0].value],
        info: bugData?.info || "",
        new_label: bugData?.new_label,
        name: bugData?.name || "",
        preventiveTreatments: preventiveTreatments || "",
        urlImage: {
            name: '',
            type: '',
            size: 0,
            base64: bugData?.urlImage,
            file: null,
        },
    }

    const validation = yup.object().shape({
        dangerLevel: yup.array().required("Required field"),
        info: yup.string().required("Required field"),
        new_label: yup.string(),
        name: yup.string().required("Required field"),
        preventiveTreatments: yup.string().required("Required field"),
        urlImage: yup.object().shape({
            name: yup.string(),
            type: yup.string(),
            size: yup.number(),
            base64: yup.string().required(),
            file: yup.mixed()
        }).required("Image not valid"), 
    })

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={(values) => {
                submitBug(values)
                toggleModal();
            }}>
            {(formik) => (
                <Form>
                    <ModalLayout
                        isOpen={isOpen}
                        toggleModal={() => { toggleModal() }}
                        header={<div>{bugData ? 'Edit bug' : 'Add bug'}</div>}
                        footer={
                            <div className="actions">
                                <ButtonComponent
                                    className="secondary auto-width"
                                    onClick={() => { toggleModal() }}
                                >
                                    Cancel
                                </ButtonComponent>
                                <ButtonComponent
                                    loading={loading}
                                    className="auto-width"
                                    onClick={formik.handleSubmit}
                                >
                                    Confirm
                                </ButtonComponent>
                            </div>
                        }
                    >
                        <div className="manage-pest-library-modal-wrapper">


                            <div className="row">
                                <div className="field-input">
                                    <ImagePicker
                                        formik={formik}
                                        id="urlImage"
                                        subtitle="Bug image"
                                    />
                                </div>
                            </div>


                            <div className="row row-3">
                                <div className="field-input">
                                    <Input
                                        type="text"
                                        formik={formik}
                                        id="new_label"
                                        label="ID"
                                        labelClassName="field-title"
                                        disabled={bugData?.new_label}
                                    />
                                </div>
                                <div className="field-input">
                                    <Input
                                        type="text"
                                        formik={formik}
                                        id="name"
                                        label="Name"
                                        labelClassName="field-title"
                                    />
                                </div>
                                <div className="field-input">
                                    <Input
                                        type="select"
                                        formik={formik}
                                        id="dangerLevel"
                                        label="Concern"
                                        options={dangerLevelOptions}
                                        multiple={false}
                                        labelClassName="field-title"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="field-input">
                                    <Input
                                        type="text"
                                        multiline
                                        formik={formik}
                                        id="info"
                                        label="Description"
                                        labelClassName="field-title"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="field-input">
                                    <Input
                                        type="textarea"
                                        multiline
                                        formik={formik}
                                        id="preventiveTreatments"
                                        label="Preventive steps"
                                        labelClassName="field-title"
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalLayout>
                </Form>
            )}
        </Formik>
    )
}

const dangerLevelOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
    { label: "NA", value: "NA" },
]


export default ManagePestLibraryModalComponent;