import React, { useState, useEffect } from "react";
import ContactsComponent from "../components/ContactsComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import { withRouter } from "react-router-dom";
import { getPestControllerContactsService } from 'services/pestController';
import { changeContactedContactService } from 'services/contact';

const Contacts = (props) => {
    const { history } = props;

    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        getContacts();
    }, [])

    const getContacts = async () => {
        try {
            const response = await getPestControllerContactsService();
            const contactsParsed = response.map(thisContact => {
                const username = `${thisContact.user.name || ''}${` ${thisContact.user.surname || ''}`}`;
                const usernameParsed = !username.replace(/\s/g, '').length ? 'Guest user' : username; // Check if contains only spaces
                return {
                    id: thisContact.contact.idContact,
                    image: (thisContact.incidence && thisContact.incidence.urlImage) || require('assets/images/user-placeholder.jpg'),
                    user: usernameParsed,
                    address: thisContact.user.zipcode,
                    submitted_date: new Date(thisContact.contact.createdAt).getTime(),
                    threat: thisContact.bug ? thisContact.bug.dangerLevel : null,
                    bugId: thisContact.bug ? thisContact.bug.new_label : null,
                    pest_prediction: thisContact.bug ? thisContact.bug.name : null,
                    isContact: true,
                    isContacted: thisContact.contact.contacted && JSON.parse(thisContact.contact.contacted)
                }
            });

            setContacts(contactsParsed);
        } catch (err) {
            console.log(err)
        }

        setLoading(false);
    }

    const setContacted = async (id, value) => {
        try {
            if ( window.confirm(value ?  "Would you like to mark as contacted?" : "Would you like to unmark as contacted?")) {
                const data = {
                    contacted: JSON.stringify(value)
                }

                await changeContactedContactService(id, data);
                getContacts();
            }
        } catch (err) {
            console.log(err)
        }
    }

    return <ContactsComponent
        setContacted={setContacted}
        contacts={contacts}
        loading={loading}
        history={history} />;
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contacts));