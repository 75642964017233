import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken, getUserId } from "./utils";

export const getPestControllersService = async () => {
    const token = getUserToken();

    try {
        let response = await customFetch(endpoints.pestControllers, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const getPestControllerService = async (id) => {
    const token = getUserToken();
    if (!id) id = getUserId();

    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const getPestControllerReviewsService = async () => {
    const token = getUserToken();
    const id = getUserId();

    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}/reviews`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getPestControllerContactsService = async () => {
    const token = getUserToken();
    const id = getUserId();

    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}/contacts`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const updatePestControllerService = async (data, id = null) => {
    const token = getUserToken();
    if (!id) id = getUserId();

    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}`, {
            method: "PATCH",
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const updatePestControllerPhotoService = async (data, id = null) => {
    const token = getUserToken();
    if (!id) id = getUserId();

    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}/updatePestControllerImage`, {
            method: "POST",
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const deletePestControllerService = async (id = null) => {
    const token = getUserToken();
    if (!id) id = getUserId();

    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}`, {
            method: "DELETE",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const replyPestControllerReviewService = async (data) => {
    const token = getUserToken();
    const id = getUserId();

    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}/replyReview`, {
            method: "POST",
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};