const BASE_URL = process.env.REACT_APP_API_URL || 'https://extrasense.pitagorinesgroup.com/'; //DEV
// const BASE_URL = process.env.REACT_APP_API_URL || "https://prod.backend.extrasense.pitagorinesgroup.com/"; //PRO

export const endpoints = {
    user: BASE_URL + 'user',
    users: BASE_URL + 'users',
    me: BASE_URL + 'users/me',

    iam: BASE_URL + 'iam',
    pestControllersAuth: BASE_URL + 'pestcontrollers',
    pestControllers: BASE_URL + 'pest-controllers',
    bugs: BASE_URL + 'bugs',
    skills: BASE_URL + 'skills',
    reviews: BASE_URL + 'reviews',
    reviewers: BASE_URL + 'reviewers',
    incidences: BASE_URL + 'incidences',
    contacts: BASE_URL + 'contacts',
    feedbacks: BASE_URL + 'FeedBacks',
    zipcodes: BASE_URL + 'ZipcodeLists',
    reviewIncidence: BASE_URL + 'incidence-reviews',
    species: BASE_URL + 'species',
    subspecies: BASE_URL + 'sub-species',
    scientificNames: BASE_URL + 'scientific-names',
    specieEmailTemplate: BASE_URL + 'specie-email-templates',
    media: BASE_URL + 'uploadFilesMultiPart',
    incidenceFormulary: BASE_URL + 'incidence-formularies',
    facilities: BASE_URL + 'facilities',
    locations: BASE_URL + 'locations',
    stadistics: 'https://pestid.extrasense.pitagorinesgroup.com/issues/stadistics',
};
