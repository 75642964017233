import React, {useState, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'; 
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import ButtonComponent from 'components/elements/Button/Button';
import InputComponent from 'components/elements/Input/Input';
import { ReactComponent as CameraSVG } from 'assets/icons/camera.svg';
import { ReactComponent as CheckCircleSVG } from 'assets/icons/checkcircle.svg';
import {ReactComponent as TrashSVG } from 'assets/icons/Trash.svg';
import Loading from 'components/elements/Loading/Loading';
import { Alert } from '@mui/material';
import './TrapForm.scss'

const TrapForm = (props) => {
    const { submit, modalVisible, setModalVisible, companies, selectedTrap, trapsTypes, companyUser } = props
    const [numPage, setNumPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [selectorTrapOpen, setSelectorTrapOpen] = useState(false)
    const [selectorCompaniesOpen, setSelectorCompaniesOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const inputRef = useRef([])
    const formRef = useRef()
  
    const initialValues = selectedTrap
    ? {
        typeTrap: selectedTrap?.trapType ? selectedTrap.trapType : {},
        name: selectedTrap.trap.name,
        idDevice: selectedTrap.trap.idDevice,
        image: selectedTrap?.trap.img,
        isEnable: selectedTrap.trap.isEnable ? ['Yes'] : ['No'],
        company: { idCompany: selectedTrap.company.idCompany, name: selectedTrap.company.name },
        idTrap: selectedTrap.trap.idTrap,
    } :
    companyUser ? {
        typeTrap: {},
        name: '',
        idDevice: '',
        image:  '',
        isEnable: [''],
        company: { idCompany: companyUser.idCompany, name: companyUser.name },
        idTrap: ''
    } :
    {
        typeTrap: {},
        name: '',
        idDevice: '',
        image:  '',
        isEnable: [''],
        company: { idCompany: '', name: '' },
        idTrap: ''
    }

    const validationSchema = Yup.object().shape({
        typeTrap: Yup.object().required(),
        name: Yup.string().required(),
        idDevice: Yup.string().required(),
        image: Yup.string().optional(),
        isEnable: Yup.array().of(Yup.string()).required(),
        company: Yup.object().shape({
            idCompany: Yup.string().required().min(1),
            name: Yup.string().required().min(1)
        }),
        idTrap: selectedTrap ? Yup.string().required() : Yup.string().optional()
    })

    const uploadFile = async(event, formik) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          const imageDataURL = e.target.result
          formik.setFieldValue('image', imageDataURL)
        }
        reader.readAsDataURL(file) 
    }

    const deleteImageRoute = (formik) => {
        formik.setFieldValue('image', '')
    }

    const handleClose = (formik) => {
        setModalVisible(false)
        formik.resetForm()
        setNumPage(1)
        setSelectorCompaniesOpen(false)
        setSelectorTrapOpen(false)
    }

    const handleCloseAlert = () => {
        setShowAlert(false)
        setNumPage(1)
    }

    const onClickSelectorCompany = () => {
        setSelectorTrapOpen(false)
        setSelectorCompaniesOpen(!selectorCompaniesOpen)
    }

    const onClickSelectorTrap = () => {
        setSelectorCompaniesOpen(false)
        setSelectorTrapOpen(!selectorTrapOpen)
    }

    const onClickRowTypeTrap = (trap, formik) => {
        formik.setFieldValue('typeTrap', trap)
        setSelectorTrapOpen(false)
    }

    const onClickRowCompany = (company, formik) => {
        formik.setFieldValue('company', company)
        setSelectorCompaniesOpen(false)
    }

    return (
        <>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
                setLoading(true)
                setNumPage(2)
                await submit(values)
            } catch (error) {
                setErrors({ submit: error.message })
                const message = error.error.statusCode === 409 ? error.error.message : 'Something went wrong, contact your administrator'
                setMessage(message)
                setShowAlert(true)
                setModalVisible(false)
                throw error
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        }}
        >
            {(formik) => (
                <Form>
                    <ModalLayout
                    maxWidth={'783px'}
                    isOpen={modalVisible}
                    toggleModal={() => handleClose(formik)}
                    footer={
                        <div className='modal-trap-footer-content'>
                            { numPage === 1 ? (    
                                <>
                                <ButtonComponent className='modal-trap-footer-button back' children={'Back'} onClick={()=> setModalVisible(false)}/>
                                <ButtonComponent
                                className='modal-trap-footer-button next'
                                children={selectedTrap ? 'Save' : 'Add'} onClick={()=> formik.handleSubmit()}
                                disabled={!formik.dirty || !formik.isValid}
                                />
                                </>
                    
                            ): (
                                <ButtonComponent children='Ok' onClick={() => handleClose(formik)} />
                            ) }
                        </div>
                    } 
                    header={
                        <div className='modal-trap-header-content'>
                            {numPage === 1 ? (<h2>{selectedTrap ? 'Edit trap' : 'Create Trap'}</h2>) : null} 
                                <div className='modal-trap-header-button-close' onClick={() => handleClose(formik)}>
                                    X
                                </div>
                        </div>
                    }
                    children={
                        <div ref={formRef} className='modal-trap-body-content'>
                            {numPage === 1 ? (
                                <>
                                <h3>Name</h3>
                                <InputComponent 
                                formik={formik} 
                                id='name' 
                                name='name'
                                type='textarea'
                                placeholder={formik.values.name}
                                />
                                <div className='modal-trap-body-content-selector-type' onClick={onClickSelectorTrap}>
                                    <h3>{formik.values.typeTrap.typeOfTrap || 'Select Trap'}</h3>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div> 
                                {selectorTrapOpen ? (
                                    <div className='modal-trap-body-content-selector-type-container'>
                                        {trapsTypes.map((trap,index) => (
                                            <div  onClick={ () => onClickRowTypeTrap(trap, formik)}  className='modal-trap-body-content-selector-type-row'>
                                                <p>{trap.typeOfTrap}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) :  null}
                                <h3>ID Device</h3>
                                <InputComponent 
                                formik={formik} 
                                id='idDevice' 
                                name='idDevice'
                                type='textarea'
                                placeholder={formik.values.idDevice}
                                />
                                <h3>Smart device active</h3>
                                <InputComponent 
                                formik={formik} 
                                id='isEnable' 
                                name='isEnable'
                                type='select'
                                options={[{label:'Yes',value:'Yes'},{label: 'No', value: 'No'}]}
                                placeholder={formik.values.smartDevice}
                                />
                                <h3>Image</h3>
                                {formik.values.image?.length > 0 ? (
                                    <div className='modal-trap-body-content-upload-button'>
                                    <div className='modal-trap-body-content-delete-button' onClick={() => deleteImageRoute(formik)}>
                                        <TrashSVG />
                                    </div>
                                    <img src={formik.values.image} alt="Selected trap" />        
                                </div>
                                    ):(
                                    <div onClick={() => inputRef.current.click()}  className='modal-trap-body-content-upload-button'>
                                        <input ref={ref => inputRef.current = ref} type="file" onChange={(event) => uploadFile(event, formik)} />
                                        <CameraSVG />
                                        <h3>Upload picture</h3>
                                        <p>Please upload a picture of the route</p>
                                    </div>
                                )}
                                {!companyUser ? (
                                    <>
                                     <div className='modal-trap-body-content-selector-type' onClick={onClickSelectorCompany}>
                                        <h3>{formik.values.company.name || 'Select company'}</h3>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div> 
                                    {selectorCompaniesOpen ? (
                                        <div className='modal-traps-body-content-selector-companies-container'>
                                            <InputComponent 
                                                InputProps={{
                                                disableUnderline: true
                                                }}
                                                id='search' 
                                                name='search'
                                                type='text'
                                                onChange={(event) => setSearchTerm(event.target.value)}
                                                placeholder='Search'
                                                />
                                            {companies.filter(company => company.name.toLowerCase().includes(searchTerm.toLowerCase())).map((company,index) => (
                                                <div key={index.toString()}  onClick={ () => onClickRowCompany(company, formik)}  className='modal-users-pestid-body-content-selector-companies-row'>
                                                    <p>{company.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ) :  null}
                                    </>
                                ): null}
                               
                                </>
                            ) : null}
                            {numPage === 2 ? (
                                <div className='modal-trap-body-content-success'>
                                    {loading ? (
                                        <Loading />
                                    ): (
                                        <>  
                                        <CheckCircleSVG />
                                        {selectedTrap ? <h2>Trap updated</h2> : <h2>Trap created</h2>}                                                                                        
                                        </>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    }
                    />
                </Form>
            )}
        </Formik>
        <ModalLayout
        maxWidth={'783px'}
        maxHeight={'200px'}
        isOpen={showAlert}
        toggleModal={handleCloseAlert}
        footer={
            <div className='modal-trap-footer-content'>
                <ButtonComponent children='Ok' onClick={handleCloseAlert} />
            </div>
        } 
        children={
                <div ref={formRef} className='modal-trap-body-content'>
                    <Alert className='map-alert' onClose={handleCloseAlert} severity='error'>
                        {message}
                    </Alert>
                </div>
        }
        />
        </>
    )
}

export default TrapForm