import React, { useState, useEffect, useMemo, useRef } from 'react';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Button from 'components/elements/Button/Button';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import SkeletonContactCard from 'components/elements/SkeletonContactCard/SkeletonContactCard';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { RiRecycleLine } from 'react-icons/ri';
import ContactCard from 'components/elements/ContactCard/ContactCard';
import FileUploadButton from 'components/elements/FileUploadButton/FileUploadButton';
import { searchTable } from 'services/utils';
import moment from 'moment';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import './IncidencesComponent.scss';
import { useIncidence } from 'contexts/incidencesContext';
import { useDateRange } from 'contexts/dateRangeContext';
import Loading from 'components/elements/Loading/Loading';

const IncidencesComponent = (props) => {
    const {
        history,
        incidences,
        csvData,
        loading,
        setLoading,
        handleStartDateFilter,
        handleEndDateFilter,
        user,
        uploadImagesBatch,
        loadingBatch,
        generateCsvData,
        getExtractedIncidenceData,
        getIncidencePriority,
        deleteOrRestoreIncidence,
        species,
        loadMoreData,
        loadingMoreData,
        statistics,
        getReviewedIncidences,
        loadingCsv,
        handleResetIncidences,
        loadingStatistics,
        totalCount,
        getAllIncidences,
    } = props;
    const csvInstance = useRef(null);
    const [incidencesData, setIncidencesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentUserModal, setCurrentUserModal] = useState({});
    const [showReviewedIncidences, setShowReviewedIncidences] = useState(false);
    const {
        state: {
            dates: { dateStart, dateEnd },
        },
    } = useDateRange();
    const listInnerRef = useRef();

    const {
        state: { incidences: incidencesToReview },
        dispatch,
        addIncidence,
        removeIncidence,
    } = useIncidence();

    useEffect(() => {
        if (incidences.length) {
            const dataParsed = incidences
                .filter(({ incidence }) => !incidence?.errorIA && incidence?.origin)
                .map((incidenceData) => {
                    const incidence = incidenceData.incidence || {};
                    const { username, os, lastReview, lastReviewer, alreadyReviewedByMe, emailShared, location, confidence, coordinates } =
                        getExtractedIncidenceData(incidenceData, user);
                    const status = lastReviewer?.name ? 'Reviewed' : incidenceData.status;
                    const reviewedBug = species.find((specie) =>
                        incidenceData.reviewPopulated[0] ? specie.specie.id === incidenceData.reviewPopulated[0].species?.id : null
                    );

                    let newIncidenceData = {
                        isPaid: incidenceData.paid,
                        bug: incidence?.bug,
                        image: incidence?.urlImage,
                        chatStatus: incidence?.chatStatus,
                        username,
                        coordinates,
                        user: `${username}${`\n`}${os}`,
                        userData: incidence.user,
                        location,
                        lat: incidence?.lat || '-',
                        long: incidence?.long || '-',
                        pest_prediction: reviewedBug?.specie.nameEn || incidence?.bug?.name,
                        ['%_confidence']: confidence,
                        submitted_date: incidence.createdAt,
                        status: status,
                        source:
                            incidence?.multibug !== undefined
                                ? `${incidence.origin.charAt(0).toUpperCase() + incidence.origin.slice(1)}.${
                                      incidence?.multibug ? 'M' : 'S'
                                  }`
                                : incidence.origin.charAt(0).toUpperCase() + incidence.origin.slice(1),
                        review_date: lastReview?.dateReview,
                        who_reviewed_last: lastReviewer?.name,
                        lastReviewerid: lastReviewer?.id,
                        id: incidence.idIncidence,
                        threat: incidence?.bug?.dangerLevel,
                        responseTime: incidence.responseTime,
                        os: incidence.os,
                        isDeleted: incidence?.isDeleted,
                        alreadyReviewedByMe,
                        emailShared,
                        requestContact: incidence?.requestContact,
                        pushToken: incidence?.pushToken,
                        sendEmail: incidenceData?.reviewPopulated[0]?.review?.sendEmail,
                        guestId: incidence?.guestId,
                        date: +new Date(incidence.createdAt),
                        action: (
                            <div className="actions">
                                {lastReview && (
                                    <div onClick={() => history.push(`/admin/incidence/${incidence.idIncidence}`)}>
                                        <AiOutlineInfoCircle />
                                    </div>
                                )}
                                <div onClick={() => deleteOrRestoreIncidence(incidence.idIncidence, !incidence.isDeleted)}>
                                    {incidence.isDeleted ? <RiRecycleLine /> : <BsTrash />}
                                </div>
                            </div>
                        ),
                    };

                    if (incidenceData.paid) newIncidenceData.paymentType = incidenceData?.product?.name;
                    return newIncidenceData;
                });

            setIncidencesData(dataParsed);
            setFilteredData(dataParsed);
        }
    }, [incidences]);

    useEffect(() => {
        if (loading) return;
        window.addEventListener('scroll', handleInfiniteScroll);
        return () => window.removeEventListener('scroll', handleInfiniteScroll);
    }, []);

    const sortableColumns = useMemo(() => {
        let column = [
            {
                column: 'Image',
                style: { marginLeft: 55, marginRight: 35, flex: 0 },
            },
            { column: 'User' },
            { column: 'Pest prediction' },
            { column: '% confidence' },
            { column: 'Submitted date' },
            { column: 'Location' },
            { column: 'Status' },
            { column: 'Source' },
            { column: 'Review date' },
            { column: 'Reviewer' },
        ];
        if (user.role === 'Admin')
            column.push({
                column: 'actions',
                style: { flex: '0 0 90px', marginRight: 35 },
            });
        return column;
    }, []);

    const getIncidence = (incidenceId) => {
        const selectedIncidence = incidences.filter((incidence) => incidence.id === incidenceId)[0];
        if (selectedIncidence.user) {
            setCurrentUserModal(selectedIncidence.user);
        }
    };

    const incidencesExportName = `incidences-${moment(new Date()).format('DD-MM-YYYY-HH[h]mm[m]')}.csv`;

    useEffect(() => {
        if (!_.isEmpty(csvData) && csvInstance && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            });
        }
    }, [csvData]);

    const handleInfiniteScroll = (e) => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight + 200 >= scrollHeight) {
                if (!loadingMoreData) {
                    showReviewedIncidences ? loadMoreData('Reviewed') : loadMoreData();
                }
            }
        }
    };

    const handleShowIncidencesByStatus = () => {
        filteredData.length > 0 && setLoading(true);
        handleResetIncidences();
        setFilteredData([]);
        !showReviewedIncidences ? getReviewedIncidences() : getAllIncidences();
        setShowReviewedIncidences((state) => !state);
    };

    const handleGetIncidencesByStartDate = (date) => {
        if (loading) return;
        handleResetIncidences();
        setFilteredData([]);
        handleStartDateFilter({ dateStart: date, showReviewedIncidences: showReviewedIncidences });
    };

    const handleGetIncidencesByEndtDate = (date) => {
        if (loading) return;
        handleResetIncidences();
        setFilteredData([]);
        handleEndDateFilter({ dateEnd: date, showReviewedIncidences: showReviewedIncidences });
    };

    return (
        <DashboardLayout
            topbarLeftComponent={
                <TopBarSearch
                    placeholder="Search pest incidences ..."
                    searchCallback={(value) => {
                        const searchData = searchTable(value, incidencesData);
                        setFilteredData(searchData);
                    }}
                />
            }
        >
            <div className="incidences-wrapper" onScroll={handleInfiniteScroll} ref={listInnerRef}>
                <div className="incidences-header">
                    <h1>Pest Incidences</h1>
                    <div className="actions">
                        <div className="date-filter">
                            <div className="date-filter-label">Date range filter:</div>

                            <DatePicker
                                showTimeSelect
                                selected={dateStart}
                                onChange={handleGetIncidencesByStartDate}
                                className="map-datepicker"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                popperModifiers={{
                                    preventOverflow: {
                                        enabled: true,
                                    },
                                }}
                            />

                            <DatePicker
                                showTimeSelect
                                selected={dateEnd}
                                onChange={handleGetIncidencesByEndtDate}
                                className="map-datepicker"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                popperModifiers={{
                                    preventOverflow: {
                                        enabled: true,
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="submissions-count">
                    <div className="card">
                        <div className="title">Images Uploaded</div>
                        <div className="value">{loading ? <Loading size={30} /> : totalCount || 0}</div>
                    </div>
                    <div className="card">
                        <div className="title">Entomologist reviews requested</div>
                        <div className="value">{loadingStatistics ? <Loading size={30} /> : statistics?.reviewCount?.count}</div>
                    </div>
                    <div className="card">
                        <div className="title">Registered users</div>
                        <div className="value">{loadingStatistics ? <Loading size={30} /> : statistics?.userCount}</div>
                    </div>
                    <div className="card">
                        <div className="title">Guest users</div>
                        <div className="value">{loadingStatistics ? <Loading size={30} /> : statistics?.guestCount}</div>
                    </div>
                    <div className="card">
                        <div className="title">Reviewed Incidences</div>
                        <div className="value">
                            {loadingStatistics ? <Loading size={30} /> : statistics?.reviewerIncidencesCount}
                            <div
                                className={`text-button ${loadingStatistics && 'disabled'}`}
                                onClick={() => !loadingStatistics && handleShowIncidencesByStatus()}
                            >
                                ({showReviewedIncidences ? 'Hide all' : 'Show all'})
                            </div>
                        </div>
                    </div>
                </div>

                {!loading && (
                    <div className="top-buttons">
                        {user.role === 'Reviewer' && (
                            <Button
                                onClick={() => history.push(`/review/incidence/${incidencesToReview[0].id}`)}
                                disabled={incidencesToReview.length <= 0}
                                style={{ width: 'auto', marginRight: 10 }}
                                className="accent"
                            >
                                Start review
                            </Button>
                        )}

                        <FileUploadButton
                            label="Upload images in batch"
                            onChange={uploadImagesBatch}
                            style={{ marginRight: 10, width: 170 }}
                            loading={loadingBatch}
                            fileProps={{
                                multiple: true,
                                accept: 'image/*',
                            }}
                        />
                        <Button onClick={() => generateCsvData()} className="csv-download-btn" loading={loadingCsv}>
                            <FontAwesomeIcon className="icon" icon={faDownload} />
                            <span>Export to CSV</span>
                        </Button>
                        <CSVLink style={{ display: 'none' }} data={csvData} filename={incidencesExportName} ref={csvInstance}>
                            <FontAwesomeIcon className="icon" icon={faDownload} />
                            <span>Export to CSV</span>
                        </CSVLink>
                    </div>
                )}

                <div id="incidence-list" className="incidences-empty">
                    <div className="table-headers">
                        {sortableColumns.map((column) => (
                            <div className="table-header" style={column.style}>
                                {column.column}
                            </div>
                        ))}
                    </div>
                    {!loadingMoreData && loading ? (
                        <div className="padding-top">
                            <SkeletonContactCard />
                            <SkeletonContactCard />
                            <SkeletonContactCard />
                        </div>
                    ) : filteredData.length ? (
                        filteredData.map((data) => {
                            return (
                                <ContactCard
                                    data={data}
                                    onClick={getIncidence}
                                    user={user}
                                    history={history}
                                    selectedIncidences={incidencesToReview}
                                    editIncidence={showReviewedIncidences}
                                    onEditClick={(id) => history.push(`/review/incidence/${id}/batch_review=false/send_email=false`)}
                                    selectIncidence={(incidenceData, selected) => {
                                        if (selected) dispatch(addIncidence(incidenceData));
                                        else dispatch(removeIncidence(incidenceData));
                                    }}
                                />
                            );
                        })
                    ) : (
                        <div className="incidences-empty">No Incidences registered yet.</div>
                    )}
                    <span>Showing {filteredData.length} results</span>
                    {loadingMoreData && <Loading />}
                </div>
            </div>
        </DashboardLayout>
    );
};

export default IncidencesComponent;
