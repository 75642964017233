import customFetch from './customFetch';
import { endpoints } from './endpoints';
import { getUserToken } from './utils';

export const getStadisticsByStartAndEndDateRange = async (startDate, endDate) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.stadistics}/${startDate}/${endDate}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getCompanyStadisticsByStartAndEndDateRange = async (idCompany,startDate, endDate) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/issues/${idCompany}/stadistics/${startDate}/${endDate}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};