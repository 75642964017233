import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MapPicker.scss';
import InputComponent from 'components/elements/Input/Input';
import { Alert } from '@mui/material';

export const MapPicker = (props) => {
  const { formik } = props
  const [currentMarker, setCurrentMarker] = useState({})
  const [center, setCenter] = useState({ lat: 40.748817, lng: -73.985428 })
  const [showAlert, setShowAlert] = useState(false)
  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      })
    }
  }, [])

  useEffect(() => {
    if(formik.values.search?.length && showAlert === true){
      setShowAlert(false)
    }
  },[formik.values, showAlert])

  const handleMapClick = async(event) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setCurrentMarker({ lat, lng })
    const geocoder = new window.google.maps.Geocoder()
    try {
      const data = await geocoder.geocode({ location: { lat, lng } })
      const { results } = data
        if (results?.length) {
          formik.setFieldValue('address', results[0].formatted_address)
          formik.setFieldValue('latitude', lat)
          formik.setFieldValue('longitude', lng)
        } 
    } catch(error) {
      console.error("No results found for location")
    }
  }

  const handleSearch = async() => {
    const geocoder = new window.google.maps.Geocoder()
    try {
      const data = await geocoder.geocode( {address: formik.values.search} ) 
      const { results } = data
      if (results?.length) {
        const { lat, lng } = results[0].geometry.location
        setCurrentMarker({ lat: lat(), lng: lng() })
        setCenter({ lat: lat(), lng: lng() })
        formik.setFieldValue('address', results[0].formatted_address)
        formik.setFieldValue('latitude', lat())
        formik.setFieldValue('longitude', lng())
        setShowAlert(false)
      }
    } catch(error) {
      console.error("No results found for address")
      setShowAlert(true)
    }
  }

  const onKeyDownSearch = (event) => {
    if (event.key === 'Enter') handleSearch()
  }

  return (
    <div className='map-wrapper'>
        <div className='map-search-box'>
            <div className='map-search-box-input'>
                <InputComponent 
                    InputProps={{
                    disableUnderline: true
                    }}
                    onBlur={() => {formik.setFieldValue('search', '')}}
                    formik={formik} 
                    id='search' 
                    name='search'
                    type='text'
                    onSubmit={onKeyDownSearch}
                    placeholder='Search'
                    setShowAlert={setShowAlert}
                />
                <FontAwesomeIcon onClick={handleSearch} icon={faSearch} />
            </div>
        </div>
        {showAlert ? (
            <Alert className='map-alert' onClose={() => setShowAlert(false)} severity='info'>
                This address was not found
            </Alert>
        ): null}
        <LoadScript googleMapsApiKey="AIzaSyBTBtSShkECf6YgHMLDgiJv1zMFiVtOE5U">
            <GoogleMap
            id='example-map'
            center={center}
            zoom={13}
            onClick={handleMapClick}
            >
              {currentMarker && <Marker position={currentMarker} />}
            </GoogleMap>
        </LoadScript>
    </div>             
  )
}

export default MapPicker