import { isEqual } from 'lodash'
import React from 'react'
import { ReactComponent as BuildingSVG } from 'assets/icons/buildingGreen.svg';

const BuildingSidebar = (props) => {
    const {locations, setSelectedLocation, selectedLocation} = props
    const locationInfo = (location) => {
        return location?.routes?.length
    }
    return (
        <div className='building-buildings-sidebar'>
        {locations.map((location, index) => {
          return (
            <div
            key={location.idLocation}
            className='building-buildings-sidebar-item'
            onClick={() => setSelectedLocation(location)}
            style={{ background: (isEqual(location, selectedLocation)) ? '#E8FFFA' : 'white', borderRight: (isEqual(location, selectedLocation)) ? '2px solid #14AF8D' : ''}}>
              <div className='building-buildings-sidebar-location'>
                <BuildingSVG className='building-buildings-sidebar-location-icon'/>
                <h3>{location.name}</h3>
              </div>
              <div>
                <h3>{locationInfo(location)} routes</h3>
              </div>
            </div>
          )
        })}
      </div>
    )
}

export default BuildingSidebar