import React, { useState, useEffect } from "react";
import SpeciesComponent from "../components/SpeciesComponent";
import { withRouter } from "react-router-dom";
import { addSpecieService, deleteSpecieService, getSpeciesService, updateSpecieService } from 'services/species';
import moment from 'moment';
import _ from 'lodash';

const Species = (props) => {
    const { history } = props;
    const [species, setSpecies] = useState([]);
    const [fetchingSpecies, setFetchingSpecies] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSpecieModal, setShowSpecieModal] = useState(false);

    useEffect(() => {
        setFetchingSpecies(true);
        getSpecies();
    }, [])

    const getSpecies = async () => {
        const data = await getSpeciesService();
        let formattedData = data.map(({ specie, subSpecies, scientificNames }) => {
            const specieData = specie?.specie || specie;
            const reviewer = specie?.reviewer || {};
            const { nameEs, nameEn, status, commonSubmane, scientificName, instar, population, registeredBy, createdAt, id } = specie;
            const priority = status === 'pending' ? 10 : 0;
            return ({
                id,
                common_name_english: nameEn,
                common_name_spanish: nameEs,
                status,
                registeredBy: reviewer?.name || "admin",
                date: createdAt,
                priority,
                subSpecies,
                scientificNames
            })
        })

        formattedData = _.orderBy(formattedData, ["priority", "date"], ["desc"]);

        setSpecies(formattedData);
        setFetchingSpecies(false);
    }

    const changeSpecieStatus = async (id, newStatus) => {
        setIsLoading(id);
        if (window.confirm(newStatus === 'rejected' ? "Are you sure you want to reject this specie?" : "Are you sure you want to accept this specie?")) {
            try {
                await updateSpecieService(id, { status: newStatus });

                setIsLoading(false);
                getSpecies();
            } catch (err) {
                setIsLoading(false);
                console.log(err);
            }
        }
    }

    const deleteSpecie = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this specie ?')) {
                await deleteSpecieService(id);
                await getSpecies()
            }
        }
        catch (e) {
            console.log('error deleteSpecie', e);
        }
        finally {
        }
    }

    const submitSpecie = async (id, values, subSpeciesList) => {
        try {
            setIsLoading(true);
            if (id) {
                await updateSpecieService(id, values);
            }
            else {
                await addSpecieService({ ...values, status: 'accepted' });
            }

            await getSpecies();
            setShowSpecieModal(false);
        }
        catch (e) {
            console.log('error deleteSpecie', e);
        }
        finally {
            setIsLoading(false);
        }
    }

    return <SpeciesComponent
        {...props}
        species={species}
        isLoading={isLoading}
        fetchingSpecies={fetchingSpecies}
        changeSpecieStatus={changeSpecieStatus}
        getSpecies={getSpecies}
        {...{ deleteSpecie, showSpecieModal, setShowSpecieModal, submitSpecie }}
    />
};

export default withRouter(Species);