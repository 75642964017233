import TrapRoutesComponent from 'components/TrapRoutes/components/TrapRoutesComponent'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getExpandedLocations, getLocationsOfCompany } from 'services/location'
import { getRouteById } from 'services/route'
import { getTrapById, getTrapsByCompany, updateTrap } from 'services/traps'
import { getCompanyUserId, getUserRole } from 'services/utils'

export const TrapRoutes = () => {
  const [locations, setLocations] = React.useState([])
  const [selectedLocation, setSelectedLocation] = useState({})
  const [selectedRoute, setSelectedRoute] = useState({})
  const [selectedTrap, setSelectedTrap] = useState({x: 0, y: 0, idRoute: '', idTrap: '', idPreviousTrap: ''})
  const [loading, setLoading] = useState(false)
  const [availableTraps, setAvailableTraps] = useState([])
  const role = getUserRole()
  const history = useHistory()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const unlisten = history.listen(() => {
      getData()
    })

    return () => {
      unlisten()
    }
  }, [history])

  useEffect(() => {
    getAvailableTraps()
  }, [selectedTrap])

  const getData = async () => {
    try {
      setLoading(true)
      const locationsResponse = await getLocations()
      setLocations(locationsResponse)
      setSelectedLocation(locationsResponse[0])
      setSelectedRoute(locationsResponse[0].routes[0])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getLocations = async() => {
    try {
      let data = []
      if(role === 'cmsmanager'){
        const companyId = getCompanyUserId()
        data = await getLocationsOfCompany(companyId)
      } else {
        data = await getExpandedLocations()
      }
      return data
    }catch(e) {
      throw new Error(e)
    }
  }

  const getAvailableTraps = async () => {
    try{
      const data = await getTrapsByCompany(selectedLocation.idCompany)
      const filterWithoutUse = data.filter((trap) => !trap.trap.hasOwnProperty('idRoute') || trap.trap.idRoute?.length === 0)
      setAvailableTraps(filterWithoutUse)
    }catch(error){
      console.error(error)
    }
  }

  const editTrap = async (previousTrap, values) => {
    try {
      const previousTrapData = await getTrapById(previousTrap.idTrap)
      await updateTrap({idRoute: ''}, previousTrap.idTrap)
      let body = {
        x: previousTrapData.x,
        y: previousTrapData.y,
        idRoute: previousTrapData.idRoute
      }
      await updateTrap(body, values.idTrap)
      const locationsResponse = await getLocations()
      const updatedRoute = await getRouteById(values.idRoute)
      const findedLocation = locationsResponse.find((location) => location.idLocation === updatedRoute.idLocation)
      const findedRoute = findedLocation.routes.find((route) => route.idRoute === values.idRoute)
      setLocations(locationsResponse)
      setSelectedLocation(findedLocation)
      setSelectedRoute(findedRoute)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteTrap = async (trap) => {
    try{
      let body = { idRoute: '' }
      await updateTrap(body, trap.idTrap)
      const locationsResponse = await getLocations()
      const updatedRoute = await getRouteById(trap.idRoute)
      const findedLocation = locationsResponse.find((location) => location.idLocation === updatedRoute.idLocation)
      const findedRoute = findedLocation.routes.find((route) => route.idRoute === trap.idRoute)
      setLocations(locationsResponse)
      setSelectedLocation(findedLocation)
      setSelectedRoute(findedRoute)
    }catch(error){
      console.error(error)
      throw error
    }
  }
 
  return (
    <TrapRoutesComponent
      loading={loading}
      locations={locations}
      editTrap={editTrap}
      deleteTrap={deleteTrap}
      setSelectedLocation={setSelectedLocation}
      selectedLocation={selectedLocation}
      setSelectedRoute={setSelectedRoute}
      selectedRoute={selectedRoute}
      selectedTrap={selectedTrap}
      setSelectedTrap={setSelectedTrap}
      availableTraps={availableTraps}
    />
  )
}

export default TrapRoutes