import ButtonComponent from 'components/elements/Button/Button'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AlertDelete from 'components/elements/AlertDelete/AlertDelete'
import UsersPestIDForm from './UsersPestIDForm'
import UsersPestIDTable from './UsersPestIDTable'
import Loading from 'components/elements/Loading/Loading'
import './UsersPestIDComponent.scss'


export const UsersPestIDComponent = (props) => {
    const { users, loading, submit, update, eliminate, companies } = props
    const [modalVisible, setModalVisible] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false)
    const [userSelected, setUserSelected] = useState(null)

    const onCreate = () => {
      setUserSelected(null)
      setModalVisible(true)
    }

    const onEdit = (item) => {
      setUserSelected(item)
      setModalVisible(true)
    }

    const onDelete = (item) => {
      setUserSelected(item)
      setAlertVisible(true)
    }

    const handleDelete = async () => {
      try{
        await eliminate(userSelected.user.id)
      }catch(error){
        console.error(error)
      }
    }
 
  return (
    <DashboardLayout>
      <div className='users-pestid-wrapper'>
        <div className='users-pestid-header'>
          <div className='users-pestid-header-title'>
            <h1>Users PestID</h1>
          </div>
          <div className='users-pestid-header-button'>
            <ButtonComponent className='users-pestid-header-button' children={'+ Create user'} onClick={onCreate}/>
          </div>
        </div>
        {loading ? (
          <Loading />
        ): (
          <div className='users-pestid-content'>
            <div className='users-pestid-table'>
              {users.length > 0 ?   <UsersPestIDTable users={users} onEdit={onEdit} onDelete={onDelete}/>
              : (
                <div className='users-pestid-table-no-content'>
                  <FontAwesomeIcon icon={faInfoCircle} color='#14AF8D'/>
                  <h3>No users have been added yet</h3>
                </div>
              )}
            </div>
            <UsersPestIDForm modalVisible={modalVisible} setModalVisible={setModalVisible}  submit={userSelected ? update : submit} userSelected={userSelected} companies={companies}/>
            <AlertDelete alertVisible={alertVisible} setAlertVisible={setAlertVisible} onDelete={handleDelete} title='Delete user' subtitle='Are you sure you want to delete this user?' />
          </div>
        )}
      </div> 
    </DashboardLayout>
  )
}

export default UsersPestIDComponent