import React from 'react';
import ModalLayout from '../ModalLayout/ModalLayout';
import { MdClose } from 'react-icons/md';
import ButtonComponent from '../Button/Button';
import './FacilityModal.scss';

const CloseButton = () => <MdClose className="icon toastr-close" />;

const FacilityModal = (props) => {
    const { children, isOpen, toggleModal, headerText, leftButtom, leftButtomClick, rightButtom, rightButtomClick, leftButtonLoading, rightButtonLoading } = props

    return (
        <div>
            <ModalLayout
                isOpen={isOpen}
                toggleModal={toggleModal}
                header={
                    <div className="FacilityModal-header-wrapper">
                        {headerText}
                        <div className="FacilityModal-close-button" onClick={toggleModal}>
                            <CloseButton />
                        </div>
                    </div>
                }
                footer={
                    <div className="FacilityModal-footer-wrapper">
                        {leftButtom && (
                            <ButtonComponent ButtonComponent loading={leftButtonLoading} white className="button" onClick={() => leftButtomClick()} style={{ padding: '11px 0' }}>
                                {leftButtom}
                            </ButtonComponent>
                        )}
                        <ButtonComponent loading={rightButtonLoading} secondary className="button" onClick={() => rightButtomClick()} >
                            {rightButtom}
                        </ButtonComponent>
                    </div>
                }
            >
                <div className="FacilityModal-content-wrapper">
                    {children}
                </div>
            </ModalLayout>
        </div>
    )
}

export default FacilityModal;
