import React from 'react';
import Lottie from 'react-lottie';
import whiteAnimationData from 'assets/lotties/loading-white.json';
import animationData from 'assets/lotties/loading.json';

const Loading = (props) => {
    const { white = true } = props;

    return <Lottie
        options={defaultOptions(white)}
        height={props?.size || 80}
        width={props?.size || 80}
        color="#000"
    />;
};

const defaultOptions = (isWhite) => {
    return {
        loop: true,
        autoplay: true,
        animationData: !isWhite ? whiteAnimationData : animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
};

export default Loading;
