import { indexOf } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeft } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as CaretDown } from 'assets/icons/CaretDown.svg';
import ButtonComponent from 'components/elements/Button/Button';
import ModalTraps from 'components/elements/ModalTraps/ModalTraps';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import './TrapRoutesSelectorComponent.scss'

import Loading from 'components/elements/Loading/Loading';
import CanvasPlane from 'components/elements/BuildingElements/CanvasPlane';
import AlertDelete from 'components/elements/AlertDelete/AlertDelete';

export const TrapRoutesSelectorComponent = (props) => {
  const { location, update, deleteTrap, submitNewTrap, loading, selectedRoute, setSelectedRoute, selectedTrap, setSelectedTrap, availableTraps } = props 
  const [displayDropdown, setDisplayDropdown] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const history = useHistory()

  const onPressAdd = (coordinates) => {
    setDisplayDropdown(false)
    setSelectedTrap({x: coordinates.x, y: coordinates.y, idRoute: selectedRoute.idRoute, idTrap: '', idPreviousTrap: ''})
    setModalVisible(true)
  }

  const onPressEdit = () => {
    setDisplayDropdown(false)
    setModalVisible(true)
  }

  const onPressDelete = () => {
    setDisplayDropdown(false)
    setAlertVisible(true)
  }

  return (
    <DashboardLayout>
      {loading ? (
        <div className='traps-wrapper-loading'>
          <Loading />
        </div>
      ) : (
        <>
        <div className="traps-wrapper">
          <div className='traps-content-header'>
            <ArrowLeft className='back-arrow' width='1.5rem' onClick={() => history.goBack()}/>
            <h2><span>{location?.address}</span> / Add traps</h2>
          </div>
          <div className='traps-content-wrapper'>
            <div className='traps-content'>
              <div className='traps-content-selected-route'>
                {selectedRoute && (
                  <>
                  <div className='traps-content-selected-route-header'>
                    <p>Please select the location of the trap on the map</p>
                    <ButtonComponent
                        onClick={() => setDisplayDropdown(!displayDropdown)}
                    >
                      <span>Route {indexOf(location.routes, selectedRoute) + 1}</span>
                      <CaretDown className='selected-route-button-icon'/>
                    </ButtonComponent>
                    {displayDropdown && (
                      <div className='traps-content-selected-route-dropdown'>
                        {location.routes.map((route, index) => (
                          <div
                              key={route.idRoute}
                              className='traps-content-selected-route-dropdown-item'
                              onClick={() => {
                              if (route !== selectedRoute) {
                                  setSelectedRoute(route)
                              }
                              setDisplayDropdown(!displayDropdown)
                              }}
                          >
                            <div className='traps-content-selected-route-dropdown-item-image'>
                              <img src={route.img} alt='route-map' />
                            </div>
                            <div className='traps-content-selected-route-dropdown-item-text'>
                              <h3>Route {index + 1}</h3>
                              <p>{location?.name} building</p>
                            </div>
                          </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div className='traps-content-selected-route-wrapper-canvas'>
                    <CanvasPlane
                    selectedRoute={selectedRoute}
                    setSelectedRoute={setSelectedRoute}
                    onDelete={onPressDelete}
                    onEdit={onPressEdit}
                    setSelectedTrap={setSelectedTrap}
                    onPressCanvas={onPressAdd}
                    />
                  </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalTraps
        submit={selectedTrap.idPreviousTrap?.length > 0 ? update : submitNewTrap}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedRoute={selectedRoute}
        selectedTrap={selectedTrap}
        availableTraps={availableTraps}
        />
        <AlertDelete
        alertVisible={alertVisible}
        setAlertVisible={setAlertVisible}
        onDelete={deleteTrap}
        title='Remove trap'
        subtitle='Are you sure you want to remove this trap?'
        />
        </>
      )}
      
    </DashboardLayout>
  )
}

export default TrapRoutesSelectorComponent