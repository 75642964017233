import React, { useState, useEffect } from 'react'
import ManagePestLibraryModalComponent from '../components/ManagePestLibraryModalComponent';
import ToastrContent from 'components/elements/Toastr/Toastr';
import moment from 'moment';
import { toast } from 'react-toastify';
import { addBugService, updateBugService } from 'services/library';

const ManagePestLibraryModal = (props) => {
    const [loading, setLoading] = useState(false);
    const { getBugs, bugData, toggleModal } = props

    const submitBug = async (values) => {
        let isEditting = false;
        let bugId = bugData?.new_label;
        let valuesToSubmit = {
            new_label: bugId || values.new_label || values.name.toLowerCase(),
            dangerLevel: values.dangerLevel[0],
            info: values.info,
            name: values.name,
            preventiveTreatments: values.preventiveTreatments.split("\n"),
            urlImage: values.urlImage.base64,
            createdAt: moment().format("YYYY-MM-DD[T]HH:mm:ss[.000Z]")
        }

        if (bugData?.new_label) {
            isEditting = true;
        }
        else {
            isEditting = false;
            bugId = valuesToSubmit.new_label;
        }

        console.log('valuesToSubmit', valuesToSubmit);

        try {
            setLoading(true);
            let res;
            if (isEditting) res = await updateBugService(bugId, valuesToSubmit);
            else res = await addBugService(valuesToSubmit);

            toast(
                <ToastrContent
                    type="success"
                    title={"Success"}
                    message={"The bug has been saved"}
                />,
                {
                    progressClassName: "toastr-progress-bar success",
                }
            );
        }
        catch (e) {
            console.log('error getBugs', e);
        }
        finally {
            setLoading(false);
            getBugs();
        }
    }

    return (
        <ManagePestLibraryModalComponent {...props}
            {...{ loading, submitBug, bugData, toggleModal }}
        />
    )
}
export default ManagePestLibraryModal;