import React, { useState, useEffect } from 'react';
import Button from 'components/elements/Button/Button';
import { GrUserWorker } from 'react-icons/gr';
import { BiBug, BiTargetLock } from 'react-icons/bi';
import { FaRegComment } from 'react-icons/fa';
import { BiCalendarAlt } from 'react-icons/bi';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import './IncidenceReviewCard.scss'
import { getReviewConfidenceName } from 'components/utils/entomologyUtils';
import moment from 'moment';

const IncidenceReviewCardComponent = (props) => {
    const { incidence = {}, review: { review = {}, species = {}, reviewer = {} }, updateIncidence, loading
    } = props;
    const { urlImage } = incidence;
    const { status, idIncidenceReview, comment, dateReview } = review;
    const { geoPoint = { x0: 90, y0: 90, x1: 180, y1: 180 } } = review;
    const [crop, setCrop] = useState(null);

    useEffect(() => {
        if (!crop && geoPoint?.x0) {
            let img = new Image();
            img.src = incidence?.urlImage;
            img.onload = () => {
                const ratio = 100 / img.height;
                const x0 = parseInt(geoPoint.x0 * ratio);
                const y0 = parseInt(geoPoint.y0 * ratio);
                const x1 = parseInt(geoPoint.x1 * ratio);
                const y1 = parseInt(geoPoint.y1 * ratio);
                const boxW = x1 - x0;
                const boxH = y1 - y0
                const data = { unit: 'px', width: boxW, height: boxH, x: x0, y: y0 }
                setCrop(data)
            }
        }
    }, [geoPoint])

    return (
        <div className="incidence-review-card-wrapper">
            <div className="imgWrapper">
                {crop && <ReactCrop
                    src={urlImage}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    keepSelection
                    locked
                />}
            </div>
            <div className="info-wrapper">
                <div className="row">
                    <div className="icon"><GrUserWorker /></div>
                    <div className="title">{reviewer.name}</div>
                </div>
                <div className="row">
                    <div className="icon"><BiBug /></div>
                    <div className="title">{species.nameEn || "-"}</div>
                </div>
                <div className="row">
                    <div className="icon"><BiTargetLock /></div>
                    <div className="title">{getReviewConfidenceName(review.confidence)}</div>
                </div>
                <div className="row">
                    <div className="icon"><FaRegComment style={{ fontSize: 14, marginRight: 2 }} /></div>
                    <div className="title">{comment || "-"}</div>
                </div>
                <div className="row">
                    <div className="icon"><BiCalendarAlt /></div>
                    <div className="title">{moment(dateReview).format('MM/DD/YY HH:mm')}</div>
                </div>
                {status === 'pending' ? <div className="row buttons">
                    <Button className="small danger"
                        loading={loading === 'rejected'}
                        onClick={() => updateIncidence(idIncidenceReview, { status: "rejected" })}
                        style={{ marginRight: 10 }}>Deny</Button>

                    <Button
                        loading={loading === 'accepted'}
                        className="small"
                        onClick={() => updateIncidence(idIncidenceReview, { status: "accepted" })} >Accept</Button>
                </div> :
                    status === 'accepted' ? <div className="row buttons accepted">
                        Accepted
                    </div> : <div className="row buttons rejected">
                        Rejected
                    </div>
                }
            </div>
        </div>
    )
}
export default IncidenceReviewCardComponent;