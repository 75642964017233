import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getMessagesService = async ({ specieId }) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.specieEmailTemplate}`, {
            method: "GET",
            token
        });
        if (response.error) throw response;

        if (specieId) return response?.filter((message) => message.specieId === specieId)[0]
        return response;
    } catch (err) {
        throw err;
    }
};

export const createMessageService = async ({ specieId, text }) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.specieEmailTemplate}`, {
            method: "POST",
            token,
            bodyReq: { specieId, text }
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};

export const updateMessageService = async ({ messageId, specieId, text }) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.specieEmailTemplate}/${messageId}`, {
            method: "PATCH",
            token,
            bodyReq: { specieId, text }
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};

export const createOrUpdateMessageService = async ({ specieId, text }) => {
    let response;
    const prevMessage = await getMessagesService({ specieId });
    console.log('prevMessage', prevMessage);
    if (prevMessage) response = await updateMessageService({ messageId: prevMessage.id, specieId, text });
    else response = await createMessageService({ specieId, text });

    return response;
}

export const uploadImageService = async (file) => {
    return new Promise((resolve, reject) => {
        var data = new FormData();
        data.append('files[]', file);

        var xhr = new XMLHttpRequest()
        xhr.withCredentials = true

        xhr.addEventListener("load", function () {
            resolve(JSON.parse(xhr.responseText))
        })

        xhr.addEventListener("error", function () {
            reject(xhr.responseText)
        })

        xhr.open("POST", endpoints.media)
        xhr.setRequestHeader("Content-Type", "multipart/form-data");
        xhr.setRequestHeader("content-type", "multipart/form-data");
        xhr.setRequestHeader("accept", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + getUserToken());
        xhr.send(data);
    })
}

export const dataURLtoFile = (dataURI, name) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    const blob = new Blob([ia], { type: mimeString })

    const file = new File([blob], name || "filename.jpeg");

    return file;
}

export const uploadBase64Service = (base64) => {
    return new Promise((resolve, reject) => {
        fetch(base64)
            .then(res => res.blob())
            .then(blob => {
                const fd = new FormData();
                const file = new File([blob], "filename.jpeg", { type: "image/jpeg" });
                fd.append('image', file);
                const API_URL = endpoints.media
                fetch(API_URL, { method: 'POST', body: fd })
                    .then(res => res.text())
                    .then(res => resolve(res))
                    .catch(e => reject(e))

            })
    });
}