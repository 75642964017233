/**
 *
 * @param {*} URL to fetch
 * @param {*} method GET/POST/PUT/...
 * @param  bodyReq request body
 * @param  params  url params
 * @param  token authorizathion token
 * @returns  response json
 * @author Adriller Ferreira
 */

export default async function customFetch(URL, { method = "GET", bodyReq = null, params = null, token = null, hideHeaders = false }) {
    if (!URL) return { error: "invalid URL" };

    const finalURL = params ? URL + "/" + params : URL;
    const headers = token
        ? {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        }
        : { "Content-Type": "application/json" };
    let response;

    try {
        let requestOptions = {
            method,
            // params:  params ? JSON.stringify(params) : null,
            body: bodyReq ? JSON.stringify(bodyReq) : null,
        }

        if (!hideHeaders) {
            requestOptions.headers = headers;
        }

        response = await fetch(finalURL, requestOptions);
    } catch (e) {
        // console.log(e);
        throw e;
    }

    let jsonResponse = response;

    if (response.headers && response.headers.get("Content-Type")) {
        if (response.headers.get("Content-Type").indexOf("application/json") > -1) {
            try {
                jsonResponse = await response.json();
            } catch (err) {
                throw err;
            }
        }
    }

    return jsonResponse;
}