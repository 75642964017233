import React from "react"
import { Formik, Form } from "formik"
import * as yup from "yup"
import Button from "components/elements/Button/Button"
import Input from "components/elements/Input/Input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight, faCheck } from "@fortawesome/free-solid-svg-icons"
import './ResetPasswordPestIDComponent.scss'
import PresentationPestID from "components/elements/PresentationPestID/PresentationPestID"

const ResetPasswordPestIDComponent = (props) => {
    const { loading, submit, error, success, history } = props

    const initialValues = {
        password: "",
        confirmPassword: "",
    }

    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .min(8)
            .required("Password is a required field"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], "Passwords must match")
            .required("Confirm password is a required field"),
    })

    return (
        <div className="reset-password-pestid-component">
            <PresentationPestID />

            <div className="formContainer">
                {
                    success ?
                        <div className="success">
                            <div className="success-icon">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div className="success-message">Password successfully changed! Now you are able to login into our app again </div>

                            <Button
                                loading={loading}
                                type="submit"
                                onClick={() => history.push('/login/pestid')}>
                                Back to dashboard
                            </Button>
                        </div> :
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => submit(values)}
                        >
                            {(formik) => (
                                <>
                                    <Form>
                                        <Input
                                            id="password"
                                            formik={formik}
                                            type="password"
                                            label="Password*"
                                            className="formControl"
                                        />

                                        <Input
                                            id="confirmPassword"
                                            formik={formik}
                                            type="password"
                                            label="Confirm password*"
                                            className="formControl"
                                        />

                                        {
                                            error ?
                                                <div className="error">{error.message}</div> :
                                                null
                                        }

                                        <div className="error">
                                            {error?.statusCode === 404 ? (
                                                <Button
                                                    className="button-new-request"
                                                    loading={loading}
                                                    type="submit"
                                                    onClick={() => history.push('/forgot-password-pestid')}>
                                                        <p> Request a new key </p>
                                                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                                                </Button>
                                            ): (
                                                <Button
                                                    loading={loading}
                                                    type="submit"
                                                    onClick={formik.handleSubmit}>
                                                    Reset password
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                }
            </div>
        </div>
    )
}

export default ResetPasswordPestIDComponent
