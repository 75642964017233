import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { getTrapById, getTrapsByCompany, updateTrap } from "services/traps";
import { getExpandedLocations } from "services/location";
import TrapRoutesSelectorComponent from "../components/TrapRoutesSelectorComponent";
import { getRouteById } from "services/route";
import { getUsersCompanyById } from "services/companies";


const TrapRouteSelector = () => {
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState({})
  const [selectedRoute, setSelectedRoute] = useState({})
  const [selectedTrap, setSelectedTrap] = useState({x: 0, y: 0, idRoute: '', idTrap: '', idPreviousTrap: ''})
  const [availableTraps, setAvailableTraps] = useState([])

  useEffect(() => {
      getAllRoutes()
  }, [])

  useEffect(() => {
    getAvailableTraps()
  }, [selectedTrap])

  const getAllRoutes = async () => {
    try{
      setLoading(true)
      const route = await getRouteById(id)
      const data = await getExpandedLocations()
      const findedLocation = data.find((item) => item.idLocation === route.idLocation)
      const findedRoute = findedLocation.routes.find((route) => route.idRoute === id)
      setLocation(findedLocation)
      setSelectedRoute(findedRoute)
    }catch(error){
      console.error(error)
    }finally{
      setLoading(false)
    }
  }

  const getAvailableTraps = async () => {
    try{
      const data = await getTrapsByCompany(location.idCompany)
      const filterWithoutUse = data.filter((trap) => !trap.trap.hasOwnProperty('idRoute') || trap.trap.idRoute?.length === 0)
      setAvailableTraps(filterWithoutUse)
    }catch(error){
      console.error(error)
    }
  }

  const submitNewTrap = async (values) => {
    try{
      let body = {
        x: values.x,
        y: values.y,
        idRoute: values.idRoute,
      }
      await updateTrap(body, values.idTrap)
      const updateLocation = await getExpandedLocations()
      const updatedRoute = await getRouteById(values.idRoute)
      const findedLocation = updateLocation.find((location) => location.idLocation === updatedRoute.idLocation)
      const findedRoute = findedLocation.routes.find((route) => route.idRoute === values.idRoute)
      setLocation(findedLocation)
      setSelectedRoute(findedRoute)
    }catch(error){
      console.error(error)
    }
  }
    
  const update = async (values) => {
    try {
      const previousTrapData = await getTrapById(selectedTrap.idPreviousTrap)
      await deleteTrap(selectedTrap)
      let body = {
        x: previousTrapData.x,
        y: previousTrapData.y,
        idRoute: previousTrapData.idRoute
      }
      await updateTrap(body, values.idTrap)
      const updateLocation = await getExpandedLocations()
      const updatedRoute = await getRouteById(values.idRoute)
      const findedLocation = updateLocation.find((location) => location.idLocation === updatedRoute.idLocation)
      const findedRoute = findedLocation.routes.find((route) => route.idRoute === values.idRoute)
      setLocation(findedLocation)
      setSelectedRoute(findedRoute)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
    
  const deleteTrap = async () => {
    try{
      let body = { idRoute: '' }
      await updateTrap(body, selectedTrap.idTrap)
      const updateLocation = await getExpandedLocations()
      const updatedRoute = await getRouteById(selectedTrap.idRoute)
      const findedLocation = updateLocation.find((location) => location.idLocation === updatedRoute.idLocation)
      const findedRoute = findedLocation.routes.find((route) => route.idRoute === selectedTrap.idRoute)
      setLocation(findedLocation)
      setSelectedRoute(findedRoute)
    }catch(error){
      console.error(error)
      throw error
    }
  }

    return (
      <TrapRoutesSelectorComponent
      loading={loading}
      location={location}
      deleteTrap={deleteTrap}
      update={update}
      submitNewTrap={submitNewTrap}
      selectedRoute={selectedRoute}
      setSelectedRoute={setSelectedRoute}
      selectedTrap={selectedTrap}
      setSelectedTrap={setSelectedTrap}
      availableTraps={availableTraps}
      />
    )
}

export default TrapRouteSelector