import React, { useEffect, useState } from 'react';
import ChatInput from 'components/elements/ChatInput/ChatInput';
import ChatMessage from 'components/elements/ChatMessage/ChatMessage';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { BiSend } from 'react-icons/bi';
import { GrAttachment } from 'react-icons/gr';
import './ChatComponent.scss';
import InputComponent from 'components/elements/Input/Input';
import * as Yup from "yup";
import { AiOutlineArrowLeft } from 'react-icons/ai';

import { useFormik } from 'formik';
import { ChatComponentI, ChatMessageI, FilesFormikI } from '../Container/types';
import ChatContact from 'components/elements/ChatContact/ChatContact';
import { BsFillChatFill } from 'react-icons/bs';
import ButtonComponent from 'components/elements/Button/Button';

const ChatComponent = (props: ChatComponentI) => {
    const { user, currentChatMessages, sendMessage, inputValue, setInputValue, thisIncidence, chatId, chats, closeChat, chatType, changeChatType, loading, setCurrentChatMessages } = props;
    const [toggleView, setToggleView] = useState(chatId ? "chats" : "contacts");

    useEffect(() => {
        var objDiv = document.getElementById("messages-wrapper");
        const height = objDiv ? objDiv.scrollHeight : 0;
        if (objDiv) objDiv.scrollTop = height;
    }, [currentChatMessages]);

    const filesFormik = useFormik<{ image?: FilesFormikI }>({
        initialValues: {
            image: undefined,
        },
        validationSchema: Yup.object().shape({
            image: Yup.object().required("Obligatorio"),
        }),
        onSubmit: (values) => { console.log(values) },
    })

    useEffect(() => {
        if (filesFormik.values.image) {
            sendMessage(filesFormik.values.image?.base64, "image");
        }
    }, [filesFormik.values])

    return (
        <DashboardLayout>
            <div className="chat-wrapper">
                {/* <div className="chat-header">
                    <h3>User Ticket -{chatId}</h3>
                </div> */}
                <div className="content-wrapper">
                    <div className={`contacts-wrapper ${toggleView === "contacts" ? "show" : "hide"}`} >
                        <div className='contacts-options-wrapper'>
                            <div className={`chat-option ${chatType === "open" ? 'selected' : null}`} onClick={() => changeChatType("open")}>
                                Open
                            </div>
                            <div className={`chat-option ${chatType === "closed" ? 'selected' : null}`} onClick={() => changeChatType("closed")}>
                                Closed
                            </div>
                        </div>
                        {!loading && chats.map((chat) => {
                            return <ChatContact chat={chat} activeChatId={chatId} onClick={() => {
                                setCurrentChatMessages([]);
                                setToggleView("chats");
                            }
                            } />
                        })}
                    </div>

                    <div className={`messages-box-wrapper ${toggleView === "chats" ? "show" : "hide"}`} >
                        {!chatId ?
                            <div className="welcome">
                                <BsFillChatFill />
                                <h3>Welcome!</h3>
                                <p>Please select a chat to get started.</p>
                            </div> :
                            <>
                                <div className="message-header">
                                    <div className="toggleContacts" onClick={() => setToggleView("contacts")}><AiOutlineArrowLeft /></div>
                                    <div className="bug-image-wrapper">
                                        <img src={thisIncidence?.urlImage} alt="" />
                                    </div>
                                    <div className="bug-infos">
                                        <h4>{thisIncidence?.bug?.name}</h4>
                                    </div>
                                    <div className="close-chat">
                                        {thisIncidence?.chatStatus === "open" ? <ButtonComponent className="small" onClick={() => closeChat()}>
                                            Close chat
                                        </ButtonComponent> : ' '}
                                    </div>
                                </div>
                                {thisIncidence?.chatStatus === "closed" &&
                                    <div className="closed-chat">This conversation has been closed</div>}
                                <div className="message-content" id="messages-wrapper">
                                    {currentChatMessages?.map((message: ChatMessageI, i: number) => <ChatMessage key={i} {...props} userName={thisIncidence?.username} message={message} user={user} />)}
                                </div>
                                <div className="message-footer">
                                    <div className="attach-icon pointer">
                                        <label
                                            className="options-items-wrapper pointer"
                                            htmlFor={'image'}
                                        >
                                            <GrAttachment />
                                        </label>
                                        <InputComponent
                                            type="file"
                                            id={'image'}
                                            formik={filesFormik}
                                            formats={['png', 'jpg', 'jpeg']}
                                        />
                                    </div>
                                    <div className="input-message" onKeyDown={(e) => e.which === 13 ? sendMessage(inputValue, "text") : null}>
                                        <ChatInput value={inputValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)} />
                                    </div>
                                    <div className="send-icon-wrapper pointer" onClick={() => sendMessage(inputValue, "text")}><BiSend /></div>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
        </DashboardLayout>
    )
}

export default ChatComponent;

