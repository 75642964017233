import React from "react";
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import "./SkeletonTable.scss";

const SkeletonTable = props => {
    const { rounded = null } = props;
    return (
        <div className="skeleton-table">
            <table className={`${rounded && 'rounded'}`}>

                <thead>
                    <tr className="table-header">
                        <th><Skeleton width={150} height={15} /></th>
                        <th><Skeleton width={150} height={15} /></th>
                        <th><Skeleton width={150} height={15} /></th>
                        <th><Skeleton width={150} height={15} /></th>
                        <th><Skeleton width={150} height={15} /></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        _.times(3, (i) => (
                            <tr className="table-row" key={i}>
                                <td><Skeleton width={150} height={15} /></td>
                                <td><Skeleton width={150} height={15} /></td>
                                <td><Skeleton width={150} height={15} /></td>
                                <td><Skeleton width={150} height={15} /></td>
                                <td><Skeleton width={150} height={15} /></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SkeletonTable;