import { store } from "redux/store";

export function getUserToken() {
    const state = store.getState();
    const token = state?.userReducer?.user?.token;
    return token;
}

export function getUserId() {
    const state = store.getState();
    const id = state?.userReducer?.user?.id;
    return id;
}

export const getUserRole = () => {
    const state = store.getState()
    const role = state?.userReducer?.user?.role
    return role
}

export const getCompanyUserId = () => {
    const state = store.getState()
    const companyId =state?.userReducer?.user?.idCompany
    return companyId
}

export const searchTable = (searchValue, allData) => {
    searchValue = searchValue.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    const allDataFiltered = allData.filter((row) => {
        let includeRow = 0;

        Object.values(row).forEach((tableCell) => {
            if (tableCell && JSON.stringify(tableCell).includes(";base64")) return;

            if (
                tableCell && typeof tableCell !== "object" &&
                JSON.stringify(tableCell)
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(searchValue)
            ) {
                includeRow = 1;
            }

            if (
                tableCell && typeof tableCell === "object" &&
                JSON.stringify(tableCell.value) &&
                JSON.stringify(tableCell.value)
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(searchValue)
            ) {
                includeRow = 1;
            }

        });

        return includeRow && row;
    });

    return allDataFiltered;
};

export const getImageData = file => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000), // kb
                base64: reader.result,
                file: file,
            };

            resolve(fileInfo);
        }

        reader.onerror = reject;
    });

}

export const getBase64Image = (url) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();

        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            }

            reader.readAsDataURL(xhr.response);
        };

        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    })
}


export const canvasToBase64 = (canvas) => {
    return canvas.toDataURL();
}

