import React, { useState, useEffect } from 'react';
import './ChatInput.scss';

interface ChatInputI {
    value: string,
    onChange(event: React.ChangeEvent<HTMLInputElement>): void,
}

const ChatInput = (props: ChatInputI) => {
    const { value, onChange } = props;

    return <input className="chat-input-wrapper" value={value} onChange={onChange} />
}

export default ChatInput;