import React, { useState, useEffect, useRef } from 'react'
import Heatmap from 'heatmap.js'

const HeatMapPlane = (props) => {
  const { selectedRoute } = props
  const containerRef = useRef(null)
  const canvasRef = useRef(null)
  const heatMapRef = useRef(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [image] = useState(new Image()) 
  const heatmapInstanceRef = useRef(null)
  const containerParentRef = useRef(null)
  
  useEffect(() => {
    const containerParent = containerParentRef.current
    const canvas = canvasRef.current
    const heatMap = heatMapRef.current
    if(heatmapInstanceRef.current) cleanPreviousHeatMap()
    const context = canvas.getContext('2d')
    image.src = selectedRoute.img
    image.onload = () => {
      setImageLoaded(true)
      updateCanvasSize()
    }
      
    const updateCanvasSize = () => {
      const containerParentHeight = containerParent.offsetHeight
      const containerHeight =  containerParentHeight - 40
      const imageWidth = image.naturalWidth
      const imageHeight = image.naturalHeight
      const canvasWidth = (containerHeight / imageHeight) * imageWidth
      const canvasHeight = containerHeight
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      heatMap.style.width = `${canvasWidth}px`
      heatMap.style.height = `${canvasHeight}px`
      if (imageLoaded) {
        context.filter = 'grayscale(100%) saturate(0.1) brightness(1.2)'
        context.drawImage(image, 0, 0, canvasWidth, canvasHeight)
        const heatmapInstance =  Heatmap.create({
          container: document.querySelector('.building-content-main-route-heatmap-container')
        })
        heatmapInstanceRef.current = heatmapInstance
        const data = []
        selectedRoute.issues.forEach((issue) => {
          const {x, y, frequency} = issue
          let point = {x: Math.floor(x * canvas.width), y: Math.floor(y * canvas.height) , value: frequency }
          data.push(point)
        })
        heatmapInstance.setData({
        max: 5,
        data: data
        })
      }
    }
  }, [selectedRoute, imageLoaded, image])

  const cleanPreviousHeatMap = () => {
    heatmapInstanceRef.current.setData({ max: 0, data: [] })
    const container = heatMapRef.current
    const heatCanvas = container.querySelector('.heatmap-canvas')
    if (heatCanvas)  container.removeChild(heatCanvas)
  }

  return (
      <div className="building-content-main-route" ref={containerParentRef}>
        <div className='building-content-main-route-wrapper' ref={containerRef}>
          <canvas className="building-content-main-route-canvas" ref={canvasRef}>
          </canvas>
          <div className="building-content-main-route-heatmap-container" ref={heatMapRef}></div>
        </div>
      </div>
  )
}

export default HeatMapPlane