import React, { useState, useEffect } from 'react';
import ManagePestControllerComponent from '../components/ManagePestControllerComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateUser } from 'redux/actions/userActions';
import { withRouter } from 'react-router-dom';
import {
    getPestControllerService,
    updatePestControllerService,
    deletePestControllerService,
    updatePestControllerPhotoService,
} from 'services/pestController';
import { getUserCoordByZipcodeService } from 'services/location';

const ManagePestController = (props) => {
    const {
        history,
        user,
        updateUser,
        match: {
            params: { id },
        },
    } = props;

    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [fetchingPestController, setFetchingPestController] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPestController, setCurrentPestController] = useState({});

    useEffect(() => {
        if (id) {
            getPestController(id);
        } else {
            getPestController(user.id);
        }
    }, [id, user]);

    const getPestController = async (id) => {
        try {
            setFetchingPestController(true);
            const pestController = await getPestControllerService(id);
            setCurrentPestController(pestController);
            setFetchingPestController(false);
        } catch (err) {
            setFetchingPestController(false);
            console.log(err);
        }
    };

    const deletePestController = async () => {
        setLoadingDelete(true);

        try {
            await deletePestControllerService(id);

            if (!id) {
                updateUser({});
                localStorage.clear();
            }

            setLoadingDelete(false);
            history.push('/admin/pests');
        } catch (err) {
            console.log(err);
            setLoadingDelete(false);
        }
    };

    const updatePestControllerPhoto = async (photoInfo) => {
        setLoadingPhoto(true);

        try {
            const response = await updatePestControllerPhotoService({ image: photoInfo.base64 }, id);
            const urlProfileImage = await response.text();

            if (!id) {
                updateUser({ ...user, urlProfileImage });
            } else {
                setCurrentPestController({ ...currentPestController, urlProfileImage });
            }

            setLoadingPhoto(false);
        } catch (err) {
            console.log(err);
            setLoadingPhoto(false);
        }
    };

    const submit = async (data) => {
        setLoading(true);
        let extraServices =
            data?.extraServices &&
            Object.keys(data?.extraServices)
                .map((item) => {
                    if (data?.extraServices[`${item}`]) return item;
                })
                .filter((item) => {
                    return item !== undefined;
                });

        let dataToSend = {
            name: data.name,
            email: data.email,
            description: data.description,
            postalCode: data.postalCode,
            geoPoint: data.geoPoint,
            country: data.country,
            city: data.city,
            pestCoverage: data.pestCoverage,
            serviceOptions: data.serviceOptions,
            phone: data.phone,
            notificationsEmail: data.notificationsEmail,
            specialitiesDescription: data.specialitiesDescription,
            historyDescription: data.historyDescription,
            ownerDescription: data.ownerDescription,
            aditionalDescription: data.aditionalDescription,
            extraServices: extraServices,
            licenseNumber: data.license,
        };

        if (data.coverageArea) dataToSend.coverageArea = { coords: data.coverageArea };

        try {
            if (dataToSend.postalCode) {
                const { results } = await getUserCoordByZipcodeService(data.postalCode);

                if (results[0]) {
                    const { lat, lng } = results[0].geometry.location;
                    data.geoPoint = {
                        lat,
                        long: lng,
                    };
                } else {
                    alert('Invalid zipcode. Please try to input a valid one.');
                    setLoading(false);
                    return;
                }
            }

            await updatePestControllerService(dataToSend, id);

            if (!id) {
                updateUser({ ...user, ...data });
            } else {
                getPestController(currentPestController.id);
            }

            setLoading(false);
            setIsEditModalOpen(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const setVisibility = async ({ active }) => {
        try {
            await updatePestControllerService({ active }, id);

            if (!id) {
                updateUser({ ...user, ...{ active } });
            } else {
                setCurrentPestController({ ...currentPestController, ...{ active } });
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ManagePestControllerComponent
            id={id}
            currentPestController={currentPestController}
            history={history}
            submit={submit}
            deletePestController={deletePestController}
            updatePestControllerPhoto={updatePestControllerPhoto}
            loading={loading}
            loadingDelete={loadingDelete}
            fetchingPestController={fetchingPestController}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            loadingPhoto={loadingPhoto}
            setVisibility={setVisibility}
            setLoadingPhoto={setLoadingPhoto}
        />
    );
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagePestController));
