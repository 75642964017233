import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './ChatContact.scss';

const ChatContact = (props) => {
    const { chat, activeChatId, onClick } = props;
    const active = activeChatId === chat?.id;

    return (
        <Link
            to={{
                pathname: `/chat/${chat?.id}`,
            }}
            onClick={() => onClick()}
            className={`chat-contact-component ${active && "active"}`} >
            <img src={chat?.img} alt="user" />
            <div className="contact-name">
                Ticket: {chat?.id}
            </div>
            {chat?.badge > 0 ? <div className="badge">
                {chat?.badge}
            </div> : null}
        </Link>
    )
}
export default ChatContact;