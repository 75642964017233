import React, { useState, useEffect } from "react";
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import SpecieCard from 'components/elements/SpecieCard/SpecieCard';
import SkeletonReviewCard from 'components/elements/SkeletonReviewCard/SkeletonReviewCard';
import { searchTable } from 'services/utils';
import Button from 'components/elements/Button/Button';
import "./SpeciesComponent.scss";
import AddSpecieModal from "./AddSpecieModal";
import SendMessageModal from "components/elements/SendMessageModal/containers/SendMessageModal";

const SpeciesComponent = (props) => {
    const { species, isLoading, fetchingReviews, showSpecieModal, setShowSpecieModal, submitSpecie, getSpecies } = props;
    const sortableColumns = [{ column: 'common_name_english', }, { column: "common_name_spanish" }, { column: 'status', style: { padding: 0, flex: '0 0 80px', } }, { column: 'registeredBy' }, { column: "date", style: { flex: 2.5 } },];
    const [filteredData, setFilteredData] = useState([]);
    const [showMessageTemplate, setShowMessageTemplate] = useState(false);

    useEffect(() => {
        if (species.length) setFilteredData(species);
    }, [species]);

    const selectSpecie = specie => {
        const selectedSpecie = species.filter(thisSpecie => thisSpecie.id === specie.id)[0];
        setShowSpecieModal(selectedSpecie);
    }

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search species ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, species);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="species-wrapper">
                <div className="species-header">
                    <h1>Species</h1>
                </div>

                <div className="top-buttons">
                    <Button
                        onClick={() => setShowSpecieModal(true)}
                        style={{ width: 'auto', }}
                        className="">Add Specie</Button>

                </div>

                {
                    fetchingReviews ?
                        <div className="species-empty padding-top">
                            <SkeletonReviewCard />
                            <SkeletonReviewCard />
                            <SkeletonReviewCard />
                        </div> :
                        filteredData.length ?
                            <TableOptions
                                columnsClassName="table-filters-custom"
                                rawData={filteredData}
                                sortableColumns={sortableColumns}
                                rowComponentProps={{ ...props, showStatus: true, updateSpecie: (specie) => selectSpecie(specie), onEditMessagePress: (specieData) => setShowMessageTemplate(specieData) }}
                                rowComponent={<SpecieCard />} /> :
                            <div className="species-empty">No species provided yet.</div>
                }

                <AddSpecieModal
                    isOpen={showSpecieModal}
                    submit={submitSpecie}
                    loading={isLoading}
                    specie={showSpecieModal}
                    setShowSpecieModal={setShowSpecieModal}
                    getSpecies={getSpecies}
                />

                <SendMessageModal
                    specieData={showMessageTemplate}
                    open={showMessageTemplate}
                    onConfirm={(message) => setShowMessageTemplate(false)}
                    onDeny={() => setShowMessageTemplate(false)}
                    confirmButtonText="Save"
                    denyButtonText="Cancel"
                />

            </div>
        </DashboardLayout>
    );
};

export default SpeciesComponent;