import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React from "react";
import { MdClose } from 'react-icons/md';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./redux/store";
import history from './services/history';

import ForgotPassword from './components/ForgotPassword/containers/ForgotPassword';
import Login from './components/Login/containers/Login';
import Register from './components/Register/containers/Register';
import ResetPassword from './components/ResetPassword/containers/ResetPassword';

import Contacts from './components/Contacts/containers/Contacts';
import ManagePestController from './components/ManagePestController/containers/ManagePestController';
import Reviews from './components/Reviews/containers/Reviews';

import { DateRangeProvider } from './contexts/dateRangeContext';
import { IncidenceProvider } from './contexts/incidencesContext';

// Admin Routes
import AdminReviews from './components/Admin/AdminReviews/containers/AdminReviews';
import Feedbacks from './components/Admin/Feedbacks/containers/Feedbacks';
import IncidenceDetails from './components/Admin/IncidenceDetails/containers/IncidenceDetails';
import Incidences from './components/Admin/Incidences/containers/Incidences';
import IncidencesReviews from './components/Admin/IncidencesReviews/containers/IncidencesReviews';
import Map from './components/Admin/Map/containers/Map';
import Pests from './components/Admin/Pests/containers/Pests';
import Reviewers from './components/Admin/Reviewers/containers/Reviewers';
import Species from './components/Admin/Species/containers/Species';
import Users from './components/Admin/Users/containers/Users';
import Zipcodes from './components/Admin/Zipcodes/containers/Zipcodes';

// Facility manager routes
import CasesFacilities from './components/FacilityManager/CasesFacilities/containers/CasesFacilities';
import CasesPests from './components/FacilityManager/CasesPests/containers/CasesPests';
import FacilityMap from './components/FacilityManager/FacilityMap/containers/FacilityMap';

import FacilityReports from "components/FacilityManager/FacilityReports/containers/FacilityReports";
import Settings from "components/FacilityManager/Settings/containers/Settings";

// Entomology Routes
import IncidenceReview from './components/Entomology/IncidenceReview/containers/IncidenceReview';

// Router Components
import PrivateRoute from 'components/elements/PrivateRoute/PrivateRoute';
import PublicRoute from 'components/elements/PublicRoute/PublicRoute';

import PestId from "components/Admin/PestId/containers/PestId";
import Reports from 'components/Admin/Reports/containers/Reports';
import BuildingRoutes from 'components/BuildingRoutes/containers/BuildingRoutes';
import ChatContainer from "components/Chat/Container/ChatContainer";
import EmailVerification from "components/EmailVerification/container/EmailVerification";
import FacilityUsers from "components/FacilityManager/FacilityUsers/containers/FacilityUsers";
import HeatMapService from 'components/HeatMapService/containers/HeatMapService';
import PestLibrary from "components/PestLibrary/Containers/PestLibraryContainer";
import TrapRoutes from 'components/TrapRoutes/containers/TrapRoutes';
import TrapRouteSelector from 'components/TrapRoutesSelector/containers/TrapRoutesSelector';
import Traps from 'components/Traps/containers/Traps';
import Companies from 'components/Companies/containers/Companies';
import './App.scss';
import UsersPestID from 'components/Admin/UsersPestID/containers/UsersPestID';
import LoginPestID from 'components/LoginPestID/containers/LoginPestID';
import ForgotPasswordPestID from 'components/ForgotPasswordPestID/containers/ForgotPasswordPestID';
import ResetPasswordPestID from 'components/ResetPasswordPestID/containers/ResetPasswordPestID';

const CloseButton = () => <MdClose className="icon toastr-close" />;


const muiTheme = createMuiTheme({
	typography: {
		"fontFamily": "GothamRounded",
		"fontSize": 14,
		"fontWeightLight": 400,
		"fontWeightRegular": 500,
		"fontWeightMedium": 600
	},
	palette: {
		primary: {
			main: '#50e3c2',
		},
	},
});

function App() {
	return (
		<MuiThemeProvider theme={muiTheme}>
			<Provider store={store}>
				<IncidenceProvider>
					<DateRangeProvider >
						{/* <Notifications /> */}
						<Router history={history}>
							<div className="toastr-div">
								<ToastContainer toastClassName="toastr-wrapper" autoClose={5000} hideProgressBar={false} pauseOnHover closeButton={<CloseButton />} />
							</div>
							<div className="App">
								<Switch>
									<PublicRoute restricted component={Login} path="/" exact />
									<PublicRoute restricted component={Login} path="/login" exact />
									<PublicRoute restricted component={LoginPestID} path="/login/pestid" exact />
									<PublicRoute restricted component={Register} path="/register" />
									<PublicRoute component={ResetPassword} path="/reset-password" />
									<PublicRoute component={ResetPasswordPestID} path="/recover/pestid/:id" />
									<PublicRoute component={ForgotPassword} path="/forgot-password" />
									<PublicRoute component={ForgotPasswordPestID} path="/forgot-password-pestid" />
									<PublicRoute component={EmailVerification} path="/verify" />

									<PrivateRoute role={["Manager"]} component={FacilityMap} path="/facility/map" />
									<PrivateRoute role={["PestController"]} component={Contacts} path="/contacts" />
									<PrivateRoute role={["PestController"]} component={Reviews} path="/reviews" />

									<PrivateRoute role={["Admin", "PestController"]} component={ManagePestController} path="/manage-pest-controller/:id?" />

									<PrivateRoute role={["Admin", "Reviewer"]} component={Map} path="/admin/map" />
									<PrivateRoute role={["Manager"]} component={CasesPests} path="/pest/cases/open" />
									<PrivateRoute role={["Manager"]} component={CasesPests} path="/pest/cases/closed" />
									<PrivateRoute role={["Manager"]} component={CasesFacilities} path="/facility/cases/open" />
									<PrivateRoute role={["Manager"]} component={CasesFacilities} path="/facility/cases/closed" />
									<PrivateRoute role={["Manager"]} component={FacilityUsers} path="/manage/users" />
									<PrivateRoute role={["Manager"]} component={FacilityReports} path="/manage/reports" />
									<PrivateRoute role={["Manager"]} component={Settings} path="/settings" />
									<PrivateRoute role={["Admin"]} component={Pests} path="/admin/pests" />
									<PrivateRoute role={["Admin"]} component={Reviewers} path="/admin/reviewers" />
									<PrivateRoute role={["Admin"]} component={AdminReviews} path="/admin/reviews" />
									<PrivateRoute role={["Admin"]} exact component={Users} path="/admin/users" />
									<PrivateRoute role={["Admin"]} exact component={Users} path="/admin/users/begbug" />
									<PrivateRoute role={["Admin", "cmsmanager"]} exact component={UsersPestID} path="/admin/users/pestid" />
									<PrivateRoute role={["Admin" ]} component={UsersPestID} path="/admin/users/pestid/:id" />
									<PrivateRoute role={["Admin"]} component={Feedbacks} path="/admin/feedbacks" />
									<PrivateRoute role={["Admin"]} component={Zipcodes} path="/admin/zipcodes" />
									<PrivateRoute role={["Admin"]} component={IncidenceDetails} path="/admin/incidence/:id" />
									<PrivateRoute role={["Admin"]} component={IncidencesReviews} path="/admin/all-reviews" />

									<PrivateRoute role={["Admin", "Reviewer"]} component={PestId} path="/admin/pestid" />
									<PrivateRoute role={["Admin", "Reviewer"]} component={Incidences} path="/admin/incidences" />
									<PrivateRoute role={["Admin", "Reviewer"]} component={Species} path="/admin/species" />
									<PrivateRoute role={["Admin", "Reviewer"]} component={IncidenceReview} path="/review/incidence/:id" />

									<PrivateRoute role={["Admin", "Reviewer"]} component={ChatContainer} path="/chat/:chatId?" />
									<PrivateRoute role={["Admin", "Reviewer"]} component={PestLibrary} path="/admin/bugs" />

									<PrivateRoute exact role={["Admin", "Manager", "cmsmanager"]} component={TrapRoutes} path="/admin/routes" />
									<PrivateRoute exact role={["Admin", "Manager", "cmsmanager"]} component={TrapRouteSelector} path="/admin/routes/add-traps/:id" />

									<PrivateRoute role={["Admin", "Manager", "cmsmanager"]} component={BuildingRoutes} path="/admin/building" />
									<PrivateRoute role={["Admin", "Manager",  "cmsmanager"]} component={HeatMapService} path="/admin/issues-map" />

									<PrivateRoute role={["Admin", "Manager", "cmsmanager"]} component={Traps} path="/admin/traps" />

									<PrivateRoute role={["Admin", "Manager", "cmsmanager"]} component={Reports} path="/admin/reports" />
									
									<PrivateRoute role={["Admin", "Manager"]} component={Companies} path="/admin/companies" />
									
								</Switch >
							</div >
						</Router >
					</DateRangeProvider >
				</IncidenceProvider >
			</Provider >
		</MuiThemeProvider >
	);
}

export default App;
