import React, { useEffect, useState } from 'react';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import TableRow from 'components/elements/TableRow/TableRow';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import FacilityModal from 'components/elements/FacilityModal/FacilityModal';
import InputComponent from 'components/elements/Input/Input';
import { Formik, Form } from "formik";
import * as yup from "yup";
import './CasesFacilitiesComponent.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaUser } from 'react-icons/fa';
import { BsFillClockFill } from 'react-icons/bs';
import moment from 'moment';

const ClosedFacilitiesComponent = (props) => {
    const { fetchingIncidences, filteredData, editFormComment } = props
    const [openModal, setOpenModal] = useState(false);
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [form, setForm] = useState(null)

    const rowClick = (id) => {
        const facilitySelected = filteredData.filter((data) => data.id === id)[0]
        setSelectedFacility(facilitySelected)
        setOpenModal(true);
    }

    const saveChanges = (values) => {
        const data = {
            comments: values.notes,
            id: selectedFacility.id
        }
        editFormComment(data)
        openModal(null)
    }

    return (
        <DashboardLayout
            topbarLeftComponent={
                <TopBarSearch
                    placeholder="Search pest incidences ..."
                    searchCallback={(value) => {
                        console.log('Closed Facilities value', value)
                    }}
                />
            }>

            <div className="users-header">
                <h1 >Closed Facility Cases</h1>
            </div>
            {
                fetchingIncidences ?
                    <SkeletonTable rounded /> :
                    filteredData.length ?
                        <TableOptions
                            rounded
                            rawData={filteredData}
                            hiddenColumns={['id', 'idIncidence', 'comments', 'bugImage']}
                            rowComponentProps={{ rowClick }}
                            isTable={true}
                            rowHeader={<TableHeader />}
                            rowComponent={<TableRow />} /> :
                        <div className="users-empty">No Users registered yet.</div>
            }

            <DetailsModal {...{ openModal, setOpenModal, form, setForm, selectedFacility, saveChanges }} />
        </DashboardLayout>
    )
}


const DetailsModal = ({ openModal, setOpenModal, form, setForm, selectedFacility, saveChanges }) => {

    const resetCard = () => {
        setOpenModal(false)
        setForm(null);
    }

    const handleModal = (values) => {

        saveChanges(values)
        setOpenModal(false)
        setForm(null);
    }
    return <FacilityModal
        isOpen={openModal}
        toggleModal={() => resetCard()}
        headerText="Closed Facility Case details"
        rightButtom="Finish"
        rightButtomClick={() => form.handleSubmit()}
    >
        <div className="modal-Facility-wrapper">
            <div className="modal-Facility-image-wrapper">
                <img src={require('assets/images/logo-white.png')} alt="bugImage" className={`bug-image background bigger`} />
                <div>
                    <div className="bug-infos">
                        <div className="info-icon">
                            <BsFillClockFill />
                        </div>
                        <div className="info">
                            Submitted: {selectedFacility?.date_submitted}
                        </div>
                    </div>
                    <div className="bug-infos">
                        <div className="info-icon" style={{ color: '#fff' }}>
                            <BsFillClockFill />
                        </div>
                        <div className="info close-date">
                            Closed: {moment().format('MM/DD/YYYY hh:mm A') //todo fix this when whe have an closed
                            }
                        </div>
                    </div>
                    <div className="bug-infos primary">
                        <div className="info-icon">
                            <img src={require('assets/icons/facility-green.png')} alt="bugIcon" />
                        </div>
                        <div className="info">
                            {selectedFacility?.type_of_problem || '-'}
                        </div>
                    </div>
                    <div className="bug-infos">
                        <div className="info-icon primary">
                            <HiOutlineLocationMarker />
                        </div>
                        <div className="info">
                            {selectedFacility?.location || '-'}
                        </div>
                    </div>
                    <div className="bug-infos">
                        <div className="info-icon">
                            <FaUser />
                        </div>
                        <div className="info ">
                            {selectedFacility?.submitted_by || '-'}
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-wrapper">
                <Formik
                    initialValues={{
                        resolution: '',
                        resolutionDetails: '',
                        notes: selectedFacility?.comments || '',
                    }}
                    validationSchema={yup.object().shape({
                        resolution: yup.string(),
                        resolutionDetails: yup.string(),
                        notes: yup.string(),
                    })}
                    onSubmit={(values) => saveChanges(values)}>
                    {(formik) => (
                        <Form>
                            <h3 className="infos-title">Review</h3>
                            {(form?.values?.resolution !== formik.values.resolution ||
                                form?.values?.resolutionDetails !== formik.values.resolutionDetails ||
                                form?.values?.notes !== formik.values.notes) && setForm(formik)}
                            <InputComponent
                                className="input-style "
                                label="Resolution"
                                type="textarea"
                                formik={formik}
                                id="resolution"
                                placeholder="include any resolution"
                            />
                            <InputComponent
                                className="input-style "
                                label="Resolution Details"
                                type="textarea"
                                formik={formik}
                                id="resolutionDetails"
                                placeholder="include any resolution details"
                            />
                            <InputComponent
                                className="input-style "
                                label="Additional Notes"
                                type="textarea"
                                formik={formik}
                                id="notes"
                                placeholder="include any additional notes"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </FacilityModal >
}


export default ClosedFacilitiesComponent;