import React, { useState, useEffect, useRef } from "react";
import MapComponent from "../components/MapComponent";
import { withRouter } from "react-router-dom";
import { getIncidencesService } from 'services/incidence';
import _ from 'lodash';
import moment from 'moment'

const Map = (props) => {
    const { history } = props;
    const incidences = useRef([]);
    const [visibleIncidences, setVisibleIncidences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState(new Date());
    const [endDateFilter, setEndDateFilter] = useState(new Date());

    useEffect(() => {
        getLastWeekIncidences();
    }, [])

    const getLastWeekIncidences = () => {
        const endDate = moment().add(5, 'hours').toDate();
        const startDate = moment(endDate).add(-1, 'days').toDate();
        setStartDateFilter(startDate);
        setEndDateFilter(endDate);

        getIncidences(startDate, endDate);
    }

    const getIncidences = async (startDate, endDate) => {
        setLoading(true);

        const data = await getIncidencesService({ startDate, endDate });

        const indicendesOrderedByDate = _.orderBy(data.myPopulatedIncidence, ['createdAt'], ['desc']);
        const dataParsed = indicendesOrderedByDate.filter((incidenceData) => (incidenceData.incidence.lat && incidenceData.incidence.long))
            .map(incidenceData => {
                const incidence = incidenceData.incidence || {};
                const username = `${incidence.user?.name || ''}${` ${incidence.user?.surname || ''}`}`;
                const usernameParsed = !username.replace(/\s/g, '').length ? 'Guest user' : username; // Check if contains only spaces

                return {
                    id: incidence.idIncidence,
                    urlImage: incidence.urlImage,
                    location: `${incidence?.lat?.toFixed(5)}, ${incidence?.long?.toFixed(5)}`,
                    username: usernameParsed,
                    bug: incidence.bug.name,
                    lat: incidence.lat,
                    long: incidence.long,
                    createdAt: incidence.createdAt
                }
            });

        incidences.current = dataParsed;

        setVisibleIncidences(dataParsed);

        setLoading(false);
    }

    const handleStartDateFilter = (newDate) => {
        let startDate = newDate;
        let endDate = endDateFilter;
        if (startDate > endDate) endDate = moment(startDate).add(7, 'days').toDate();
        setStartDateFilter(startDate);
        setEndDateFilter(endDate);

        getIncidences(startDate, endDate);
    }

    const handleEndDateFilter = (newDate) => {
        let startDate = startDateFilter;
        let endDate = newDate;
        if (startDate > endDate) startDate = moment(endDate).add(-7, 'days').toDate();
        setStartDateFilter(startDate);
        setEndDateFilter(endDate);

        getIncidences(startDate, endDate);
    }

    return <MapComponent
        visibleIncidences={visibleIncidences}
        history={history}
        startDateFilter={startDateFilter}
        handleStartDateFilter={handleStartDateFilter}
        endDateFilter={endDateFilter}
        handleEndDateFilter={handleEndDateFilter}
        loading={loading} />;
};

export default withRouter(Map);