import React, { useEffect, useState } from 'react';
import { emailValidate } from 'services/user';
import EmailVerificationComponent from '../component/EmailVerificationComponent';

const EmailVerification = (props) => {
    const {
        location: {
            search: token,
        },
    } = props;
    const [tokenSended, setTokenSended] = useState(false)

    useEffect(() => {
        sendToken()
    }, [])

    const sendToken = async () => {
        try {
            const resp = await emailValidate(token.split('?token=')[1])

            setTokenSended(true);
        }
        catch (e) {
            console.log('error updating', e);
        }
    }

    return <EmailVerificationComponent tokenSended={tokenSended} />

}

export default EmailVerification;