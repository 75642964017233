import React from 'react';
import './EmailVerificationComponent.scss';
import logo from "logo.png";

const EmailVerificationComponent = (props) => {
    const { tokenSended } = props

    return (
        <div className="verification-wrapper">
            <img src={logo} width="100" alt="logo" />
            <div className="confirmation-text">
                Your email has been verified, please log in using the app
                {/* {tokenSended ? 'Your email has been verified, please log in using the app' : ''} */}
            </div>
        </div>
    )
}

export default EmailVerificationComponent;