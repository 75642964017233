import React, { useState, useEffect, useRef } from "react";
import ZipcodesComponent from "../components/ZipcodesComponent";
import { withRouter } from "react-router-dom";
import { getZipcodesService, updateZipcodeService, createZipcodeService } from 'services/zipcode';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import _ from 'lodash';

momentDurationFormatSetup(moment);

const Zipcodes = (props) => {
    const { history } = props;
    const [zipcodes, setZipcodes] = useState([]);
    const [updatingZipcodes, setUpdatingZipcodes] = useState(false);
    const [fetchingZipcodes, setFetchingZipcodes] = useState(true);

    const zipCodesEntityId = useRef(null);

    useEffect(() => {
        getZipcodes();
    }, [])

    const getZipcodes = async () => {
        try {
            const data = await getZipcodesService();
            console.log('data', data);
            const zipcodes = data && data[0]?.list;
            zipCodesEntityId.current = data && data[0]?.id;
            setZipcodes(zipcodes);
        } catch (err) {
            console.log(err);
        }

        setFetchingZipcodes(false);
    }

    const updateZipcodes = async (newZipcodes) => {
        console.log('newZipcodes', newZipcodes);
        setUpdatingZipcodes(true);

        try {
            const zipcodesArray = newZipcodes.split(",");
            let data;
            if (zipCodesEntityId.current) data = await updateZipcodeService(zipCodesEntityId.current, { list: zipcodesArray });
            else data = await createZipcodeService({ list: zipcodesArray });
            console.log('response', data);
            await getZipcodes();
        } catch (err) {
            console.log(err);
        }

        setUpdatingZipcodes(false);
    }

    return <ZipcodesComponent
        zipcodes={zipcodes}
        history={history}
        fetchingZipcodes={fetchingZipcodes}
        updatingZipcodes={updatingZipcodes}
        updateZipcodes={updateZipcodes}
    />
};

export default withRouter(Zipcodes);