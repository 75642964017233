import React, { useState, useEffect, useRef } from 'react';
import PestIdComponent from '../components/PestIdComponent';
import moment from 'moment';
import { getIncidencesService } from 'services/incidence';
import { useDateRange } from 'contexts/dateRangeContext';
import { getSubspecieByIdService } from 'services/subspecies';
import { getScientificNameService } from 'services/scientificNames';
import { getExtractedIncidenceData } from 'components/utils/incidenceUtils';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from 'lodash';

const PestId = (props) => {
    const { user } = props;
    const [incidences, setIncidences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const { state: { dates: { dateStart, dateEnd } } } = useDateRange();
    const rawIncidences = useRef([]);

    useEffect(() => {
        getLastWeekIncidences();
    }, [])

    const getLastWeekIncidences = () => {
        getIncidences(dateStart, dateEnd);
    }

    const getIncidences = async (startDate, endDate) => {
        setLoading(true);
        const data = await getIncidencesService({ startDate: startDate || dateStart, endDate: endDate || dateEnd });
        rawIncidences.current = data.myPopulatedIncidence;

        setIncidences(data);
        setLoading(false);
    }

    const generateCsvData = async () => {
        const dataParsedSortedByDate = _.orderBy(rawIncidences.current, ['date'], ['desc']);

        let csvDataParsed = dataParsedSortedByDate.filter((data) => !data.incidence?.isDeleted).map(async (incidenceData) => {
            if (incidenceData.incidence.origin !== 'pestid') return;

            const incidence = incidenceData.incidence || {};
            const { username, os, emailShared, acceptedReview, confidence, weather, location, coordinates, } = getExtractedIncidenceData(incidenceData, user);
            const box = acceptedReview?.review?.multipleGeoPoint?.[0] || acceptedReview?.review?.geoPoint;

            let scientificName, subname;
            if (acceptedReview?.review?.scientificName) scientificName = await getScientificNameService(acceptedReview?.review?.scientificName);
            if (acceptedReview?.review?.commonSubmane) subname = await getSubspecieByIdService(acceptedReview?.review?.commonSubmane);

            const data = {
                id: incidence.idIncidence,
                errored: incidence?.errorIA ? "true" : "false",
                requested_review: emailShared ? "true" : "false",
                photo: incidence?.urlImage,
                souce: incidence?.source || "-",
                location: location,
                coordinates: coordinates,
                pest_prediction: incidence?.bug?.name,
                "%_confidence": confidence,
                threat: incidence?.bug?.dangerLevel,
                user_name: username || '-',
                os: os || '-',
                user_email: emailShared || incidence.user?.email || '-',
                user_zipcode: incidence.user?.zipcode || '-',
                created_at: moment(incidence.createdAt).format('DD/MM/YYYY HH:mm'),
                response_time: incidence.responseTime,
                r_common_name: acceptedReview?.species?.nameEn || "-",
                r_common_subname: subname?.nameEng || "-",
                r_scientific_name: scientificName?.name || "",
                r_instar: acceptedReview?.review?.instar || "-",
                r_population: acceptedReview?.review?.population || "-",
                r_box: box ? [[box.x0, box.y0], [box.x1, box.y1]] : "-",
                r_entomologist_name: acceptedReview?.reviewer?.name || "-",
                r_date: (acceptedReview?.review?.dateReview && moment(acceptedReview?.review?.dateReview).format('DD/MM/YYYY HH:mm')) || "-",
            }

            const weatherFields = ['main', 'description', 'temp', 'feels_like', 'temp_min', 'temp_max', 'pressure', 'humidity', 'sea_level', 'grnd_level', 'country', 'city', 'wind_speed', 'wind_deg'];
            if (weather) {
                weatherFields.forEach((key) => data[key] = weather[key] || "-");
            }
            return data;
        })

        csvDataParsed = (await Promise.all(csvDataParsed)).filter((x) => x !== undefined);

        const csvFormat = [];
        csvFormat.push(_.keys(csvDataParsed[0]));
        csvDataParsed.forEach(csvRow => {
            csvFormat.push(_.values(csvRow));
        });

        setCsvData(csvFormat);
    }


    return <PestIdComponent {...props} {...{ generateCsvData, incidences, loading, csvData }} />

}

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

export default withRouter(connect(mapStateToProps)(PestId));