import React, {useState, useRef} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import ButtonComponent from 'components/elements/Button/Button';
import InputComponent from 'components/elements/Input/Input';
import { ReactComponent as CheckCircleSVG } from 'assets/icons/checkcircle.svg';
import Loading from 'components/elements/Loading/Loading';
import { Alert } from '@mui/material';
import './CompanyForm.scss'

const CompanyForm = (props) => {
    const { submit, modalVisible, setModalVisible, companySelected } = props
    const [numPage, setNumPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const formRef = useRef()
    
    const initialValues = companySelected 
    ? {
        idCompany:  companySelected.idCompany,
        name: companySelected.name,
        mail: companySelected.email
    } 
    : {
        idCompany: '',
        name: '',
        mail: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        mail: Yup.string().email().required(),
    })

    const handleClose = (formik) => {
        setModalVisible(false)
        formik.resetForm()
        setNumPage(1)
    }

    const handleCloseAlert = () => {
        setShowAlert(false)
        setNumPage(1)
    }

    return (
        <>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
                setLoading(true)
                setNumPage(2)
                await submit(values)
            } catch (error) {
                setErrors({ submit: error.message })
                const message = error.error.statusCode === 409 ? error.error.message : 'Something went wrong, contact your administrator'
                setMessage(message)
                setShowAlert(true)
                setModalVisible(false)
                throw error
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        }}
        >
            {(formik) => (
                <Form>
                    <ModalLayout
                    maxWidth={'783px'}
                    maxHeight={'400px'}
                    isOpen={modalVisible}
                    toggleModal={() => handleClose(formik)}
                    footer={
                        <div className='modal-company-footer-content'>
                            { numPage === 1 ? (    
                                <>
                                <ButtonComponent className='modal-company-footer-button back' children={'Cancel'} onClick={()=> setModalVisible(false)}/>
                                <ButtonComponent
                                className='modal-company-footer-button next'
                                children={companySelected ? 'Save' : 'Add'} onClick={()=> formik.handleSubmit()}
                                disabled={!formik.dirty || !formik.isValid}
                                />
                                </>
                    
                            ): (
                                <ButtonComponent children='Ok' onClick={() => handleClose(formik)} />
                            ) }
                        </div>
                    } 
                    header={
                        <div className='modal-company-header-content'>
                            {numPage === 1 ? ( <h2>{companySelected ? 'Update company' : 'New company'}</h2>) : null} 
                                <div className='modal-company-header-button-close' onClick={() => handleClose(formik)}>
                                    X
                                </div>
                        </div>
                    }
                    children={
                        <div ref={formRef} className='modal-company-body-content'>
                            {numPage === 1 ? (
                                <>
                                <h3>Company name</h3>
                                <InputComponent 
                                formik={formik} 
                                id='name' 
                                name='name'
                                type='textarea'
                                placeholder={formik.values.name}
                                />
                                <h3>Company mail</h3>
                                <InputComponent 
                                formik={formik} 
                                id='mail' 
                                name='mail'
                                type='textarea'
                                placeholder={formik.values.mail}
                                />
                                </>
                            ) : null}
                            {numPage === 2 ? (
                                <div className='modal-company-body-content-success'>
                                    {loading ? (
                                        <Loading />
                                    ): (
                                        <>  
                                        <CheckCircleSVG />
                                        {companySelected ? <h2>Company updated</h2>   : <h2>Company created</h2> }                                                                                     
                                        </>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    }
                    />
                </Form>
            )}
        </Formik>
        <ModalLayout
        maxWidth={'783px'}
        maxHeight={'200px'}
        isOpen={showAlert}
        toggleModal={handleCloseAlert}
        footer={
            <div className='modal-trap-footer-content'>
                <ButtonComponent children='Ok' onClick={handleCloseAlert} />
            </div>
        } 
        children={
                <div ref={formRef} className='modal-trap-body-content'>
                    <Alert className='map-alert' onClose={handleCloseAlert} severity='error'>
                        {message}
                    </Alert>
                </div>
        }
        />
        </>
    )
}

export default CompanyForm