import React, { useState, useEffect, useMemo } from 'react'
import { endpoints } from 'services/endpoints';
import { createOrUpdateMessageService, dataURLtoFile, getMessagesService, testFormData, uploadBase64Service, uploadImageService } from 'services/messagesTemplate';
import SendMessageModalComponent from '../components/SendMessageModalComponent';

const SendMessageModal = (props) => {
    const { onConfirm, specieData } = props;
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const initialMessage = useMemo(async () => {
        if (specieData?.id) {
            const res = await getMessagesService({ specieId: specieData?.id });
            setMessage(res?.text || "");
        }
        else {
            setMessage("")
        }
        return null;
    }, [specieData?.id])

    const submit = async (text) => {
        try {
            setLoading(true);

            const div = document.createElement("div");
            div.innerHTML = text;

            const images = div.getElementsByTagName("img");

            for (let i = 0; i < images.length; i++) {
                let currImage = images[i].src;
                if (currImage.includes("base64")) {
                    const url = await uploadBase64Service(currImage);
                    images[i].src = url;
                }
            }

            const res = await createOrUpdateMessageService({ specieId: specieData?.id, text: `${div.innerHTML}` });
            onConfirm();
        }
        catch (e) {
            console.log('error submit', e);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <SendMessageModalComponent {...props}
            {...{ submit, loading, message, setMessage }}
        />
    )
}
export default SendMessageModal;