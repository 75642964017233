
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getTraps = async () => {
    const filter = {where: {active: true}, order: 'createdAt DESC'}
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/traps?filter=${JSON.stringify(filter)}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getTrapsByCompany = async (idCompany) => {
    const filter = {where: {active: true, idCompany: idCompany}, order: 'createdAt DESC'}
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/traps?filter=${JSON.stringify(filter)}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getTrapsTypes = async () => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/trapTypes`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const createTrap =  async (bodyReq) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/traps`, {
            method: "POST",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const updateTrap =  async (bodyReq, idTrap) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/traps/${idTrap}`, {
            method: "PATCH",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const deleteTrap = async (idTrap) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/traps/${idTrap}`, {
            method: "DELETE",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getTrapById = async (idTrap) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/traps/${idTrap}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getTrapsInRoute = async (idRoute) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/routes/${idRoute}/traps`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getTypeTrapById =  async (idTypeTrap) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/trapTypes/${idTypeTrap}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};