import React, { useState, useEffect, isValidElement, cloneElement } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faSort } from '@fortawesome/free-solid-svg-icons';
import { HiChevronRight, HiChevronDoubleRight, HiChevronLeft, HiChevronDoubleLeft } from 'react-icons/hi'
import { onPageChange, paginateData, clickHeaderToSort } from 'services/paginationUtils'
import _ from 'lodash';
import "./TableOptions.scss";

const TableOptions = (props) => {
    const { rawData, sortableColumns, rowHeader, rowComponent, rowComponentProps, hiddenColumns, isTable, columnsClassName, rounded } = props;

    // Table pagination and sort options
    const [sortedData, setSortedData] = useState([]);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentSortColumn, setCurrentSortColumn] = useState({ column: '', sortAsc: true });

    // Table header
    let headerElement = '';

    if (rowHeader && isValidElement(rowHeader)) {
        headerElement = cloneElement(rowHeader, {
            ...rowComponentProps,
            data: rawData[0],
            hiddenColumns
        })
    }

    useEffect(() => {
        if (currentSortColumn.column && sortedData.length) {
            paginateData(sortedData, setTotalPages, setCurrentPageData, setSortedData, setCurrentPage, currentPage);
        }
    }, [sortedData])

    useEffect(() => {
        paginateData(rawData, setTotalPages, setCurrentPageData, setSortedData, setCurrentPage, currentPage);
    }, [rawData])

    const renderTableContent = () => (
        <>
            {rowHeader && headerElement}

            {
                _.map(currentPageData, (rowData, i) => {
                    let elementToRender = '';

                    if (isValidElement(rowComponent)) {
                        elementToRender = cloneElement(rowComponent, {
                            ...rowComponentProps,
                            data: rowData,
                            key: i,
                            dataClickCallback: rowData.id,
                            hiddenColumns
                        })
                    }

                    return elementToRender;
                })
            }
        </>
    )

    return (
        <div className="table-content">
            {
                sortableColumns &&
                <div className="table-filters">
                    <div className={`table-sort ${columnsClassName}`}>
                        {
                            sortableColumns.map((sortableColumn, i) => {
                                const columnStyle = sortableColumn.width ? { width: sortableColumn.width } : { flex: 1 };
                                return (
                                    <div className={`sort-option ${sortableColumn.column}`} style={{ ...columnStyle, ...sortableColumn.style }} key={i} onClick={() => clickHeaderToSort(sortableColumn.column, currentSortColumn, setCurrentSortColumn, rawData, setSortedData)}>
                                        <span>{sortableColumn.column.split("_").join(" ")}</span>
                                        {
                                            currentSortColumn.column === sortableColumn.column ?
                                                (
                                                    currentSortColumn.sortAsc ?
                                                        <FontAwesomeIcon icon={faSortDown} /> :
                                                        <FontAwesomeIcon icon={faSortUp} />
                                                ) :
                                                <FontAwesomeIcon icon={faSort} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }

            <div className={`table-content-body ${rounded && 'rounded'}`}>
                {
                    isTable ?
                        <table>
                            {renderTableContent()}
                        </table> :
                        renderTableContent()
                }
            </div>

            <div className="table-pagination">
                <div className="pagination">
                    <div className="change-page" onClick={() => onPageChange(1, totalPages, sortedData, setCurrentPageData, setCurrentPage)}>
                        <HiChevronDoubleLeft style={{ fontSize: 15 }} />
                    </div>

                    <div className="change-page" onClick={() => onPageChange(currentPage - 1, totalPages, sortedData, setCurrentPageData, setCurrentPage)}>
                        <HiChevronLeft style={{ fontSize: 17 }} />
                    </div>

                    <span>{currentPage} of {totalPages} pages</span>

                    <div className="change-page" onClick={() => onPageChange(currentPage + 1, totalPages, sortedData, setCurrentPageData, setCurrentPage)}>
                        <HiChevronRight style={{ fontSize: 17 }} />
                    </div>

                    <div className="change-page" onClick={() => onPageChange(totalPages, totalPages, sortedData, setCurrentPageData, setCurrentPage)}>
                        <HiChevronDoubleRight style={{ fontSize: 15 }} />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TableOptions;