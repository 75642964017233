import React, { useState, useEffect } from "react";
import PestsComponent from "../components/PestsComponent";
import { withRouter } from "react-router-dom";
import { getPestControllersService, updatePestControllerService } from 'services/pestController';
import _ from "lodash";

const Pests = (props) => {
    const { history } = props;
    const [pestControllers, setPestControllers] = useState([]);
    const [fetchingPestControllers, setFetchingPestControllers] = useState(false);
    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        setFetchingPestControllers(true);
        getPestControllers();
    }, [])

    const getPestControllers = async () => {
        const data = await getPestControllersService();
        const dataParsed = data.map(pestController => {
            const pestControllerSkills = pestController.skills.map(skill => skill.name).join(", ");

            return {
                id: pestController.id,
                name: `${pestController.name || ''} ${pestController.lastName || ''}`,
                location: pestController.city ? `${pestController.city} (${pestController.country})` : "No location",
                type_of_services: pestControllerSkills || 'No type of services',
                average_rating: pestController.stars || 'No reviews',
            }
        })

        setPestControllers(dataParsed);
        setFetchingPestControllers(false);
    }

    const updateRole = async (id) => {
        console.log(' id', id);
        try {
            setLoading(true);
            const res = await updatePestControllerService({ role: "Reviewer" }, id);
        }
        catch (e) {
            console.log('error updateRole', e);
        }
        finally {
            setLoading(false);
        }
    }

    const generateCsvData = async () => {
        const csvDataParsed = pestControllers;
        const csvFormat = [];
        csvFormat.push(_.keys(csvDataParsed[0]));
        csvDataParsed.forEach(csvRow => {
            csvFormat.push(_.values(csvRow));
        });

        setCsvData(csvFormat);
    }

    return <PestsComponent
        pestControllers={pestControllers}
        history={history}
        fetchingPestControllers={fetchingPestControllers}
        updateRole={updateRole}
        generateCsvData={generateCsvData}
        csvData={csvData}
    />;
};

export default withRouter(Pests);