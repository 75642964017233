import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ResetPasswordComponent from "../components/ResetPasswordComponent";
import { resetPasswordService } from "services/user"

const ResetPassword = (props) => {
    const { history, location } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const token = location.search.split("?token=")[1];

    const submit = async (values) => {
        setLoading(true);

        try {
            const data = {
                newPassword: values.password,
                resetPasswordToken: token
            };

            await resetPasswordService(data);
            setSuccess(true);
        } catch ({ error }) {
            setError(error.message);
        }

        setLoading(false);
    }

    return (<ResetPasswordComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        history={history}
        success={success}
    />);
}

export default withRouter(ResetPassword);