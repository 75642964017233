import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken, getUserId } from './utils';
import moment from "moment";

export const getReviewsService = async (token) => {
    try {
        let response = await customFetch(endpoints.reviews, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const updateReviewStatusService = async (data) => {
    const token = getUserToken();
    const idUser = getUserId();

    data = {
        ...data,
        idUser
    }

    try {
        let response = await customFetch(`${endpoints.reviews}/${data.idReview}/changeStatus`, {
            method: "PUT",
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
