import React, { useEffect, useState } from 'react';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import FacilityModal from 'components/elements/FacilityModal/FacilityModal';
import TableRow from 'components/elements/TableRow/TableRow';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { BsFillClockFill } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { Formik, Form } from "formik";
import * as yup from "yup";
import InputComponent from 'components/elements/Input/Input';
import OptionCard from 'components/elements/OptionCard/OptionCard';
import './CasesFacilitiesComponent.scss';

const OpenFacilitiesComponent = (props) => {
    const { fetchingIncidences, filteredData, loading, editFormComment } = props
    const [selectedFacility, setSelectedFacility] = useState()
    const [form, setForm] = useState()
    const [modalSteps, setModalSteps] = useState(null)
    const [seccondStepSelectedCard, setSeccondStepSelectedCard] = useState()

    const saveChanges = (values) => {
        const data = {
            comments: values.notes,
            id: selectedFacility.id
        }
        editFormComment(data, 'facility')
        setModalSteps(null)
    }

    const rowClick = (id) => {
        const facilitySelected = filteredData.filter((data) => data.id === id)[0]
        setSelectedFacility(facilitySelected)
        setModalSteps('1');
    }

    return (
        <DashboardLayout
            topbarLeftComponent={
                <TopBarSearch
                    placeholder="Search pest incidences ..."
                    searchCallback={(value) => {
                        console.log('Open Facilities value', value)
                    }}
                />
            }>

            <div className="users-header">
                <h1 >Open Facilities Cases</h1>
            </div>
            {
                fetchingIncidences ?
                    <SkeletonTable rounded /> :
                    filteredData.length ?
                        <TableOptions
                            rounded
                            rawData={filteredData}
                            hiddenColumns={['id', 'idIncidence', 'comments', 'bugImage']}
                            rowComponentProps={{ rowClick }}
                            isTable={true}
                            rowHeader={<TableHeader />}
                            rowComponent={<TableRow />} /> :
                        <div className="users-empty">No Facilities registered yet.</div>
            }

            <FirstStepModal {...{ modalSteps, setModalSteps, form, loading, selectedFacility, saveChanges, setForm }} />

            <SeccondStepModal {...{ modalSteps, setModalSteps, loading, seccondStepSelectedCard, setSeccondStepSelectedCard }} />

            <ThirdStepModal {...{ modalSteps, setModalSteps, seccondStepSelectedCard, setSeccondStepSelectedCard, form, setForm }} />
        </DashboardLayout>
    )
}

const FirstStepModal = ({ modalSteps, setModalSteps, form, loading, selectedFacility, saveChanges, setForm }) => {

    return <FacilityModal
        isOpen={modalSteps === '1'}
        toggleModal={() => setModalSteps(null)}
        headerText="Open Facility Case details"
        leftButtom="Save"
        leftButtomClick={() => form.handleSubmit()}
        leftButtonLoading={loading}
        rightButtom="Resolve"
        rightButtomClick={() => setModalSteps('2')}
    >
        <div className="modal-Facility-wrapper">
            <div className="modal-Facility-image-wrapper">
                <img src={selectedFacility?.bugImage ? selectedFacility?.bugImage : require('assets/images/logo-white.png')} alt="bugImage" className={`bug-image ${!selectedFacility?.bugImage && 'background'}`} />
                <div>
                    <div className="bug-infos">
                        <div className="info-icon">
                            <BsFillClockFill />
                        </div>
                        <div className="info">
                            {selectedFacility?.date_submitted}
                        </div>
                    </div>
                    <div className="bug-infos primary">
                        <div className="info-icon">
                            <img src={require('assets/icons/facility-green.png')} alt="bugIcon" />
                        </div>
                        <div className="info">
                            {selectedFacility?.type_of_problem || '-'}
                        </div>
                    </div>
                    <div className="bug-infos">
                        <div className="info-icon primary">
                            <HiOutlineLocationMarker />
                        </div>
                        <div className="info">
                            {selectedFacility?.location || '-'}
                        </div>
                    </div>
                    <div className="bug-infos">
                        <div className="info-icon">
                            <FaUser />
                        </div>
                        <div className="info ">
                            {selectedFacility?.submitted_by || '-'}
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-wrapper">
                <Formik
                    initialValues={{ notes: selectedFacility?.comments || '' }}
                    validationSchema={yup.object().shape({
                        notes: yup.string(),
                    })}
                    onSubmit={(values) => saveChanges(values)}>
                    {(formik) => (
                        <Form>
                            {form?.values?.notes !== formik.values.notes && setForm(formik)}
                            <InputComponent
                                label="Notes"
                                type="textarea"
                                formik={formik}
                                id="notes"
                                placeholder="include any notes"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </FacilityModal>
}

const SeccondStepModal = ({ modalSteps, setModalSteps, seccondStepSelectedCard, setSeccondStepSelectedCard }) => {

    const handleModal = () => {
        if (seccondStepSelectedCard === 'norisk') {
            setModalSteps('3')
        }
        if (seccondStepSelectedCard === 'risk') {
            setModalSteps('4')
        }
    }

    const resetCard = () => {
        setModalSteps(null)
        setSeccondStepSelectedCard(null);
    }

    return <FacilityModal
        isOpen={modalSteps === '2'}
        toggleModal={() => resetCard()}
        headerText="What was the resolution?"
        leftButtom="Cancel"
        leftButtomClick={() => resetCard()}
        rightButtom="Continue"
        rightButtomClick={() => handleModal()}
    >
        <div className="resolution-modal-wrapper">
            <div className="card-wrapper">
                <OptionCard
                    focused={seccondStepSelectedCard === 'norisk'}
                    onClick={() => setSeccondStepSelectedCard('norisk')}
                    backgroundColor="#50e3c220"
                    description="Submission was determined to not be a pest risk"
                />
                <OptionCard
                    focused={seccondStepSelectedCard === 'risk'}
                    onClick={() => setSeccondStepSelectedCard('risk')}
                    backgroundColor="#00000010"
                    icon={<img src={require('assets/icons/facilityIssue.png')} alt="riskIcon" className="risk-icon" />}
                    description="Facility issue was addressed"
                />
            </div>
        </div>
    </FacilityModal >
}

const ThirdStepModal = ({ modalSteps, setModalSteps, setSeccondStepSelectedCard, form, setForm }) => {
    const modalHeader = modalSteps === '3' ? 'Any additional notes?' : 'How was the issue addressed?';

    const handleModal = (values) => {

        if (modalSteps === '3') {
            console.log('missing implementation "3"');
        } else {
            console.log('missing implementation "4"');
        }
        setModalSteps(null);
        resetCard();
    }

    const resetCard = () => {
        setModalSteps(null)
        setSeccondStepSelectedCard(null);
    }
    return <FacilityModal
        isOpen={['3', '4'].includes(modalSteps)}
        toggleModal={() => resetCard()}
        headerText={modalHeader}
        leftButtom="Back"
        leftButtomClick={() => setModalSteps('2')}
        rightButtom="Finish"
        rightButtomClick={() => form.handleSubmit()}
    >
        <div className="last-modal-wrapper">
            <div className="input-wrapper">
                <Formik
                    initialValues={{ notes: '' }}
                    validationSchema={yup.object().shape({
                        notes: yup.string(),
                    })}
                    onSubmit={(values) => handleModal(values)}>
                    {(formik) => (
                        <Form>
                            {form?.values?.notes !== formik.values.notes && setForm(formik)}
                            <InputComponent
                                label="Notes"
                                type="textarea"
                                formik={formik}
                                id="notes"
                                placeholder="include any notes"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </FacilityModal >
}

export default OpenFacilitiesComponent;