
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getAllCompanies = async () => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/companies`, {
            method: "GET",
            token: getUserToken()
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getUsersCompanyById = async (idCompany) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/companies/${idCompany}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const createCompany =  async (bodyReq) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/companies`, {
            method: "POST",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const updateCompany =  async (bodyReq, idCompany) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/companies/${idCompany}`, {
            method: "PUT",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const deleteCompany= async (idCompany) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/companies/${idCompany}`, {
            method: "DELETE",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};
