import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import "./SkeletonReviewCard.scss";

const SkeletonReviewCard = props => {
    const [screenDimensions, setScreenDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

    const updateWindowDimensions = () => {
        setScreenDimensions({ width: window.innerWidth, height: window.innerHeight });
    }

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);

        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, [])

    return (
        <div className="skeleton-review-card">
            <div className="review-picture">
                <Skeleton width={40} height={40} circle={true} />
            </div>
            <div className="review-name">
                <Skeleton width={70} height={12} />
            </div>
            <div className="review-message">
                <Skeleton width={screenDimensions.width > 768 ? 400 : 150} height={12} />
            </div>
            <div className="review-stars">
                {
                    _.times(5, (i) => (
                        <FontAwesomeIcon key={i} icon={faStar} />
                    ))
                }
            </div>
            <div className="review-actions">
                <Skeleton width={30} height={30} />
                <Skeleton width={30} height={30} />
            </div>
        </div>
    )
}

export default SkeletonReviewCard;