import React, { useState, useRef } from "react";
import { GoogleMap, LoadScript, Marker, OverlayView } from '@react-google-maps/api';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faUser, faClock, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import moment from 'moment';
import _ from 'lodash';
import { Element, scroller } from 'react-scroll'
import { Lightbox } from "react-modal-image";

import "./MapComponent.scss";
import "react-datepicker/dist/react-datepicker.css";
import { LocationIncidence, LocationIncidenceSkeleton } from "components/elements/LocationIncidence/LocationIncidence";

const defaultIncidenceEntity = {
    id: null,
    lat: 0,
    long: 0,
    bug: null,
    username: '',
    location: '',
}

const MapMarker = props => {
    const { incidence, selectedIncidence, setSelectedIncidence, setSelectedIncidenceByList } = props;
    const selectedMarker = selectedIncidence?.id === incidence.id;

    return (
        <Marker
            onClick={() => {
                setSelectedIncidence(incidence);
                setSelectedIncidenceByList(false);
                scroller.scrollTo(incidence.id, {
                    duration: 500,
                    delay: 0,
                    smooth: true,
                    containerId: 'map-incidences',
                })
            }}
            cursor="pointer"
            icon={require('assets/images/map-marker.png')}
            opacity={selectedMarker ? 1 : 0.6}
            position={{ lat: incidence.lat, lng: incidence.long }} />
    )
}

const MapMarkerDetails = (props) => {
    // Obs.: using invisible class to workaround a still not merged PR that "getPixelPositionOffset" not works in 
    // first rendering (wrong positioning) - https://github.com/JustFly1984/react-google-maps-api/issues/882

    const centerOverlayView = (width, height) => {
        return {
            x: 30,
            y: -(height) + 25,
        }
    }

    const { selectedIncidence: { id, urlImage, lat, long, username, bug, location, createdAt } } = props;

    return (
        <OverlayView
            position={{ lat, lng: long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={centerOverlayView}>
            <div className={id ? "map-overlay-view" : "map-overlay-view invisible"}>
                <div className="overlay-title">
                    <img src={require('assets/icons/location-pin.png')} alt="" />
                    <span>{location}</span>
                </div>

                <div className="incidence-content">
                    <div className="incidence-infos">
                        <div className="overlay-item">
                            <div className="overlay-item__icon">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <span>{username}</span>
                        </div>

                        <div className="overlay-item">
                            <div className="overlay-item__icon">
                                <FontAwesomeIcon icon={faBug} />
                            </div>
                            <span>{bug}</span>
                        </div>

                        <div className="overlay-item">
                            <div className="overlay-item__icon">
                                <FontAwesomeIcon icon={faClock} />
                            </div>
                            <span>{moment(createdAt).format("MM/DD/YYYY LT")}</span>
                        </div>
                    </div>

                    <div className="incidence-picture">
                        <img src={urlImage} alt="incidence-bug" />
                    </div>
                </div>
            </div>
        </OverlayView >
    )
}

const MapComponent = (props) => {
    const { visibleIncidences, startDateFilter, handleStartDateFilter, endDateFilter, handleEndDateFilter, loading } = props;
    const [isPictureModalVisible, setIsPictureModalVisible] = useState(false);
    const [pictureModalImage, setPictureModalImage] = useState(null);
    const [showMobileIncidencesList, setShowMobileIncidencesList] = useState(false);
    const [selectedIncidence, setSelectedIncidence] = useState(defaultIncidenceEntity);
    const [selectedIncidenceByList, setSelectedIncidenceByList] = useState(false);
    const [mapCenter, setMapCenter] = useState({
        lat: 37.7581337,
        lng: -122.4119373
    })
    const mapRef = useRef(null);

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const handleLoad = (map) => {
        mapRef.current = map;
    }

    const handleCenterChanged = () => {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setSelectedIncidenceByList(false);
        setMapCenter(newPos);
    }

    return (
        <DashboardLayout>
            <div className="map-wrapper">
                <div id="map-incidences" className={showMobileIncidencesList ? "map-incidences" : "map-incidences list-invisible"}>
                    <div className="mobile-toggle-incidences" onClick={() => setShowMobileIncidencesList(!showMobileIncidencesList)}>
                        <span>Show incidences list</span>
                        <FontAwesomeIcon icon={showMobileIncidencesList ? faChevronUp : faChevronDown} />
                    </div>

                    <div className="incidences-list">
                        {loading ? null : <div className="incidencesNumber">{visibleIncidences.length} registered incidences</div>}
                        {
                            loading ?
                                _.times(3, (i) => (
                                    <LocationIncidenceSkeleton key={i} />
                                )) :
                                visibleIncidences.length ?
                                    _.map(visibleIncidences, (incidence, i) => (
                                        <>
                                            <Element name={incidence.id} key={i}>
                                                <LocationIncidence
                                                    incidence={incidence}
                                                    selectedIncidence={selectedIncidence?.id === incidence.id}
                                                    setSelectedIncidenceByList={setSelectedIncidenceByList}
                                                    setPictureModalImage={setPictureModalImage}
                                                    setIsPictureModalVisible={setIsPictureModalVisible}
                                                    onClick={() => {
                                                        setSelectedIncidence(incidence);
                                                        setSelectedIncidenceByList(true);
                                                        setShowMobileIncidencesList(false);
                                                    }} />
                                            </Element>
                                        </>
                                    )) :
                                    null
                        }
                    </div>
                </div>
                <div className="map-container">
                    <LoadScript googleMapsApiKey="AIzaSyBTBtSShkECf6YgHMLDgiJv1zMFiVtOE5U">
                        <GoogleMap
                            onLoad={handleLoad}
                            onDragEnd={handleCenterChanged}
                            mapContainerStyle={containerStyle}
                            center={selectedIncidenceByList ? { lat: selectedIncidence.lat, lng: selectedIncidence.long } : mapCenter}
                            zoom={13}
                            onClick={(e) => {
                                setSelectedIncidence(defaultIncidenceEntity);
                                setSelectedIncidenceByList(false);
                            }}
                            options={{
                                styles: [
                                    {
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#f5f5f5"
                                            }
                                        ]
                                    },
                                    {
                                        "elementType": "labels.icon",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    },
                                    {
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#616161"
                                            }
                                        ]
                                    },
                                    {
                                        "elementType": "labels.text.stroke",
                                        "stylers": [
                                            {
                                                "color": "#f5f5f5"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#bababa"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative",
                                        "elementType": "labels.text.stroke",
                                        "stylers": [
                                            {
                                                "color": "#ffffff"
                                            },
                                            {
                                                "weight": 1
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative.land_parcel",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#bdbdbd"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#eeeeee"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#757575"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi.park",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#e5e5e5"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi.park",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#9e9e9e"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#ffffff"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.arterial",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#757575"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#dadada"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#616161"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.local",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#9e9e9e"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "transit.line",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#e5e5e5"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "transit.station",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#eeeeee"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "water",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#c9c9c9"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "water",
                                        "elementType": "geometry.fill",
                                        "stylers": [
                                            {
                                                "color": "#cad2d3"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "water",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#9e9e9e"
                                            }
                                        ]
                                    }
                                ],
                                streetViewControl: false,
                                fullscreenControl: false,
                                mapTypeControlOptions: {
                                    mapTypeIds: []
                                },
                                clickableIcons: false
                            }}>

                            <div className="date-filter">
                                <div className="date-filter-label">Date range filter:</div>

                                <DatePicker
                                    showTimeSelect
                                    selected={startDateFilter}
                                    onChange={handleStartDateFilter}
                                    className="map-datepicker"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    popperModifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                        },
                                    }}
                                />

                                <DatePicker
                                    showTimeSelect
                                    selected={endDateFilter}
                                    onChange={handleEndDateFilter}
                                    className="map-datepicker"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    popperModifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                        },
                                    }}
                                />
                            </div>

                            {
                                _.map(visibleIncidences, (incidence, i) => (
                                    <MapMarker
                                        key={i}
                                        incidence={incidence}
                                        selectedIncidence={selectedIncidence}
                                        setSelectedIncidence={setSelectedIncidence}
                                        setSelectedIncidenceByList={setSelectedIncidenceByList}
                                    />
                                ))
                            }

                            {
                                selectedIncidence &&
                                <MapMarkerDetails
                                    selectedIncidence={selectedIncidence}
                                />
                            }

                        </GoogleMap>
                    </LoadScript>
                </div>
            </div>

            {
                isPictureModalVisible &&
                <Lightbox
                    medium={pictureModalImage}
                    alt="Incidence bug image"
                    onClose={() => setIsPictureModalVisible(false)}
                />
            }
        </DashboardLayout>
    );
};

export default MapComponent;