import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getSubspecieService = async (token) => {
    try {
        let response = await customFetch(endpoints.subspecies, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const addSubspecieService = async (data) => {
    const token = getUserToken();
    
    try {
        let response = customFetch(`${endpoints.subspecies}`, {
            method: "POST",
            token,
            bodyReq: data
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const updateSubspecieService = async (id, data) => {
    const token = getUserToken();

    try {
        let response = customFetch(`${endpoints.subspecies}/${id}`, {
            method: "PATCH",
            token,
            bodyReq: data
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteSubspecieService = async (id) => {
    const token = getUserToken();

    try {
        let response = customFetch(`${endpoints.subspecies}/${id}`, {
            method: "DELETE",
            token
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getSubspecieByIdService = async (id) => {
    const token = getUserToken();

    try {
        let response = customFetch(`${endpoints.subspecies}/${id}`, {
            method: "GET",
            token
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        console.log('err', err);
    }
};