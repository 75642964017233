import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import React, { useState, useEffect } from 'react'
import Loading from 'components/elements/Loading/Loading'
import SkeletonBuildingView from 'components/elements/BuildingElements/SkeletonBuildingView';

const HeatMapServiceComponent = (props) => {
    const { loading, locations} = props
    const [selectedLocation, setSelectedLocation] = useState({})
    const [selectedRoute, setSelectedRoute] = useState({})

    useEffect(() => {
        setSelectedLocation(locations[0])
    }, [locations])
    
    useEffect(() => {
        if (selectedLocation?.routes?.length > 0) {
            setSelectedRoute(selectedLocation?.routes[0])
        }
    }, [selectedLocation])
    
    return (
        <DashboardLayout>
            { loading ? (
                <div className='building-wrapper-loading'>
                    <Loading />
                </div>
            ) : (
                <SkeletonBuildingView
                setSelectedLocation={setSelectedLocation}
                selectedLocation={selectedLocation}
                setSelectedRoute={setSelectedRoute}
                locations={locations}
                selectedRoute={selectedRoute}
                title='Heat map'
                heatMap={true}
                />
            )}
        </DashboardLayout>
    )
}

export default HeatMapServiceComponent