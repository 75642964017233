import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import firebase from 'firebase/app'
// import "firebase/firestore";
// import "firebase/storage";

//dev
// const firebaseConfig = {
//     apiKey: "AIzaSyBoZlw2_N6u-4aLIf81seld0NdU9WPmsT4",
//     authDomain: "extrasensedev.firebaseapp.com",
//     projectId: "extrasensedev",
//     storageBucket: "extrasensedev.appspot.com",
//     messagingSenderId: "362289824340",
//     appId: "1:362289824340:web:4b500b2d3739ac549063c1"
// };
//pro
const firebaseConfig = {
    apiKey: "AIzaSyB568rvZMFA8RXONv3RIexLRxx8FiA-vxc",
    authDomain: "extrasense-pro.firebaseapp.com",
    projectId: "extrasense-pro",
    storageBucket: "extrasense-pro.appspot.com",
    messagingSenderId: "618892130634",
    appId: "1:618892130634:web:123afa9ea2eb86f4af69b3"
};

firebase.initializeApp(firebaseConfig);

export const firebaseApp = firebase;
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
// export const storage = firebase.storage();
// export const storageRef = storage.ref();
export const db = firebaseApp.firestore();
