import React, { useEffect } from 'react'
import CompaniesComponent from '../components/CompaniesComponent'
import { createCompany, updateCompany, deleteCompany, getAllCompanies } from "services/companies"

export const Companies = () => {
  const [companies, setCompanies] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    getCompanies()
  }, []) 

  const fetchCompanies = async () => {
    try {
      const data = await getAllCompanies()
      return data
    } catch(e) {
      console.error(e)
      throw new Error('error getting companies')
    }
  }

  const getCompanies = async () => {
    try {
      setLoading(true)
      const data = await fetchCompanies()
      setCompanies(data)
    } catch(e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const submit = async (values) => {
    try {
      let body = {
          name: values.name,
          email: values.mail
      }
      const newCompany = await createCompany(body)
      setCompanies((prevCompanies) => {
          return [newCompany, ...prevCompanies ]
      })
    } catch(error) {
      console.error(error)
      throw error
    }
  }

  const update = async (values) => {
    try {
      let body= {
          idCompany: values.idCompany,
          name: values.name,
          email: values.mail
      }
      await updateCompany(body, values.idCompany)
      const dataUpdated = await fetchCompanies()
      const updatedCompany = dataUpdated.find(company => company.idCompany === values.idCompany)
      setCompanies((prevCompanies) => {
          const index = prevCompanies.findIndex(company => company.idCompany === values.idCompany)
          return [
            ...prevCompanies.slice(0, index),
            updatedCompany,
            ...prevCompanies.slice(index + 1)
          ]
        })
    } catch(error) {
      console.error(error)
      throw error
    }
  }

  const eliminate = async (idCompany) => {
    try {
      await deleteCompany(idCompany)
      setCompanies(prevCompanies => prevCompanies.filter(company => company.idCompany !== idCompany))
    } catch(error) {
        console.error(error)
        alert("Something went wrong, contact your administrator")
    }
  }
 
  return (
    <CompaniesComponent companies={companies} loading={loading} submit={submit} update={update} eliminate={eliminate}/>
  )
}

export default Companies