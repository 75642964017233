import React from 'react';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import ButtonComponent from 'components/elements/Button/Button';
import './TrapImageViewer.scss'
import defaultImage  from '../../../assets/images/no-trap-image.svg'

const TrapImageViewer = (props) => {
   const {viewerVisible, setViewerVisible, trap } = props
   
    return (
        <ModalLayout
        maxWidth={'500px'}
        maxHeight={'500px'}
        isOpen={viewerVisible}
        toggleModal={() => setViewerVisible(false)}
        footer={
          <div className='viewer-trap'>
            <ButtonComponent children='Close' onClick={() => setViewerVisible(false)} /> 
          </div>
        } 
        header={
          <div>
            <div className='viewer-trap-header-button-close' onClick={() => setViewerVisible(false)}>
                X
            </div>
          </div>
        }
        children={
                <div className='viewer-trap-body'>
                    <h2>{trap.trap.name}</h2>
                    <img src={trap.trap.img || defaultImage } alt="Selected trap" />  
                </div>
        }
        />
    )
}

export default TrapImageViewer