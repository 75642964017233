import React from "react";
import Button from "../../elements/Button/Button";
import Input from "../../elements/Input/Input";
import { Formik, Form } from "formik";
import * as yup from "yup";
import logo from '../../../logo.png';
import './Register.scss';
import Presentation from "components/elements/Presentation/Presentation";

const RegisterComponent = props => {
    const { error, submit, loading, history } = props;

    return (
        <div className="registerComponent">
            <Presentation />

            <div className="formContainer">
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        // phone: "", 
                        password: "",
                        confirmPassword: ""
                    }}
                    validationSchema={yup.object().shape({
                        name: yup.string().required("Name is a required field"),
                        email: yup.string().email("Invalid email format").required("Email is a required field"),
                        // phone: yup.string().required("Phone is a required field"),
                        password: yup
                            .string()
                            .min(8)
                            .required("Password is a required field"),
                        confirmPassword: yup
                            .string()
                            .oneOf([yup.ref('password'), null], "Passwords must match")
                            .required("Confirm password is a required field"),
                    })}
                    onSubmit={(values) => submit(values)}>
                    {(formik) => (
                        <>
                            <Form>
                                <Input
                                    id="name"
                                    formik={formik}
                                    type="text"
                                    label="Company name*"
                                    className="formControl"
                                />
                                <Input
                                    id="email"
                                    formik={formik}
                                    type="text"
                                    label="Email*"
                                    className="formControl"
                                />

                                {/* <Input
                                    id="phone"
                                    formik={formik}
                                    type="text"
                                    mask="(999) 999-9999"
                                    label="Phone*"
                                    className="formControl"
                                /> */}

                                <Input
                                    id="password"
                                    formik={formik}
                                    type="password"
                                    label="Password*"
                                    className="formControl"
                                />
                                <Input
                                    id="confirmPassword"
                                    formik={formik}
                                    type="password"
                                    label="Confirm password*"
                                    className="formControl"
                                />

                                {
                                    error ?
                                        <div className="error">{error}</div> :
                                        null
                                }

                                <Button
                                    loading={loading}
                                    type="submit"
                                    onClick={formik.handleSubmit}>
                                    Register
                                </Button>

                                <span className="separator">or</span>

                                <Button
                                    className="secondary"
                                    type="button"
                                    onClick={() => history.push('/login')}>
                                    Back to login
                                </Button>
                            </Form>
                        </>
                    )}
                </Formik>

            </div>
        </div>
    );
}

export default RegisterComponent;
