import React, { useState, useEffect } from "react";
import ReviewsComponent from "../components/ReviewsComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import { withRouter } from "react-router-dom";
import { getPestControllerReviewsService, replyPestControllerReviewService } from 'services/pestController';
import { updateReviewStatusService } from 'services/review';
import _ from 'lodash';

const Reviews = (props) => {
    const { history } = props;
    const [reviews, setReviews] = useState([]);
    const [fetchingReviews, setFetchingReviews] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [replyLoading, setReplyLoading] = useState(false);
    const [denounceLoading, setDenounceLoading] = useState(false);

    useEffect(() => {
        setFetchingReviews(true);
        getReviews();
    }, [])

    const getReviews = async () => {
        const reviews = await getPestControllerReviewsService();
        const reviewsOrderedByDate = _.orderBy(reviews, ['dateReview'], ['desc']);
        const reviewsParsed = reviewsOrderedByDate.map(review => {
            return {
                id: review.idReview,
                photo: review.user.urlProfileImage || require('assets/images/user-placeholder.jpg'),
                name: `${review.user.name || ''} ${review.user.surname || ''}`,
                message: review.content,
                rating: review.stars,
                status: review.status,
                reply: review.reply,
            }
        });

        setFetchingReviews(false);
        setReviews(reviewsParsed);
    }

    const replyReview = async (id, reply) => {
        setReplyLoading(true);

        const data = {
            idReview: id,
            reply
        }

        try {
            await replyPestControllerReviewService(data);
            setReplyLoading(false);
            getReviews();
            setIsModalOpen(false);
        } catch (err) {
            setReplyLoading(false);
            console.log(err);
        }
    }

    const denounceReview = async (id) => {
        setDenounceLoading(id);

        const data = {
            idReview: id,
            status: 'Denounced'
        }

        if (window.confirm("Are you sure you want to denounce this review?")) {
            try {
                await updateReviewStatusService(data);
                setDenounceLoading(false);
                getReviews();
                setIsModalOpen(false);
            } catch (err) {
                setDenounceLoading(false);
                console.log(err);
            }
        }
    }

    return <ReviewsComponent
        reviews={reviews}
        history={history}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        replyReview={replyReview}
        replyLoading={replyLoading}
        denounceLoading={denounceLoading}
        fetchingReviews={fetchingReviews}
        denounceReview={denounceReview} />;
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reviews));