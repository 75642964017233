import React, { useState } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled, TablePagination } from '@mui/material';
import { ReactComponent as PaperClipSVG } from 'assets/icons/Paperclip.svg'
import { ReactComponent as NotePencilSVG } from 'assets/icons/NotePencilGreen.svg'
import { ReactComponent as TrashRed } from 'assets/icons/TrashRed.svg'
import './TrapsTable.scss'
import InputComponent from "components/elements/Input/Input";

const rowsTraps = ['Name', 'Smart service', 'Image', 'Device ID', 'Location', 'Address','Company' , 'TypeTrap', 'Actions']

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded'
  }))

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded',
    fontWeight: 600,
  }))

const StyledTableCellButtons = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded',
    minWidth: '90px',
    maxWidth:'90px',
    display: 'flex',
    justifyContent: 'flex-start',
}))


const TrapsTable = (props) => {
    const {traps, isInUse, onEdit, onDelete, onPressImage} = props
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [searchTerm, setSearchTerm] = useState('')
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }


    return (
      <>
      <div className='traps-content-search-table'>
          <InputComponent 
          InputProps={{
          disableUnderline: true
          }}
          id='search' 
          name='search'
          type='text'
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder='Search'
          />
      </div>
      <TableContainer className='traps-content-table' component={(props) => <Paper {...props} style={{borderRadius: '17px'}} />}>
      <Table>
        <TableHead>
          <TableRow>
              {rowsTraps.map((row) => (
                  <StyledTableCellHead>{row}</StyledTableCellHead>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {traps
         .filter((trap) => {
          return trap.trap.name.toLowerCase().includes(searchTerm.toLowerCase()) || trap.trap.idDevice.toLowerCase().includes(searchTerm.toLowerCase());
          })
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((trap) => (
            <TableRow key={trap.trap.id}>
              <StyledTableCell >{trap.trap.name}</StyledTableCell>
              <StyledTableCell>{trap.trap.isEnable ? 'on': 'off'}</StyledTableCell>
              <StyledTableCell>
                  <div className="traps-content-table-cell-image" onClick={() =>onPressImage(trap)}>
                      <PaperClipSVG/>
                      <p>Image</p>
                  </div>
              </StyledTableCell>
              <StyledTableCell>{trap.trap.idDevice}</StyledTableCell>
              <StyledTableCell>{trap?.location?.name || '--'}</StyledTableCell>
              <StyledTableCell>{trap?.location?.address || '--'}</StyledTableCell>
              <StyledTableCell>{trap?.company?.name || '--'}</StyledTableCell>
              <StyledTableCell>{trap?.trapType?.nameEnglish || '--'}</StyledTableCell>
                  <StyledTableCellButtons>
                    <div className="traps-table-button">
                      <NotePencilSVG onClick={() => onEdit(trap)}/>
                    </div>
                    <div className="traps-table-button">
                      <TrashRed onClick={() => onDelete(trap)} />
                    </div>
              </StyledTableCellButtons>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
      component="div"
      count={traps.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 25, 50]}
    />
    </TableContainer>
      </>




    )
}

export default TrapsTable