import React, { useEffect, useState, useRef } from 'react';
import ChatComponent from '../Component/ChatComponent';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateUser } from "redux/actions/userActions";
import _ from 'lodash';
import { getUnreadMessagesCount, listenToRoomsService, messageListenerService, sendMessageService, updateChatService } from 'services/chat';
import { ChatMessageI, Chat as ChatType, Room } from './types';
import moment from 'moment';
import { getIncidenceService, getOpenIncidencesService, updateIncidenceService } from 'services/incidence';
// import addNotification from 'react-push-notification';

const ChatContent = (props: any) => {
    const { user, location: { state: incidenceData }, match: { params: { chatId } } } = props;

    const [currentChatMessages, setCurrentChatMessages] = useState<any>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [thisIncidence, setThisIncidence] = useState<any>({});
    const [chats, setChats] = useState([]);
    const [chatType, setChatType] = useState("open");

    let messagesListened: ChatMessageI[] = [];
    const chatsMetadata = useRef<{ [chatId: string]: ChatType }>({});
    const chatsRef = useRef<{ [chatId: string]: ChatType }>({});
    const chatObservers = useRef<any>([]);
    const chatTypeRef = useRef<string>("open");

    useEffect(() => {
        initializeChats();
    }, [])

    useEffect(() => {
        if (chatId) {
            getThisIncidence();
            updateLastSeen();
            messageListenerService(chatId, messageListenerCallback);
        }
    }, [chatId])

    const getThisIncidence = async () => {
        let data = await getIncidenceService(chatId)

        setThisIncidence(data);
    }

    const updateLastSeen = async () => {
        const now = moment().utc().valueOf();
        await updateChatService(chatId, {
            lastSeenEntomologist: now
        })
    }

    const messageListenerCallback = (message: ChatMessageI) => {
        messagesListened.push(message);
        // const currentChatMessagesSorted = _.orderBy(messagesListened, ['createdAt'], ['asc']);
        setCurrentChatMessages([...messagesListened]);
    }

    const sendMessage = async (messageText: string, type = 'text') => {
        if (!messageText || messageText === "") return;
        const now = moment().utc().valueOf();

        if (type === "text") setInputValue('');
        const message: ChatMessageI = {
            userId: user.id,
            createdAt: now,
            type,
            message: messageText,
            seenEntomologist: true,
            seenUser: false
        };


        await sendMessageService(chatId, message);
        if (thisIncidence?.chatStatus !== "open") {
            await closeChat("open");
        }

        const chatsNeedUpdate = !chats.map((chat: ChatType) => chat.id).includes(chatId);
        if (chatsNeedUpdate) initializeChats(false);
    }

    const initializeChats = async (showLoading = true) => {
        const firebaseChats = await getChats(showLoading);
        startListening(_.keys(firebaseChats));
    }

    const getChats = async (showLoading?: boolean): Promise<{ [chatId: string]: ChatType } | undefined> => {
        try {
            if (showLoading) setLoading(true);
            const incidences: any = await getOpenIncidencesService(chatTypeRef.current);

            let firebaseChats: { [chatId: string]: ChatType } = {};
            incidences?.forEach((i: any) => {
                firebaseChats[i?.incidence?.idIncidence] = {
                    active: true,
                    firebaseId: i?.incidence?.idIncidence,
                    img: i?.incidence?.urlImage,
                    name: `${i?.incidence?.bug?.name}`,
                    chatExists: false,
                    chatStatus: i?.incidence?.chatStatus,
                    id: i?.incidence?.idIncidence,
                }
            })

            chatsMetadata.current = firebaseChats;

            //Put chat in chatsRef if it wasn't there yet
            _.keys(firebaseChats).forEach((key) => {
                if (!chatsRef.current[key]) chatsRef.current[key] = firebaseChats[key];
            })

            orderAndSetChats(_.values(chatsRef.current));

            return firebaseChats;
        }
        catch (e) {
            console.log('error getting chats', e);
        }
        finally {
            setLoading(false);
        }
    }

    const orderAndSetChats = (chatsToOrder: ChatType[]) => {
        const ordered: any = chatsToOrder.filter((chat) => chat.chatExists && chatTypeRef.current === "open" ? chat.chatStatus === chatTypeRef.current : chat.chatStatus === chatTypeRef.current).sort((a, b) => {
            const startA = a?.lastMessage?.createdAt || a.createdAt || 0;
            const startB = b?.lastMessage?.createdAt || b.createdAt || 0;
            return startB - startA;
        })
        setChats(ordered);
    }

    const startListening = async (firebaseChatsIds: string[]) => {
        try {
            const newObservers: any = listenToRoomsService(firebaseChatsIds, roomListenedCallback);
            chatObservers.current = [...chatObservers.current, newObservers];
        }
        catch (e) {
            console.log('error listener', e);
        }
    }

    const roomListenedCallback = async (room: Room) => {
        if (!room.id) return;
        const metadata = chatsMetadata.current[room.id];
        if (metadata) {
            const unseenMessages = await getUnreadMessagesCount(room.id, room?.lastSeenEntomologist || 0);
            let newRoom: ChatType = {
                ...room,
                ...metadata,
                chatExists: true,
                badge: unseenMessages,
            }
            chatsRef.current[room?.id] = newRoom;
            // addNotification({
            //     title: `New message`,
            //     subtitle: `Ticket ${newRoom?.id}`,
            //     message: newRoom?.lastMessage?.message || "",
            //     theme: 'light',
            //     native: true // when using native, your OS will handle theming.
            // });
        }

        orderAndSetChats(_.values(chatsRef.current));
    }

    const closeChat = async (status: "open" | "closed" = "closed") => {
        const chatStatus = status;
        setThisIncidence({ ...thisIncidence, chatStatus });
        updateChatService(chatId, { chatStatus });
        await updateIncidenceService(chatId, { chatStatus });
        if (chatsRef.current[chatId]) chatsRef.current[chatId].chatStatus = status;
        orderAndSetChats(_.values(chatsRef.current));
    }

    const changeChatType = (type: string) => {
        setChatType(type);
        chatTypeRef.current = type;
        initializeChats();
    }

    return <ChatComponent
        user={user}
        currentChatMessages={currentChatMessages}
        sendMessage={sendMessage}
        {...{ inputValue, setInputValue, thisIncidence, chatId, chats, closeChat, chatType, changeChatType, loading, setCurrentChatMessages }}
    />
}

const mapStateToProps = (store: any) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatContent));