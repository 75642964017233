import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import IncidenceReviewCard from 'components/elements/IncidenceReviewCard/containers/IncidenceReviewCard';
import _ from 'lodash';
import "./IncidenceDetailsComponent.scss";

const IncidenceDetailsComponent = (props) => {
    const { incidence, reviews, getIncidenceAndReviews } = props;

    return (
        <DashboardLayout >
            <div className="incidence-details-wrapper">
                <div className="incidences-header">
                    <h1>Incidence reviews</h1>
                </div>

                <div className="table-content">
                    {reviews.map((review, x) => (
                        <IncidenceReviewCard incidence={incidence} review={review} onChangeStatus={getIncidenceAndReviews} />
                    ))
                    }
                </div>

            </div>
        </DashboardLayout>
    );
};

export default IncidenceDetailsComponent;