import { endpoints } from './endpoints';
import customFetch from './customFetch';
import { getUserToken, getUserId } from './utils';
import moment from 'moment';

export const getIncidencesService = async ({ startDate, endDate }) => {
    const token = getUserToken();
    let filters = [];
    if (startDate) {
        startDate = moment(startDate).utc().format('YYYY-MM-DD[T]HH:mm:[00.000Z]');
        filters.push(`dateStart=${startDate}`);
    }
    if (endDate) {
        endDate = moment(endDate).utc().format('YYYY-MM-DD[T]HH:mm:[00.000Z]');
        filters.push(`dateEnd=${endDate}`);
    }

    if (filters.length > 0) filters = '?' + filters.join('&');
    else filters = '';

    try {
        let response = await customFetch(`${endpoints.incidences}${filters}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getIncidencesByStatusService = async ({ startDate, endDate, page = 0, status }) => {
    const token = getUserToken();
    let filters = [];
    if (startDate) {
        startDate = moment(startDate).utc().format('YYYY-MM-DD[T]HH:mm:[00.000Z]');
        filters.push(`dateStart=${startDate}`);
    }
    if (endDate) {
        endDate = moment(endDate).utc().format('YYYY-MM-DD[T]HH:mm:[00.000Z]');
        filters.push(`dateEnd=${endDate}`);
    }
    filters.push(`limit=15`);
    filters.push(`skip=${page * 15}`);
    if (status) filters.push(`status=${status}`);
    if (filters.length > 0) filters = '?' + filters.join('&');
    else filters = '';

    try {
        let response = await customFetch(`${endpoints.incidences}${filters}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getStatisticsService = async ({ startDate, endDate }) => {
    const token = getUserToken();
    let filters = [];
    if (startDate) {
        startDate = moment(startDate).utc().format('YYYY-MM-DD[T]HH:mm:[00.000Z]');
        filters.push(`dateStart=${startDate}`);
    }
    if (endDate) {
        endDate = moment(endDate).utc().format('YYYY-MM-DD[T]HH:mm:[00.000Z]');
        filters.push(`dateEnd=${endDate}`);
    }
    if (filters.length > 0) filters = '?' + filters.join('&');
    else filters = '';

    try {
        let response = await customFetch(`${endpoints.incidences}/statistics${filters}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getOpenIncidencesService = async (chatStatus) => {
    const token = getUserToken();
    const filters = {
        where: {
            and: [{ chatStatus: chatStatus }],
        },
    };

    if (chatStatus === 'closed') {
        filters['order'] = 'createdAt DESC';
        filters['limit'] = 15;
    }

    try {
        let response = await customFetch(`${endpoints.incidences}/filter?filter=${JSON.stringify(filters)}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getIncidenceService = async (id) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.incidences}/${id}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getFilteredIncidencesService = async (filters) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.incidences}/filter?filter=${JSON.stringify(filters)}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createIncidenceService = async (incidenceData) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.incidences}`, {
            method: 'POST',
            token,
            bodyReq: incidenceData,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const updateIncidenceService = async (id, data) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.incidences}/${id}`, {
            method: 'PATCH',
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
