import React from "react";
import Lottie from "react-lottie";
import animationData from "assets/lotties/loading-white.json";
import "./FileUploadButton.scss";

const FileUploadButton = (props) => {
    const { loading, onChange, label, fileProps, style = {}, labelStyle = {} } = props;

    return (
        <div className="file-upload-button" style={style}>
            {
                loading ?
                    <div style={{ flex: 1, height: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Lottie options={defaultOptions} height={40} width={40} />
                    </div> :
                    <div>
                        <input
                            type="file"
                            id="upload-batch"
                            hidden
                            onChange={onChange}
                            disabled={loading}
                            {...fileProps}
                        />
                        <label htmlFor="upload-batch" style={labelStyle}>{label}</label>
                    </div>
            }

        </div>
    );
};

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default FileUploadButton;
