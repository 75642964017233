import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import TableRow from 'components/elements/TableRow/TableRow';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import { searchTable } from 'services/utils';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import "./PestsComponent.scss";

const csvName = `pest-pros-${moment().format("YYYY-MM-DD")}`;

const PestsComponent = (props) => {
    const { pestControllers, history, fetchingPestControllers, generateCsvData, csvData } = props;
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (pestControllers.length) setFilteredData(pestControllers);
    }, [pestControllers])

    const rowClick = (id) => {
        history.push(`/manage-pest-controller/${id}`);
    }

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search pest pros ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, pestControllers);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="pests-wrapper">
                <div className="pests-header">
                    <h1>Pest Pros</h1>
                </div>

                <div className="top-buttons">
                    <CSVLink
                        className="csv-download-btn"
                        data={csvData}
                        filename={csvName}
                        asyncOnClick={true}
                        onClick={(event, done) => {
                            generateCsvData().then(() => {
                                done();
                            });
                        }}
                    >
                        <FontAwesomeIcon
                            className="icon"
                            icon={faDownload}
                        />
                        <span>Export to CSV</span>
                    </CSVLink>
                </div>

                {
                    fetchingPestControllers ?
                        <SkeletonTable /> :
                        filteredData.length ?
                            <TableOptions
                                rawData={filteredData}
                                hiddenColumns={['id']}
                                rowComponentProps={{ rowClick }}
                                isTable={true}
                                rowHeader={<TableHeader />}
                                rowComponent={<TableRow />} /> :
                            <div className="pests-empty">No Pest Pros registered yet.</div>
                }
            </div>
        </DashboardLayout>
    );
};

export default PestsComponent;