import React, { useState, useEffect } from "react";
import FeedbacksComponent from "../components/FeedbacksComponent";
import { withRouter } from "react-router-dom";
import { getFeedbacksService } from 'services/feedback';
import moment from 'moment';
import { MdSentimentVerySatisfied, MdSentimentDissatisfied, MdSentimentNeutral } from 'react-icons/md';
import momentDurationFormatSetup from 'moment-duration-format';
import _ from 'lodash';

momentDurationFormatSetup(moment);

const Feedbacks = (props) => {
    const { history } = props;
    const [feedbacks, setFeedbacks] = useState([]);
    const [fetchingFeedbacks, setFetchingFeedbacks] = useState(false);

    useEffect(() => {
        setFetchingFeedbacks(true);
        getFeedbacks();
    }, [])

    const getFeedbacks = async () => {
        const data = await getFeedbacksService();
        const dataSorted = _.orderBy(data, (feedback) => +new Date(feedback.createdAt), ['desc']);
        const dataParsed = dataSorted.map(feedback => {
            return {
                id: feedback.id,
                message: feedback.message || "-",
                satisfaction: feedback.mood === "satisfied" ? <MdSentimentVerySatisfied style={{ color: 'green' }} /> : feedback.mood === "neutral" ? <MdSentimentNeutral style={{ color: 'gray' }} /> : feedback.mood === "dissatisfied" ? <MdSentimentDissatisfied style={{ color: 'red' }} /> : null,
                created_at: (feedback.createdAt && moment(feedback.createdAt).format('MM/DD/YYYY LT')) || '-',
            }
        })

        setFeedbacks(dataParsed);
        setFetchingFeedbacks(false);
    }

    return <FeedbacksComponent
        feedbacks={feedbacks}
        history={history}
        fetchingFeedbacks={fetchingFeedbacks}
    />
};

export default withRouter(Feedbacks);