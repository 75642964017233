import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken, getUserId } from "./utils";

export const getBugsService = async (token) => {
    try {
        let response = await customFetch(endpoints.bugs, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const addBugService = async ({ name, info, urlImage, dangerLevel, createdAt, preventiveTreatments, new_label }) => {
    const token = getUserToken();
    const userId = getUserId();
    try {
        let response = customFetch(`${endpoints.bugs}`, {
            method: "POST",
            token,
            bodyReq: { name, info, urlImage, dangerLevel, createdAt, preventiveTreatments,new_label }
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }

};

export const updateBugService = async (id, data) => {
    const token = getUserToken();
    try {
        let response = customFetch(`${endpoints.bugs}/${id}`, {
            method: "PATCH",
            token,
            bodyReq: data
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteBugService = async (id) => {
    const token = getUserToken();
    try {
        let response = customFetch(`${endpoints.bugs}/${id}`, {
            method: "DELETE",
            token
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }

};