import _ from 'lodash';

export const getExtractedIncidenceData = (incidenceData, user) => {
    const incidence = incidenceData.incidence || {}
    const rawUsername = incidence.email || `${incidence.user?.name || ''}${` ${incidence.user?.surname || ''}`}`;
    const username = !rawUsername.replace(/\s/g, '').length ? 'Guest user' : rawUsername; // Check if contains only spaces
    const os = incidence.os ? `(${incidence.os || ""})` : "";
    const lastReview = _.last(incidenceData?.reviewPopulated)?.review || null;
    const lastReviewer = _.last(incidenceData?.reviewPopulated)?.reviewer || null;
    const alreadyReviewedByMe = lastReviewer?.id === user.id;
    const emailShared = incidence.email;
    const acceptedReview = incidenceData?.reviewPopulated?.[0];
    const confidence = incidence.prob ? `${parseInt(incidence.prob * 10000) / 100}%` : "-";
    const location = incidence?.infoWeather?.name || `${incidence?.lat?.toFixed(5)}, ${incidence?.long?.toFixed(5)}` || "-";
    const coordinates = `${incidence?.lat?.toFixed(5)}, ${incidence?.long?.toFixed(5)}`;
    const weather = { ...incidence.infoWeather?.mainWeather, ...incidence.infoWeather?.weather, city: incidence.infoWeather?.name, country: incidence.infoWeather?.sys?.country, wind_speed: incidence.infoWeather?.wind?.speed, wind_deg: incidence.infoWeather?.wind?.deg }

    return { username, os, lastReview, lastReviewer, alreadyReviewedByMe, emailShared, acceptedReview, confidence, location, weather, coordinates };
}