import React, { useState, useEffect, useMemo } from 'react';
import { getFilteredIncidencesService } from "services/incidence";
import { changeFormularyIncidences, getFormularyIncidences } from 'services/incidenceFormulary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import OpenPestsComponent from '../components/OpenPestsComponent';
import ClosedPestsComponent from '../components/ClosedPestsComponent';


const CasesPests = (props) => {
    const { location: { pathname } } = props;
    const currentPath = pathname;
    const [loading, setLoading] = useState(false)
    const [formularies, setFormularies] = useState()
    const [filteredData, setFilteredData] = useState([])
    const [fetchingIncidences, setFetchingIncidences] = useState()


    useEffect(() => {
        const status = currentPath.split('/')[3];
        const type = currentPath.split('/')[1];
        getReviewsByStatus(type, status);
    }, [])

    const getIncidences = async (forms) => {
        try {
            setFetchingIncidences(true)
            const filter = {
                where: {
                    or: forms.map((form) => ({ idIncidence: form.idIncidence }))
                }
            }

            const res = await getFilteredIncidencesService(filter); // add opended/closed filter and change review/details 
            const ordenedIncidences = []

            res.forEach((incidence) => {
                const selectedIncidence = formularies.filter((form) => incidence.incidence.idIncidence === form.idIncidence && form.id)
                selectedIncidence
                    .forEach((inc) => {
                        ordenedIncidences.push(
                            {
                                date_submitted: moment(incidence.incidence.createdAt).format('MM/DD/YYYY hh:mm A'),
                                type_of_problem: incidence.incidence.bug.name,
                                location: inc.location,
                                submitted_by: `${incidence.incidence.user.name} ${incidence.incidence.user.surname}`,
                                review: <FontAwesomeIcon icon={faSearch} />,
                                id: inc.id,
                                idIncidence: inc.idIncidence,
                                comments: inc.comments,
                                bugImage: incidence.incidence.bug.urlImage,
                            }
                        )
                    })
            })

            setFilteredData(ordenedIncidences)
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setFetchingIncidences(false)
        }
    }

    const getNewIncidences = useMemo(() => {
        getIncidences(formularies);
    }, [formularies])

    const getReviewsByStatus = async (type, status) => {
        try {
            setFetchingIncidences(true)
            const filter = {
                where: {
                    and: [
                        { incidenceType: type },
                    ]
                }
            }

            const res = await getFormularyIncidences(filter);
            setFormularies(res);
        }
        catch (e) {
            console.log('error updating', e);
        }
    }

    const editFormComment = async (values, type) => {
        try {
            setLoading(true)
            const resp = await changeFormularyIncidences(values.id, { comments: values.comments })

            getReviewsByStatus(type);
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false)
        }
    }


    return currentPath.includes("open") ? (
        <OpenPestsComponent {...{ loading, filteredData, fetchingIncidences, editFormComment, }} />
    ) : <ClosedPestsComponent {...{ loading, filteredData, fetchingIncidences, editFormComment, }} />

}

export default CasesPests;