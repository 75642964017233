import { endpoints } from "./endpoints"
import { getUserToken } from "./utils"

export const uploadFileService = async (
	file
) => {
	const data = new FormData()
	data.append('file', file)
	const myHeaders = new Headers()
	myHeaders.append('Authorization', 'Bearer ' + getUserToken())
	const requestOptions= {
		method: 'POST',
		headers: myHeaders,
		body: data,
		redirect: 'follow',
	}

	return fetch(endpoints.media, requestOptions)
		.then((response) => response.text())
		.catch((error) => console.log('error', error))
}

export const base64ToFile = (base64String) => {
	const byteString = atob(base64String.split(',')[1])
	const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0]
	const ab = new ArrayBuffer(byteString.length)
	const ia = new Uint8Array(ab)
	for (let i = 0; i < byteString.length; i++) {
	  ia[i] = byteString.charCodeAt(i)
	}
	return new File([ab], 'image.png', { type: mimeString })
  };