import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import TableRow from 'components/elements/TableRow/TableRow';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import { searchTable } from 'services/utils';
import "./FeedbacksComponent.scss";

const FeedbacksComponent = (props) => {
    const { feedbacks, fetchingFeedbacks } = props;
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (feedbacks.length) setFilteredData(feedbacks);
    }, [feedbacks])

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search feedbacks ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, feedbacks);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="feedbacks-wrapper">
                <div className="feedbacks-header">
                    <h1>Feedbacks</h1>
                </div>
                {
                    fetchingFeedbacks ?
                        <SkeletonTable /> :
                        filteredData.length ?
                            <TableOptions
                                rawData={filteredData}
                                hiddenColumns={['id']}
                                isTable={true}
                                rowHeader={<TableHeader />}
                                rowComponent={<TableRow notHoverable />} /> :
                            <div className="users-empty">No feedbacks registered yet.</div>
                }
            </div>
        </DashboardLayout>
    );
};

export default FeedbacksComponent;