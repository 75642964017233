import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Button from "components/elements/Button/Button";
import Input from "components/elements/Input/Input";
import logo from '../../../logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import './ResetPasswordComponent.scss';
import Presentation from "components/elements/Presentation/Presentation";

const ResetPasswordComponent = (props) => {
    const { loading, submit, error, success, history } = props;

    return (
        <div className="reset-password-component">
            <Presentation />

            <div className="formContainer">
                {
                    success ?
                        <div className="success">
                            <div className="success-icon">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div className="success-message">Password successfully changed! Now you are able to login into our app again </div>

                            <Button
                                loading={loading}
                                type="submit"
                                onClick={() => history.push('')}>
                                Back to dashboard
                            </Button>
                        </div> :
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={yup.object().shape({
                                password: yup
                                    .string()
                                    .min(8)
                                    .required("Password is a required field"),
                                confirmPassword: yup
                                    .string()
                                    .oneOf([yup.ref('password'), null], "Passwords must match")
                                    .required("Confirm password is a required field"),
                            })}
                            onSubmit={(values) => submit(values)}
                        >
                            {(formik) => (
                                <>
                                    <Form>
                                        <Input
                                            id="password"
                                            formik={formik}
                                            type="password"
                                            label="Password*"
                                            className="formControl"
                                        />

                                        <Input
                                            id="confirmPassword"
                                            formik={formik}
                                            type="password"
                                            label="Confirm password*"
                                            className="formControl"
                                        />

                                        {
                                            error ?
                                                <div className="error">{error}</div> :
                                                null
                                        }

                                        <Button
                                            loading={loading}
                                            type="submit"
                                            onClick={formik.handleSubmit}>
                                            Reset password
                                        </Button>
                                    </Form>
                                </>
                            )}
                        </Formik>
                }
            </div>
        </div>
    );
}

export default ResetPasswordComponent;
