import React, { useState } from "react";
import ManageSubspecieModalComponent from '../components/ManageSubspecieModalComponent';
import { addSubspecieService, updateSubspecieService } from 'services/subspecies';
import _ from 'lodash';
import { addScientificNameService, updateScientificNameService } from "services/scientificNames";

/**
 * 
 * this can be used for addingand editing subnames or scrientific names
 */
const ManageSubspecieModal = (props) => {
    const {
        speciesId,
        getSpecies,
        selectedSpecie,
        setSelectedSpecie,
        selectedSubspecie,
        setIsOpen,
        isOpen
    } = props;
    const [isLoading, setIsLoading] = useState(null);

    const modalType = isOpen;

    const submit = async (id, values) => {
        setIsLoading(true);

        try {
            const formData = {
                ...values,
                speciesId
            }

            let newSelectedSpecie = {};

            let updatedValues;
            let updatedFieldName;
            if (id) {
                if (modalType === "subname") {
                    await updateSubspecieService(id, formData);
                    updatedFieldName = "subSpecies"
                }
                else {
                    await updateScientificNameService(id, formData);
                    updatedFieldName = "scientificNames"
                }

                //clone all subnames or scrientific names
                updatedValues = _.cloneDeep(selectedSpecie?.[updatedFieldName]);

                //get subname or scientific name index
                const index = updatedValues.findIndex(specie => specie.id === id);
                //update subname/scrientific-name object
                updatedValues[index] = {
                    ...selectedSubspecie,
                    ...formData,
                };
                //update specie object
                newSelectedSpecie = {
                    ...selectedSpecie,
                    [updatedFieldName]: updatedValues
                }
            } else {
                let newValue;
                if (modalType === "subname") {
                    newValue = await addSubspecieService(formData);
                    updatedFieldName = "subSpecies"
                }
                else {
                    newValue = await addScientificNameService(formData);
                    updatedFieldName = "scientificNames"
                }

                updatedValues = selectedSpecie?.[updatedFieldName] ? [...selectedSpecie?.[updatedFieldName]] : []

                newSelectedSpecie = {
                    ...selectedSpecie,
                    [updatedFieldName]: [
                        ...updatedValues,
                        newValue
                    ]
                }
            }

            await getSpecies();
            setSelectedSpecie(newSelectedSpecie)
            setIsOpen(false);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    }

    return (
        <ManageSubspecieModalComponent
            {...props}
            isLoading={isLoading}
            submit={submit}
        />
    );
};


export default ManageSubspecieModal;