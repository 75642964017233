import React, { useState, useEffect } from "react";
import IncidencesReviewsComponent from "../components/IncidencesReviewsComponent";
import { withRouter } from "react-router-dom";
import { getIncidenceService } from 'services/incidence';
import { getAllIncidenceReviewsService, updateIncidenceReviewService } from 'services/entomologist';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import _ from 'lodash';
import moment from 'moment'

const IncidencesReviews = (props) => {
    const { } = props;
    const [loading, setLoading] = useState(false);
    const [loadingAcceptAll, setLoadingAcceptAll] = useState(false);
    const [reviews, setReviews] = useState([]);


    useEffect(() => {
        getIncidenceAndReviews();
    }, [])

    const getIncidenceAndReviews = async () => {
        try {
            setLoading(true);
            const res = await getAllIncidenceReviewsService();
            setReviews(res);
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
        }
    }

    const acceptAll = async () => {
        try {
            setLoadingAcceptAll(true);
            const promises = reviews.map((r) => {
                return updateIncidence(r?.review?.idIncidenceReview, { status: "accepted" });
            })

            await Promise.all(promises);
            await getIncidenceAndReviews();
        }
        catch (e) {
            console.log('e', e);
        }
        finally {
            setLoadingAcceptAll(false);
        }

    }

    const updateIncidence = async (id, data) => {
        try {
            const res = await updateIncidenceReviewService(id, data);
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
        }
    }


    return <IncidencesReviewsComponent
        {...props}
        {... { loading, reviews, getIncidenceAndReviews, acceptAll, loadingAcceptAll }}
    />;
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncidencesReviews));
