import React, { useState } from 'react';
import RegisterComponent from "../components/RegisterComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import { registerService } from "services/user"

const Register = (props) => {
    const { updateUser, history } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async (values) => {
        setLoading(true);
        setError(false);

        const data = {
            name: values.name,
            email: values.email,
            password: values.password,
            // phone: values.phone
        }

        try {
            const response = await registerService(data);
            updateUser(response);
            history.push("/manage-pest-controller");
            setLoading(false);
        } catch (error) {
            console.log('error.message', error?.error?.message);
            console.log('error.message', error?.error);
            console.log('error.message', error);
            setError(error?.error?.message);
            setLoading(false);
        }
    };

    return <RegisterComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        history={history}
    />;
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Register));