import _ from 'lodash';

const sortDataByColumn = (data, column, direction) => {
    console.log('data, column, direction', data, column, direction);
    const getValueAsString = value => typeof value === 'string' ? value?.toLowerCase() : JSON.stringify(value)?.toLowerCase();

    return _.orderBy(data, (row) => {
        return getValueAsString(row[column]);
    }, [direction ? 'asc' : 'desc']);
}

export const onPageChange = (page, totalPages, sortedData, setCurrentPageData, setCurrentPage) => {
    const entriesPerPage = 20;
    if (page <= totalPages && page >= 1) {
        const firstDataIndexPage = (page - 1) * entriesPerPage;
        const lastDataIndexPage = page * entriesPerPage;
        const currentData = sortedData.slice(firstDataIndexPage, lastDataIndexPage);
        setCurrentPageData(currentData);
        setCurrentPage(page);
    }
}

export const paginateData = (data, setTotalPages, setCurrentPageData, setSortedData, setCurrentPage, currentPage) => {
    const entriesPerPage = 20;
    const numberOfPages = Math.ceil(data.length / entriesPerPage);
    setSortedData(data);
    setTotalPages(numberOfPages);
    onPageChange(currentPage, numberOfPages, data, setCurrentPageData, setCurrentPage)
}

export const clickHeaderToSort = (column, currentSortColumn, setCurrentSortColumn, rawData, setSortedData) => {
    const direction = column === currentSortColumn.column ? !currentSortColumn.sortAsc : true;
    setCurrentSortColumn({ column, sortAsc: direction });

    const dataSorted = sortDataByColumn(rawData, column, direction);
    setSortedData(dataSorted);
}