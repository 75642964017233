import React, { useState, useEffect } from 'react';
import { updateUser } from "redux/actions/userActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCompanyEmployees, postDisableFacilityEmployeeService } from 'services/facility';
import UsersComponent from '../components/UsersComponent';
import Switch from 'components/elements/Switch/Switch';

const FacilityUsers = (props) => {
    const { user } = props;
    const [fetchingUsers, setFetchingUsers] = useState()
    const [employees, setEmployees] = useState()
    const [loading, setLoading] = useState(false)

    const hardCodedEmployee = [
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Steven Tucker',
            email: 'steven.tucker@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '6',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Christian Hill',
            email: 'christian.hill@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '4',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
        {
            name: 'Alison Langdon',
            email: 'alison.langdon@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '2',
            hadAccess: true,
            access: <Switch value={true} onClick={() => toggleAbledUser(true)} />,
        },
        {
            name: 'Thomas Cameron',
            email: 'thomas.cameron@gmail.com',
            admin_Permissions: 'Office',
            images_Submitted: '1',
            hadAccess: false,
            access: <Switch value={false} onClick={() => toggleAbledUser(false)} />,
        },
    ]

    const toggleAbledUser = async (employee) => {
        try {
            setLoading(true)
            const res = await postDisableFacilityEmployeeService(employee.id)

            getFacilityUsers();
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getFacilityUsers();
    }, [])

    const getFacilityUsers = async () => {
        const id = user?.idFacility;
        try {
            setFetchingUsers(true)
            const users = await getCompanyEmployees(id);

            const filteredUsers = users.employeesPopulated.map((employee) => ({
                name: employee.name || '-',
                email: employee.email || '-',
                admin_Permissions: 'Office',
                images_Submitted: employee?.imagesSubmitted || '-',
                hadAccess: employee.canAccess || false,
                id: employee.id,
                access: <Switch value={employee.canAccess || false} onClick={() => toggleAbledUser(employee)} />, //todo is doing double click
            }));

            setEmployees(filteredUsers);
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setFetchingUsers(false)
        }
    }

    return <UsersComponent {...props} {...{ fetchingUsers, employees, toggleAbledUser }} />

}
const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacilityUsers));