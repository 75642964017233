import BuildingRoutesComponent from 'components/BuildingRoutes/components/BuldingRoutesComponent'
import React, { useEffect, useState } from 'react'
import { getAllCompanies, getUsersCompanyById } from 'services/companies';
import { assignRouteToLocation, createLocation, deleteLocation, getExpandedLocations, updateLocation, getLocationsOfCompany } from 'services/location'
import { removeRouteFromLocation, updateRoute } from 'services/route';
import { base64ToFile, uploadFileService } from 'services/uploadFile';
import { getCompanyUserId, getUserRole } from 'services/utils';

export const BuildingRoutes = (props) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [companyUser, setCompanyUser] = useState(null)
  const role = getUserRole()
  useEffect(() => {
    getData();
  }, [])
  
  const getData = async () => {
    try {
      setLoading(true)
      const locationsData = await getLocations()
      const companiesData = await getCompanies()
      const companyUserData = await getCompanyUser()
      setLocations(locationsData)
      setCompanies(companiesData)
      setCompanyUser(companyUserData)
    } catch (error) {
      console.error(error);
    }finally{
      setLoading(false)
    }
  }

  const getLocations = async() => {
    try {
      let data = []
      if(role === 'cmsmanager'){
        const companyId = getCompanyUserId()
        data = await getLocationsOfCompany(companyId)
      } else {
        data = await getExpandedLocations()
      }
      return data
    }catch(e) {
      throw new Error(e)
    }
  }

  const getCompanies = async() => {
    try {
      const data =  await getAllCompanies()
      return data
    }catch(e) {
      throw new Error(e)
    }
  }

  const getCompanyUser = async() => {
    let companyUser = null
    try {
      if(role === 'cmsmanager'){
        const companyId = getCompanyUserId()
        const dataCompany = await getUsersCompanyById(companyId)
        companyUser = {idCompany: dataCompany.idCompany, name: dataCompany.name}
      }
      return companyUser
    }catch(e) {
      throw new Error(e)
    }
  }

  const submit = async(values) => {
    try{
      const bodyReq = {
        name: values.nameLocation,
        lat: values.latitude,
        long: values.longitude,
        address: values.address,
        idCompany: values.company.idCompany
      }
      const createdLocation = await createLocation(bodyReq)
      for (let index = 0; index < values.namesRoutes.length; index++) {
        const route = values.namesRoutes[index];
        const file = base64ToFile(values.imagesRoutes[index]);
        const image = await uploadFileService(file);
        let body = {
          name: route,
          img: image,
          idLocation: createdLocation.idLocation
        };
        await assignRouteToLocation(body, createdLocation.idLocation);
      }
      await updateData(createdLocation.idLocation);
    }catch(error){
      console.error(error)
      throw error
    }
  }

  const updateBuilding = async(values) => {
    try {
      if(values.idRoutesToDelete.length > 0 ){
        const deletePromises = values.idRoutesToDelete.map(idRouteToDelete => removeRouteFromLocation(idRouteToDelete));
        await Promise.all(deletePromises);
      }
      const bodyReq = {
        name: values.nameLocation
      }
      await updateLocation(bodyReq, values.idLocation)
      for (const [index, routeName] of values.namesRoutes.entries()) {
        let image = values.imagesRoutes[index];
        if (image.includes("data:image/png;base64,")) {
          const file = base64ToFile(values.imagesRoutes[index])
          image = await uploadFileService(file)
        }
        let body = {
          name: routeName,
          img: image
        }
        let isAlreadyCreated = values.idRoutes[index]
        if (isAlreadyCreated) await updateRoute(body, values.idRoutes[index])
        if (!isAlreadyCreated) await assignRouteToLocation(body, values.idLocation)
      }
      await updateData(values.idLocation)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const elimineLocation = async(idLocation) => {
    try {
      await deleteLocation(idLocation)
      setLocations(prevLocations => prevLocations.filter(location => location.idLocation !== idLocation));
    }catch(error){
      console.error(error)
      alert('Something went wrong, contact your administrator')
    }
  }

  const updateData = async(idLocation) => {
    try{
      const updateData = await getLocations()
      const updateLocation = updateData.find((location) => location.idLocation === idLocation)
      const isAnUpdate = locations.find((location) => location.idLocation === idLocation)
      if(isAnUpdate){
        setLocations((prevLocations) => {
          const index = prevLocations.findIndex(location => location.idLocation === updateLocation.idLocation)
          return [
            ...prevLocations.slice(0, index),
            updateLocation,
            ...prevLocations.slice(index + 1)
          ]
        })
      }else{
        setLocations((prevLocations) =>  [...prevLocations, updateLocation])
      }
    }catch(error){
      console.error(error)
    }
  }
 

  return (
    <BuildingRoutesComponent locations={locations} loading={loading} updateBuilding={updateBuilding} submit={submit} elimineLocation={elimineLocation} companies={companies} companyUser={companyUser}/>
  )
}

export default BuildingRoutes