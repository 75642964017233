import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { createUserPestID, updateUserPestID, deleteUserPestID, getUsersPestIDAdmin, getUserById, getUsersPestIDCmsManager } from "services/usersPestID"
import UsersPestIDComponent from '../components/UsersPestIDComponent'
import { getAllCompanies } from 'services/companies'
import { getCompanyUserId, getUserRole } from 'services/utils'

export const UsersPestID = () => {
  const [users, setUsers] = useState([])
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const match = useRouteMatch()
  const companyId = match.params.id
  const role = getUserRole()
  const companyUserId = getCompanyUserId()


  useEffect(() => {
    loadInitialData()
  }, [companyId])

  const fetchUserData = async () => {
    if (companyId) {
      return await getUsersPestIDCmsManager(companyId)
    }
    if (role === 'cmsmanager') {
      return await getUsersPestIDCmsManager(companyUserId)
    }
    return await getUsersPestIDAdmin()
  }

  const loadInitialData = async () => {
    try {
      setLoading(true)
      const usersData = await fetchUserData()
      const companiesData = await getAllCompanies()
      setUsers(usersData)
      setCompanies(companiesData)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const submit = async (values) => {
    try {
      let body = {
        name: values.name,
        email: values.mail,
        role: values.role === 'Employee' ? 'Professional' : 'cmsmanager',
        password: generateRandomPassword(),
        active: false,
        idCompany: values.company.idCompany
      }
      const newUser = await createUserPestID(body)

      const { user } = newUser
      const missingData = await getUserById(user.id)
      const findCompany = companies.find((item) => item.idCompany === user.idCompany)
      const formatNewUser = {
        company: findCompany,
        name: missingData.name,
        user: { ...user },
      }
      setUsers((prevUsers) => {
        return [formatNewUser, ...prevUsers]
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const generateRandomPassword = (length = 12) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let password = ""
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      password += charset[randomIndex]
    }
    return password
  }

  const update = async (values) => {
    try {
      let body = {
        userIAM: {
          email: values.mail,
          role: values.role === 'Employee' ? 'Professional' : 'cmsmanager',
          idCompany: values.company.idCompany
        },
        userFeatures: {
          name: values.name,
        },
      }

      await updateUserPestID(body, values.idUser)
      const dataUpdated = await fetchUserData()
      updateUsersState(dataUpdated, values)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateUsersState = (dataUpdated, itemUpdated) => {
    const updatedUser = dataUpdated.find(user => user.user.id === itemUpdated.idUser)
    setUsers((prevUsers) => {
      const index = prevUsers.findIndex(user => user.user.id === itemUpdated.idUser)
      return [
        ...prevUsers.slice(0, index),
        updatedUser,
        ...prevUsers.slice(index + 1),
      ]
    })
  }

  const eliminate = async (idUser) => {
    try {
      await deleteUserPestID(idUser)
      setUsers(prevUsers => prevUsers.filter(user => user.user.id !== idUser))
    } catch (error) {
      console.error(error)
      alert("Something went wrong, contact your administrator")
    }
  }

  return <UsersPestIDComponent users={users} loading={loading} submit={submit} update={update} eliminate={eliminate} companies={companies} />
}

export default UsersPestID