

export const getReviewConfidenceName = (confidence) => {
    switch (confidence) {
        case 0:
            return "I'm not sure"
        case 1:
            return "I'm not really sure"
        case 2:
            return "I'm sure"

        default:
            return "";
    }
}