import ButtonComponent from 'components/elements/Button/Button';
import React, {useEffect, useState} from 'react';
import { ReactComponent as CheckCircleSVG } from 'assets/icons/checkcircle.svg';
import * as Yup from 'yup';
import './ModalTraps.scss';
import { Form, Formik } from 'formik';
import ModalLayout from '../ModalLayout/ModalLayout';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@material-ui/core';
import './ModalTraps.scss'

export const ModalTraps = (props) => {
  const { submit, modalVisible, setModalVisible, selectedTrap, selectedRoute, availableTraps } = props
  const [loading, setLoading] = useState(false)
  const [numPage, setNumPage] = useState(1)
  const [initialValues, setInitialValues] = useState({x: 0, y: 0, idRoute: '', idTrap: '', idPreviousTrap: ''})
  const [searchValue, setSearchValue] = useState("");


  const validationSchema = Yup.object().shape({
    x: Yup.number().required(),
    y: Yup.number().required(),
    idRoute: Yup.string().required(),
    idTrap: Yup.string().required(),
    idPreviousTrap: Yup.string().optional()
  })
  
  useEffect(() => {
    if(selectedRoute && selectedTrap){
      setInitialValues({
        x: selectedTrap.x,
        y: selectedTrap.y,
        idRoute: selectedTrap.idRoute,
        idTrap: selectedTrap.idTrap,
        idPreviousTrap: selectedTrap.idTrap,
      })
    }
  },[selectedTrap, selectedRoute])

  const onPressRow = (formik, trap) => {
    formik.setFieldValue('idTrap', trap.trap.idTrap)
  }

  const handleClose = (formik) => {
    setNumPage(1)
    setModalVisible(false)
    formik.resetForm()
  }

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    enableReinitialize={true}
    onSubmit={async (values, { setSubmitting, setErrors }) => {
      try {
        setLoading(true)
        setNumPage(2)
        await submit(values)
      } catch (error) {
        setErrors({ submit: error.message })
        alert("Something went wrong, contact your administrator")
        setModalVisible(false)
        throw error
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    }}
    >
    {(formik) => (
        <Form>
         <Form>
            <ModalLayout 
            maxWidth={'783px'}
            isOpen={modalVisible}
            toggleModal={() => handleClose(formik)}
            footer={
              numPage === 1 ? (
              <div className='modal-footer-content'>
                <ButtonComponent className='modal-footer-button back' children={'Back'} onClick={() => handleClose(formik)}/>
                <ButtonComponent className='modal-footer-button next' children={selectedTrap.idPreviousTrap.length > 0 ? 'Save' : 'Add'} onClick={() => formik.handleSubmit()} disabled={!formik.dirty || !formik.isValid} />
              </div>
              ): (
                  <ButtonComponent children='Ok' onClick={() => handleClose(formik)} />
              )
            } 
            header={
              <div className='modal-header-content'>
                  {numPage === 1 ? ( <h2>{selectedTrap.idPreviousTrap.length > 0 ?'Edit trap' : 'Add trap'}</h2>) : null} 
                  <div className='modal-header-button-close' onClick={() => setModalVisible(false)}>
                      X
                  </div>
              </div>
            }
            children={
              <div className='modal-body-content'>
                  {numPage === 1 ? (
                    <>
                    {loading ? (
                      <Loading />
                    ): (
                      <>
                      <div className='add-traps-search-box-input'>
                        <FontAwesomeIcon icon={faSearch} />
                        <TextField 
                          InputProps={{
                            disableUnderline: true
                          }}
                          id='search' 
                          name='search'
                          type='text'
                          placeholder='Search'
                          onChange={e => setSearchValue(e.target.value)}
                      />
                      </div>
                      {availableTraps
                      .filter(trap => 
                        trap.trap.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                        trap.trap.idDevice.toLowerCase().includes(searchValue.toLowerCase())
                      )
                      .map((trap, index) => (
                        <div className='modal-body-content-row-add-trap' onClick={() => onPressRow(formik, trap)} style={formik.values.idTrap === trap.trap.idTrap ? {backgroundColor: '#E8FFFA'} : {backgroundColor: 'white'}}>
                          {formik.values.idTrap === trap.trap.idTrap &&  (
                            <div className='modal-body-content-row-wrapper-icon'>
                              <CheckCircleSVG />
                            </div>
                          )}
                          <div className='modal-body-content-row-description' >
                            <h4>{trap.trap.name}</h4>
                            <p>{trap.trap.idDevice}</p>
                          </div>
                        </div>
                      ))}
                       {availableTraps.length === 0 && (
                        <div className='modal-body-content-add-traps-no-available'>
                          <FontAwesomeIcon icon={faInfoCircle} size='lg' color='#14AF8D' />
                          <p>No traps available</p>
                        </div>
                      )}
                      </>
                    )}
                    </>
                  ) : null }
                  {numPage === 2 ? (
                  <div className='modal-body-content-success'>
                      {loading ? (
                          <Loading />
                      ): (
                          <>
                          <CheckCircleSVG />
                          {selectedTrap.idPreviousTrap.length > 0 ? <h2>Trap updated</h2> : <h2>Trap added</h2>}
                          </>
                      )}
                  </div>
                  ) : null}
              </div>
            }
            />
            </Form>
        </Form>
    )}
    </Formik>
    
  )
}


export default ModalTraps
