import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Skeleton from "react-loading-skeleton";
import './LocationIncidence.scss';


export const LocationIncidenceSkeleton = props => {
    return (
        <div className="location-incidence-card">
            <div className="location-incidence-card__wrapper">
                <div className="location-incidence-title">
                    <img src={require('assets/icons/location-pin.png')} alt="" />
                    <Skeleton width={150} height={12} />
                </div>
                <div className="location-incidence-content">
                    <div className="incidence-item">
                        <div className="incidence-item__icon">
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                        <Skeleton width={70} height={12} />
                    </div>
                    <div className="incidence-item">
                        <div className="incidence-item__icon">
                            <FontAwesomeIcon icon={faBug} />
                        </div>
                        <Skeleton width={50} height={12} />
                    </div>
                    <div className="incidence-item">
                        <div className="incidence-item__icon">
                            <FontAwesomeIcon icon={faBug} />
                        </div>
                        <Skeleton width={100} height={12} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const LocationIncidence = (props) => {
    const { incidence: { location, urlImage, username, bug, createdAt, incidenceType = null }, onClick, selectedIncidence, setPictureModalImage, setIsPictureModalVisible } = props;

    const openPictureModal = () => {
        setPictureModalImage(urlImage);
        setIsPictureModalVisible(true);
    }

    return (
        <div className={selectedIncidence ? "location-incidence-card selected" : "location-incidence-card"} onClick={onClick}>
            <div className="location-incidence-card__wrapper">
                <div className="location-incidence-title">
                    <img src={require('assets/icons/location-pin.png')} alt="" />
                    <span>{location}</span>
                    {incidenceType && (
                        <div style={{ marginLeft: 'auto' }}>
                            {incidenceType === 'pest' && <CardType incidenceType="pest" />}
                            {incidenceType === 'facility' && <CardType incidenceType="facility" />}
                        </div>
                    )}
                </div>
                <div className="location-incidence-content">
                    <div className="incidence-item">
                        <div className="incidence-item__icon">
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                        <span>{username || 'Guest user'}</span>
                    </div>
                    <div className="incidence-item clickable" onClick={() => openPictureModal()}>
                        <div className="incidence-item__icon">
                            <FontAwesomeIcon icon={faBug} />
                        </div>
                        <span>{bug}</span>
                    </div>
                    <div className="incidence-item">
                        <div className="incidence-item__icon">
                            <FontAwesomeIcon icon={faClock} />
                        </div>
                        <span>{moment(createdAt).format("MM/DD/YYYY LT")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CardType = ({ incidenceType }) => {
    const pest = incidenceType === 'pest';
    const facility = incidenceType === 'facility';

    return (
        < div className={`card-type-wrapper ${pest && 'pest'} ${facility && 'facility'}`} >
            {pest && 'PEST'}
            {facility && 'FACILITY'}
        </ div>
    )
}