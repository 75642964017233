import React, { useState, useEffect, useMemo } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import ContactPestProCard from 'components/elements/ContactPestProCard/ContactPestProCard';
import SkeletonContactCard from 'components/elements/SkeletonContactCard/SkeletonContactCard';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import { searchTable } from 'services/utils';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./ContactsComponent.scss";

const ContactsComponent = (props) => {
    const { contacts, loading, setContacted } = props;
    const [filteredData, setFilteredData] = useState([]);

    const incidencesExportName = `incidences-${moment(new Date()).format("DD-MM-YYYY-HH[h]mm[m]")}.csv`

    useEffect(() => {
        if (contacts.length) setFilteredData(contacts);
    }, [contacts])

    const sortableColumns = useMemo(() => {
        let column = [
            { column: 'image', style: { marginLeft: 25, width: 75 } }, { column: 'user', style: { flex: 1 } }, { column: 'zipcode', style: { flex: 1 } }, { column: 'pest_prediction', style: { flex: 1 } },
            { column: 'concern', style: { flex: 1 } }, { column: 'submitted_date', style: { flex: 1 } }, { column: 'actions', style: { flex: 1, } },
        ];
        return column;
    }, [])

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search contacts ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, contacts);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="contacts-wrapper">
                <div className="contacts-header">
                    <h1>Contacts</h1>
                    {
                        !loading &&
                        <CSVLink className="csv-download-btn" data={filteredData} filename={incidencesExportName}>
                            <FontAwesomeIcon className="icon" icon={faDownload} />
                            <span>Export to CSV</span>
                        </CSVLink>
                    }
                </div>

                {
                    loading ?
                        <div className="contacts-empty padding-top">
                            <SkeletonContactCard />
                            <SkeletonContactCard />
                            <SkeletonContactCard />
                        </div> :
                        filteredData.length ?
                            <TableOptions
                                rawData={filteredData}
                                sortableColumns={sortableColumns}
                                rowComponentProps={{ setContacted }}
                                rowComponent={<ContactPestProCard />} /> :
                            <div className="contacts-empty">No Contacts registered yet.</div>
                }
            </div>
        </DashboardLayout>
    );
};

export default ContactsComponent;