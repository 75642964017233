import React, { useState } from 'react';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import ButtonComponent from 'components/elements/Button/Button';
import Loading from 'components/elements/Loading/Loading';
import './AlertDelete.scss'

const AlertDelete = (props) => {
  const {alertVisible, setAlertVisible, onDelete, title, subtitle } = props
  const [loading, setLoading] = useState(false)
  
  const handleDelete = async() => {
    try{
      setLoading(true)
      await onDelete()
    }catch(error){
      console.error(error)
    }finally{
      setLoading(false)
      setAlertVisible(false)
    }
  }
   
  return (
    <ModalLayout
    maxWidth={'700px'}
    maxHeight={'300px'}
    isOpen={alertVisible}
    toggleModal={() => setAlertVisible(false)}
    footer={!loading && 
      <div className='modal-footer-alert-delete'>
        <ButtonComponent className='next' children='No' onClick={() => setAlertVisible(false)} />
        <ButtonComponent children='Yes' onClick={handleDelete} /> 
      </div>
    } 
    header={
      <div>
        <div className='modal-header-button-close' onClick={() => setAlertVisible(false)}>
            X
        </div>
      </div>
    }
    children={
      <div className='modal-body-alert-delete'>
        {loading ? (
          <Loading />
        ): (
          <>
          <h2>{title}</h2>
          <p>{subtitle}</p>
          </>
        )}
      </div>
    }
    />
  )
}

export default AlertDelete