import React, { useState } from "react";
import Button from "components/elements/Button/Button";
import Quill from "components/elements/ReactQuill/ReactQuill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faReply,
    faStar,
    faCheck,
    faTimes,
    faTimesCircle,
    faExclamationTriangle,
    faCheckCircle,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { BsTrashFill } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";
import "./SpecieCard.scss";
import { BiEdit } from "react-icons/bi";

const getCardColor = (status) => {
    if (status === "rejected") return "gray-bg";

    return "";
};

const SpecieCard = (props) => {
    const {
        data,
        data: {
            id,
            common_name_english,
            common_name_spanish,
            status,
            registeredBy,
            date,
        },
        changeSpecieStatus,
        isLoading,
        showStatus,
        deleteSpecie,
        updateSpecie,
        onEditMessagePress
    } = props;


    const cardColor = getCardColor(status);

    let statusTip;
    let statusIcon;

    if (status === "pending") {
        statusTip = "Pending";
        statusIcon = faSpinner;
    }

    if (status === "accepted") {
        statusTip = "Approved";
        statusIcon = faCheckCircle;
    }

    if (status === "rejected") {
        statusTip = "Rejected";
        statusIcon = faTimesCircle;
    }

    return (
        <div className={`specie-card ${cardColor}`}>
            <div className="row">{common_name_english}</div>
            <div className="row">{common_name_spanish}</div>

            {showStatus && (
                <div className=" review-status">
                    <FontAwesomeIcon data-tip={statusTip} icon={statusIcon} />
                </div>
            )}

            <div className="row">{registeredBy}</div>
            <div className="row">{moment(date).format("MM/DD/YYYY HH:mm")}</div>

            <div className="row review-actions">
                {changeSpecieStatus && (
                    <div className="accept-deny-buttons">
                        {status === "accepted" ? (
                            <>
                                <Button className="small no-actions">
                                    <FontAwesomeIcon icon={faCheck} />
                                    <span>Approved</span>
                                </Button>
                            </>
                        ) : null}

                        {status === "rejected" ? (
                            <>
                                <Button className="small no-actions">
                                    <FontAwesomeIcon icon={faTimes} />
                                    <span>Rejected</span>
                                </Button>
                            </>
                        ) : null}

                        {status === "pending" ? (
                            <>
                                <Button
                                    loading={isLoading === id}
                                    className="light small"
                                    onClick={() =>
                                        changeSpecieStatus(id, "rejected")
                                    }
                                >
                                    Reject
                                </Button>
                                <Button
                                    loading={isLoading === id}
                                    className="small"
                                    onClick={() =>
                                        changeSpecieStatus(id, "accepted")
                                    }
                                >
                                    Approve
                                </Button>
                            </>
                        ) : null}
                    </div>
                )}
                <div className="delete" onClick={() => deleteSpecie(id)}>
                    <BsTrashFill />
                </div>
                <div
                    className="delete"
                    onClick={() =>
                        updateSpecie({
                            id,
                            nameEn: common_name_english,
                            nameEs: common_name_spanish,
                        })
                    }
                >
                    <AiFillEdit />
                </div>
                <div className="delete" onClick={() => onEditMessagePress(data)}>
                    <BiEdit />
                </div>
            </div>

            <ReactTooltip effect="solid" />

        </div>
    );
};

export default SpecieCard;
