import React, { useEffect, useState } from 'react';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { ReactComponent as PencilSVG } from 'assets/icons/pencil.svg';
import { ReactComponent as TrashBlack } from 'assets/icons/Trash.svg';
import BuildingForm from './BuildingForm';

import Loading from 'components/elements/Loading/Loading';
import EditBuildingForm from './EditBuildingForm';

import SkeletonBuildingView from 'components/elements/BuildingElements/SkeletonBuildingView';
import AlertDelete from 'components/elements/AlertDelete/AlertDelete';

export const BuildingRoutesComponent = (props) => {
  const { locations, companies, companyUser, submit, loading, updateBuilding, elimineLocation, deleteRoute } = props
  const [selectedLocation, setSelectedLocation] = useState({})
  const [selectedRoute, setSelectedRoute] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const [editModal, setEditModal] = useState(false)

  useEffect(() => {
    setSelectedLocation(locations[0])
  }, [locations])

  useEffect(() => {
    if (selectedLocation?.routes?.length > 0) {
      setSelectedRoute(selectedLocation?.routes[0])
    }else{
      setSelectedRoute(null)
    }
  }, [selectedLocation])

  const onDeleteLocation = async() => {
    await elimineLocation(selectedLocation.idLocation)
  }

  return (
    <DashboardLayout>
      
      { loading ? (
                <div className='building-wrapper-loading'>
                    <Loading />
                </div>
            ) : (
              <>
              <SkeletonBuildingView
              setSelectedLocation={setSelectedLocation}
              selectedLocation={selectedLocation}
              setSelectedRoute={setSelectedRoute}
              locations={locations}
              selectedRoute={selectedRoute}
              title='Buildings'
              onPressNew={{title:'+ Add new', action: ()=> setModalVisible(true)}}
              onPressEdit={{title: <div className='button-upload'><PencilSVG /><p>Edit</p></div>, action: ()=> setEditModal(true)}}
              onPressDelete={{title: <div className='button-upload'><TrashBlack /></div>, action: () => setAlertVisible(true)}}
              plane
              />
              <BuildingForm modalVisible={modalVisible} setModalVisible={setModalVisible} submit={submit} companies={companies} companyUser={companyUser}/>
              <EditBuildingForm modalVisible={editModal} setModalVisible={setEditModal} selectedLocation={selectedLocation} submit={updateBuilding} deleteRoute={deleteRoute}/>
              <AlertDelete alertVisible={alertVisible} setAlertVisible={setAlertVisible} onDelete={onDeleteLocation} title='Delete building' subtitle='Are you sure you want to delete this building?' />
              </>
            )}
     
     
    </DashboardLayout>
  )
}

export default BuildingRoutesComponent