import React, { useEffect, useState } from 'react';
import Modal, { Styles } from "react-modal";
import moment from 'moment';
import { GrFormClose } from 'react-icons/gr';
import './ChatMessage.scss';
import { ElementChatMessageI } from 'components/Chat/Container/types';
import ReactHtmlParser from 'react-html-parser';

import entoIcon from 'assets/icons/entomologistIcon.svg';
import userIcon from 'assets/icons/userIcon.svg';

const ChatMessage = (props: ElementChatMessageI): JSX.Element => {
    const { message, user, userName } = props;

    const [open, setOpen] = useState<boolean>(false);

    const isMine = user.id === message.userId;
    const entomologistUser = user.role.toLowerCase() === 'reviewer';

    const customStyles: Styles = {
        overlay: {
            background: "#00000080",
            zIndex: 999999,
        },
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            padding: "0",
            border: "5px solid #FFF",
            overflow: "auto",
            borderRadius: "27px",
            maxHeight: "90%",
            maxWidth: "90%",
            display: "flex",
            flexDirection: "column",
            background: "none",
            position: 'absolute',
        },
    };

    const renderMessageByType = () => {
        switch (message.type) {
            case 'text': return message.message;
            case 'image': return <img src={message.message} alt="userImage" onClick={() => setOpen(true)} />;
            case 'html': return <div>{ReactHtmlParser(message.message)} </div>;
            case 'video': return (
                <video  height="300" controls>
                    <source src={message.message} type="video/mp4" />
                </video>
            )
            default: break;
        }
    }

    const verifyUserIcon = () => {
        if (entomologistUser) {
            if (isMine) {
                return false
            } else {
                return true
            }
        } else {
            if (isMine) {
                return true
            } else {
                return false
            }
        }
    }

    return (
        <>
            {message &&
                <div className={`message-card-wrapper ${!isMine && 'message-reply'}`}>
                    <div className={`user-image ${!isMine && 'message-reply'}`}>
                        <img src={verifyUserIcon() ? userIcon : entoIcon} alt="logo" />
                    </div>
                    <div className={`message-wrapper ${!isMine && 'message-reply'}`}>
                        <h5 className={`title ${!isMine && 'primary'}`}>
                            {isMine ? user?.name : userName}
                        </h5>
                        <div className="message">
                            {renderMessageByType()}
                        </div>
                        <div className="time">{moment(message.createdAt).format("DD/MM HH:mm")}</div>
                    </div>
                </div>}
            <Modal isOpen={open} onRequestClose={() => setOpen(false)} style={customStyles}>
                <div className="close-chat-modal-wrapper" onClick={() => setOpen(false)}>
                    <GrFormClose />
                </div>
                {message.type === 'image' && <img src={message.message} alt="userImage" />}
            </Modal>
        </>
    )
}

export default ChatMessage;