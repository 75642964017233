import React, { useState, useEffect } from "react";
import ReviewersComponent from "../components/ReviewersComponent";
import { withRouter } from "react-router-dom";
import { getPestControllersService, updatePestControllerService } from 'services/pestController';
import { FaExchangeAlt } from 'react-icons/fa';
import Button from 'components/elements/Button/Button';
import { getEntomologistsService, createEntomologistService } from "services/entomologist";
import { populateSpeciesService } from "services/species";

const Reviewers = (props) => {
    const { history } = props;
    const [reviewers, setReviewers] = useState([]);
    const [fetchingReviewers, setFetchingReviewers] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFetchingReviewers(true);
        getEntomologists();
    }, [])

    const getEntomologists = async () => {
        const data = await getEntomologistsService();
        const dataParsed = data.map(reviewer => {
            return {
                id: reviewer.id,
                name: reviewer.name,
                email: reviewer.email,
            }
        })

        setReviewers(dataParsed);
        setFetchingReviewers(false);
    }


    const submit = async ({ name, email, password }) => {
        try {
            setLoading(true);
            const res = await createEntomologistService({ name, email, password });
            console.log('res', res);
            getEntomologists();
            setIsAddModalOpen(false);
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
        }
    }

    return <ReviewersComponent
        {...{
            isAddModalOpen,
            setIsAddModalOpen,
            loading,
            submit,
            fetchingReviewers,
            history,
            reviewers
        }}
    />;
};

export default withRouter(Reviewers);