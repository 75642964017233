import React, { useState, useEffect } from "react";
import IncidenceDetailsComponent from "../components/IncidenceDetailsComponent";
import { withRouter } from "react-router-dom";
import { getIncidenceService } from 'services/incidence';
import { getReviewsByIncidenceService } from 'services/entomologist';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import _ from 'lodash';
import moment from 'moment'

const IncidenceDetails = (props) => {
    const { match: { params: { id } } } = props;
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [incidence, setIncidence] = useState({});


    useEffect(() => {
        getIncidenceAndReviews();
    }, [])

    const getIncidenceAndReviews = async () => {
        try {
            setLoading(true);
            const res = await getReviewsByIncidenceService(id);
            setReviews(res);
            const incRes = await getIncidenceService(id);
            setIncidence(incRes);
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
        }
    }


    return <IncidenceDetailsComponent
        {...props}
        {... { incidence, loading, reviews, getIncidenceAndReviews }}
    />;
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncidenceDetails));
