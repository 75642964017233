import React, { useState, useMemo, useEffect } from 'react';
import SettingsComponent from '../components/SettingsComponent';
import { updateUser } from "redux/actions/userActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { patchUserService, changePasswordService, getRegularUserInfoService } from 'services/user';
import _ from 'lodash';
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';
import { getCompanyData } from 'services/facility';

const Settings = (props) => {
    const { user, updateUser } = props;
    const [loading, setLoading] = useState()
    const [companyName, setCompanyName] = useState(null)

    const saveChanges = async (values) => {
        const data = _.cloneDeep(values);
        delete data.password;
        delete data.companyName;
        try {
            setLoading(true)
            const res = await patchUserService(data);

            let newUserData = _.cloneDeep(user);

            newUserData.name = res.name;
            newUserData.email = res.email;

            updateUser({ ...newUserData })

        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false)
        }
    }

    const changePassword = async (values) => {
        const data = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
        }

        try {
            setLoading(true)
            const res = await changePasswordService(data);

            if (res.ok) toast(
                <ToastrContent
                    type="success"
                    title={"Success"}
                    message={"Your password has been changed"}
                />,
                {
                    progressClassName: "toastr-progress-bar success",
                }
            );
        }
        catch (e) {
            console.log('error updating', e);
            toast(
                <ToastrContent
                    type="danger"
                    title={"Error"}
                    message={"Try again later!"}
                />,
                {
                    progressClassName: "toastr-progress-bar danger",
                }
            );
        }
        finally {
            setLoading(false)
        }
    }

    const getCompanyName = async (id) => {
        try {
            const res = await getCompanyData(id)

            if (res.name) setCompanyName(res.name)
        }
        catch (e) {
            console.log('error updating', e);
        }
    }

    const companyNamee = useMemo(() => {
        getCompanyName(user.idFacility)
    }, [user])

    return <SettingsComponent {...props} {...{ changePassword, loading, saveChanges, companyName }} />

}
const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));