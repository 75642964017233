import React, { useState } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled, TablePagination } from '@mui/material';
import { ReactComponent as NotePencilSVG } from 'assets/icons/NotePencilGreen.svg'
import { ReactComponent as TrashRed } from 'assets/icons/TrashRed.svg'

import './UsersPestIDTable.scss'
import InputComponent from "components/elements/Input/Input";

const rowsUsersPestID = ['Name', 'Mail', 'Company', 'Role', 'Actions' ]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded'
  }))

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded',
    fontWeight: 600,
  }))

const StyledTableCellButtons = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded',
    minWidth: '90px',
    maxWidth:'90px',
    display: 'flex',
    justifyContent: 'flex-start',
}))


const UsersPestIDTable = (props) => {
    const {users, onEdit, onDelete } = props
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [searchTerm, setSearchTerm] = useState('')
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    return (
      <>
      <div className='users-pestid-content-search-table'>
          <InputComponent 
          InputProps={{
          disableUnderline: true
          }}
          id='search' 
          name='search'
          type='text'
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder='Search'
          />
      </div>
      <TableContainer className='users-pestid-content-table' component={(props) => <Paper {...props} style={{borderRadius: '17px'}} />}>
      <Table>
        <TableHead>
          <TableRow>
              {rowsUsersPestID.map((row, index) => (
                  <StyledTableCellHead key={index.toString()}>{row}</StyledTableCellHead>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {users
         .filter((user) => {
          return user.name?.toLowerCase().includes(searchTerm.toLowerCase()) || user.user?.email?.toLowerCase().includes(searchTerm.toLowerCase());
          })
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((user, index) => (
            <TableRow key={user.user.id}>
                <StyledTableCell >{user.name}</StyledTableCell>
                <StyledTableCell>{user.user.email}</StyledTableCell>
                <StyledTableCell>{user.company?.name}</StyledTableCell>
                <StyledTableCell>{user.user.role === 'Professional' ? 'Employee' : 'Admin'}</StyledTableCell>
                <StyledTableCellButtons>
                  <div className="user-pestid-table-button" >
                    <NotePencilSVG onClick={() => onEdit(user)}/>
                  </div>
                  <div className="user-pestid-table-button" >
                    <TrashRed onClick={() => onDelete(user)} />
                  </div>
                </StyledTableCellButtons>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
      component="div"
      count={users.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 25, 50]}
    />
    </TableContainer>
      </>




    )
}

export default UsersPestIDTable