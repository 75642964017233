import React from 'react';


export const CircleIcon = ({ fillColor, width = 17, height = 18 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="16" height="16" rx="6.5" fill={fillColor} stroke="#646464"/>
    </svg>
  );
};
export default CircleIcon;