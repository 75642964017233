import React from "react";
import Button from 'components/elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faStar, faCheck, faTimes, faTimesCircle, faExclamationTriangle, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import ReactTooltip from "react-tooltip";
import ClipLoader from "react-spinners/ClipLoader";
import "./ReviewCard.scss";

const getCardColor = status => {
    // if (status === 'AcceptedInitial' || status === 'AcceptedDenounced') return 'green-bg';
    if (status === 'DeniedInitial' || status === 'DeniedDenounced') return 'gray-bg';
    // if (status === 'Denounced') return 'yellow-bg';

    return '';
}

const ReviewCard = props => {
    const {
        data: {
            id,
            photo,
            name,
            message,
            rating,
            status,
            reply
        },
        clickReplyButton,
        clickDenounceButton,
        changeReviewStatus,
        isLoading,
        denounceLoading,
        showStatus
    } = props;

    const cardColor = getCardColor(status);
    const alreadyDenounced = status === 'Denounced' || status === 'AcceptedDenounced';

    let statusTip;
    let statusIcon;

    if (status === 'InitialReview') {
        statusTip = 'Pending';
        statusIcon = faSpinner;
    }

    if (status === 'AcceptedInitial') {
        statusTip = 'Approved';
        statusIcon = faCheckCircle;
    }

    if (status === 'DeniedInitial') {
        statusTip = 'Rejected'
        statusIcon = faTimesCircle;
    }

    if (status.toLowerCase().includes('denounced')) {
        statusTip = 'Flagged'
        statusIcon = faExclamationTriangle;
    }

    return (
        <div className={`review-card ${cardColor}`}>
            <div className="review-picture">
                <img src={photo} alt="user" />
            </div>
            <div className="review-name">{name}</div>

            {
                showStatus &&
                <div className="review-status">
                    <FontAwesomeIcon
                        data-tip={statusTip}
                        icon={statusIcon} />
                </div>
            }

            <div className="review-message">{message}</div>
            <div className="review-stars">
                {
                    _.times(rating, (i) => (
                        <FontAwesomeIcon key={i} className={rating >= 3 ? 'green' : 'yellow'} icon={faStar} />
                    ))
                }
                {
                    _.times(5 - rating, (i) => (
                        <FontAwesomeIcon key={i} icon={faStar} />
                    ))
                }
            </div>
            <div className="review-actions">
                {
                    changeReviewStatus &&
                    <div className="accept-deny-buttons">
                        {
                            status === 'AcceptedInitial' || status === 'AcceptedDenounced' ?
                                <>
                                    <Button className="small no-actions">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <span>Approved</span>
                                    </Button>
                                </> :
                                null
                        }

                        {
                            status === 'DeniedInitial' || status === 'DeniedDenounced' ?
                                <>
                                    <Button className="small no-actions">
                                        <FontAwesomeIcon icon={faTimes} />
                                        <span>Rejected</span>
                                    </Button>
                                </> :
                                null
                        }

                        {
                            status === 'InitialReview' || status === 'Denounced' ?
                                <>
                                    <Button loading={isLoading === id} className="light small" onClick={() => changeReviewStatus(id, status, 'deny')}>Reject</Button>
                                    <Button loading={isLoading === id} className="small" onClick={() => changeReviewStatus(id, status, 'accept')}>Approve</Button>
                                </> :
                                null
                        }
                    </div>
                }

                {
                    clickReplyButton && clickDenounceButton &&
                    <>
                        <div className="info-action" onClick={() => !alreadyDenounced && !denounceLoading && clickDenounceButton(id)}>
                            {
                                denounceLoading === id ?
                                    <div className="info-action__loading">
                                        <ClipLoader
                                            size={15}
                                            color={"#696969"}
                                            loading={true}
                                        />
                                    </div> :
                                    <div className="info-icon" data-tip={alreadyDenounced ? "Already reported" : "Report"}>
                                        {
                                            alreadyDenounced ?
                                                <FontAwesomeIcon icon={faCheck} /> :
                                                <img src={require('assets/icons/info-circle.png')} alt="" />
                                        }
                                    </div>
                            }
                        </div>
                        <div className="redirect-action" onClick={() => clickReplyButton(id)}>
                            {
                                reply ?
                                    <span>Replied</span> :
                                    <FontAwesomeIcon icon={faReply} />
                            }
                        </div>
                    </>
                }
            </div>
            <ReactTooltip effect="solid" />
        </div>
    )
}

export default ReviewCard;