import React, { useState, useEffect, useRef } from 'react'
import {ReactComponent as IconSVG} from 'assets/icons/locationTrap.svg'
import DropdownPlane from './DropDownPlane'
import './CanvasPlane.scss'

const CanvasPlane = (props) => {
  const { selectedRoute, onPressCanvas, onDelete, onEdit, setSelectedTrap } = props
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [currentTrap, setCurrentTrap] = useState({})
  const [image] = useState(new Image()) 
  const [icons, setIcons] = useState([])
  const iconDimensions = 40
  const containerRef = useRef(null)
  const canvasRef = useRef(null)
  const containerWrapperRef = useRef(null)
   
  useEffect(() => {
    const container = containerRef.current
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    
    const updateCanvasSize = () => {
      const containerHeight = container.offsetHeight
      const containerWidth = container.offsetWidth
      const containerWrapperHeight =  containerHeight - 40
      const imageWidth = image.naturalWidth
      const imageHeight = image.naturalHeight
      const scaleFactor = Math.min(containerWrapperHeight / imageHeight, containerWidth / imageWidth);
      const canvasWidth = scaleFactor * imageWidth;
      const canvasHeight = scaleFactor * imageHeight;
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      if (imageLoaded) {
        context.drawImage(image, 0, 0, canvasWidth, canvasHeight)
        let hasTraps = selectedRoute?.traps?.length > 0 
        let newIcons = []
        hasTraps && selectedRoute.traps.forEach((icon, index) => {
            const { x, y } = icon
            newIcons.push({
              ...icon,
              x: Math.floor(x * canvas.width - (iconDimensions/2)),
              y: Math.floor(y * canvas.height - (iconDimensions)),
            })
        })
        setIcons(newIcons)
      }
    }
  
    image.onload = () => {
      setImageLoaded(true)
      updateCanvasSize()
    }
    image.src = selectedRoute.img
    window.addEventListener('resize', updateCanvasSize)
    return () => {
      window.removeEventListener('resize', updateCanvasSize)
    }
  }, [selectedRoute, imageLoaded, image])
    
  const onPressIcon = (trap) => {
    let isTheSame = currentTrap.idTrap === trap.idTrap     
    setIsDropdownOpen(!isTheSame)
    setCurrentTrap(trap.idTrap === currentTrap.idTrap ? {} : trap)
    setSelectedTrap({x: trap.x, y: trap.y, idTrap: trap.idTrap, idRoute: trap.idRoute, idPreviousTrap: trap.idTrap})
  }

  const onPressEdit = () => {
    setIsDropdownOpen(false)
    onEdit()
  }

  const onPressDelete= () => {
    setIsDropdownOpen(false)
    onDelete()
  }

  const handleClick = (event) => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    const getXCoordinates = x/ rect.width
    const getYCoordinates = y / rect.height
    const coordinates = {
      x: getXCoordinates,
      y: getYCoordinates
    }
    setIsDropdownOpen(false)
    onPressCanvas && onPressCanvas(coordinates)
  }

  return (
      <div className="building-content-main-route" ref={containerRef}>
        <div className='building-content-main-route-wrapper' ref={containerWrapperRef}>
          <canvas onClick={handleClick} className="building-content-main-route-canvas" ref={canvasRef}>
          </canvas>
          {icons.map((icon) => (
            <>
            <div 
            key={icon.idTrap}
            onClick={() => onPressIcon(icon)}
            style={{
              position: 'absolute',
              left: icon.x,
              top: icon.y,
              width: `${iconDimensions}px`,
              height: `${iconDimensions}px`,
              cursor: 'pointer',
            }}>
              <IconSVG />
            </div>
            <DropdownPlane
            isDropdownOpen={isDropdownOpen}
            onDelete={onPressDelete}
            onEdit={onPressEdit}
            trap={icon}
            currentTrap={currentTrap}
            coords={{x: icon.x, y: icon.y}}
            />
            </>
          ))}
        </div>
      </div>
  )
}

export default CanvasPlane