import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAlertIssues } from 'services/issues'

const ALERTS_TYPES = {
  PESTACTIVITY: {
    name: 'Pest Activity',
    icon: require('assets/images/alerts/pest-activity.png'),
  },
  ENVIROMENTAL: {
    name: 'Environmental issue',
    icon: require('assets/images/alerts/enviromental.png'),
  },
  REBALT: {
    name: 'Rebalt',
    icon: require('assets/images/alerts/rebalt.png'),
  },
  MATERIALREPLACEMENT: {
    name: 'Material replacement',
    icon: require('assets/images/alerts/material-replacement.png'),
  },
  RECOMMENDEDACTION: {
    name: 'Recommended action',
    icon: require('assets/images/alerts/recomendation.png'),
  },
  NOACESS: {
    name: 'No access',
    icon: require('assets/images/bedbug.png'),
  },
}

const SidebarAlerts = () => {
  const [issues, setIssues] = useState([])

  useEffect(() => {
    getIssuesServices()
  }, [])

  const getIssuesServices = async () => {
    try {
      const data = await getAlertIssues()
      setIssues(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="sidebar-alerts-container">
      <div className="sidebar-alerts-header">
        <h3>Alerts</h3>
        <Link to="#">
          <span>Read all</span>
        </Link>
      </div>
      <div className="sidebar-alerts-body">
        {issues.map((issue) => {
          return (
            <div className="sidebar-alerts-item" key={issue.idIssue}>
              <div className="sidebar-alerts-item-icon">
                <img src={ALERTS_TYPES[issue.type].icon} alt={ALERTS_TYPES[issue.type].name} width={26} height={26} />
              </div>
              <div className="sidebar-alerts-content">
                <div className="sidebar-alerts-item-header">
                  <h4>{ALERTS_TYPES[issue.type]?.name ?? '---'}</h4>
                  <span>{moment(issue.issueDate).format('MM/DD/YYYY')}</span>
                </div>
                <div className="sidebar-alerts-item-body">
                  <p>{issue.note && issue.note.length > 0 ? issue.note : 'No note about this issue'}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SidebarAlerts
