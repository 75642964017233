import React from "react";
import _ from 'lodash';
import './TableHeader.scss';

const TableHeader = props => {
    const { data, hiddenColumns } = props;
    const headerDataWithoutHiddenColumns = _.omit(data, hiddenColumns);

    return (
        <thead className="custom-table-header">
            <tr>
                {
                    _.map(_.keys(headerDataWithoutHiddenColumns), (rowHeader, i) => (
                        <th key={i}>{rowHeader.split("_").join(" ")}</th>
                    ))
                }
            </tr>
        </thead>
    )
}

export default TableHeader;