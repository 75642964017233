import React from "react";
import Skeleton from "react-loading-skeleton";
import "./SkeletonContactCard.scss";

const SkeletonContactCard = props => {
    return (
        <div className="skeleton-contact-card">
            <div className="contact-picture">
                <Skeleton width={50} height={50} />
            </div>

            <div className="contact-content">
                <div className="contact-field">
                    <Skeleton width={150} height={20} />
                </div>
                <div className="contact-field">
                    <Skeleton width={150} height={20} />
                </div>
                <div className="contact-field">
                    <Skeleton width={150} height={20} />
                </div>
                <div className="contact-field">
                    <Skeleton width={150} height={20} />
                </div>
                <div className="contact-field">
                    <Skeleton width={150} height={20} />
                </div>
            </div>
        </div>
    )
}

export default SkeletonContactCard;