import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, LoadScript, Marker, OverlayView } from '@react-google-maps/api';
import Modal from 'react-modal';
import _ from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';

const IncidenceMap = (props) => {
    const { incidence, style = containerStyle, isOpen = true, setIsOpen } = props;
    const mapRef = useRef(null);

    const handleLoad = (map) => {
        mapRef.current = map;
    }

    return (

        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            className="extrasense-modal"
            overlayClassName="modal-overlay with-background"
            ariaHideApp={false}>
            <OutsideClickHandler onOutsideClick={(e) => setIsOpen(false)}>
                {!incidence?.lat || !incidence?.long ? <div>This incidence doesn't have any location.</div> :

                    <LoadScript googleMapsApiKey="AIzaSyBTBtSShkECf6YgHMLDgiJv1zMFiVtOE5U">
                        <GoogleMap
                            onLoad={handleLoad}
                            mapContainerStyle={style}
                            center={{ lat: incidence.lat, lng: incidence.long }}
                            zoom={13}
                            onClick={(e) => {
                                // setSelectedIncidence(defaultIncidenceEntity);
                                // setSelectedIncidenceByList(false);
                            }}
                            options={{
                                styles: [
                                    {
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#f5f5f5"
                                            }
                                        ]
                                    },
                                    {
                                        "elementType": "labels.icon",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    },
                                    {
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#616161"
                                            }
                                        ]
                                    },
                                    {
                                        "elementType": "labels.text.stroke",
                                        "stylers": [
                                            {
                                                "color": "#f5f5f5"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#bababa"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative",
                                        "elementType": "labels.text.stroke",
                                        "stylers": [
                                            {
                                                "color": "#ffffff"
                                            },
                                            {
                                                "weight": 1
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative.land_parcel",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#bdbdbd"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#eeeeee"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#757575"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi.park",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#e5e5e5"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "poi.park",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#9e9e9e"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#ffffff"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.arterial",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#757575"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#dadada"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.highway",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#616161"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "road.local",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#9e9e9e"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "transit.line",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#e5e5e5"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "transit.station",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#eeeeee"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "water",
                                        "elementType": "geometry",
                                        "stylers": [
                                            {
                                                "color": "#c9c9c9"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "water",
                                        "elementType": "geometry.fill",
                                        "stylers": [
                                            {
                                                "color": "#cad2d3"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "water",
                                        "elementType": "labels.text.fill",
                                        "stylers": [
                                            {
                                                "color": "#9e9e9e"
                                            }
                                        ]
                                    }
                                ],
                                streetViewControl: false,
                                fullscreenControl: false,
                                mapTypeControlOptions: {
                                    mapTypeIds: []
                                },
                                clickableIcons: false
                            }}>


                            <Marker
                                cursor="pointer"
                                icon={require('assets/images/map-marker.png')}
                                opacity={1}
                                position={{ lat: incidence.lat, lng: incidence.long }} />
                        </GoogleMap>
                    </LoadScript>}

            </OutsideClickHandler>
        </Modal>
    )
}

const containerStyle = {
    width: 500,
    height: 500
};

export default IncidenceMap;