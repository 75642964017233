import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { compressImage } from './utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './input.scss';

const InputComponent = (props) => {
    const {
        type,
        label,
        id,
        options,
        className,
        formik,
        mask,
        labelClassName,
        multiple,
        disabled,
        formats,
        checked,
        onChange,
        onClick,
        onSubmit,
        onBlur,
    } = props;
    let formikProps = {};
    const [charsLength, setCharsLength] = useState(0);

    if (formik) {
        formikProps = {
            value: formik.values[id],
            onChange: (e) => formik.setFieldValue(id, e.target.value),
            error: formik.touched[id] && formik.errors[id],
        };
    }

    const handleChangeMultiple = (event) => {
        const {
            target: { value },
        } = event;
        const prevValues = formikProps.value || [];

        let values = {};
        if (multiple) {
            value.forEach((v) => {
                values[v] = true;
            });
        } else {
            prevValues.forEach((v) => {
                values[v] = false;
            });
            values[value] = true;
        }

        const parsedArray = Object.keys(values)
            .filter((key) => values[key])
            .map((key) => key);
        formik.setFieldValue(id, parsedArray);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        encodeImageFileAsURL(file);
    };

    const encodeImageFileAsURL = async (file) => {
        if (!file) return;
        var reader = new FileReader();
        reader.onload = async function () {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000), //kb
                base64: reader.result,
                file: file,
            };
            const nameParts = file.name.split('.');
            let extension = nameParts[nameParts.length - 1];
            fileInfo.type = extension;
            if (formats && !formats.includes(extension)) {
                alert(`Invalid file. Insert a ${formats.join(', ')} file`);
                return;
            }
            if (file.type?.includes('image')) {
                const downscaledImage = await compressImage(reader.result);
                fileInfo.base64 = downscaledImage;
                fileInfo.type = extension;
            }
            submitFile(fileInfo);
        };
        reader.readAsDataURL(file);
    };

    const submitFile = (file) => {
        formik.setFieldValue(id, file);
    };

    return (
        <div>
            {mask ? (
                <InputMask mask={mask} value={formikProps.value} onChange={formikProps.onChange}>
                    {(inputProps) => (
                        <TextField
                            {...inputProps}
                            type={type}
                            id={id}
                            name={id}
                            fullWidth
                            label={label}
                            variant="standard"
                            className={className}
                        />
                    )}
                </InputMask>
            ) : (
                <>
                    {(type === 'text' || type === 'textarea' || type === 'password') && (
                        <TextField
                            {...props}
                            type={type}
                            id={id}
                            name={id}
                            label={label}
                            variant="standard"
                            fullWidth
                            className={className}
                            onBlur={onBlur}
                            onKeyDown={onSubmit}
                            InputLabelProps={{ style: { fontWeight: 600, letterSpacing: 0.5, opacity: 1, minHeight: '22px' } }}
                            {...formikProps}
                        />
                    )}

                    {type === 'select' && (
                        <>
                            <InputLabel id={`demo-mutiple-checkbox-label`} className={labelClassName}>
                                {label}
                            </InputLabel>
                            <Select
                                {...props}
                                id={id}
                                multiple={multiple}
                                name={id}
                                label={label}
                                value={options
                                    ?.filter((o) => formikProps.value?.indexOf(o.value) >= 0 || formikProps.value?.indexOf(o.label) >= 0)
                                    .map((o) => o.label)}
                                onChange={handleChangeMultiple}
                                InputLabelProps={{ style: { fontWeight: 600, letterSpacing: 0.5, opacity: 0.9 } }}
                                renderValue={(selected) => selected?.join(', ')}
                                fullWidth
                            >
                                {options?.map(({ label, value }) => {
                                    return (
                                        <MenuItem key={value} value={value}>
                                            <Checkbox color="primary" checked={formik.values[id].indexOf(value) > -1} />
                                            <ListItemText primary={label} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </>
                    )}

                    {type === 'autocomplete' && (
                        <>
                            <InputLabel id={`demo-mutiple-checkbox-label`} className={labelClassName}>
                                {label}
                            </InputLabel>
                            <Autocomplete
                                fullWidth
                                id={id}
                                options={options}
                                disableClearable
                                value={formik.values[id]}
                                getOptionSelected={(option, value) => option.value === value.value}
                                // onInputChange={(_, newInputValue) => formik.setFieldValue(id, { label: newInputValue, value: null })}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(id, newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id={id}
                                        name={id}
                                        variant="standard"
                                        type="text"
                                        // onChange={(e) => formik.setFieldValue(id, { label: e.target.value, value: null })}
                                        value={formik.values[id]?.label}
                                        error={formik.errors[id]}
                                    />
                                )}
                                renderOption={(option) => <p>{option.label}</p>}
                            />
                        </>
                    )}

                    {type === 'checkbox' && (
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={onClick ? onClick : () => formik?.setFieldValue(id, !formik?.values[id])}
                        >
                            <Checkbox
                                color="primary"
                                checked={formik?.values[id] || checked}
                                onChange={onChange ? onChange : (e) => formik?.setFieldValue(id, e.target.checked)}
                            />
                            <ListItemText primary={label} style={{ cursor: 'pointer' }} />
                        </div>
                    )}

                    {type === 'file' && (
                        <input
                            style={{
                                width: '0.1px',
                                height: '0.1px',
                                opacity: 0,
                                overflow: 'hidden',
                                position: 'absolute',
                                zIndex: -1,
                            }}
                            type="file"
                            disabled={disabled}
                            name={id}
                            id={id}
                            onChange={(event) => handleImageUpload(event)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default InputComponent;
