import React, { useState, useEffect } from 'react';
import './Switch.scss';

const Switch = (props) => {
    const { value, onClick } = props

    return (
        <div onClick={onClick}>
            <label class="switch">
                <input type="checkbox" checked={value} />
                <span class="slider round"></span>
            </label>
        </div>
    )
}

export default Switch;