import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './TopBarSearch.scss';

const TopBarSearch = props => {
    const { placeholder, searchCallback } = props;

    return (
        <div className="search-bar__input">
            <FontAwesomeIcon icon={faSearch} />
            <input type="text" placeholder={placeholder} onChange={e => searchCallback(e.target.value)} />
        </div>
    )
}

export default TopBarSearch;