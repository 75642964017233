import React from 'react';
import './OptionCard.scss';
import { MdDone } from 'react-icons/md'

const OptionCard = (props) => {
    const { focused, onClick, backgroundColor, icon, description } = props

    return (
        <div className={`option-card-wrapper ${focused && 'focused'}`} onClick={onClick}>
            {focused && (
                <div className="checked">
                    <MdDone />
                </div>
            )}
            <div div className="card-icon" style={backgroundColor && { backgroundColor: backgroundColor }}>
                {icon ? icon :
                    <div className="icon-wrapper" style={icon && { border: 'none' }}>
                        <MdDone />
                    </div>
                }
            </div>
            <div className="description-wrapper">{description}</div>
        </div >
    )
}

export default OptionCard;