import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import Modal from 'react-modal';
import _ from 'lodash';
import { HiOutlineMail } from 'react-icons/hi';
import "./ReviewersComponent.scss";

const ManagePestControllerComponent = (props) => {
    const {
        id,
        submit,
        loading,
        isOpen,
        toggleModal = () => { },
    } = props;

    const initialValues = {
        name: "",
        email: "",
        password: "",
    }

    const validation = yup.object().shape({
        name: yup.string().required("Name is a required field"),
        email: yup.string().email("Invalid email").required("Email is a required field"),
        password: yup.string().required("Password is a required field"),
    })

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            className="extrasense-modal"
            overlayClassName="modal-overlay with-background"
            ariaHideApp={false}>
            <div className="edit-profile-modal__wrapper">
                <div className="modal-header">
                    <div>Add Entomologist</div>
                </div>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={(values) => submit(values)}>
                    {(formik) => (
                        <Form>
                            <div className="modal-body">
                                <div className="input-wrapper fill-row">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="name"
                                        label="Name"
                                        placeholder="Name"
                                        className="formControl" />
                                </div>
                                <div className="input-wrapper fill-row">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="email"
                                        label="Email"
                                        placeholder="Email"
                                        className="formControl" />
                                </div>
                                <div className="input-wrapper fill-row">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="password"
                                        label="Temporary password"
                                        placeholder="Password to be used at the first time login."
                                        className="formControl" />
                                </div>
                            </div>

                            <div className="modal-footer">
                                <Button className="light" onClick={toggleModal}>Cancel</Button>
                                <Button loading={loading} type="button" onClick={formik.handleSubmit}>Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};


export default ManagePestControllerComponent;