import React, { useEffect, useState } from 'react'
import { getIssues, getIssuesByCompany } from 'services/issues'
import { getExpandedLocations, getLocationsOfCompany } from "services/location"
import HeatMapServiceComponent from '../components/HeatMapServiceComponent'
import { getTrapById } from 'services/traps'
import { getRouteById } from 'services/route'
import { getCompanyUserId, getUserRole } from 'services/utils'

const HeatMapService = (props) => {
    const [issuesByRoute, setIssuesByRoute] = useState([])
    const [locations, setLocations] = useState([])
    const [loading, setLoading] = useState(false)
    const role = getUserRole()
    const companyId = getCompanyUserId()

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
      try {
        setLoading(true)
        const locationData = await getLocations()
        const issues = await getIssuesServices()
        const filteredData = locationData.filter((location) => location.hasOwnProperty('routes'))
        const locationsWithIssues = filteredData.map(location => {
          return {
            ...location,
            routes: location.routes.map(route => {
              const issuesPerRoute = issues.filter(issue => issue.idRoute === route.idRoute);
              return issuesPerRoute ? {...route, issues: issuesPerRoute} : route;
            })
          }
        })
        
        setLocations(locationsWithIssues)
      } catch (error) {
        console.error(error)
      }finally{
        setLoading(false)
      }
    }

    const getLocations = async() => {
      try {
        let data = []
        if(role === 'cmsmanager'){
          data = await getLocationsOfCompany(companyId)
        } else {
          data = await getExpandedLocations()
        }
        return data
      }catch(e) {
        throw new Error(e)
      }
    }

    const getIssuesServices = async () => {
        try{
            const data = await getIssues()
            const issuesData = await Promise.all( data.map(async (issue) => {
              const trap = await getTrapById(issue.idTrap)
              const route = await getRouteById(trap.idRoute)
              return {...issue,...trap, idRoute: route.idRoute}
            }))

            const issuesCount = issuesData.reduce((accumulator, issue) => {
                const key = `${issue.idRoute},${issue.x},${issue.y}`
                accumulator[key] = (accumulator[key] || 0) + 1
                return accumulator
              }, {})

            const uniqueIssuesRoute = Object.entries(issuesCount).map(([key, frequency]) => {
              const [idRoute, x, y] = key.split(',')
              return {
                idRoute,
                x: Number(x),
                y: Number(y),
                frequency,
              }
            })

            setIssuesByRoute(uniqueIssuesRoute)
            return uniqueIssuesRoute
        }catch(error){
            console.error(error)
        }
        
    }

    return <HeatMapServiceComponent issuesByRoute={issuesByRoute} locations={locations} loading={loading} />
}

export default HeatMapService