import Circle from 'assets/icons/Circle.jsx'
import ChartColumnComponent from 'components/elements/Charts/ChartColumn/ChartColumnComponent'
import ChartPieComponent from 'components/elements/Charts/ChartPie/ChartPieComponent'
import Loading from 'components/elements/Loading/Loading'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import React from 'react'
import SidebarAlerts from '../components/SidebarAlerts'
import './ReportsComponent.scss'

const ReportsComponent = (props) => {
  const { legend, chartPieData, chartColumnData, loading } = props

  return (
    <DashboardLayout>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="reports">
            <div className="reports-container">
              <div className="section-titles">
                <h4>Pests Activity Metrics</h4>
                <h4>Pest Species Distribution</h4>
              </div>
              <div className="section-content">
                <div className="reports-column-chart">
                  <ChartColumnComponent legend={legend} data={chartColumnData} />
                </div>
                <div className="reports-pie-container">
                  <div className="reports-pie-chart">
                    <ChartPieComponent legend={legend} data={chartPieData} />
                  </div>
                  <div className="legend-container">
                    {legend.map((item) => {
                      return (
                        <div className="legend" key={item.name}>
                          <Circle width={30} height={24} fillColor={item.color} />
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="reports-right-sidebar">
              <SidebarAlerts />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  )
}

export default ReportsComponent
