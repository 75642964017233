import React, { useState, useEffect } from 'react';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import FacilityModal from 'components/elements/FacilityModal/FacilityModal';
import TableRow from 'components/elements/TableRow/TableRow';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { BsFillClockFill } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { Formik, Form } from "formik";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import InputComponent from 'components/elements/Input/Input';
import OptionCard from 'components/elements/OptionCard/OptionCard';
import './CasesPestsComponent.scss';

const OpenPestsComponent = (props) => {
    const { loading, filteredData, fetchingIncidences, editFormComment } = props
    const [selectedFacility, setSelectedFacility] = useState()
    const [form, setForm] = useState()
    const [exterminationPlanForm, setExterminationPlanForm] = useState()
    const [modalSteps, setModalSteps] = useState({
        step: null,
        lastStep: null,
    })
    const [seccondStepSelectedCard, setSeccondStepSelectedCard] = useState()

    const saveChanges = (values) => {
        const data = {
            comments: values.notes,
            id: selectedFacility.id
        }
        editFormComment(data, 'pest')
        setModalSteps({
            step: null,
            lastStep: modalSteps.lastStep,
        })
    }

    const rowClick = (id) => {
        const facilitySelected = filteredData.filter((data) => data.id === id)[0]
        setSelectedFacility(facilitySelected)
        setModalSteps({
            step: '1',
            lastStep: modalSteps.lastStep,
        });
    }

    return (
        <DashboardLayout
            topbarLeftComponent={
                <TopBarSearch
                    placeholder="Search pest incidences ..."
                    searchCallback={(value) => {
                        console.log('Open Pests value', value)
                    }}
                />
            }>
            <div className="users-header">
                <h1>Open Pest Cases</h1>
            </div>
            {
                fetchingIncidences ?
                    <SkeletonTable /> :
                    filteredData.length ?
                        <TableOptions
                            rounded
                            rawData={filteredData}
                            hiddenColumns={['id', 'idIncidence', 'comments', 'bugImage']}
                            rowComponentProps={{ rowClick }}
                            isTable={true}
                            rowHeader={<TableHeader />}
                            rowComponent={<TableRow />} /> :
                        <div className="users-empty">No Pests registered yet.</div>
            }

            <FirstStepModal {...{ modalSteps, setModalSteps, form, loading, selectedFacility, saveChanges, setForm }} />
            <SeccondStepModal {...{ modalSteps, setModalSteps, loading, seccondStepSelectedCard, setSeccondStepSelectedCard }} />
            <ThirdStepModal {...{ modalSteps, setModalSteps }} form={exterminationPlanForm} setForm={setExterminationPlanForm} />
            <LastStepModal {...{ modalSteps, setModalSteps, setSeccondStepSelectedCard, form, setForm }} />
        </DashboardLayout>
    )
}


const FirstStepModal = ({ modalSteps, setModalSteps, form, loading, selectedFacility, saveChanges, setForm }) => {

    return <FacilityModal
        isOpen={modalSteps.step === '1'}
        toggleModal={() => setModalSteps({
            step: null,
            lastStep: '1',
        })}
        headerText="Open Pest Case details"
        leftButtom="Save"
        leftButtomClick={() => form.handleSubmit()}
        leftButtonLoading={loading}
        rightButtom="Resolve"
        rightButtomClick={() => setModalSteps({
            step: '2',
            lastStep: '1',
        })}
    >
        <div className="modal-pest-wrapper">
            <div className="modal-pest-image-wrapper">
                <img src={selectedFacility?.bugImage ? selectedFacility?.bugImage : require('assets/images/logo-white.png')} alt="bugImage" className={`bug-image ${!selectedFacility?.bugImage && 'background'}`} />
                <div>
                    <div className="bug-infos">
                        <div className="info-icon">
                            <BsFillClockFill />
                        </div>
                        <div className="info">
                            {selectedFacility?.date_submitted}
                        </div>
                    </div>
                    <div className="bug-infos primary">
                        <div className="info-icon">
                            <img src={require('assets/icons/BugBeetle.png')} alt="bugIcon" />
                        </div>
                        <div className="info">
                            {selectedFacility?.type_of_problem || '-'}
                        </div>
                    </div>
                    <div className="bug-infos">
                        <div className="info-icon primary">
                            <HiOutlineLocationMarker />
                        </div>
                        <div className="info">
                            {selectedFacility?.location || '-'}
                        </div>
                    </div>
                    <div className="bug-infos">
                        <div className="info-icon">
                            <FaUser />
                        </div>
                        <div className="info ">
                            {selectedFacility?.submitted_by || '-'}
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-wrapper">
                <Formik
                    initialValues={{ notes: selectedFacility?.comments || '' }}
                    validationSchema={yup.object().shape({
                        notes: yup.string(),
                    })}
                    onSubmit={(values) => saveChanges(values)}>
                    {(formik) => (
                        <Form>
                            {form?.values?.notes !== formik.values.notes && setForm(formik)}
                            <InputComponent
                                label="Notes"
                                type="textarea"
                                formik={formik}
                                id="notes"
                                placeholder="include any notes"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </FacilityModal>
}

const SeccondStepModal = ({ modalSteps, setModalSteps, seccondStepSelectedCard, setSeccondStepSelectedCard }) => {
    const actualStep = modalSteps.step;
    const handleModal = () => {
        if (seccondStepSelectedCard === 'norisk') {
            setModalSteps({
                step: '4',
                lastStep: actualStep,
            })
        }
        if (seccondStepSelectedCard === 'risk') {
            setModalSteps({
                step: '3',
                lastStep: actualStep,
            })
        }
    }

    const resetCard = () => {
        setModalSteps({
            step: null,
            lastStep: actualStep,
        })
        setSeccondStepSelectedCard(null);
    }

    return <FacilityModal
        isOpen={modalSteps.step === '2'}
        toggleModal={() => resetCard()}
        headerText="What was the resolution?"
        leftButtom="Cancel"
        leftButtomClick={() => resetCard()}
        rightButtom="Continue"
        rightButtomClick={() => handleModal()}
    >
        <div className="resolution-modal-wrapper">
            <div className="card-wrapper">
                <OptionCard
                    focused={seccondStepSelectedCard === 'norisk'}
                    onClick={() => setSeccondStepSelectedCard('norisk')}
                    backgroundColor="#50e3c220"
                    description="Submission was determined to not be a pest risk"
                />
                <OptionCard
                    focused={seccondStepSelectedCard === 'risk'}
                    onClick={() => setSeccondStepSelectedCard('risk')}
                    backgroundColor="#00000010"
                    icon={<img src={require('assets/icons/pestIssue.png')} alt="riskIcon" className="risk-icon" />}
                    description="Facility issue was addressed"
                />
            </div>
        </div>
    </FacilityModal >
}

const ThirdStepModal = ({ modalSteps, setModalSteps, form, setForm, }) => {
    const actualStep = modalSteps.step;


    const initialValues = {
        name: '',
        email: '',
        chemicalsOrPoison: false,
        followUpSchedule: true,
    }

    const validation = (yup.object().shape({
        name: yup.string().required("Required"),
        email: yup.string().required("Required"),
        chemicalsOrPoison: yup.boolean(),
        chemicalsDescription: yup.string(),
        followUpSchedule: yup.boolean(),
        schedule: yup.string(),
    }))

    const resetCard = () => {
        setModalSteps({
            step: null,
            lastStep: actualStep,
        })
    }

    const handleModal = (values) => {
        console.log('values', values); 
    }

    return <FacilityModal
        isOpen={modalSteps.step === '3'}
        toggleModal={() => resetCard()}
        headerText="Extermination Plan Details"
        leftButtom="Back"
        leftButtomClick={() => setModalSteps({
            step: modalSteps.lastStep,
            lastStep: actualStep,
        })}
        rightButtom="Next"
        rightButtomClick={() => form.handleSubmit()}
    >
        <div className="last-modal-wrapper">
            <div className="input-wrapper">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={(values) => handleModal(values)}>
                    {(formik) => (
                        <Form>
                            <div className="checkbox-title" style={{ padding: 0 }}>Who is executing the plan?</div>
                            <div className="half-size-wrapper">
                                <InputComponent
                                    label="Name"
                                    type="textarea"
                                    formik={formik}
                                    id="name"
                                    placeholder="Name of the person who will exterminate"
                                />
                                <InputComponent
                                    label="Email"
                                    type="textarea"
                                    formik={formik}
                                    id="email"
                                    placeholder="email of the person who will exterminate"
                                />
                            </div>
                            <div>
                                <div className="checkbox-title">Will chemicals or poison be used?</div>
                                <div className="checkbox-wrapper">
                                    <InputComponent
                                        label="Yes"
                                        type="checkbox"
                                        checked={formik.values.chemicalsOrPoison}
                                        onChange={(e) => formik.setFieldValue('chemicalsOrPoison', !formik.values.chemicalsOrPoison)}
                                        onClick={() => formik.setFieldValue('chemicalsOrPoison', !formik.values.chemicalsOrPoison)}
                                    />
                                    <div className="left">
                                        <InputComponent
                                            label="No"
                                            type="checkbox"
                                            checked={!formik.values.chemicalsOrPoison}
                                            onChange={(e) => formik.setFieldValue('chemicalsOrPoison', !formik.values.chemicalsOrPoison)}
                                            onClick={() => formik.setFieldValue('chemicalsOrPoison', !formik.values.chemicalsOrPoison)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {formik.values.chemicalsOrPoison && (
                                <InputComponent
                                    style={{ margin: '10px 0' }}
                                    label="Chemicals"
                                    type="textarea"
                                    formik={formik}
                                    id="chemicalsDescription"
                                    placeholder="List out chemicals and/or poisons"
                                />)}
                            <div>
                                <div className="checkbox-title">Is a follow up scheduled?</div>
                                <div className="checkbox-wrapper">
                                    <InputComponent
                                        label="Yes"
                                        type="checkbox"
                                        checked={formik.values.followUpSchedule}
                                        onChange={(e) => formik.setFieldValue('followUpSchedule', !formik.values.followUpSchedule)}
                                        onClick={() => formik.setFieldValue('followUpSchedule', !formik.values.followUpSchedule)}
                                    />
                                    <div className="left">
                                        <InputComponent
                                            label="No"
                                            type="checkbox"
                                            checked={!formik.values.followUpSchedule}
                                            onChange={(e) => formik.setFieldValue('followUpSchedule', !formik.values.followUpSchedule)}
                                            onClick={() => formik.setFieldValue('followUpSchedule', !formik.values.followUpSchedule)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {formik.values.followUpSchedule && (
                                <div className="date-picker">
                                    <span className="title">
                                        Date:
                                    </span>
                                    <DatePicker
                                        showTimeSelect
                                        className="map-datepicker"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        popperPlacement='right'
                                        popperModifiers={{
                                            flip: {
                                                behavior: ['right']
                                            },
                                            preventOverflow: {
                                                enabled: true
                                            },
                                            hide: {
                                                enabled: false
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </FacilityModal >
}

const LastStepModal = ({ modalSteps, setModalSteps, setSeccondStepSelectedCard, form, setForm }) => {
    const modalHeader = modalSteps.step === '4' ? 'Any additional notes?' : 'How was the issue addressed?';
    const actualStep = modalSteps.step;

    const handleModal = (values) => {

        if (modalSteps.step === '4') {
            console.log('missing implementation "4"');
        } else {
            console.log('missing implementation "5"');
        }
        setModalSteps(null);
        resetCard();
    }

    const resetCard = () => {
        setModalSteps(null)
        setSeccondStepSelectedCard(null);
    }
    return <FacilityModal
        isOpen={['4', '5'].includes(modalSteps.step)}
        toggleModal={() => resetCard()}
        headerText={modalHeader}
        leftButtom="Back"
        leftButtomClick={() => setModalSteps({
            step: modalSteps.lastStep,
            lastStep: actualStep,
        })}
        rightButtom="Finish"
        rightButtomClick={() => form.handleSubmit()}
    >
        <div className="last-modal-wrapper">
            <div className="input-wrapper">
                <Formik
                    initialValues={{ notes: '' }}
                    validationSchema={yup.object().shape({
                        notes: yup.string(),
                    })}
                    onSubmit={(values) => handleModal(values)}>
                    {(formik) => (
                        <Form>
                            {form?.values?.notes !== formik.values.notes && setForm(formik)}
                            <InputComponent
                                label="Notes"
                                type="textarea"
                                formik={formik}
                                id="notes"
                                placeholder="include any notes"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </FacilityModal >
}

export default OpenPestsComponent;