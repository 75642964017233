import customFetch from "./customFetch";
import { getUserToken } from 'services/utils'

export const getUserCoordByZipcodeService = async (zipcode) => {
    try {
        let response = await customFetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode},US&key=AIzaSyBTBtSShkECf6YgHMLDgiJv1zMFiVtOE5U`, {
            method: "GET",
            hideHeaders: true
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};
export const getExpandedLocations = async () => {
    const filter = {
        where: {
            active: true
        },
        include: [
            {
                relation: "routes",
                scope: {
                    where: {
                        active: true
                    },
                    include: [
                        {
                            relation: "traps",
                            scope: {
                                where: {
                                    active: true
                                },
                            }
                        }
                    ]
                }
            }
        ]
    }
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations?filter=${JSON.stringify(filter)}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getLocationsOfCompany = async (idCompany) => {
    const filter = {
        where: {
            active: true,
            idCompany: idCompany,
        },
        include: [
            {
                relation: "routes",
                scope: {
                    where: {
                        active: true
                    },
                    include: [
                        {
                            relation: "traps",
                            scope: {
                                where: {
                                    active: true
                                },
                            }
                        }
                    ]
                }
            }
        ]
    }
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations?filter=${JSON.stringify(filter)}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};
export const createLocation = async (bodyReq) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations`, {
            method: "POST",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const assignRouteToLocation = async(bodyReq, idLocation) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations/${idLocation}/routes`, {
            method: "POST",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
}

export const updateLocation = async(bodyReq, idLocation) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations/${idLocation}`, {
            method: "PATCH",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
}

export const deleteLocation = async( idLocation) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations/${idLocation}`, {
            method: "DELETE",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
}

export const getLocationById = async( idLocation) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations/${idLocation}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
}
