import React, { useState, useEffect } from 'react';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { RiEdit2Fill } from 'react-icons/ri';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import { Formik, Form, useFormik } from 'formik';
import * as yup from 'yup';
import './SettingsComponent.scss';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import { IoClose } from 'react-icons/io5';

const SettingsComponent = (props) => {
    const { user, loading, changePassword, saveChanges, companyName } = props;
    const [isEditting, setIsEditting] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);;


    const resetForms = () => {
        setChangePasswordModal(false)
        setIsEditting(false)
    }

    const formik = useFormik({
        initialValues: {
            email: user.email,
            password: 'xxxxxxx',
            name: user.name,
            companyName: companyName || '-',
        },
        validationSchema: yup.object().shape({
            email: yup.string().email('Invalid email'),
            password: yup.string(),
            name: yup.string(),
            companyName: yup.string(),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            saveChanges(values);
            resetForms()
        },
    });

    const passwordFormik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: yup.object().shape({
            oldPassword: yup.string().required("Insert your password"),
            newPassword: yup
                .string()
                .min(8, "Min of 8 characters")
                .required("Password is a required field"),
            confirmPassword: yup
                .string()
                .oneOf([yup.ref('newPassword'), null], "Passwords must match")
                .required("Confirm password is a required field"),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            changePassword(values)
            passwordFormik.resetForm();
            resetForms()
        },
    });

    return (
        <DashboardLayout>
            <div className="users-header">
                <h1>Settings</h1>
            </div>
            <div className="card">
                <div className="header">
                    <h1>Account information</h1>

                    <div className="header-buttons-wrapper">
                        {isEditting && <Button secondary onClick={() => setIsEditting(false)} className="cancel-button">Cancel</Button>}
                        <Button
                            loading={loading}
                            className="edit-button"
                            onClick={() => !isEditting ? setIsEditting(true) : formik.handleSubmit()}
                        >
                            {isEditting ? (
                                <>
                                    <img src={require('assets/icons/floppy-disk-white.png')} alt="save" />
                                    Save
                                </>
                            ) : (
                                <>
                                    <RiEdit2Fill className="icon" />
                                    Edit
                                </>
                            )}
                        </Button>
                    </div>
                </div>
                <div className="content">
                    <div>
                        <Input
                            formik={formik}
                            type="text"
                            id="email"
                            label="Email"
                            placeholder="Email"
                            disabled={!isEditting}
                            className="formControl" />
                    </div>
                    <div className="password-wrapper">
                        <Input
                            formik={formik}
                            type="password"
                            id="password"
                            label="Password"
                            placeholder="Password to be used at the login."
                            disabled
                            className={`formControl ${isEditting && 'disabled'}`} />
                        {isEditting && <div className="reset" onClick={() => setChangePasswordModal(true)}>
                            Change
                        </div>}
                    </div>
                    <div>
                        <Input
                            formik={formik}
                            type="text"
                            id="name"
                            label="Name"
                            placeholder="Name"
                            disabled={!isEditting}
                            className="formControl" />
                    </div>
                    <div>
                        <Input
                            formik={formik}
                            type="text"
                            id="companyName"
                            label="Company Name"
                            placeholder="Company Name"
                            disabled
                            className="formControl" />
                    </div>
                </div>
            </div>
            <ModalLayout
                isOpen={changePasswordModal}
                toggleModal={() => setChangePasswordModal(false)}
                header={
                    <div className="settings-modal-header">
                        <h1>Account information</h1>
                        <IoClose onClick={() => setChangePasswordModal(false)} className="close-icon" />
                    </div>
                }
            >
                <div className="settings-modal-content">
                    <div>
                        <Input
                            formik={passwordFormik}
                            type="password"
                            id="oldPassword"
                            label="Current password"
                            placeholder="Current password"
                            className="formControl" />
                    </div>
                    <div className="password-wrapper">
                        <Input
                            formik={passwordFormik}
                            type="password"
                            id="newPassword"
                            label="New password"
                            placeholder="Password to be used at the login."
                            className="formControl" />
                    </div>
                    <div>
                        <Input
                            formik={passwordFormik}
                            type="password"
                            id="confirmPassword"
                            label="Confirm password"
                            placeholder="Confirm your password"
                            className="formControl" />
                    </div>
                    <div className="settings-modal-button-wrapper">
                        <Button secondary onClick={() => passwordFormik.handleSubmit()} className="settings-modal-button">Save</Button>
                    </div>
                </div>
            </ModalLayout>
        </DashboardLayout>
    );
};

export default SettingsComponent;
