import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReportCard from 'components/elements/ReportCard/ReportCard';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import DatePicker from 'react-datepicker';
import Chart from 'react-apexcharts';
import './FacilityReportsComponent.scss';
import animationData from "assets/lotties/loading.json";
import { CSVLink } from "react-csv";
import Lottie from "react-lottie";
import moment from 'moment';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/elements/Input/Input';

const FacilityReportsComponent = (props) => {
    const {
        dateEnd,
        dateStart,
        getIncidencesByDate,
        fetchingGraphIncidences,
        pestIncidences,
        setPestIncidences,
        facilityIncidences,
        setFacilityIncidences,
        quickCsvData,
        detailedCsvData,
        generateQuickCsvData,
        generateDetailedCsvData,
        pestHeaderFilter,
        setPestHeaderFilter,
        quickIncidencesData
    } = props;
    const [lastSearchedDate, setLastSearchedDate] = useState({
        start: dateStart,
        end: dateEnd,
    })
    const [headerStartDateFilter, setheaderStartDateFilter] = useState(dateStart);
    const [headerEndDateFilter, setheaderEndDateFilter] = useState(dateEnd);
    const [quickStartDateFilter, setquickStartDateFilter] = useState(dateStart);
    const [quickEndDateFilter, setquickEndDateFilter] = useState(dateEnd);
    const [detailedStartDateFilter, setDetailedStartDateFilter] = useState(dateStart);
    const [detailedEndDateFilter, setDetailedEndDateFilter] = useState(dateEnd);
    const headerDelayTimer = useRef(null)
    const filterDelayTimer = useRef(null)
    const [loading, setLoading] = useState(false)
    const quickCsvInstance = useRef(null);
    const detailedCsvInstance = useRef(null);
    const quickIncidencesExportName = `incidences-${moment(new Date()).format(
        "DD-MM-YYYY-HH[h]mm[m]"
    )}.csv`;//todo make it an state

    const detailedIncidencesExportName = `incidences-${moment(new Date()).format(
        "DD-MM-YYYY-HH[h]mm[m]"
    )}.csv`;//todo make it an state

    const graphSearch = (startDate, endDate) => {
        setLoading(false);
        getIncidencesByDate(startDate, endDate);
    }

    const formik = useFormik({
        initialValues: {
            bugType: [],
        },
        validationSchema: Yup.object().shape({
            bugType: Yup.array(),
        })
    })

    useEffect(() => {
        setLoading(true);
        clearTimeout(headerDelayTimer.current)
        if (moment(lastSearchedDate.end).format('LLL') !== moment(headerEndDateFilter).format('LLL') || moment(lastSearchedDate.start).format('LLL') !== moment(headerStartDateFilter).format('LLL')) { //fix to don't re render when load the page
            headerDelayTimer.current = setTimeout(async function () {
                setLastSearchedDate({
                    start: headerStartDateFilter,
                    end: headerEndDateFilter,
                })
                graphSearch(headerStartDateFilter, headerEndDateFilter)
            }, 2000)
        }
    }, [headerStartDateFilter, headerEndDateFilter])

    useEffect(() => {
        if (!_.isEmpty(quickCsvData) && quickCsvInstance.current?.link) {
            setTimeout(() => {
                quickCsvInstance.current.link.click();
            });
        }
    }, [quickCsvData]);

    useEffect(() => {
        if (!_.isEmpty(detailedCsvData) && detailedCsvInstance.current?.link) {
            setTimeout(() => {
                detailedCsvInstance.current.link.click();
            });
        }
    }, [detailedCsvData]);

    return (
        <DashboardLayout>
            <div className="users-header">
                <h1>Reports</h1>
                {/* <div className="reports-header-pest-type">
                    <h2>Pests: </h2>
                    <Input type="select"
                        formik={formik}
                        multiple
                        id="bugType"
                        options={pestHeaderFilter}
                    />
                </div> */}
            </div>
            <div className="facility-report-wrapper">
                <div className="graph-wrapper">
                    <div className="header">
                        <h2>Incidences</h2>

                        <div className="date-filter">
                            <div className="date-filter-label">Date range filter:</div>

                            <div className="dates-wrapper">
                                <DatePicker
                                    showTimeSelect
                                    selected={headerStartDateFilter}
                                    onChange={(date) => setheaderStartDateFilter(date)}
                                    className="map-datepicker"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    popperModifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                        },
                                    }}
                                />

                                <DatePicker
                                    showTimeSelect
                                    selected={headerEndDateFilter}
                                    onChange={(date) => setheaderEndDateFilter(date)}
                                    className="map-datepicker"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    popperModifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {fetchingGraphIncidences ? (
                        <Lottie options={defaultOptions} height={100} width={100} />
                    )
                        : <Chart
                            options={{
                                chart: {
                                    type: 'area',
                                    toolbar: {
                                        show: false,
                                    },
                                    zoom: {
                                        enabled: false,
                                    },
                                },
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        shade: 'light',
                                        type: 'vertical',
                                        shadeIntensity: 1,
                                        opacityFrom: 0,
                                        opacityTo: 1,
                                        stops: [0, 100],
                                        colorStops: [
                                            [
                                                {
                                                    offset: 0,
                                                    color: '#24a7ff',
                                                    opacity: 0.3,
                                                },
                                                {
                                                    offset: 50,
                                                    color: '#24a7ff',
                                                    opacity: 0.1,
                                                },
                                                {
                                                    offset: 100,
                                                    color: '#24a7ff',
                                                    opacity: 0,
                                                },
                                            ],
                                            [
                                                {
                                                    offset: 0,
                                                    color: '#3ecf8e',
                                                    opacity: 0.3,
                                                },
                                                {
                                                    offset: 50,
                                                    color: '#3ecf8e',
                                                    opacity: 0.1,
                                                },
                                                {
                                                    offset: 100,
                                                    color: '#3ecf8e',
                                                    opacity: 0,
                                                },
                                            ],
                                        ],
                                    },
                                },
                                dataLabels: {
                                    enabled: false,
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 2,
                                    colors: ['#24a7ff', '#3ecf8e'],
                                },
                                grid: {
                                    padding: {
                                        right: 20,
                                        left: 20,
                                    },
                                },
                                xaxis: {
                                    categories: pestIncidences.map(item => item.date) || facilityIncidences.map(item => item.date) || [],
                                    labels: {
                                        formatter: function (value) {
                                            let valueFormat = moment(value).format("DD MMM");

                                            return valueFormat
                                        }
                                    },
                                },
                            }}
                            series={[
                                {
                                    name: 'Pest incidences',
                                    data: pestIncidences.map(item => item.count)
                                },
                                {
                                    name: 'Facility incidences',
                                    data: facilityIncidences.map(item => item.count)
                                },
                            ]}
                            type="area"
                            width="100%"
                            height={260}
                        />}
                </div>

                <div className="report-cards-wrapper">

                    <CSVLink
                        style={{ display: "none" }}
                        data={quickCsvData}
                        filename={quickIncidencesExportName}
                        ref={quickCsvInstance}
                    />
                    <ReportCard
                        title="Quick Report"
                        button="dark"
                        startDateFilter={quickStartDateFilter}
                        handleStartDateFilter={(date) => setquickStartDateFilter(date)}
                        endDateFilter={quickEndDateFilter}
                        handleEndDateFilter={(date) => setquickEndDateFilter(date)}
                        generateReport={() => generateQuickCsvData(quickStartDateFilter, quickEndDateFilter)}
                    />
                    <CSVLink
                        style={{ display: "none" }}
                        data={detailedCsvData}
                        filename={detailedIncidencesExportName}
                        ref={detailedCsvInstance}
                    />
                    <ReportCard
                        title="Detailed Report"
                        startDateFilter={detailedStartDateFilter}
                        handleStartDateFilter={(date) => setDetailedStartDateFilter(date)}
                        endDateFilter={detailedEndDateFilter}
                        handleEndDateFilter={(date) => setDetailedEndDateFilter(date)}
                        generateReport={() => generateDetailedCsvData(detailedStartDateFilter, detailedEndDateFilter)}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
};

export default FacilityReportsComponent;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};