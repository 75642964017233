import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const updateRoute = async (bodyReq, idRoute) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/routes/${idRoute}`, {
            method: "PATCH",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};


export const getRouteById = async (idRoute) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/routes/${idRoute}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
}

export const getAllRoutesOfLocation = async (idLocation) => {
    const filter = 
      {
        where: {
          active: true
        },
        include: [
          {
            relation: "traps",
            scope: {
              where: {
                active: true
              }
            }
          }
        ]
      }
    
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/locations/${idLocation}/routes?filter=${JSON.stringify(filter)}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
}

export const createAssignTrapToRoute = async (idRoute, bodyReq) => {
  try {
      let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/routes/${idRoute}/traps`, {
          method: "GET",
          bodyReq,
          token: getUserToken()
      });

      if (response.error) throw response;
      return response;
  } catch (err) {
      throw err
  }
}
export const removeRouteFromLocation = async( idRoute) => {
  const bodyReq = {
      idLocation: ''
  }
  try {
      let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/routes/${idRoute}`, {
          method: "PATCH",
          bodyReq,
          token: getUserToken()
      });

      if (response.error) throw response;
      return response;
  } catch (err) {
      throw err
  }
}