import React, { useState, useEffect } from 'react';
import './ReportCard.scss';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import DatePicker from "react-datepicker";
import ButtonComponent from '../Button/Button';

const ReportCard = (props) => {
    const { title, button, startDateFilter, handleStartDateFilter, endDateFilter, handleEndDateFilter, generateReport } = props

    return (
        <div className="report-wrapper">
            <div className="header">
                <h2>{title}</h2>
                <AiOutlineQuestionCircle className="icon" />
            </div>

            <div className="date-filter">
                <div className="date-filter-label">Date range filter:</div>

                <div className="dates-wrapper">
                    <DatePicker
                        showTimeSelect
                        selected={startDateFilter}
                        onChange={handleStartDateFilter}
                        className="map-datepicker"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        popperModifiers={{
                            preventOverflow: {
                                enabled: true,
                            },
                        }}
                    />

                    <DatePicker
                        showTimeSelect
                        selected={endDateFilter}
                        onChange={handleEndDateFilter}
                        className="map-datepicker"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        popperModifiers={{
                            preventOverflow: {
                                enabled: true,
                            },
                        }}
                    />
                </div>
            </div>

            <ButtonComponent
                secondary={button?.toLowerCase() === 'dark'}
                style={button?.toLowerCase() === 'dark' ? { width: 'unset' } : { color: '#202020', width: 'unset' }}
                onClick={generateReport}
            >
                <div className="button-icon">
                    <FiDownload />
                </div>
                Generate report
            </ButtonComponent>
        </div>
    )
}

export default ReportCard;