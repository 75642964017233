import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Button from 'components/elements/Button/Button';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import ReviewCard from 'components/elements/ReviewCard/ReviewCard';
import SkeletonReviewCard from 'components/elements/SkeletonReviewCard/SkeletonReviewCard';
import Modal from 'react-modal';
import { searchTable } from 'services/utils';
import "./ReviewsComponent.scss";

const ReviewsComponent = (props) => {
    const { reviews, isModalOpen, setIsModalOpen, replyReview, denounceReview, replyLoading, denounceLoading, fetchingReviews } = props;
    const sortableColumns = [{ column: 'name', width: 'auto' }, { column: 'message', width: 'auto' }, { column: 'rating', width: 'auto' },];

    const [isReviewReplied, setIsReviewReplied] = useState("");
    const [reply, setReply] = useState("");
    const [currentReplyingReviewId, setCurrentReplyingReviewId] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (reviews.length) setFilteredData(reviews);
    }, [reviews])

    const toggleModal = (id) => {
        setCurrentReplyingReviewId(id);
        const selectedReview = reviews.filter((review) => review.id === id)[0];

        if (selectedReview && selectedReview.reply) {
            setIsReviewReplied(selectedReview.reply)
        } else {
            setIsReviewReplied("")
        }

        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsReviewReplied("");
        setReply("")
        setIsModalOpen(false);
    }

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search reviews ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, reviews);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="reviews-wrapper">
                <div className="reviews-header">
                    <h1>Reviews</h1>
                </div>

                {
                    fetchingReviews ?
                        <div className="reviews-empty padding-top">
                            <SkeletonReviewCard />
                            <SkeletonReviewCard />
                            <SkeletonReviewCard />
                        </div> :
                        filteredData.length ?
                            <TableOptions
                                rawData={filteredData}
                                sortableColumns={sortableColumns}
                                rowComponentProps={{
                                    clickReplyButton: toggleModal,
                                    clickDenounceButton: denounceReview,
                                    denounceLoading
                                }}
                                rowComponent={<ReviewCard />} /> :
                            <div className="reviews-empty">No reviews provided yet.</div>
                }
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => closeModal()}
                className="extrasense-modal"
                overlayClassName="modal-overlay with-background"
                contentLabel="Example Modal"
                ariaHideApp={false}>
                <div className="reply-modal__wrapper">
                    <div className="modal-header">Reply</div>
                    <div className="modal-body">
                        {
                            isReviewReplied ?
                                <p>{isReviewReplied}</p> :
                                <textarea
                                    rows="5"
                                    placeholder="Type your reply here ..."
                                    onChange={e => setReply(e.target.value)}></textarea>
                        }
                    </div>
                    <div className="modal-footer">
                        <Button className="light" onClick={() => closeModal()}>{isReviewReplied ? "Close" : "Cancel"}</Button>
                        {
                            !isReviewReplied &&
                            <Button loading={replyLoading} onClick={() => replyReview(currentReplyingReviewId, reply)}>Send</Button>
                        }
                    </div>
                </div>
            </Modal>
        </DashboardLayout >
    );
};

export default ReviewsComponent;