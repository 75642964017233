import React, {useState, useEffect, useRef} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import ButtonComponent from 'components/elements/Button/Button';
import InputComponent from 'components/elements/Input/Input';
import { ReactComponent as CameraSVG } from 'assets/icons/camera.svg';
import { ReactComponent as CheckCircleSVG } from 'assets/icons/checkcircle.svg';
import { ReactComponent as TrashSVG } from 'assets/icons/Trash.svg';
import { ReactComponent as Dots } from 'assets/icons/threedots.svg';
import Loading from 'components/elements/Loading/Loading';

const EditBuildingForm = (props) => {
    const { submit, modalVisible, setModalVisible, selectedLocation } = props
    const [numPage, setNumPage] = useState(1)
    const [initialValues, setInitialValues] = useState({nameLocation: '', imagesRoutes: [], namesRoutes: [], idLocation: '', idRoutes: []})
    const [loading, setLoading] = useState(true)
    const [showMenu, setShowMenu] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false)
    const inputRefs = useRef([])
    const formikRef = useRef();

    useEffect(() => {
        if(selectedLocation?.routes?.length){
            const newInitialValues = {
                nameLocation: selectedLocation.name,
                imagesRoutes: selectedLocation.routes.map((route) => route.img),
                namesRoutes: selectedLocation.routes.map((route) => route.name),
                idLocation: selectedLocation.idLocation,
                idRoutes: selectedLocation.routes.map((route) => route.idRoute),
                idRoutesToDelete : []
            }
            setInitialValues(newInitialValues)
            if(formikRef.current && modalVisible === true) formikRef.current.setValues(newInitialValues)
            for(let i = 0; i < selectedLocation?.routes?.length; i++) {
                if(!inputRefs.current[i]) {
                inputRefs.current[i] = React.createRef()
                }
            }
        }
    },[modalVisible, selectedLocation])

    const validationSchema = Yup.object().shape({
        nameLocation: Yup.string().required(),
        imagesRoutes: Yup.array().of(Yup.string()).required(),
        namesRoutes: Yup.array().of(Yup.string()).required(),
        idLocation: Yup.string().required(),
        idRoutes: Yup.array().of(Yup.string()),
        idRoutesToDelete: Yup.array().of(Yup.string()),
    })

    const uploadFile = async(event, index, formik) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          const imageDataURL = e.target.result
          formik.setFieldValue(`imagesRoutes[${index}]`, imageDataURL)
        }
        reader.readAsDataURL(file) 
    }

    const checkIsDisabled = (formik) => {
        let isDisabled = false
        formik.values.imagesRoutes.forEach((image) => {
        if(image.length === 0) isDisabled = true
        })
        formik.values.namesRoutes.forEach((name) => {
        if(name.length === 0) isDisabled = true
        })
        return isDisabled
    }

    const deleteImageRoute = (index, formik) => {
        formik.setFieldValue(`imagesRoutes[${index}]`, '')
    }

    const addRoute = (formik) => {
        formik.setFieldValue('namesRoutes', [...formik.values.namesRoutes, ''])
        formik.setFieldValue('imagesRoutes', [...formik.values.imagesRoutes, ''])
        inputRefs.current.push(null)
    }

    const handleClose = () => {
        setModalVisible(false)
        setNumPage(1)
    }

    const handleDeleteRoute = (formik, index) => {
        let currentNames = [...formik.values.namesRoutes]
        let currentImages = [...formik.values.imagesRoutes]
        let currentIds = [...formik.values.idRoutes]
        let currentIdsToDelete = [...formik.values.idRoutesToDelete];  
        let idRouteToRemove = currentIds[index]
        currentNames.splice(index, 1)
        currentImages.splice(index, 1)
        currentIds.splice(index, 1)
        currentIdsToDelete.push(idRouteToRemove) 
        formik.setFieldValue('namesRoutes', currentNames)
        formik.setFieldValue('imagesRoutes', currentImages)
        formik.setFieldValue('idRoutes', currentIds)
        formik.setFieldValue('idRoutesToDelete', currentIdsToDelete)
    }

    return (
        <>
        <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
                setLoading(true)
                setNumPage(2)
                await submit(values)
            } catch (error) {
                setErrors({ submit: error.message })
                alert("Something went wrong, contact your administrator")
                setModalVisible(false)
                throw error
            } finally {
                setSubmitting(false)
                setLoading(false)
            }
        }}
        >
            {(formik) => (
                <Form>
                    <ModalLayout
                    maxWidth={'783px'}
                    isOpen={modalVisible}
                    toggleModal={handleClose}
                    footer={
                        <div className='modal-footer-content'>
                            { numPage === 1 ? (    
                                <>                  
                                    <ButtonComponent
                                    className='modal-footer-button next'
                                    children={'Save'} onClick={()=>  formik.handleSubmit()}
                                    disabled={checkIsDisabled(formik)}
                                    />
                                </>
                    
                            ): (
                                <ButtonComponent children='Ok' onClick={handleClose} />
                            ) }
                        </div>
                    } 
                    header={
                        <div className='modal-header-content'>
                            {numPage === 1 ? ( <h2>Edit building</h2>) : null} 
                                <div className='modal-header-button-close' onClick={handleClose}>
                                    X
                                </div>
                        </div>
                    }
                    children={
                            <div className='modal-body-content'>
                                {numPage === 1 ? (
                                    <>
                                        <h3>Building name</h3>
                                        <InputComponent 
                                        formik={formik} 
                                        id='nameLocation' 
                                        name='nameLocation'
                                        type='textarea'
                                        placeholder={formik.values.nameLocation}
                                        />
                                        {formik.values.namesRoutes.map((name, index) => (
                                            <div className='modal-body-content-row'>
                                                <div className='modal-body-content-row-header'>
                                                    <h3>Route {index + 1} name</h3>
                                                    <InputComponent 
                                                        formik={formik} 
                                                        id={`namesRoutes[${index}]`}
                                                        name={`namesRoutes[${index}]`}
                                                        type='textarea'
                                                        placeholder={`Route ${index + 1} - ${name}`}
                                                    />

                                                    <div className='modal-body-content-row-header-menu' onClick={() => setShowMenu(!showMenu)}>
                                                        <Dots />
                                                        { showMenu ? (
                                                            <div className='modal-body-content-row-header-menu-dropdown' onClick={() => handleDeleteRoute(formik, index)}>
                                                                <TrashSVG />
                                                                <span>Delete</span>
                                                            </div>
                                                        ): null }
                                                    </div>
                                                </div>
                                                <h3>Image</h3>
                                                {formik.values.imagesRoutes[index]?.length > 0 ? (
                                                     <div className='modal-body-content-upload-button'>
                                                        <div className='modal-body-content-delete-button' onClick={() => deleteImageRoute(index, formik)}>
                                                            <TrashSVG />
                                                        </div>
                                                        <img src={formik.values.imagesRoutes[index]} alt="Selected route" />        
                                                    </div>
                                                ):(
                                                    <div onClick={() => inputRefs.current[index].click()}  className='modal-body-content-upload-button'>
                                                         <input key={index}  ref={ref => inputRefs.current[index] = ref} type="file" onChange={(event) => uploadFile(event, index, formik)} />
                                                        <CameraSVG />
                                                        <h3>Upload picture</h3>
                                                        <p>Please upload a picture of the route</p>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        <div className='modal-body-content-upload-route' onClick={() => addRoute(formik)}>
                                            <h3>+ Add route</h3>
                                        </div>
                                    </>
                                ) : null}
                                {numPage === 2 ? (
                                    <div className='modal-body-content-success'>
                                        {loading ? (
                                            <Loading />
                                        ): (
                                            <>
                                            <CheckCircleSVG />
                                            <h2>Building updated</h2>
                                            </>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                    }
                    />
                </Form>
            )}
        </Formik>
        <ModalLayout
        maxWidth={'700px'}
        isOpen={alertVisible}
        toggleModal={() => setAlertVisible(false)}
        footer={
          <div className='modal-footer-alert-delete'>
            <ButtonComponent className='next' children='Ok' onClick={() => setAlertVisible(false)} />
          </div>
        } 
        header={
          <div>
            <div className='modal-header-button-close' onClick={() => setAlertVisible(false)}>
                X
            </div>
          </div>
        }
        children={
                <div className='modal-body-alert-delete'>
                    <h2>Ruta eliminada</h2>
                </div>
        }
        />
        </>
    )
}

export default EditBuildingForm