import { faCommentDots, faStar, faUser } from '@fortawesome/free-regular-svg-icons';
import { faBars, faChevronDown, faMapMarked, faSignOutAlt, faTimes, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as ChartLine } from 'assets/icons/ChartLine.svg';
import { ReactComponent as BugSVG } from 'assets/icons/bugsvg.svg';
import { ReactComponent as Building } from 'assets/icons/building.svg';
import { ReactComponent as MapTrifold } from 'assets/icons/mapTrifold.svg';
import { ReactComponent as CompaniesIcon } from 'assets/icons/companiesIcon.svg';
import React, { useEffect, useState } from "react";
import { AiFillBug, AiOutlineUser } from 'react-icons/ai';
import { BsChatDots } from "react-icons/bs";
import { HiSearch, HiViewList } from 'react-icons/hi';
import { RiFeedbackLine } from 'react-icons/ri';
import OutsideClickHandler from 'react-outside-click-handler';
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { updateUser } from "redux/actions/userActions";
import { version } from '../../../package.json';
import "./DashboardLayout.scss";

const DashboardLayout = (props) => {
    const { user, updateUser } = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [screenDimensions, setScreenDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [openMenu, setOpenMenu] = useState(null);
    const history = useHistory()

    const updateWindowDimensions = () => {
        setScreenDimensions({ width: window.innerWidth, height: window.innerHeight });
    }

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);

        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, [])

    const pestControllerRoutes = [
        {
            icon: <FontAwesomeIcon icon={faCommentDots} />,
            label: 'Contacts',
            routeLink: '/contacts',
        },
        {
            icon: <FontAwesomeIcon icon={faStar} />,
            label: 'Reviews',
            routeLink: '/reviews',
        },
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            label: 'Profile',
            routeLink: '/manage-pest-controller',
        },
    ];

    const adminRoutes = [
        {
            imageIconInactive: require('assets/icons/map-white.png'),
            imageIconActive: require('assets/icons/map-dark-green.png'),
            label: 'Map',
            routeLink: '/admin/map',
        },
        {
            imageIconInactive: require('assets/icons/exclamation-white.png'),
            imageIconActive: require('assets/icons/exclamation-dark-green.png'),
            label: 'Pest Incidences',
            routeLink: '/admin/incidences',
        },
        {
            imageIconInactive: require('assets/icons/bug-white.png'),
            imageIconActive: require('assets/icons/bug-dark-green.png'),
            label: 'Pest Pros',
            routeLink: '/admin/pests',
        },
        {
            icon: <HiSearch />,
            label: 'Entomologists',
            routeLink: '/admin/reviewers',
        },
        {
            icon: <BugSVG />,
            label: 'Traps',
            routeLink: '/admin/traps',
        },
        {
            icon: <MapTrifold />,
            label: 'Routes',
            routeLink: '/admin/routes',
        },
        {
            icon: <Building />,
            label: 'Buildings',
            routeLink: '/admin/building',
        },
        {
            icon: <FontAwesomeIcon icon={faMapMarked} />,
            label: 'Service Issues',
            routeLink: '/admin/issues-map'
        },
        {
            icon: <CompaniesIcon />,
            label: 'Companies',
            routeLink: '/admin/companies',
        },
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            label: 'Users',
            routeLink: '/admin/users',
            subroutes: [
                {
                    label: 'Begbug',
                    routeLink: '/begbug',
                    icon:  <FontAwesomeIcon icon={faCircle} color='#14AF8D' className='pestid-icon' />,
                },
                {
                    label: 'PestID',
                    routeLink: '/pestid',
                    icon:  <FontAwesomeIcon icon={faCircle} color='#14AF8D' className='pestid-icon' />,
                }
            ]
        },
        {
            icon: <ChartLine />,
            label: 'Reports',
            routeLink: '/admin/reports',
        },
        {
            icon: <FontAwesomeIcon icon={faStar} />,
            label: 'Reviews',
            routeLink: '/admin/reviews',
        },
        {
            icon: <RiFeedbackLine />,
            label: 'Feedbacks',
            routeLink: '/admin/feedbacks',
        },
        {
            icon: <HiViewList />,
            label: 'Species',
            routeLink: '/admin/species',
        },
        {
            icon: <AiFillBug />,
            label: 'Pest library',
            routeLink: '/admin/bugs',
        },
        // {
        //     icon: <AiFillBug />,
        //     label: 'Pest ID',
        //     routeLink: '/admin/pestid',
        // },
    ];

    const generalMillisUserRoutes = [
        {
            icon: <BugSVG />,
            label: 'Traps',
            routeLink: '/admin/traps',
        },
        {
            icon: <MapTrifold />,
            label: 'Routes',
            routeLink: '/admin/routes',
        },
        {
            icon: <Building />,
            label: 'Buildings',
            routeLink: '/admin/building',
        },
        {
            icon: <FontAwesomeIcon icon={faMapMarked} />,
            label: 'Service Issues',
            routeLink: '/admin/issues-map'
        },
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            label: 'Users',
            routeLink: '/admin/users/pestid',
        },
        {
            icon: <ChartLine />,
            label: 'Reports',
            routeLink: '/admin/reports',
        },
    ];

    const facilityManagerRoutes = [
        // {
        //     imageIconInactive: require('assets/icons/map-white.png'),
        //     imageIconActive: require('assets/icons/map-dark-green.png'),
        //     label: 'Dashboard',
        //     routeLink: '/facility/map',
        // },
        // {
        //     imageIconInactive: require('assets/icons/exclamation-white.png'),
        //     imageIconActive: require('assets/icons/exclamation-dark-green.png'),
        //     label: 'Pest Cases',
        //     routeLink: '/pest/cases',
        //     subroutes: [
        //         {
        //             label: 'Open Pest Cases',
        //             routeLink: '/open',
        //             icon: <IoIosArrowDropright />,
        //         },
        //         {
        //             label: 'Closed Pest Cases',
        //             routeLink: '/closed',
        //             icon: <IoIosArrowDropright />,
        //         },
        //     ],
        // },
        // {
        //     imageIconInactive: require('assets/icons/facility-plan-white.png'),
        //     imageIconActive: require('assets/icons/facility-plan-dark-green.png'),
        //     label: 'Facility Cases',
        //     routeLink: '/facility/cases',
        //     subroutes: [
        //         {
        //             label: 'Open Facility Cases',
        //             routeLink: '/open',
        //             icon: <IoIosArrowDropright />,
        //         },
        //         {
        //             label: 'Closed Facility Cases',
        //             routeLink: '/closed',
        //             icon: <IoIosArrowDropright />,
        //         },
        //     ],
        // },
        {
            icon: <AiOutlineUser />,
            label: 'Users',
            routeLink: '/manage/users',
        },
        {
            imageIconInactive: require('assets/icons/graph-white.png'),
            imageIconActive: require('assets/icons/graph-green.png'),
            label: 'Reports',
            routeLink: '/manage/reports',
        },
        {
            imageIconInactive: require('assets/icons/gear-white.png'),
            imageIconActive: require('assets/icons/gear-green.png'),
            label: 'Settings',
            routeLink: '/settings',
        },
    ];

    const reviewerRoutes = [
        {
            imageIconInactive: require('assets/icons/map-white.png'),
            imageIconActive: require('assets/icons/map-dark-green.png'),
            label: 'Map',
            routeLink: '/admin/map',
        },
        {
            imageIconInactive: require('assets/icons/exclamation-white.png'),
            imageIconActive: require('assets/icons/exclamation-dark-green.png'),
            label: 'Pest Incidences',
            routeLink: '/admin/incidences',
        },
        {
            icon: <BsChatDots />,
            label: 'Chats',
            routeLink: '/chat',
        },
        {
            icon: <HiViewList />,
            label: 'Species',
            routeLink: '/admin/species',
        },
        {
            icon: <AiFillBug />,
            label: 'Pest library',
            routeLink: '/admin/bugs',
        },
        {
            icon: <AiFillBug />,
            label: 'Pest ID',
            routeLink: '/admin/pestid',
        },
    ];
    

    const routes = user.role === 'PestController' ? pestControllerRoutes : user.role === 'Reviewer' ? reviewerRoutes : user.role === 'Manager' ? facilityManagerRoutes : user.role === 'Admin'  ? adminRoutes : generalMillisUserRoutes;;

    const { children, location: { pathname }, topbarLeftComponent } = props;
    const currentPath = pathname;

    const username = `${user.name || ''} ${user.lastName || ''}`
    const userphoto = user.urlProfileImage;

    const logout = () => {
        updateUser({});
        if(user.role === 'cmsmanager'){
           history.push('/login/pestid')
        }else{
            history.push('/login')
        }
        localStorage.clear();
    }

    const handleOpenMenu = (link) => setOpenMenu(link);

    return (
        <div className="dashboard-layout">
            <div className={screenDimensions.width < 768 && mobileMenuOpen ? "sidebar mobile-visible" : "sidebar"}>
                <div className="logo">
                    <img className="icon" src={require('assets/images/logo-white.png')} alt="logo-full" />
                    <img className="bedbug" src={require('assets/images/bedbug.png')} alt="logo-bedbug" />
                    <span className="version">v{version}</span>
                </div>

                {
                    routes.map((route, i) => (
                        SideBarItems({ route, i, currentPath, openMenu, handleOpenMenu, user })
                    ))
                }
            </div>

            <div className="content">
                <div className="top-bar">
                    <div className="mobile-options">
                        <div className="sidebar-toggle" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                            {
                                mobileMenuOpen ?
                                    <FontAwesomeIcon icon={faTimes} /> :
                                    <FontAwesomeIcon icon={faBars} />
                            }
                        </div>

                        <div className="logo">
                            <img className="icon" src={require('assets/images/logo-white.png')} alt="logo-full" />
                            <img className="bedbug" src={require('assets/images/bedbug.png')} alt="logo-bedbug" />
                            <span className="version">v{version}</span>
                        </div>
                    </div>

                    {topbarLeftComponent}

                    <div className="logged-user-info">
                        <OutsideClickHandler onOutsideClick={(e) => setIsDropdownOpen(false)}>
                            <div className="logged-user-info__content" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                <img src={userphoto || require('assets/images/user-placeholder.jpg')} alt="user-placeholder" />
                                <span title={username}>{username}</span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>

                            {
                                isDropdownOpen &&
                                <div className="logged-user-info__options">
                                    <div className="option" onClick={() => logout()}>
                                        <FontAwesomeIcon icon={faSignOutAlt} />
                                        <span>Logout</span>
                                    </div>
                                </div>

                            }
                        </OutsideClickHandler>
                    </div>
                </div>
                <div className="content-children">
                    {children}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout))

const SideBarItems = ({ route, i, currentPath, openMenu, handleOpenMenu, user }) => {
    const hasSubroutes = route.subroutes?.length > 0;
    const isMenuOpen = (currentPath?.includes(route.routeLink) || openMenu === route.routeLink)
    return (
        <div className="route">
            {hasSubroutes ?
                 <Link
                 to={route.routeLink}
                 key={i}
                 className={`sidebar-item ${currentPath.includes(route.routeLink) ? "active" : ""}`}
                 onMouseEnter={() => handleOpenMenu(route.routeLink)}
             >
                    <div className="sidebar-icon">
                        {
                            route.icon ?
                                route.icon :
                                <>
                                    <img className="icon-active" src={route.imageIconActive} alt="icon-active" />
                                    <img className="icon-inactive" src={route.imageIconInactive} alt="icon-active" />
                                </>
                        }
                    </div>
                    <div className="sidebar-label">{route.label}</div>
                </Link>
                : <Link
                    to={route.routeLink}
                    key={i}
                    className={`sidebar-item ${currentPath.includes(route.routeLink) ? "active" : ""}`}>
                    <div className="sidebar-icon">
                        {
                            route.icon ?
                                route.icon :
                                <>
                                    <img className="icon-active" src={route.imageIconActive} alt="icon-active" />
                                    <img className="icon-inactive" src={route.imageIconInactive} alt="icon-active" />
                                </>
                        }
                    </div>
                    <div className="sidebar-label">{route.label}</div>
                </Link>
            }
            {isMenuOpen && route.subroutes?.map((subroute, si) => {
                return (
                    <Link
                        to={route.routeLink + subroute.routeLink}
                        key={si}
                        className={`sidebar-subroutes-link ${currentPath?.includes(`${route.routeLink}${subroute.routeLink}`) ? "active" : null}`}
                    >
                        <div className="sidebar-icon">
                            {
                                subroute.icon ?
                                    subroute.icon :
                                    <>
                                        <img className="icon-active" src={subroute.imageIconActive} alt="icon-active" />
                                        <img className="icon-inactive" src={subroute.imageIconInactive} alt="icon-active" />
                                    </>
                            }
                        </div>
                        <div className="sidebar-label">{subroute.label}</div>
                    </Link>
                )
            })}
        </div>
    )
}