import React, { useState } from "react";
import Modal from 'react-modal';
import { Formik, Form } from "formik";
import * as yup from "yup";
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import "./ManageSubspecieModalComponent.scss";

const ManageSubspecieModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        selectedSubspecie,
        submit,
        isLoading
    } = props;

    const modalType = isOpen;
    const addOrEdit = selectedSubspecie?.id ? "Manage" : "Add"

    const initialValues = modalType === "subname" ? {
        nameEng: selectedSubspecie?.nameEng || "",
        nameSpa: selectedSubspecie?.nameSpa || "",
    } :
        {
            name: selectedSubspecie?.name
        }

    const validation = modalType === "subname" ? yup.object().shape({
        nameEng: yup.string().required("Name is a required field"),
        nameSpa: yup.string().required("Name is a required field"),
    }) :
        yup.object().shape({
            name: yup.string().required("Name is a required field"),
        })

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            className="extrasense-modal"
            overlayClassName="modal-overlay with-background modal-subspecies"
            ariaHideApp={false}>
            <div className="edit-profile-modal__wrapper">
                <div className="modal-header">
                    <div>{addOrEdit}  {modalType}</div>
                </div>

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={(values) => submit(selectedSubspecie?.id, values)}>
                    {(formik) => (
                        <Form>
                            {modalType === "subname" ? <div className="modal-body">
                                <div className="input-wrapper fill-row">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="nameEng"
                                        label="Common name in English"
                                        placeholder="Common name in English"
                                        className="formControl" />
                                </div>
                                <div className="input-wrapper fill-row">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="nameSpa"
                                        label="Common name in Spanish"
                                        placeholder="Common name in Spanish"
                                        className="formControl" />
                                </div>
                            </div> :
                                <div className="modal-body">
                                    <div className="input-wrapper fill-row">
                                        <Input
                                            formik={formik}
                                            type="text"
                                            id="name"
                                            label="Name"
                                            placeholder="Scientific name"
                                            className="formControl" />
                                    </div>
                                </div>
                            }

                            <div className="modal-footer">
                                <Button className="light" onClick={() => setIsOpen(false)}>Cancel</Button>
                                <Button loading={isLoading} type="button" onClick={formik.handleSubmit}>Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};


export default ManageSubspecieModalComponent;