import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import TableRow from 'components/elements/TableRow/TableRow';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import { searchTable } from 'services/utils';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import "./UsersComponent.scss";

const csvName = `users-${moment().format("YYYY-MM-DD")}`;

const UsersComponent = (props) => {
    const { users, fetchingUsers, generateCsvData, csvData } = props;
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (users.length) setFilteredData(users);
    }, [users])

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search users ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, users);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="users-wrapper">
                <div className="users-header">
                    <h1>Users</h1>
                </div>
                <div className="top-buttons">
                    <CSVLink
                        className="csv-download-btn"
                        data={csvData}
                        filename={csvName}
                        asyncOnClick={true}
                        onClick={(event, done) => {
                            generateCsvData().then(() => {
                                done();
                            });
                        }}
                    >
                        <FontAwesomeIcon
                            className="icon"
                            icon={faDownload}
                        />
                        <span>Export to CSV</span>
                    </CSVLink>
                </div>
                {
                    fetchingUsers ?
                        <SkeletonTable /> :
                        filteredData.length ?
                            <TableOptions
                                rawData={filteredData}
                                hiddenColumns={['id']}
                                isTable={true}
                                rowHeader={<TableHeader hiddenColumns={["id", "threat", "user", "responseTime", "os"]} />}
                                rowComponent={<TableRow />} /> :
                            <div className="users-empty">No Users registered yet.</div>
                }
            </div>
        </DashboardLayout>
    );
};

export default UsersComponent;