import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const changeContactedContactService = async (id, data) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.contacts}/${id}`, {
            method: "PATCH",
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};