import React, { useState } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled, TablePagination } from '@mui/material';
import { ReactComponent as NotePencilSVG } from 'assets/icons/NotePencilGreen.svg'
import { ReactComponent as TrashRed } from 'assets/icons/TrashRed.svg'
import { ReactComponent as UserIcon } from 'assets/icons/userGrayIcon.svg'

import './CompaniesTable.scss'
import InputComponent from "components/elements/Input/Input";

const rowsTraps = ['Company name', 'Mail', 'Actions' ]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded'
  }))

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded',
    fontWeight: 600,
  }))

const StyledTableCellButtons = styled(TableCell)(({ theme }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#F6F6F6',
    divider: false,
    boxShadow: 'none',
    fontFamily: 'GothamRounded',
    minWidth: '90px',
    maxWidth:'90px',
    display: 'flex',
    justifyContent: 'space-around',
}))


const CompaniesTable = (props) => {
    const {companies, onEdit, onDelete, onPressUsers } = props
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [searchTerm, setSearchTerm] = useState('')
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    return (
      <>
      <div className='companies-content-search-table'>
          <InputComponent 
          InputProps={{
          disableUnderline: true
          }}
          id='search' 
          name='search'
          type='text'
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder='Search'
          />
      </div>
      <TableContainer className='companies-content-table' component={(props) => <Paper {...props} style={{borderRadius: '17px'}} />}>
      <Table>
        <TableHead>
          <TableRow>
              {rowsTraps.map((row) => (
                  <StyledTableCellHead>{row}</StyledTableCellHead>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {companies
         .filter((company) => {
          return company.idCompany.toLowerCase().includes(searchTerm.toLowerCase());
          })
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((company) => (
            <TableRow key={company.id}>
                <StyledTableCell >{company?.name}</StyledTableCell>
                <StyledTableCell>{company?.mail}</StyledTableCell>
                <StyledTableCellButtons>
                  <div className="companies-table-button">
                    <NotePencilSVG  onClick={() => onEdit(company)}/>
                  </div>
                  <div className="companies-table-button">
                    <TrashRed onClick={() => onDelete(company)} />
                  </div>
                  <div className="companies-table-button">
                    <UserIcon onClick={() => onPressUsers(company)} />
                  </div> 
                </StyledTableCellButtons>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
      component="div"
      count={companies.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 25, 50]}
    />
    </TableContainer>
      </>




    )
}

export default CompaniesTable