import React, { useState, useEffect } from 'react'
import { updateIncidenceReviewService } from 'services/entomologist';
import IncidenceReviewCardComponent from '../components/IncidenceReviewCardComponent'

const IncidenceReviewCardContainer = (props) => {
    const { onChangeStatus = () => { } } = props;
    const [loading, setLoading] = useState(false);

    const updateIncidence = async (id, data) => {
        try {
            setLoading(data.status);
            const res = await updateIncidenceReviewService(id, data);
            await onChangeStatus();
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <IncidenceReviewCardComponent
            {...props}
            {...{ updateIncidence, loading }}
        />
    )
}
export default IncidenceReviewCardContainer;