import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBug, faClock, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Button from 'components/elements/Button/Button';
import moment from 'moment';
import { Lightbox } from "react-modal-image";
import "./ContactCard.scss";
import IncidenceMap from "../IncidenceMap/IncidenceMap";
import { AiOutlineMail } from 'react-icons/ai';
import { BsChatDots } from 'react-icons/bs';
import { FiEdit3 } from 'react-icons/fi'
import { BiDollar } from 'react-icons/bi'

const ContactCard = props => {
    const {
        data,
        data: {
            id,
            image,
            lat,
            username,
            user,
            userData,
            address,
            bug,
            pest_prediction,
            status,
            source,
            review_date,
            who_reviewed_last,
            bugId,
            submitted_date,
            isContact,
            isContacted,
            chatStatus,
            isDeleted,
            emailShared,
            sendEmail,
            requestContact,
            isPaid,
            paymentType
        },
        user: userState,
        selectedIncidences = [],
        selectIncidence = () => { },
        history,
        setContacted,
        editIncidence,
        onEditClick
    } = props;
    const [isLocationOpen, setIsLocationOpen] = useState(false);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);

    const role = userState?.role;
    const showEntomologistdata = role === "Reviewer";
    const allowToReview = status === "Captured" && showEntomologistdata;

    const [isPictureModalVisible, setIsPictureModalVisible] = useState(false);
    const contacted = chatStatus === "closed" || chatStatus === "open";
    const pendingContact = !contacted && requestContact;

    return (
        <div className={`contact-card ${isDeleted && "deleted"}`} >
            <Link
                to={{
                    pathname: `/chat/${id}`,
                    state: {
                        image,
                        bug,
                        username
                    }
                }}
                className={`chat-icon ${chatStatus ? "contacted" : ""} ${pendingContact ? "pending" : ""} `} > <BsChatDots /></Link>

            {sendEmail === true ? <div className="payment-icon active"> <AiOutlineMail /></div> : emailShared && <div className="payment-icon"> <AiOutlineMail /></div>}
            {
                isPaid === true ?
                    <div className={`payment-icon ${paymentType === 'Value Option' && 'immediate'} ${paymentType === 'Economic Option' && 'slow'}`}> <BiDollar /></div > :
                    null
            }

            {
                isContact &&
                <div className="checkbox-contact">
                    <input
                        id={`contacted-${id}`}
                        type="checkbox"
                        checked={isContacted}
                        onChange={e => setContacted && setContacted(id, e.target.checked)} />
                    <label htmlFor={`contacted-${id}`}>Already contacted</label>
                </div>
            }

            <div className="select-incidence">
                {allowToReview &&
                    <input
                        id={`seleceted-${id}`}
                        type="checkbox"
                        checked={selectedIncidences.filter((incidenceData) => incidenceData.id === id)[0]}
                        onChange={e => selectIncidence(data, e.target.checked)} />
                }
            </div>

            <div className={`contact-content`} >
                <div className={`contact-picture  hoverable`} onClick={() => setIsPictureModalVisible(image)}>
                    <img src={image || "http://placehold.it/90x90"} alt="" />
                </div>
                <div className={`contact-field user  ${user && "clickable"}`} onClick={() => userData && setIsUserModalOpen(true)} >
                    <span>{user}</span>
                </div>


                {address && <div className="contact-field " >
                    <span>{address}</span>
                </div>}

                <div className="contact-field pest_prediction" >
                    <span>{pest_prediction}</span>
                </div>

                <div className="contact-field confidence">
                    <span>{data["%_confidence"]}</span>
                </div>

                <div className="contact-field submitted_date">
                    <span>{moment(submitted_date).format('MM/DD/YYYY HH:mm')}</span>
                </div>

                <div className="contact-field location" onClick={() => setIsLocationOpen(true)}>
                    <span> {data.location}</span>
                    <IncidenceMap incidence={data} isOpen={isLocationOpen} setIsOpen={setIsLocationOpen} />
                </div>

                <div className="contact-field status">
                    <span>{status}</span>
                </div>

                <div className="contact-field source">
                    <span>{source}</span>
                </div>

                <div className="contact-field review_date">
                    <span>{review_date ? moment(review_date).format('MM/DD/YYYY HH:mm') : "-"}</span>
                </div>

                <div className="contact-field who_reviewed_last">
                    <span>{who_reviewed_last || "-"}</span>
                </div>

                {editIncidence && <div className="edit_incidence" onClick={() => onEditClick(id)}>
                    <span ><FiEdit3 /></span>
                </div>}
                {role === "Admin" &&
                    <div className="contact-field " style={{ flex: '0 0 100px' }}>
                        {data.action}
                    </div>
                }

            </div>
            {
                bugId &&
                <div className="contact-field">
                    <div className="icon">
                        <FontAwesomeIcon icon={faBug} />
                    </div>
                    <span>{bugId}</span>
                </div>
            }

            {
                isPictureModalVisible &&
                <Lightbox
                    medium={isPictureModalVisible}
                    alt="Incidence bug image"
                    onClose={() => setIsPictureModalVisible(false)}
                />
            }

            <Modal
                isOpen={isUserModalOpen}
                onRequestClose={() => setIsUserModalOpen(false)}
                className="extrasense-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="user-info-modal__wrapper">
                    <div className="modal-header">
                        <div>User information</div>
                    </div>
                    <div className="modal-body">
                        <div className="user-picture">
                            <img src={userData?.urlProfileImage || require('assets/images/user-placeholder.jpg')} alt="user-profile" />
                        </div>
                        <div className="user-fields">
                            <div className="user-field">
                                <div className="user-field__title">Full name</div>
                                <div className="user-field__value">{`${userData?.name || ''} ${userData?.surname || ''}`}</div>
                            </div>
                            <div className="user-field">
                                <div className="user-field__title">E-mail</div>
                                <div className="user-field__value">{userData?.email}</div>
                            </div>
                            <div className="user-field">
                                <div className="user-field__title">Zipcode</div>
                                <div className="user-field__value">{userData?.zipcode || "No zipcode"}</div>
                            </div>
                            <div className="user-field">
                                <div className="user-field__title">Reviews sent</div>
                                <div className="user-field__value">{(userData?.reviews && userData?.reviews.length) || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button className="light" onClick={() => setIsUserModalOpen(false)}>Close</Button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default ContactCard;