import React, { useState, useEffect } from 'react';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import './UsersComponent.scss';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import TableRow from 'components/elements/TableRow/TableRow';

const UsersComponent = (props) => {
    const { fetchingUsers, employees, user, toggleAbledUser } = props
    const [filteredData, setFilteredData] = useState()

    useEffect(() => {
        if (employees) {
            const abledAccessEmployees = employees.filter((employee) => employee.hadAccess === true)
            const disabledAccessEmployees = employees.filter((employee) => employee.hadAccess === false)

            const employeesByAccess = abledAccessEmployees.concat(disabledAccessEmployees);
            setFilteredData(employeesByAccess)
        }
    }, [employees])

    return (
        <DashboardLayout>
            <div className="users-header">
                <h1 >Users</h1>
            </div>
            <div className="facility-users-content-wrapper">
                <div className="qr-code-wrapper">
                    <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${user.idFacility}`} alt="qr-code" />
                    <div className="qr-description">
                        Facility QR Code
                    </div>
                </div>
                <div className="table-wrapper">
                    {
                        fetchingUsers ?
                            <SkeletonTable rounded /> :
                            filteredData?.length ?
                                <TableOptions
                                    rounded
                                    rawData={filteredData}
                                    rowComponentProps={{ toggleAbledUser }}
                                    hiddenColumns={['hadAccess', 'id']}
                                    isTable={true}
                                    rowHeader={<TableHeader />}
                                    rowComponent={<TableRow />} /> :
                                <div className="users-empty">No Users registered yet.</div>
                    }
                </div>
            </div>
        </DashboardLayout>
    )
}

export default UsersComponent;