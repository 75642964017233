import React, { useState } from 'react'

import LoginPestIDComponent from "../components/LoginPestIDComponent"
import { loginServicePestID } from 'services/usersPestID'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";

export const LoginPestID = (props) => {
    const { updateUser} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const history = useHistory()
    
    const submit = async (values) => {
        setLoading(true)

        const data = {
            email: values.email,
            password: values.password
        }

        try {
            const response = await loginServicePestID(data)
            updateUser(response)
        } catch (error) {
            setError(true)
        }finally {
            setLoading(false)
        }
    }

    return (<LoginPestIDComponent 
                {...props}
                submit={submit}
                loading={loading}
                error={error}
                history={history}
            />)

}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(LoginPestID));