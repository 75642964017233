import ButtonComponent from 'components/elements/Button/Button'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import React, { useState } from 'react'
import AlertDelete from 'components/elements/AlertDelete/AlertDelete'
import CompaniesTable from './CompaniesTable'
import './CompaniesComponent.scss'
import Loading from 'components/elements/Loading/Loading'
import CompanyForm from './CompanyForm'
import { useHistory } from 'react-router-dom';



export const CompaniesComponent = (props) => {
    const { companies, loading, submit, update, eliminate } = props
    const [modalVisible, setModalVisible] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false)
    const [companySelected, setCompaySelected] = useState(null)
    const history = useHistory()

    const onCreate = () => {
      setCompaySelected(null)
      setModalVisible(true)
    }

    const onEdit = (item) => {
      setCompaySelected(item)
      setModalVisible(true)
    }

    const onDelete = (item) => {
      setCompaySelected(item)
      setAlertVisible(true)
    }

    const handleDelete = async () => {
      try{
        await eliminate(companySelected.idCompany)
      }catch(error){
        console.error(error)
      }
    }

    const onPressUsers = (item) => {
      setCompaySelected(item)
      history.push(`/admin/users/pestID/${item.idCompany}`)
    }
 
  return (
    <DashboardLayout>
      <div className='companies-wrapper'>
        <div className='companies-header'>
          <div className='companies-header-title'>
            <h1>Companies</h1>
          </div>
          <div className='companies-header-button'>
            <ButtonComponent className='companies-header-button' children={'+ Create companies'} onClick={onCreate}/>
          </div>
        </div>
        {loading ? (
          <Loading />
        ): (
          <div className='companies-content'>
            <div className='companies-table'>
              <CompaniesTable companies={companies} onEdit={onEdit} onDelete={onDelete} onPressUsers={onPressUsers}/>
            </div>
            <CompanyForm modalVisible={modalVisible} setModalVisible={setModalVisible}  submit={companySelected ? update : submit} companySelected={companySelected}/>
            <AlertDelete alertVisible={alertVisible} setAlertVisible={setAlertVisible} onDelete={handleDelete} title='Delete company' subtitle='Are you sure you want to delete this company?' />
          </div>
        )}
      </div> 
    </DashboardLayout>
  )
}

export default CompaniesComponent