import React, { useState } from 'react'
import { toast } from "react-toastify"
import ToastrContent from 'components/elements/Toastr/Toastr'
import ForgotPasswordPestIDComponent from '../components/ForgotPasswordPestIDComponent'
import { sendResetEmailService } from 'services/usersPestID'

const ForgotPasswordPestID = (props) => {
    const [loading, setLoading] = useState(false)

    const submit = async ({ email }) => {
        try {
            setLoading(true)
            await sendResetEmailService(email)
            toast(
                <ToastrContent
                    type="success"
                    title={"Success"}
                    message={"Your email has been sent"}
                />,
                {
                    progressClassName: "toastr-progress-bar success",
                }
            )
        }
        catch (e) {
            console.log('error updating', e)
            toast(
                <ToastrContent
                    type="danger"
                    title={"Error"}
                    message={"Error sending email"}
                />,
                {
                    progressClassName: "toastr-progress-bar success",
                }
            )
        }
        finally {
            setLoading(false)
        }
    }

    return <ForgotPasswordPestIDComponent {...props} submit={submit} loading={loading} />

}

export default ForgotPasswordPestID