import React, { useState, useEffect } from "react";
import UsersComponent from "../components/UsersComponent";
import { withRouter } from "react-router-dom";
import { getUsersService } from 'services/user';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import _ from "lodash";

momentDurationFormatSetup(moment);

const Users = (props) => {
    const { history } = props;
    const [users, setUsers] = useState([]);
    const [fetchingUsers, setFetchingUsers] = useState(false);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        setFetchingUsers(true);
        getUsers();
    }, [])

    const getUsers = async () => {
        const data = await getUsersService();
        const dataParsed = data.map(user => {
            return {
                id: user.id,
                name: `${user.name || ''} ${user.surname || ''}`,
                email: user.email,
                zipcode: user.zipcode || "No zipcode",
                reviews_sent: (user.reviews && user.reviews.length) || 0,
                last_time_login: (user.lastLogin && moment(user.lastLogin).format('MM/DD/YYYY LT')) || '-',
                usage_time: (user.usageTime && moment.duration(user.usageTime, "milliseconds").format("d[d]h[h]m[m]s[s]")) || 0
            }
        })

        setUsers(dataParsed);
        setFetchingUsers(false);
    }

    const generateCsvData = async () => {
        const csvDataParsed = users;
        const csvFormat = [];
        csvFormat.push(_.keys(csvDataParsed[0]));
        csvDataParsed.forEach(csvRow => {
            csvFormat.push(_.values(csvRow));
        });

        setCsvData(csvFormat);
    }

    return <UsersComponent
        users={users}
        history={history}
        fetchingUsers={fetchingUsers}
        generateCsvData={generateCsvData}
        csvData={csvData}
    />;
};

export default withRouter(Users);