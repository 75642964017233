import React, { useState, useEffect } from 'react';
import { getURLResetPassword } from 'services/user';
import ForgotPasswordComponent from '../components/ForgotPasswordComponent';
import { toast } from "react-toastify";
import ToastrContent from 'components/elements/Toastr/Toastr';

const ForgotPassword = (props) => {
    const [loading, setLoading] = useState(false);

    const submit = async ({ email }) => {
        try {
            setLoading(true);

            const resp = await getURLResetPassword(email);

            toast(
                <ToastrContent
                    type="success"
                    title={"Success"}
                    message={"Your email has been sent"}
                />,
                {
                    progressClassName: "toastr-progress-bar success",
                }
            );
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false);
        }
    }

    return <ForgotPasswordComponent {...props} submit={submit} loading={loading} />

}

export default ForgotPassword;