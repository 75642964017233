import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBug, faClock, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Lightbox } from "react-modal-image";
import "./ContactPestProCard.scss";

const ContactCard = props => {
    const {
        data,
        data: {
            id,
            image,
            user,
            address,
            location,
            pest_prediction,
            review_date,
            who_reviewed_last,
            lastReviewerid,
            bugId,
            submitted_date,
            threat,
            // user,
            isContact,
            isContacted,
            os,
        },
        user: userState,
        selectedIncidences = [],
        selectIncidence = () => { },
        clickCard,
        setContacted
    } = props;

    const role = userState?.role;

    const [isPictureModalVisible, setIsPictureModalVisible] = useState(false);

    const getThreatName = threatValue => {
        return threatValue.toLowerCase();
    }

    const threatName = threat && getThreatName(threat);

    return (
        <div className={"contact-pest-pro-card"} >

            <div className={`contact-content`} >
                <div className={`contact-picture  hoverable`} onClick={() => setIsPictureModalVisible(image)}>
                    <img src={image || "http://placehold.it/90x90"} alt="" />
                </div>

                <div className={`contact-field user`} onClick={() => clickCard && clickCard(id)} >
                    <span>{user}</span>
                </div>

                {address && <div className="contact-field " >
                    <span>{address}</span>
                </div>}

                <div className="contact-field pest_prediction" >
                    <span>{pest_prediction}</span>
                </div>

                <div className="contact-field pest_prediction" >
                    <span>{threat || "-"}</span>
                </div>

                <div className="contact-field submitted_date">
                    <span>{moment(submitted_date).format('MM/DD/YYYY HH:mm')}</span>
                </div>

                <div className="contact-field checkbox-contact ">
                        <input
                            id={`contacted-${id}`}
                            type="checkbox"
                            checked={isContacted}
                            onChange={e => setContacted && setContacted(id, e.target.checked)} />
                        <label htmlFor={`contacted-${id}`}>Already contacted</label>
                </div>

            </div>

            {
                isPictureModalVisible &&
                <Lightbox
                    medium={isPictureModalVisible}
                    alt="Incidence bug image"
                    onClose={() => setIsPictureModalVisible(false)}
                />
            }
        </div>
    )
}

export default ContactCard;