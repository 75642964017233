import { endpoints } from './endpoints';
import customFetch from './customFetch';
import { getUserToken, getUserId } from './utils';

export const getFormularyIncidences = async (filters) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.incidenceFormulary}?filter=${JSON.stringify(filters)}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const changeFormularyIncidences = async (id, data) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.incidenceFormulary}/${id}`, {
            method: 'PATCH',
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
