import React from "react";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

import "./ReactQuill.scss";

const Quill = (props) => {
    const { placeholder, onChange, value } = props;

    const modules = {
        toolbar: [
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            ["image"],
            ["code-block"],
        ],
    };

    return (
        <ReactQuill
            className="react-quill-wrapper"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            modules={modules}
        />
    );
};

export default Quill;
