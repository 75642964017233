import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Button from 'components/elements/Button/Button';
import "./ZipcodesComponent.scss";

const ZipcodesComponent = (props) => {
    const { zipcodes, fetchingZipcodes, updateZipcodes, updatingZipcodes } = props;
    const [updatedZipcodes, setUpdatedZipcodes] = useState("");

    useEffect(() => {
        setUpdatedZipcodes(zipcodes.join(","));
    }, [zipcodes])

    return (
        <DashboardLayout>
            <div className="zipcodes-wrapper">
                <div className="zipcodes-header">
                    <h1>Zipcodes</h1>
                </div>
                <div className="zipcodes-content">
                    <div className="input-title">Allowed zipcodes (separated by commas):</div>
                    <textarea
                        cols="30"
                        rows="10"
                        placeholder="Type zipcodes separated by commas to allow users use the app"
                        onChange={e => setUpdatedZipcodes(e.target.value)}
                        value={updatedZipcodes}
                    ></textarea>
                    <Button loading={updatingZipcodes} onClick={() => updateZipcodes(updatedZipcodes)}>Update</Button>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default ZipcodesComponent;