import { endpoints } from './endpoints';
import { getUserId, getUserToken } from './utils';
import customFetch from './customFetch';

export const loginService = async (credentials) => {
    try {
        let response = await customFetch(`${endpoints.iam}/login`, {
            method: 'POST',
            bodyReq: credentials,
        });

        if (response.error) throw response;

        const { id, permissions, idFacility } = await getUserIdService(response.token);
        let userInfo;
        try {
            if (permissions.includes('ManageExterminationPlan')) {
                userInfo = await getRegularUserInfoService(id, response.token);
            } else userInfo = await getUserInfoService(id, response.token); //TODO remove this for Reviewer
        } catch (e) {
            userInfo = await geReviewerInfoService(id, response.token);
        }

        return { ...userInfo, token: response.token, idFacility };
    } catch (err) {
        throw err;
    }
};

export const registerService = async (credentials) => {
    try {
        let response = await customFetch(`${endpoints.pestControllersAuth}/register`, {
            method: 'POST',
            bodyReq: credentials,
        });

        if (response.error) throw response;

        const { id } = await getUserIdService(response.token);
        const userInfo = await getUserInfoService(id, response.token);

        return { ...userInfo, token: response.token };
    } catch (err) {
        throw err;
    }
};

export const patchUserService = async (data) => {
    const idUser = getUserId();
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.users}/${idUser}`, {
            method: 'PATCH',
            bodyReq: data,
            token,
        });

        const res = await getRegularUserInfoService(idUser, token);

        console.log('res', res); //TODO remove log

        if (response.error) throw response;
    } catch (err) {
        throw err;
    }
};

export const getUserIdService = async (token) => {
    try {
        let response = await customFetch(`${endpoints.users}/me`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        let response = await customFetch(`${endpoints.user}/me`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    }
};

export const getUserInfoService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.pestControllers}/${id}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getRegularUserInfoService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.user}/${id}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const geReviewerInfoService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.reviewers}/${id}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getUsersService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.users}`, {
            method: 'GET',
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const resetPasswordService = async (data) => {
    try {
        let response = await customFetch(`${endpoints.iam}/resetPassword`, {
            method: 'POST',
            bodyReq: data,
            token: data.resetPasswordToken,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const changePasswordService = async (data) => {
    const userId = getUserId();
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.users}/${userId}/changePassword`, {
            method: 'POST',
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getURLResetPassword = async (userEmail) => {
    try {
        let response = await customFetch(`${endpoints.iam}/urlToResetPassword/${userEmail}`, {
            method: 'GET',
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const emailValidate = async (token) => {
    try {
        let response = await customFetch(`${endpoints.users}/validate`, {
            method: 'PATCH',
            bodyReq: {Validatetoken: token},
            token
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
