import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import TableRow from 'components/elements/TableRow/TableRow';
import TableHeader from 'components/elements/TableHeader/TableHeader';
import SkeletonTable from 'components/elements/SkeletonTable/SkeletonTable';
import { searchTable } from 'services/utils';
import AddEntomologistModal from './AddEntomologistModal';
import Button from 'components/elements/Button/Button';
import "./ReviewersComponent.scss";

const ReviewersComponent = (props) => {
    const { reviewers, history, fetchingReviewers, isAddModalOpen, setIsAddModalOpen, submit, loading } = props;
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (reviewers.length) setFilteredData(reviewers);
    }, [reviewers])

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search entomologists ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, reviewers);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="pests-wrapper">
                <div className="pests-header">
                    <h1>Entomologists</h1>
                </div>

                <div className="top-buttons">
                    <Button
                        onClick={() => setIsAddModalOpen(true)}
                        style={{ width: 'auto', }}
                        className="">Add Entomologist</Button>

                </div>

                {
                    fetchingReviewers ?
                        <SkeletonTable /> :
                        filteredData.length ?
                            <TableOptions
                                rawData={filteredData}
                                hiddenColumns={['id']}
                                isTable={true}
                                rowHeader={<TableHeader />}
                                rowComponent={<TableRow notHoverable={true} />} /> :
                            <div className="pests-empty">No Entomologists registered yet.</div>
                }
            </div>
            <AddEntomologistModal
                isOpen={isAddModalOpen}
                toggleModal={() => setIsAddModalOpen(!isAddModalOpen)}
                submit={submit}
                loading={loading}
            />
        </DashboardLayout>
    );
};

export default ReviewersComponent;