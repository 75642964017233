const randomColors = ['0, 255, 255', '255, 0, 0', '0, 255, 0']

const randomRgbColor = () => {
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)
  return `rgb(${r}, ${g}, ${b})`
}

for (let i = 0; i < 50; i++) {
  randomColors.push(randomRgbColor())
}

export default randomColors
