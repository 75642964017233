import React, { useState, useEffect } from 'react';
import ToastrContent from 'components/elements/Toastr/Toastr';
import { toast } from 'react-toastify';
import { getBugsService, deleteBugService } from 'services/library';
import PestLibraryComponent from '../Components/PestLibraryComponent';

const PestLibrary = (props) => {
    const [bugs, setBugs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        getBugs();
    }, [])

    const getBugs = async () => {
        try {
            setIsFetching(true);
            const res = await getBugsService();
            setBugs(res);
        }
        catch (e) {
            console.log('error getBugs', e);
        }
        finally {
            setIsFetching(false);
        }
    }

    const deleteBug = async (id) => {
        try {
            setLoading(true);
            const res = await deleteBugService(id);

            toast(
                <ToastrContent
                    type="success"
                    title={"Success"}
                    message={"The bug was removed successfully"}
                />,
                {
                    progressClassName: "toastr-progress-bar success",
                }
            );
        }
        catch (e) {
            console.log('error getBugs', e);
        }
        finally {
            setLoading(false);
            getBugs();
        }
    }

    return <PestLibraryComponent
        {...props}
        {...{ getBugs, bugs, loading, deleteBug, isFetching }}
    />

}

export default PestLibrary;