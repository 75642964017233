import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Button from 'components/elements/Button/Button';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Input from 'components/elements/Input/Input';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MdEmail } from 'react-icons/md';
import './IncidenceReviewComponent.scss';
import SendMessageModal from 'components/elements/SendMessageModal/containers/SendMessageModal';
import _, { isEmpty } from 'lodash';

const IncidenceReviewComponent = (props) => {
    const {
        history,
        incidence,
        loading,
        species,
        submit,
        getMessages,
        croppedArray,
        loadingSelectBug,
        reviewedIncidence,
        calculateCrop,
        showOtherCrops,
        selectOtherBug,
    } = props;

    const [crop, setCrop] = useState(null);
    const [dontSendChatMessage, setDontSendChatMessage] = useState(false);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [imageRatio, setImageRatio] = useState({ ratioH: 1, ratioW: 1 });
    const [croppedImageIndex, setCroppedImageIndex] = useState(null);
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);

    const [sendMessage, setSendMessage] = useState(false);

    let defaultSpecie = useMemo(() => {
        const noBugSpecieId = species
            ?.filter((specie) => specie?.specie?.nameEn === 'no bug')
            .map((specie) => ({ value: specie?.specie?.id, label: specie?.specie?.nameEn }));
        const modelSpecie = species
            ?.filter((specie) =>
                reviewedIncidence?.review?.idSpecie
                    ? specie?.specie?.id === reviewedIncidence?.review?.idSpecie
                    : specie?.specie?.nameEn === incidence?.new_label?.toLowerCase()
            )
            .map((specie) => ({ value: specie?.specie?.id, label: specie?.specie.nameEn }));
        let initialValue = modelSpecie[0] ? modelSpecie[0] : noBugSpecieId[0];
        return initialValue;
    }, [species]);

    let reviewedProps = useMemo(() => {
        if (_.isEmpty(species)) return {};
        const selectedSpecie = species?.filter(({ specie }) => specie.id === reviewedIncidence?.review?.idSpecie)[0];
        let commonSubmane = selectedSpecie?.subSpecies
            ?.filter((sub) => [sub.nameEng, sub.id].includes(reviewedIncidence?.review?.commonSubmane))
            .map((sub) => ({ label: sub.nameEng, value: sub.id }))[0] || { label: 'none', value: 'none' };
        let instar = instarOptions.filter((option) => option.value === reviewedIncidence?.review?.instar)[0];
        let scientificName = selectedSpecie?.scientificNames
            ?.filter((sn) => [sn.name, sn.id].includes(reviewedIncidence?.review?.scientificName))
            .map((sn) => ({ label: sn.name, value: sn.id }))[0] || { label: 'none', value: 'none' };
        let population = populationOptions.filter((option) => option.value === reviewedIncidence?.review?.population)[0];
        let confidence = confidenceOptions.filter((option) => option.value == reviewedIncidence?.review?.confidence)[0];
        let comment = reviewedIncidence?.review?.comment;
        let numberBugs = reviewedIncidence?.review?.numberBugs;

        if (reviewedIncidence)
            return {
                commonSubmane,
                instar,
                scientificName,
                population,
                confidence,
                comment,
                numberBugs,
            };
        return {};
    }, [reviewedIncidence, species]);

    useEffect(() => {
        const coordinates = incidence?.box?.split(',');
        if ((coordinates?.length && incidence?.urlImage) || reviewedIncidence) {
            let img = new Image();
            img.src = incidence?.urlImage;
            img.onload = () => {
                const ratioH = 500 / img.height;
                const ratioW = 500 / img.width;
                setImageRatio({ ratioH, ratioW });

                const x0 = parseInt(coordinates[0] * ratioW);
                const x1 = parseInt(coordinates[2] * ratioW);
                const y0 = parseInt(coordinates[1] * ratioH);
                const y1 = parseInt(coordinates[3] * ratioH);
                const crop = calculateCrop({ x0, x1, y0, y1 });

                setCrop(crop);
            };
        }
    }, [incidence]);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const canvas = document.getElementById('main-canvas');
        const image = imgRef.current;
        const crop = completedCrop;

        if (croppedImageIndex !== null) {
        }
        buildCroppedImage(image, canvas, crop);
    }, [completedCrop]);

    useEffect(() => {
        if (imgRef.current && reviewedIncidence?.multipleGeoPoint) {
            const { naturalWidth, width, naturalHeight, height } = imgRef.current;
            showOtherCrops({ naturalWidth, width, naturalHeight, height });
        }
    }, [imgRef.current, reviewedIncidence]);

    // useEffect(() => {
    //     if (croppedImageIndex !== null || croppedArray.length <= 0 || !imgRef.current) return;
    //     document.getElementById("cropped-array").innerHTML = ""
    //     croppedArray.forEach((crop, i) => {
    //         buildCroppedImage(imgRef.current, null, crop.crop, i);
    //     })

    // }, [croppedArray])

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const buildCroppedImage = async (image, canvas, crop, createNewIncidence) => {
        image.crossOrigin = 'annonymous';
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        if (!canvas) {
            canvas = document.createElement('CANVAS');
            const div = document.getElementById('cropped-array');
            div.appendChild(canvas);
        }
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.scale(2, 2);
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.width * scaleY, 0, 0, crop.width, crop.height);

        if (createNewIncidence) {
            const id = await selectOtherBug({ crop: crop }, canvas);
            canvas.id = `cropped-${id}`;
            canvas.onclick = () => window.open(`${window.location.origin}/review/incidence/${id}`, '_blank', 'noopener,noreferrer');
        }
    };

    // const deleteCrop = (index) => {
    //     document.getElementById("cropped-array").removeChild(document.getElementById(`cropped-${index}`));
    //     const copy = _.cloneDeep(croppedArray);
    //     copy.splice(index, 1);
    //     setCroppedArray(copy);
    // }

    return (
        <DashboardLayout>
            <div className="incidences-review-wrapper">
                <div className="incidences-header">
                    <h1>{reviewedIncidence ? 'Update review' : 'Review Incidence'}</h1>
                </div>

                <div className="review-body">
                    <div className="review-incidence-card">
                        <div className="image-selection">
                            <div className="field-title" style={{ marginBottom: 10 }}>
                                1. Adjust square offset
                            </div>
                            <div className="image-boxes">
                                {crop && (
                                    <ReactCrop
                                        src={incidence?.urlImage}
                                        onImageLoaded={onLoad}
                                        crop={crop}
                                        onChange={(c) => setCrop(c)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        keepSelection
                                    />
                                )}

                                <AiOutlineArrowRight />
                                <div className="cropped-image">
                                    <canvas
                                        id="main-canvas"
                                        ref={previewCanvasRef}
                                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                    />
                                </div>

                                <div id="cropped-array" className="cropped-array">
                                    {/* {croppedArray.map((image, i) => {
                                        return <canvas
                                            id={`croped-image-${i}`}
                                            ref={image.canvas}
                                            onClick={() => { selectCroppedImage(image, i) }}
                                        />
                                    })} */}
                                </div>
                            </div>

                            <div className="crop-buttons-wrapper">
                                {!isNaN(crop?.height) && crop?.height > 0 && (
                                    <div className="new-crop">
                                        <Button
                                            loading={loadingSelectBug}
                                            onClick={async () => {
                                                buildCroppedImage(imgRef.current, null, completedCrop, true);
                                                setCroppedImageIndex(null);
                                            }}
                                        >
                                            Select other bug
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="classification-wrapper">
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    specie: defaultSpecie || { label: '', value: null },
                                    nameEs: '',
                                    nameEn: '',
                                    scientificName: reviewedProps?.scientificName || { label: 'none', value: null },
                                    commonSubmane: reviewedProps?.commonSubmane || { label: 'none', value: null },
                                    comment: reviewedProps?.comment || '',
                                    confidence: reviewedProps?.confidence || confidenceOptions[0],
                                    instar: reviewedProps.instar || instarOptions[0],
                                    population: reviewedProps?.population || populationOptions[0],
                                    numberBugs: reviewedProps?.numberBugs || 1,
                                }}
                                validationSchema={Yup.object().shape({
                                    specie: Yup.object().test('hasValue', 'Please select one from the list', (params) => {
                                        return params?.value;
                                    }),
                                    // confidence: Yup.array().required("Required"),
                                    // instar: Yup.array().required("Required"),
                                    // population: Yup.array().required("Required"),
                                    nameEs: Yup.string(),
                                    nameEn: Yup.string(),
                                    comment: Yup.string(),
                                    // scientificName: Yup.object().nullable(),
                                    // commonSubmane: Yup.object().nullable,
                                    numberBugs: Yup.number(),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    submit({
                                        ...values,
                                        crop,
                                        imageRatio,
                                        croppedArray,
                                        isEdditingReview: !_.isEmpty(reviewedIncidence?.review?.idIncidenceReview),
                                        dontSendChatMessage,
                                    });
                                    resetForm();
                                }}
                            >
                                {(formik) => {
                                    const selectedSpecies = species?.filter(({ specie }) => specie.id === formik.values.specie?.value)[0];
                                    let subNamesOptions =
                                        selectedSpecies?.subSpecies?.map((subSpecie) => ({
                                            label: subSpecie.nameEng,
                                            value: subSpecie.id,
                                        })) || [];
                                    subNamesOptions.unshift({
                                        label: 'none',
                                        value: null,
                                    });
                                    let scientificNamesOptions =
                                        selectedSpecies?.scientificNames?.map((sn) => ({
                                            label: sn.name,
                                            value: sn.id,
                                        })) || [];
                                    scientificNamesOptions.unshift({
                                        label: 'none',
                                        value: null,
                                    });
                                    return (
                                        <Form>
                                            <div className="field-input" style={{ marginBottom: 20 }}>
                                                <Input
                                                    type="autocomplete"
                                                    formik={formik}
                                                    id="specie"
                                                    label="2. Common name*"
                                                    options={species?.map(({ specie }) => ({
                                                        label: specie.nameEn,
                                                        value: specie.id,
                                                    }))}
                                                    labelClassName="field-title"
                                                />
                                            </div>

                                            <div className="field-input" style={{ marginBottom: 20 }}>
                                                <Input
                                                    type="autocomplete"
                                                    formik={formik}
                                                    id="instar"
                                                    label="3. Instar*"
                                                    options={instarOptions}
                                                    labelClassName="field-title"
                                                />
                                            </div>

                                            {formik.values.specie?.value === 'e20bfb54-9b92-455c-bc0d-91f7f107e996' ||
                                            formik.values.specie?.value === '17b17276-8195-4679-aa0a-5e876971c181' ? (
                                                <div className="field-input">
                                                    <Input label="2.1 Common name in spanish" type="text" formik={formik} id="nameEs" />
                                                </div>
                                            ) : null}

                                            {formik.values.specie?.value === 'e20bfb54-9b92-455c-bc0d-91f7f107e996' ||
                                            formik.values.specie?.value === '17b17276-8195-4679-aa0a-5e876971c181' ? (
                                                <div className="field-input">
                                                    <Input label="2.2 Common name in english" type="text" formik={formik} id="nameEn" />
                                                </div>
                                            ) : null}

                                            <div className="field-input">
                                                <Input
                                                    type="autocomplete"
                                                    formik={formik}
                                                    id="scientificName"
                                                    label="4. Scientific name"
                                                    options={scientificNamesOptions}
                                                    labelClassName="field-title"
                                                />
                                            </div>
                                            <div className="field-input">
                                                <Input
                                                    type="autocomplete"
                                                    formik={formik}
                                                    id="commonSubmane"
                                                    label="5. Common subname"
                                                    options={subNamesOptions}
                                                    labelClassName="field-title"
                                                />
                                            </div>

                                            <div className="field-input" style={{ marginBottom: 20 }}>
                                                <Input
                                                    type="autocomplete"
                                                    formik={formik}
                                                    id="population"
                                                    label="6. Population*"
                                                    options={populationOptions}
                                                    labelClassName="field-title"
                                                />
                                            </div>

                                            <div className="field-input" style={{ marginBottom: 20 }}>
                                                <Input
                                                    type="autocomplete"
                                                    formik={formik}
                                                    id="confidence"
                                                    label="7. How sure you are about the classification?*"
                                                    options={confidenceOptions}
                                                    labelClassName="field-title"
                                                />
                                            </div>
                                            <div className="field-input multiline-input" style={{ marginTop: -16 }}>
                                                <Input
                                                    type="text"
                                                    formik={formik}
                                                    id="numberBugs"
                                                    label="8. Pest sightings"
                                                    labelClassName="field-title"
                                                    multiline
                                                    rows={2}
                                                />
                                            </div>
                                            <div className="field-input multiline-input" style={{ marginTop: -16 }}>
                                                <Input
                                                    type="text"
                                                    formik={formik}
                                                    id="comment"
                                                    label="9. Do you have any comments?"
                                                    labelClassName="field-title"
                                                    multiline
                                                    rows={2}
                                                />
                                            </div>
                                            <div className="field-input">
                                                <Button type="button" className="small" onClick={() => setSendMessage(true)}>
                                                    <MdEmail />
                                                    Edit Message
                                                </Button>
                                                <div
                                                    className="field-title message-checkbox"
                                                    onChange={() => setDontSendChatMessage(!dontSendChatMessage)}
                                                >
                                                    <input id="checkbox" type="checkbox" checked={dontSendChatMessage} />
                                                    <label htmlFor="checkbox">Don't send a message for this review</label>
                                                </div>
                                            </div>
                                            <SendMessageModal
                                                open={sendMessage}
                                                specieData={
                                                    species?.filter((specie) => specie?.specie?.id === formik?.values?.specie?.value)[0]
                                                        ?.specie
                                                }
                                                onConfirm={() => {
                                                    setSendMessage(false);
                                                    getMessages();
                                                }}
                                                onDeny={() => setSendMessage(false)}
                                                confirmButtonText="Save"
                                                denyButtonText="Cancel"
                                            />
                                            <div className="submit">
                                                <Button
                                                    onClick={() => history.push(`/admin/incidences`)}
                                                    className="secondary auto-width small"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button onClick={formik.handleSubmit} className="auto-width small">
                                                    Save changes
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

const confidenceOptions = [
    {
        label: "I'm sure ",
        value: '2',
    },
    {
        label: "I'm not sure",
        value: '0',
    },
];

const instarOptions = [
    { label: 'none', value: 'none' },
    { label: 'adult', value: 'adult' },
    { label: 'larva', value: 'larva' },
    { label: 'molt', value: 'molt' },
    { label: 'nymph', value: 'nymph' },
    { label: 'pupa', value: 'pupa' },
    { label: 'egg', value: 'egg' },
    { label: 'mix', value: 'mix' },
];

const populationOptions = [
    { label: '1', value: '1' },
    { label: '2-5', value: '2-5' },
    { label: '6-10', value: '6-10' },
    { label: '11-25', value: '11-25' },
    { label: '26-50', value: '26-50' },
    { label: '>50', value: '>50' },
];

export default IncidenceReviewComponent;
