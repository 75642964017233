import React, { useState, useEffect } from "react";
import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import ReviewCard from 'components/elements/ReviewCard/ReviewCard';
import SkeletonReviewCard from 'components/elements/SkeletonReviewCard/SkeletonReviewCard';
import { searchTable } from 'services/utils';
import "./AdminReviewsComponent.scss";

const AdminReviewsComponent = (props) => {
    const { reviews, changeReviewStatus, isLoading, fetchingReviews } = props;
    const sortableColumns = [{ column: 'name', style: { marginLeft: 75, flex: '0 0 140px'  } }, { column: 'status', width: 80 }, { column: 'message', style: { flex: 1 } }, { column: 'rating', width: 'auto' },];
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (reviews.length) setFilteredData(reviews);
    }, [reviews])

    return (
        <DashboardLayout topbarLeftComponent={
            <TopBarSearch
                placeholder="Search reviews ..."
                searchCallback={(value) => {
                    const searchData = searchTable(value, reviews);
                    setFilteredData(searchData);
                }} />
        }>
            <div className="admin-reviews-wrapper">
                <div className="admin-reviews-header">
                    <h1>Reviews</h1>
                </div>

                {
                    fetchingReviews ?
                        <div className="admin-reviews-empty padding-top">
                            <SkeletonReviewCard />
                            <SkeletonReviewCard />
                            <SkeletonReviewCard />
                        </div> :
                        filteredData.length ?
                            <TableOptions
                                rawData={filteredData}
                                sortableColumns={sortableColumns}
                                rowComponentProps={{ changeReviewStatus, isLoading, showStatus: true }}
                                rowComponent={<ReviewCard />} /> :
                            <div className="admin-reviews-empty">No reviews provided yet.</div>
                }
            </div>
        </DashboardLayout>
    );
};

export default AdminReviewsComponent;