import React from 'react'

const BuildingCard = (props) => {
    const {route, index, setSelectedRoute} = props
    return (
        <div className='building-content-route-item' key={route.idRoute} onClick={() => setSelectedRoute(route)}>
            <img className='building-content-main-route-image' src={route.img} alt="Selected route" />
            <h3>Route {index + 1}</h3>
            <p>{route.name}</p>
        </div>
    )
}

export default BuildingCard