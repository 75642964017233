import React, { useState, useEffect, useMemo } from 'react';
import Input from 'components/elements/Input/Input';
import Button from 'components/elements/Button/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-modal';
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from 'react-icons/fa';

import './ModalEditProfile.scss';
import SimpleMap from '../GoogleMap/GoogleMap';

const ModalEditProfile = (props) => {
    const { currentPestController, isOpen, onRequestClose, submit, loading, changeIsOpenValue } = props;
    const [mapModalOpen, setMapModalOpen] = useState(false);
    const [isDragable, setIsDragable] = useState(false);
    const [focusMap, setFocusMap] = useState(false);
    const [editProfileInitialValues, setEditProfileInitialValues] = useState({
        name: '',
        phone: '',
        notificationsEmail: '',
        city: '',
        country: '',
        postalCode: '',
        pestCoverage: [],
        serviceOptions: [],
        description: '',
        license: '',
        extraServices: {
            //todo not fetching data
            kidFriendly: false,
            petFriendly: false,
            enrironmentalFriendly: false,
        },
        specialitiesDescription: '',
        historyDescription: '',
        ownerDescription: '',
        aditionalDescription: '',
        coverageArea: {},
    });

    const profileInitialValues = useMemo(() => {
        setEditProfileInitialValues({
            name: currentPestController?.name || '',
            phone: currentPestController?.phone || '',
            notificationsEmail: currentPestController?.notificationsEmail || currentPestController?.email || '',
            city: currentPestController?.city || '',
            country: currentPestController?.country || '',
            postalCode: currentPestController?.postalCode || '',
            pestCoverage: currentPestController?.pestCoverage || [],
            serviceOptions: currentPestController?.serviceOptions || [],
            description: currentPestController?.description || '',
            license: currentPestController?.licenseNumber || '',
            extraServices: {
                kidFriendly: currentPestController?.extraServices?.includes('kidFriendly') || false,
                petFriendly: currentPestController?.extraServices?.includes('petFriendly') || false,
                enrironmentalFriendly: currentPestController?.extraServices?.includes('enrironmentalFriendly') || false,
            },
            specialitiesDescription: currentPestController?.specialitiesDescription || '',
            historyDescription: currentPestController?.historyDescription || '',
            ownerDescription: currentPestController?.ownerDescription || '',
            aditionalDescription: currentPestController?.aditionalDescription || '',
            coverageArea: currentPestController?.coverageArea || {},
        });
    }, [currentPestController]);

    const editProfileValidationSchema = yup.object().shape({
        name: yup.string().required('Name is a required field'),
        phone: yup.string(),
        notificationsEmail: yup.string(),
        city: yup.string(),
        country: yup.string(),
        postalCode: yup.string(),
        pestCoverage: yup.array(),
        serviceOptions: yup.array(),
        description: yup.string(),
        license: yup.string(),
        extraServices: yup.object(),
        specialitiesDescription: yup.string(),
        historyDescription: yup.string(),
        ownerDescription: yup.string(),
        aditionalDescription: yup.string(),
        coverageArea: yup.array(),
    });

    const formik = useFormik({
        initialValues: editProfileInitialValues,
        validationSchema: editProfileValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => console.log('values', values),
    });

    const handleSubmit = (values) => {
        if (!!formik.errors?.name) return;
        submit(values);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => {
                    setMapModalOpen(false);
                    onRequestClose();
                }}
                className="edit-perdil-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}
            >
                <div className="edit-profile-modal-wrapper">
                    <div className="modal-header">
                        <div>Complete your profile</div>
                    </div>
                    <>
                        <div className="modal-body">
                            <div className="grid-wrapper">
                                <div className="input-wrapper fill-3">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="name"
                                        label="Company name"
                                        placeholder="Write here your company name"
                                        className="formControl"
                                    />
                                </div>

                                <div className="input-wrapper fill-3">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="license"
                                        label="Pest Control License Number"
                                        placeholder="Write here your license"
                                        className="formControl"
                                    />
                                </div>
                            </div>

                            <div className="grid-wrapper">
                                <div className="input-wrapper fill-3">
                                    <Input
                                        id="phone"
                                        formik={formik}
                                        type="text"
                                        mask="(999) 999-9999"
                                        label="Phone"
                                        className="formControl"
                                    />
                                </div>

                                <div className="input-wrapper fill-3">
                                    <Input
                                        id="notificationsEmail"
                                        formik={formik}
                                        type="text"
                                        label="Point of contact e-mail"
                                        className="formControl"
                                    />
                                </div>
                            </div>

                            <div className="grid-wrapper">
                                <div className="input-wrapper fill-2">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="city"
                                        label="City"
                                        placeholder="Write here your city"
                                        className="formControl"
                                    />
                                </div>

                                <div className="input-wrapper fill-2">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="country"
                                        label="Country"
                                        placeholder="Write here your country"
                                        className="formControl"
                                    />
                                </div>

                                <div className="input-wrapper fill-2">
                                    <Input
                                        formik={formik}
                                        type="text"
                                        id="postalCode"
                                        label="Postal code"
                                        placeholder="Write here your postal code"
                                        className="formControl"
                                    />
                                </div>
                            </div>

                            <div className="grid-wrapper">
                                <div className="input-wrapper fill-3">
                                    <Input
                                        formik={formik}
                                        type="select"
                                        id="pestCoverage"
                                        label="Pest coverage (select all that apply)"
                                        options={pestCoverageItems}
                                        multiple
                                        className="formControl"
                                    />
                                </div>

                                <div className="input-wrapper fill-3">
                                    <Input
                                        formik={formik}
                                        type="select"
                                        id="serviceOptions"
                                        label="Additional service options"
                                        options={serviceOptionsItems}
                                        multiple
                                        className="formControl"
                                    />
                                </div>
                            </div>

                            <div className="grid-wrapper">
                                <div className="coverage-area-wrapper fill-6">
                                    <div className="input-title">Coverage area</div>
                                    <div className="input-subtitle"> Please draw an area on map and select Apply</div>
                                    <div className={`map ${focusMap && 'map-focus'}`}>
                                        {!isDragable && (
                                            <div className="expad-map-icon-wrapper" onClick={() => setFocusMap(!focusMap)}>
                                                {focusMap ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
                                            </div>
                                        )}

                                        <SimpleMap whenDragable={(value) => setIsDragable(value)} formik={formik} />
                                    </div>
                                </div>
                            </div>

                            <div className="grid-wrapper">
                                <div className="inputs-wrapper fill-4">
                                    <div className="input-title">Additional information</div>
                                    {aditionalInformation.map((item) => {
                                        return (
                                            <div className="fill-3">
                                                <Input
                                                    formik={formik}
                                                    type="checkbox"
                                                    checked={formik.values?.extraServices[`${item.value}`]}
                                                    id={`extraServices[${item.value}]`}
                                                    label={item.label}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="input-wrapper">
                                <Input
                                    formik={formik}
                                    type="text"
                                    id="specialitiesDescription"
                                    label="Specialities"
                                    placeholder="This is where you describe what your business does best. Include your top menu items, your area of expertise, or product lines you carry."
                                    className="formControl"
                                    multiline
                                    rows={3}
                                />
                            </div>
                            <div className="input-wrapper">
                                <Input
                                    formik={formik}
                                    type="text"
                                    id="historyDescription"
                                    label="History"
                                    placeholder="The section lets you tell the story of the business -who started it, why it was started, etc."
                                    className="formControl"
                                    multiline
                                    rows={3}
                                />
                            </div>
                            <div className="input-wrapperow">
                                <Input
                                    formik={formik}
                                    type="text"
                                    id="ownerDescription"
                                    label="Meet the Business Owner"
                                    placeholder="Include a brief biography of the owner(s). This could include where they’re from, how they got into business, etc."
                                    className="formControl"
                                    multiline
                                    rows={3}
                                />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <Button className="light" onClick={onRequestClose}>
                                Cancel
                            </Button>
                            <Button
                                loading={loading}
                                onClick={() => {
                                    handleSubmit(formik.values);
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </>
                </div>
            </Modal>
        </>
    );
};

const serviceOptionsItems = [
    {
        label: 'Crawl Space Services',
        value: 'Crawl Space Services',
    },
    {
        label: 'Attic Insulation Services',
        value: 'Attic Insulation Services',
    },
    {
        label: 'Covid Disinfection & Sanitization',
        value: 'Covid Disinfection & Sanitization',
    },
    {
        label: 'Commercial Pest Control',
        value: 'Commercial Pest Control',
    },
];

const pestCoverageItems = [
    {
        label: 'Ants',
        value: 'Ants',
    },
    {
        label: 'Cockroaches',
        value: 'Cockroaches',
    },
    {
        label: 'Mosquitoes',
        value: 'Mosquitoes',
    },
    {
        label: 'Spiders',
        value: 'Spiders',
    },
    {
        label: 'Wildlife',
        value: 'Wildlife',
    },
    {
        label: 'Rodents',
        value: 'Rodents',
    },
    {
        label: 'Ticks',
        value: 'Ticks',
    },
    {
        label: 'Termite control',
        value: 'Termite control',
    },
    {
        label: 'Bed bug removal',
        value: 'Bed bug removal',
    },
];

const aditionalInformation = [
    {
        label: 'Pet Friendly',
        value: 'petFriendly',
    },
    {
        label: 'Kid Friendly',
        value: 'kidFriendly',
    },
    {
        label: 'Enrironmental Friendly',
        value: 'enrironmentalFriendly',
    },
];

export default ModalEditProfile;
