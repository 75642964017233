import React, { useState, useRef } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Element } from 'react-scroll'
import { Lightbox } from "react-modal-image";

import "./FacilityMapComponent.scss";
import { LocationIncidence, LocationIncidenceSkeleton } from "components/elements/LocationIncidence/LocationIncidence";

const defaultIncidenceEntity = {
    id: null,
    lat: 0,
    long: 0,
    bug: null,
    username: '',
    location: '',
}

const FacilityMapComponent = (props) => {
    const { visibleIncidences, loading } = props;
    const [isPictureModalVisible, setIsPictureModalVisible] = useState(false);
    const [pictureModalImage, setPictureModalImage] = useState(null);
    const [showMobileIncidencesList, setShowMobileIncidencesList] = useState(false);
    const [selectedIncidence, setSelectedIncidence] = useState(defaultIncidenceEntity);
    const [selectedIncidenceByList, setSelectedIncidenceByList] = useState(false);

    return (
        <DashboardLayout>
            <div className="map-wrapper">
                <div id="map-incidences" className={showMobileIncidencesList ? "map-incidences" : "map-incidences list-invisible"}>
                    <div className="mobile-toggle-incidences" onClick={() => setShowMobileIncidencesList(!showMobileIncidencesList)}>
                        <span>Show incidences list</span>
                        <FontAwesomeIcon icon={showMobileIncidencesList ? faChevronUp : faChevronDown} />
                    </div>

                    <div className="incidences-list">
                        {loading ? null : <div className="incidencesNumber">{visibleIncidences.length} registered incidences</div>}
                        {
                            loading ?
                                _.times(3, (i) => (
                                    <LocationIncidenceSkeleton key={i} />
                                )) :
                                visibleIncidences.length ?
                                    _.map(visibleIncidences, (incidence, i) => (
                                        <>
                                            <Element name={incidence.id} key={i}>
                                                <LocationIncidence //todo add pest/facility to the card
                                                    incidence={incidence}
                                                    selectedIncidence={selectedIncidence?.id === incidence.id}
                                                    setSelectedIncidenceByList={setSelectedIncidenceByList}
                                                    setPictureModalImage={setPictureModalImage}
                                                    setIsPictureModalVisible={setIsPictureModalVisible}
                                                    onClick={() => {
                                                        setSelectedIncidence(incidence);
                                                        setSelectedIncidenceByList(true);
                                                        setShowMobileIncidencesList(false);
                                                    }} />
                                            </Element>
                                        </>
                                    )) :
                                    null
                        }
                    </div>
                </div>
                <div className="map-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    MAP
                </div>
            </div>

            {
                isPictureModalVisible &&
                <Lightbox
                    medium={pictureModalImage}
                    alt="Incidence bug image"
                    onClose={() => setIsPictureModalVisible(false)}
                />
            }
        </DashboardLayout>
    );
};

export default FacilityMapComponent;