import { endpoints } from './endpoints';
import customFetch from './customFetch';
import { getUserToken, getUserId } from './utils';
import moment from 'moment';

export const getEntomologistsService = async () => {
    try {
        let response = await customFetch(endpoints.reviewers, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createEntomologistService = async ({ name, email, password }) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.reviewers}`, {
            method: 'POST',
            token,
            bodyReq: { name, email, password },
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const updateIncidenceReviewService = async (id, data) => {
    const token = getUserToken();
    const idUser = getUserId();

    try {
        let response = await customFetch(`${endpoints.reviewIncidence}/${id}`, {
            method: 'PATCH',
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getIncidenceReviewFields = (
    id,
    values,
    idSpecie,
    geoPoint = { x0: 0, y0: 0, x1: 0, y1: 0 },
    templates,
    multipleCoordinates
) => {
    const idUser = getUserId();
    const specieTemplate = templates?.filter((t) => t.specieId === idSpecie)[0];

    let data = {
        idSpecie,
        confidence: parseInt(values?.confidence?.value || '2'),
        idIncidence: id,
        comment: values?.comment || '',
        instar: values?.instar?.value || 'none',
        population: values?.population?.value || '1',
        scientificName: values?.scientificName?.value || 'none',
        commonSubmane: values?.commonSubmane?.value || 'none',
        idReviewer: idUser,
        dateReview: moment().format('YYYY-MM-DD[T]HH:mm:[00.000Z]'),
        sendEmail: false,
        reviewMessage: specieTemplate?.text,
        numberBugs: Number(values?.numberBugs) || 1,
    };

    if (multipleCoordinates.length > 1) {
        data['multipleGeoPoint'] = multipleCoordinates;
    } else {
        data['geoPoint'] = geoPoint;
    }

    return data;
};

export const postReviewService = async (reviews) => {
    const token = getUserToken();
    try {
        let response = await customFetch(`${endpoints.reviewIncidence}`, {
            method: 'POST',
            token,
            bodyReq: reviews,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getReviewsByIncidenceService = async (incidenceId) => {
    try {
        let response = await customFetch(`${endpoints.reviewIncidence}?filter={"where":{"and":[{"idIncidence": "${incidenceId}"}]}}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getAllIncidenceReviewsService = async () => {
    try {
        let response = await customFetch(`${endpoints.reviewIncidence}?filter={"where":{"and":[{"status": "pending"}]}}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
