import TopBarSearch from 'components/elements/TopBarSearch/TopBarSearch';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import React, { useState, useEffect } from 'react';
import './CasesPestsComponent.scss';

const ClosedPestsComponent = (props) => {
    const { } = props

    return (
        <DashboardLayout
            topbarLeftComponent={
                <TopBarSearch
                    placeholder="Search pest incidences ..."
                    searchCallback={(value) => {console.log('Closed Pests value', value)
                    }}
                />
            }>
            ClosedPestsComponent
        </DashboardLayout>
    )
}

export default ClosedPestsComponent;