import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken, getUserId } from "./utils";
import _ from "lodash";

export const getScientificNamesService = async () => {
    try {
        const token = getUserToken();
        let response = await customFetch(endpoints.scientificNames, {
            method: "GET",
            token,
        });
        if (response.error) throw response;

        response = response.map((sn) => ({
            value: sn.name,
            label: sn.name,
        }))

        const oldScientificNames = [
            {
                value: 'none',
                label: 'none',
            },
            {
                value: 'Cimex sp.',
                label: 'Cimex sp.',
            },
            {
                value: 'Cimex lectularius',
                label: 'Cimex lectularius',
            },
            {
                value: 'Anthrenus sp.',
                label: 'Anthrenus sp.',
            },
            {
                value: 'Anthrenus verbasci',
                label: 'Anthrenus verbasci',
            },
            {
                value: 'Liposcelis sp.',
                label: 'Liposcelis sp.',
            },
            {
                value: 'Ahasverus sp.',
                label: 'Ahasverus sp.',
            },
            {
                value: 'Blattella germanica',
                label: 'Blattella germanica',
            },
            {
                value: 'Ahasverus advena',
                label: 'Ahasverus advena',
            },
            {
                value: 'Attagenus sp.',
                label: 'Attagenus sp.',
            },
            {
                value: 'Gibbium psylloides',
                label: 'Gibbium psylloides',
            },
            {
                value: 'Periplaneta americana',
                label: 'Periplaneta americana',
            },
            {
                value: 'Curculionidae',
                label: 'Curculionidae',
            },
            {
                value: 'Oniscidea',
                label: 'Oniscidea',
            },
            {
                value: 'Dermestidae',
                label: 'Dermestidae',
            },
            {
                value: 'Blatella germanica',
                label: 'Blatella germanica',
            },
            {
                value: 'Attagenus unicolor',
                label: 'Attagenus unicolor',
            },
            {
                value: 'Thripidae',
                label: 'Thripidae',
            },
            {
                value: 'Oryzaephilus surinamensis',
                label: 'Oryzaephilus surinamensis',
            },
            {
                value: 'Rhopalidae',
                label: 'Rhopalidae',
            },
            {
                value: 'Formicidae',
                label: 'Formicidae',
            },
            {
                value: 'Oryzaephilus sp.',
                label: 'Oryzaephilus sp.',
            },
            {
                value: 'Coccinellidae',
                label: 'Coccinellidae',
            },
            {
                value: 'Reticulitermes sp.',
                label: 'Reticulitermes sp.',
            },
            {
                value: 'Miridae',
                label: 'Miridae',
            },
            {
                value: 'Chrysomelidae',
                label: 'Chrysomelidae',
            },
            {
                value: 'Halyomorpha halys',
                label: 'Halyomorpha halys',
            },
            {
                value: 'Ixodoidea',
                label: 'Ixodoidea',
            },
            {
                value: 'Forficulidae',
                label: 'Forficulidae',
            },
            {
                value: 'Elateridae',
                label: 'Elateridae',
            },
            {
                value: 'Ctenocephalides canis',
                label: 'Ctenocephalides canis',
            },
            {
                value: 'Tenebrionidae',
                label: 'Tenebrionidae',
            },
            {
                value: 'Lepismatidae',
                label: 'Lepismatidae',
            },
            {
                value: 'Bryobia sp.',
                label: 'Bryobia sp.',
            },
            {
                value: 'Ixodes sp.',
                label: 'Ixodes sp.',
            },
            {
                value: 'Carabidae',
                label: 'Carabidae',
            },
            {
                value: 'Melanolestes',
                label: 'Melanolestes',
            },
            {
                value: 'Salticidae',
                label: 'Salticidae',
            },
            {
                value: 'Scarabaeidae',
                label: 'Scarabaeidae',
            },
            {
                value: 'Blatta orientalis',
                label: 'Blatta orientalis',
            },
            {
                value: 'Dermaptera',
                label: 'Dermaptera',
            },
            {
                value: 'Solenopsis sp.',
                label: 'Solenopsis sp.',
            },
            {
                value: 'Ixodidae',
                label: 'Ixodidae',
            },
            {
                value: 'Pediculus humanus',
                label: 'Pediculus humanus',
            },
            {
                value: 'Anisolabididae',
                label: 'Anisolabididae',
            },
            {
                value: 'Lycosidae',
                label: 'Lycosidae',
            },
            {
                value: 'Cimex hemipterus',
                label: 'Cimex hemipterus',
            },
            {
                value: 'Dermestes',
                label: 'Dermestes',
            },
            {
                value: 'Erotylidae',
                label: 'Erotylidae',
            },
            {
                value: 'Geocoridae',
                label: 'Geocoridae',
            },
            {
                value: 'Myrmeleontidae',
                label: 'Myrmeleontidae',
            },
            {
                value: 'Pentatomidae',
                label: 'Pentatomidae',
            },
            {
                value: 'Oniscidea sp.',
                label: 'Oniscidea sp.',
            },
            {
                value: 'Pulex sp.',
                label: 'Pulex sp.',
            },
            {
                value: 'Pseudoscorpionida',
                label: 'Pseudoscorpionida',
            },
            {
                value: 'Ctenocephalides sp.',
                label: 'Ctenocephalides sp.',
            },
            {
                value: 'Reticulitermes hesperus',
                label: 'Reticulitermes hesperus',
            },
            {
                value: 'Bostrichidae',
                label: 'Bostrichidae',
            },
            {
                value: 'Dermestes lardarius',
                label: 'Dermestes lardarius',
            },
            {
                value: 'Anisolabis maritima',
                label: 'Anisolabis maritima',
            },
            {
                value: 'Ctenocephalides felis',
                label: 'Ctenocephalides felis',
            },
            {
                value: 'Bathynomus giganteus',
                label: 'Bathynomus giganteus',
            },
            {
                value: 'Hemiptera',
                label: 'Hemiptera',
            },
            {
                value: 'Blatella germaanica',
                label: 'Blatella germaanica',
            },
            {
                value: 'Acalymma',
                label: 'Acalymma',
            },
            {
                value: 'Anthicidae',
                label: 'Anthicidae',
            },
            {
                value: 'Balaustium sp.',
                label: 'Balaustium sp.',
            },
            {
                value: 'Staphylinidae',
                label: 'Staphylinidae',
            },
            {
                value: 'Noctuidae',
                label: 'Noctuidae',
            },
            {
                value: 'Ctenolepisma sp.',
                label: 'Ctenolepisma sp.',
            },
        ]

        const allScientificNames = [...response, ...oldScientificNames];

        return _.sortBy(allScientificNames, (sn) => sn.value);
    } catch (err) {
        throw err;
    }
};

export const addScientificNameService = async ({ name, speciesId }) => {
    const token = getUserToken();
    const userId = getUserId();
    try {
        let response = customFetch(`${endpoints.scientificNames}`, {
            method: "POST",
            token,
            bodyReq: { name, registeredBy: userId, speciesId }
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};

export const updateScientificNameService = async (id, data) => {
    const token = getUserToken();

    try {
        let response = customFetch(`${endpoints.scientificNames}/${id}`, {
            method: "PATCH",
            token,
            bodyReq: data
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteScientificNameService = async (id) => {
    const token = getUserToken();

    try {
        let response = customFetch(`${endpoints.scientificNames}/${id}`, {
            method: "DELETE",
            token
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getScientificNameService = async (id) => {
    const token = getUserToken();

    try {
        let response = customFetch(`${endpoints.scientificNames}/${id}`, {
            method: "GET",
            token
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};