import React, { useState, useEffect } from "react";
import AdminReviewsComponent from "../components/AdminReviewsComponent";
import { withRouter } from "react-router-dom";
import { getReviewsService } from 'services/review';
import { updateReviewStatusService } from 'services/review';
import _ from 'lodash';

const AdminReviews = (props) => {
    const { history } = props;
    const [reviews, setReviews] = useState([]);
    const [fetchingReviews, setFetchingReviews] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFetchingReviews(true);
        getReviews();
    }, [])

    const getReviews = async () => {
        const data = await getReviewsService();

        // const dataFiltered = data.filter(review => review.status === 'InitialReview' || review.status === 'Denounced');
        const denouncedReviewDataOrderedByDate = _.orderBy(data, ['dateReview'], ['desc']);

        const dataParsed = denouncedReviewDataOrderedByDate.map(review => {
            return {
                id: review.idReview,
                photo: review.user.urlProfileImage || require('assets/images/user-placeholder.jpg'),
                name: `${review.user.name || ''} ${review.user.surname || ''}`,
                message: review.content,
                rating: review.stars,
                status: review.status
            }
        });

        setReviews(dataParsed);
        setFetchingReviews(false);
    }

    const changeReviewStatus = async (id, currentStatus, pressedButton) => {
        setIsLoading(id);
        if (window.confirm(pressedButton === 'deny' ? "Are you sure you want to deny and delete this review?" : "Are you sure you want to accept this review?")) {
            try {
                let newStatus;

                switch (currentStatus) {
                    case 'InitialReview':
                        newStatus = pressedButton === 'deny' ? 'DeniedInitial' : 'AcceptedInitial';
                        break;

                    case 'Denounced':
                        newStatus = pressedButton === 'deny' ? 'DeniedDenounced' : 'AcceptedDenounced';
                        break;

                    default:
                        throw new Error("Status changing not allowed");
                }

                const data = {
                    idReview: id,
                    status: newStatus
                }

                await updateReviewStatusService(data);
                setIsLoading(false);
                getReviews();
            } catch (err) {
                setIsLoading(false);
                console.log(err);
            }
        }
    }

    return <AdminReviewsComponent
        reviews={reviews}
        history={history}
        isLoading={isLoading}
        fetchingReviews={fetchingReviews}
        changeReviewStatus={changeReviewStatus} />;
};

export default withRouter(AdminReviews);