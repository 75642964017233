import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import Button from "components/elements/Button/Button";
import './PestIdComponent.scss';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import _ from 'lodash';
import moment from 'moment';

const PestIdComponent = (props) => {
    const { generateCsvData, incidences, loading, csvData } = props
    const csvInstance = useRef(null);

    const incidencesExportName = `incidences-${moment(new Date()).format(
        "DD-MM-YYYY-HH[h]mm[m]"
    )}.csv`;

    useEffect(() => {
        if (!_.isEmpty(csvData) && csvInstance && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            });
        }
    }, [csvData]);

    return (
        <DashboardLayout>
            <Button onClick={() => generateCsvData()}
                className="csv-download-btn">
                <FontAwesomeIcon
                    className="icon"
                    icon={faDownload}
                />
                <span>Export to CSV</span>
            </Button>
            <CSVLink
                style={{ display: "none" }}
                data={csvData}
                filename={incidencesExportName}
                ref={csvInstance}

            >
                <FontAwesomeIcon
                    className="icon"
                    icon={faDownload}
                />
                <span>Export to CSV</span>
            </CSVLink>
        </DashboardLayout>
    )
}

export default PestIdComponent;