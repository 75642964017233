import whiteAnimationData from "assets/lotties/loading-white.json";
import animationData from "assets/lotties/loading.json";
import { isEmpty } from "lodash";
import React from "react";
import Lottie from "react-lottie";
import "./Button.scss";

const ButtonComponent = (props) => {
    const { children, onClick, disabled, type, loading, className, style, secondary, shape, unset, white } = props;

    return (
        <button
            className={`button-component ${disabled ? "disabled" : ''} ${className || ''} ${secondary && "secondary"} ${!isEmpty(shape) && shape} ${white && "white"} ${unset && "unset"}`}
            onClick={() => onClick?.()}
            disabled={disabled || loading}
            type={type || "button"}
            style={style}
        >
            {
                loading ?
                    <div style={{ flex: 1, height: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Lottie options={defaultOptions(white)} height={40} width={40} color="#000" />
                    </div> :
                    <div className="button-content">{children}</div>
            }
        </button>
    );
};

const defaultOptions = (isWhite) => {

    return ({
        loop: true,
        autoplay: true,
        animationData: !isWhite ? whiteAnimationData : animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    })
};

export default ButtonComponent;
