import React, { useState, useEffect } from 'react';
import IncidenceReviewComponent from '../components/IncidenceReviewComponent';
import { withRouter } from 'react-router-dom';
import { createIncidenceService, getIncidenceService, updateIncidenceService } from 'services/incidence';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateUser } from 'redux/actions/userActions';
import _ from 'lodash';
import ToastrContent from '../../../elements/Toastr/Toastr';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
    getIncidenceReviewFields,
    getReviewsByIncidenceService,
    postReviewService,
    updateIncidenceReviewService,
} from 'services/entomologist';
import { useIncidence } from 'contexts/incidencesContext';
import { addSpecieService, getSpeciesService, populateSpeciesService } from 'services/species';
import { getMessagesService } from 'services/messagesTemplate';
import { sendMessageService } from 'services/chat';
import { canvasToBase64, canvasToBase64Image, getBase64Image, getImageData } from 'services/utils';

const IncidenceReview = (props) => {
    const {
        history,
        user,
        match: {
            params: { id },
        },
    } = props;
    const [incidence, setIncidence] = useState([]);
    const [reviewedIncidence, setReviewedIncidence] = useState(null);
    const [loading, setLoading] = useState(false);
    const [species, setSpecies] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [croppedArray, setCroppedArray] = useState([]);
    const [loadingSelectBug, setLoadingSelectBug] = useState(false);

    const {
        state: { incidences: incidencesToReview },
        dispatch,
        addIncidence,
        removeIncidence,
    } = useIncidence();

    useEffect(() => {
        getIncidence();
        getIncidenceReviews();
    }, [id]);

    const getIncidence = async () => {
        setLoading(true);

        const data = await getIncidenceService(id);

        getMessages();
        setIncidence({ ...data, id });
        // setIncidence({ ...data, id, urlImage: "https://images.unsplash.com/photo-1638913662529-1d2f1eb5b526?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" });

        const speciesRes = await getSpeciesService();
        const formattedSpecies = speciesRes?.filter(({ specie }) => specie.status === 'accepted');

        setSpecies(formattedSpecies);

        setLoading(false);
    };

    const getIncidenceReviews = async () => {
        try {
            const data = await getReviewsByIncidenceService(id);
            if (data.length > 0) {
                let multipleGeoPoint = data[0].review.multipleGeoPoint || [];

                if (data[0].review.geoPoint) multipleGeoPoint.push(data[0].review.geoPoint);
                setReviewedIncidence({ ...data[0], id, multipleGeoPoint });
            }
        } catch (e) {
            console.log('error updating', e);
        }
    };

    const calculateCrop = ({ x0, x1, y0, y1 }) => {
        const boxW = x1 - x0;
        const boxH = y1 - y0;
        const data = { unit: 'px', width: boxW, height: boxH, x: x0, y: y0, aspect: 1 / 1 };
        return data;
    };

    const getMessages = () => {
        getMessagesService({}).then((res) => {
            setTemplates(res);
        });
    };

    const cropCoordinates = (crop, imageRatio) => {
        return {
            x0: crop?.x / imageRatio?.ratioW || 0,
            y0: crop?.y / imageRatio?.ratioH || 0,
            x1: (crop?.x + crop?.width) / imageRatio?.ratioW || 0,
            y1: (crop?.y + crop?.height) / imageRatio?.ratioH || 0,
        };
    };

    const submit = async (values) => {
        try {
            setLoading(true);
            const dontSendMessage = values.dontSendChatMessage;
            const crop = values.crop;
            const imageRatio = values.imageRatio;
            const coordinates = cropCoordinates(crop, imageRatio);
            let mainCropIsEqual = false;

            let multipleCoordinates = values.croppedArray.map((image) => {
                if (image.crop === crop) mainCropIsEqual = true;
                return cropCoordinates(image.crop, imageRatio);
            });

            !mainCropIsEqual && multipleCoordinates.push(cropCoordinates(crop, imageRatio));

            let specieId = values.specie?.value;

            if (specieId === 'e20bfb54-9b92-455c-bc0d-91f7f107e996' || specieId === '17b17276-8195-4679-aa0a-5e876971c181') {
                const newSpecieRes = await addSpecieService({
                    nameEs: values.nameEs,
                    nameEn: values.nameEn,
                });
                specieId = newSpecieRes.id;
            }

            let reviews = [getIncidenceReviewFields(id, values, specieId, coordinates, templates, multipleCoordinates)];
            let incidences = [{ ...incidence, id }];
            dispatch(removeIncidence({ id }));

            values.isEdditingReview
                ? await updateIncidenceReviewService(reviewedIncidence.review.idIncidenceReview, reviews[0])
                : await postReviewService(reviews);

            !dontSendMessage && sendChatMessages(incidences, specieId);

            toast(<ToastrContent type="success" title={'Success'} message={'Your review has been sent'} />, {
                progressClassName: 'toastr-progress-bar success',
            });

            setCroppedArray([]);
            const nextIncidence = incidencesToReview[1]?.id;
            if (!nextIncidence) history.push(`/admin/incidences`);
            else history.push(`/review/incidence/${nextIncidence}`);
        } catch (e) {
            console.log('error submit review', e);
        } finally {
            setLoading(false);
        }
    };

    const sendChatMessages = async (incidences, specieId) => {
        try {
            const now = moment().utc().valueOf();

            incidences
                .filter((i) => i.requestContact)
                .forEach((incidence) => {
                    const specieTemplate = templates?.filter((t) => t.specieId === specieId)[0];
                    if (specieTemplate) {
                        const message = {
                            userId: user.id,
                            createdAt: now,
                            seen: false,
                            type: 'html',
                            message: specieTemplate.text,
                            seenEntomologist: true,
                            seenUser: false,
                            chatStatus: 'open',
                        };

                        const roomData = {
                            clientPushToken: incidence?.pushToken || '',
                            chatStatus: 'open',
                        };

                        sendMessageService(incidence.id, message, roomData);
                        updateIncidenceService(incidence.id, { chatStatus: 'open' });
                    }
                });
        } catch (e) {
            console.log('error', e);
        }
    };

    const showOtherCrops = ({ naturalWidth, width, naturalHeight, height }) => {
        const multipleGeoPoint = reviewedIncidence?.multipleGeoPoint;
        const scaleX = naturalWidth / width;
        const scaleY = naturalHeight / height;
        console.log('scaleX, scaleY', scaleX, scaleY);

        const crops = multipleGeoPoint?.map((point) => {
            console.log('point', point);
            return {
                canvas: null,
                crop: {
                    aspect: 1,
                    height: (point.y1 - point.y0) / scaleY,
                    unit: 'px',
                    width: (point.x1 - point.x0) / scaleX,
                    x: point.x0 / scaleX,
                    y: point.y0 / scaleY,
                },
            };
        });

        setCroppedArray(crops);
    };

    const selectOtherBug = async (crop, canvas) => {
        setLoadingSelectBug(true);
        try {
            setCroppedArray([...croppedArray, crop]);

            const imageData = await canvasToBase64(canvas);

            const incidenceData = {
                urlImage: imageData,
            };

            const newIncidence = await createIncidenceService(incidenceData);
            console.log('newIncidence', newIncidence);
            dispatch(addIncidence({ ...newIncidence, id: newIncidence?.idIncidence }));
            return newIncidence?.idIncidence;
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoadingSelectBug(false);
        }
    };

    return (
        <IncidenceReviewComponent
            {...props}
            incidence={incidence}
            reviewedIncidence={reviewedIncidence}
            loading={loading}
            submit={submit}
            getMessages={getMessages}
            {...{ species, croppedArray, setCroppedArray, calculateCrop, showOtherCrops, selectOtherBug, loadingSelectBug }}
        />
    );
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncidenceReview));
