import { createTrap, deleteTrap, getTraps, getTrapsByCompany, getTrapsTypes, updateTrap } from "services/traps"
import TrapsComponent from "../components/TrapsComponent"
import React, { useEffect, useState } from 'react'
import { getLocationById } from "services/location"
import { base64ToFile, uploadFileService } from "services/uploadFile"
import { getAllCompanies, getUsersCompanyById } from "services/companies"
import { getCompanyUserId, getUserRole } from "services/utils"

const Traps = () => {
    const [traps, setTraps] = useState([])
    const [companies, setCompanies] = useState([])
    const [trapsTypes, setTrapsTypes] = useState([])
    const [companyUser, setCompanyUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const role = getUserRole()

    useEffect(() => {
        loadInitialData()
    }, [])


    const loadInitialData = async () => {
        try {
            setLoading(true)
            const trapsCompleted = await getCompletedTraps()
            const companiesData = await getAllCompanies()
            const trapsTypesData = await getTrapsTypes()
            const companyUserData = await getCompanyUser()
            setTrapsTypes(trapsTypesData)
            setTraps(trapsCompleted)
            setCompanies(companiesData)
            setCompanyUser(companyUserData)
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false)
        }
    }

    
  const getCompanyUser = async() => {
    let companyUser = null
    try {
      if(role === 'cmsmanager'){
        const companyId = getCompanyUserId()
        const dataCompany = await getUsersCompanyById(companyId)
        companyUser = {idCompany: dataCompany.idCompany, name: dataCompany.name}
      }
      return companyUser
    }catch(e) {
      throw new Error(e)
    }
  }

    const getCompletedTraps = async () => {
        let completedTraps = []
        try{
            let data = []
            if(role === 'cmsmanager'){
                const companyId = getCompanyUserId()
                data = await getTrapsByCompany(companyId)
            } else {
                data = await getTraps()
            }
            const filterWithoutUse = data.filter((trap) => !trap.trap.hasOwnProperty('idRoute') || trap.trap.idRoute?.length === 0)
            const filterInUse = data.filter((trap) => trap.trap.idRoute?.length > 0)
            const trapsCompleted = await Promise.all( filterInUse.map(async (trap) => {
                const location= await getLocationById(trap.route.idLocation)
                return {...trap, location: location}
            }))
            completedTraps = [...filterWithoutUse,...trapsCompleted]
            
        }catch(error){
            throw new Error(error)
        }finally{
            return completedTraps
        }   
    }

    const submit = async (values) => {
        try {
            let image = ''
            let body = {
                trapTypeId: values.typeTrap.id,
                idDevice: values.idDevice,
                name: values.name,
                isEnable: values.isEnable[0] === 'Yes' ? true : false,
                idCompany: values.company.idCompany
            }
            if(values.image.length > 0 ){
                const file = base64ToFile(values.image)
                image = await uploadFileService(file)
                body = {...body, img: image}
            } 
            const newTrap = await createTrap(body)
            const formatTrap = {
                trap: newTrap,
                company: values.company,
                trapType: values.typeTrap
            }
            setTraps((prevTraps) => {
                return [formatTrap, ...prevTraps ]
            })
        } catch(error) {
            console.error(error)
            throw error
        }
    }

    const update = async (values) => {
        try {
            let image = values.image || ''
            if (image.includes("data:image/png;base64,") || image.includes("data:image/jpeg;base64,")) {
                const file = base64ToFile(values.image)
                image = await uploadFileService(file)
            }
            let body= {
                trapTypeId: values.typeTrap.id,
                idDevice: values.idDevice,
                name: values.name,
                img: image,
                isEnable: values.isEnable[0] === 'Yes' ? true : false,
                idCompany: values.company.idCompany
            }
            await updateTrap(body, values.idTrap)
            const dataUpdated = await getCompletedTraps()
            const updatedTrap = dataUpdated.find(trap => trap.trap.idTrap === values.idTrap)
            setTraps((prevTraps) => {
                const index = prevTraps.findIndex(trap => trap.trap.idTrap === values.idTrap)
                return [
                  ...prevTraps.slice(0, index),
                  updatedTrap,
                  ...prevTraps.slice(index + 1)
                ]
              })
        } catch(error) {
            console.error(error)
            throw error
        }
    }

    const eliminate = async (idTrap) => {
        try {
            await deleteTrap(idTrap)
            setTraps(prevLocations => prevLocations.filter(trap => trap.trap.idTrap !== idTrap))
        } catch(error) {
            console.error(error)
            alert("Something went wrong, contact your administrator")
        }
    }

    return <TrapsComponent loading={loading} traps={traps} submit={submit} update={update} eliminate={eliminate} companies={companies} trapsTypes={trapsTypes} companyUser={companyUser}/>
}

export default Traps