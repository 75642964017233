import _indexOf from 'lodash/indexOf'
import _sample from 'lodash/sample'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getStadisticsByStartAndEndDateRange, getCompanyStadisticsByStartAndEndDateRange } from 'services/stadistics'
import ReportsComponent from '../components/ReportsComponent'
import availableColors from '../components/availableUserColors'
import { getCompanyUserId, getUserRole } from 'services/utils'

const Reports = () => {
  const dates = moment()
  const [loading, setLoading] = useState(true)
  const [dailyStatistics, setDailyStatistics] = useState([])
  const [totalStatistics, setTotalStatistics] = useState([])
  const [legend, setLegend] = useState([])
  const role = getUserRole()
  const idCompany = getCompanyUserId()

  const getRandomColorFromList = () => {
    return availableColors.splice(_indexOf(availableColors, _sample(availableColors)), 1)[0]
  }

  useEffect(() => {
    const getStadistics = async () => {
      const weekStart = dates.clone().startOf('week').format('YYYY-MM-DD')
      const weekEnd = dates.clone().endOf('week').format('YYYY-MM-DD')
      try {
        let result
        if(role === 'cmsmanager'){
          result  = await getCompanyStadisticsByStartAndEndDateRange(idCompany, weekStart, weekEnd)
        }else{
          result = await getStadisticsByStartAndEndDateRange(weekStart, weekEnd)
        }
        let formattedLegends = []
  
        Object.keys(result?.totalStatistics).forEach((key) => {
          formattedLegends.push({
            name: key,
            color: getRandomColorFromList(),
          })
        })

        setDailyStatistics(result?.dailyStatistics)
        setTotalStatistics(result?.totalStatistics)
        setLegend(formattedLegends)

      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getStadistics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ReportsComponent loading={loading} legend={legend} chartColumnData={dailyStatistics} chartPieData={totalStatistics} />
}

export default withRouter(Reports)
