import moment from 'moment';
import customFetch from './customFetch';
import { getUserToken } from './utils';
const BASE_URL = 'https://pestid.extrasense.pitagorinesgroup.com';

export const getIssues = async () => {
    const filter = { where: { active: true } };
    try {
        let response = await customFetch(`${BASE_URL}/issues?filter=${JSON.stringify(filter)}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};

export const getIssuesByCompany = async (idCompany) => {
    const filter = { where: { active: true, idCompany: idCompany } };
    try {
        let response = await customFetch(`${BASE_URL}/issues?filter=${JSON.stringify(filter)}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
}

export const getAlertIssues = async () => {
    const filter = {
        where: {
            active: true,
            issueDate: {
                gt: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            },
        },
        limit: 10,
        order: 'issueDate DESC',
    };
    try {
        let response = await customFetch(`${BASE_URL}/issues?filter=${JSON.stringify(filter)}`, {
            method: 'GET',
            token: getUserToken(),
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};
