import React, { useState, useEffect } from 'react';
import { updateUser } from 'redux/actions/userActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useDateRange } from 'contexts/dateRangeContext';
import { withRouter } from 'react-router-dom';
import FacilityReportsComponent from '../components/FacilityReportsComponent';
import { getDetailedResportService, getIncidencesBetweenDatesService, getQuickResportService } from 'services/facility';
import moment from 'moment';
import _ from 'lodash';

const FacilityReports = (props) => {
    const { user } = props;
    const dateEnd = moment().add(5, 'hours').toDate();
    const dateStart = moment(dateEnd).add(-30, 'days').toDate();
    const [fetchingGraphIncidences, setFetchingGraphIncidences] = useState(null);
    const [pestIncidences, setPestIncidences] = useState([]);
    const [facilityIncidences, setFacilityIncidences] = useState([]);
    const [quickCsvData, setQuickCsvData] = useState([]);
    const [detailedCsvData, setDetailedCsvData] = useState([]);
    const [pestHeaderFilter, setPestHeaderFilter] = useState([]);
    const [quickIncidencesData, setQuickIncidencesData] = useState();

    useEffect(() => {
        getIncidencesByDate(dateStart, dateEnd);
    }, []);

    const getPestsFilterTypes = async (idFacility, initialDate, finalDate) => {
        try {
            const incidencesData = await getQuickResportService(idFacility, initialDate, finalDate);
            const pestIncidencesOnly = incidencesData.table.filter((incidence) => incidence.issueType.toLowerCase() === 'pest');
            setQuickIncidencesData(pestIncidencesOnly);

            let types = [
                ...new Set(
                    incidencesData?.table
                        .map((incidence) => (incidence?.issueType.toLowerCase() === 'pest' ? incidence.incidence : ''))
                        .filter((type) => type !== '')
                ),
            ];

            const filteredPests = types.map((value) => ({
                label: value,
                value: value,
            }));

            setPestHeaderFilter(filteredPests);
        } catch (e) {
            console.log('error updating', e);
        } finally {
        }
    };

    const getIncidencesByDate = async (initialDate, finalDate) => {
        try {
            setFetchingGraphIncidences(true);
            const res = await getIncidencesBetweenDatesService(user.idFacility, initialDate, finalDate);

            const pest = res.incidencePest.map((incidence) => incidence);
            const pestSorted = _.sortBy(pest, (p) => moment(p.date).valueOf());
            const facility = res.incidenceFacilities.map((incidence) => incidence);
            const facilitySorted = _.sortBy(facility, (f) => moment(f.date).valueOf());

            setPestIncidences(pestSorted);
            setFacilityIncidences(facilitySorted);

            // await getPestsFilterTypes(user.idFacility, initialDate, finalDate);
        } catch (e) {
            console.log('error updating', e);
        } finally {
            setFetchingGraphIncidences(false);
        }
    };

    const generateQuickCsvData = async (initialDate, finalDate) => {
        let incidencesData = await getQuickResportService(user.idFacility, initialDate, finalDate);
        let csvHeader = ['Date', 'Type', 'Incidence', 'Location', 'User'];

        const csvFormat = [
            ['Company', incidencesData.companyName],
            ['Client', user?.name],
            ['Report date', moment().format('MM/DD/YYYY')],
            ['Date range selected', `${moment(initialDate).format('MM/DD/YYYY')} - ${moment(finalDate).format('MM/DD/YYYY')}`],
            [],
        ];
        csvFormat.push(csvHeader);

        incidencesData.table.forEach((csvRow) => {
            csvFormat.push([csvRow.date, csvRow.issueType, csvRow.incidence, csvRow.location, csvRow.user]);
        });

        setQuickCsvData(csvFormat);
    };

    const generateDetailedCsvData = async (initialDate, finalDate) => {
        let incidencesData = await getDetailedResportService(user.idFacility, initialDate, finalDate);

        let csvHeader = ['Date', 'Type', 'Incidence', 'Location', 'User', 'Image', 'Comments', 'Pest Information'];

        const csvFormat = [
            ['Company', incidencesData.companyName],
            ['Client', user?.name],
            ['Report date', moment().format('MM/DD/YYYY')],
            ['Date range selected', `${moment(initialDate).format('MM/DD/YYYY')} - ${moment(finalDate).format('MM/DD/YYYY')}`],
            [],
        ];
        csvFormat.push(csvHeader);

        incidencesData.table.forEach((csvRow) => {
            csvFormat.push([
                csvRow.date,
                csvRow.issueType,
                csvRow.incidence,
                csvRow.location,
                csvRow.user,
                csvRow.image,
                csvRow.comments,
                csvRow.pestInfo,
            ]);
        });

        setDetailedCsvData(csvFormat);
    };

    return (
        <FacilityReportsComponent
            {...props}
            {...{
                dateEnd,
                dateStart,
                getIncidencesByDate,
                fetchingGraphIncidences,
                pestIncidences,
                setPestIncidences,
                facilityIncidences,
                setFacilityIncidences,
                quickCsvData,
                detailedCsvData,
                generateQuickCsvData,
                generateDetailedCsvData,
                pestHeaderFilter,
                setPestHeaderFilter,
                quickIncidencesData,
            }}
        />
    );
};
const mapStateToProps = (store) => ({
    user: store.userReducer.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacilityReports));
