import React from "react";
import _ from 'lodash';
import './TableRow.scss';

const TableRow = props => {
    const { data, rowClick, dataClickCallback, hiddenColumns, notHoverable = '' } = props;
    const rowWithoutHiddenColumns = _.omit(data, hiddenColumns);

    return (
        <tbody>
            <tr className={"custom-table-row "} >
                {
                    _.map(_.keys(rowWithoutHiddenColumns), (rowKey, i) => {
                        const rowValue = rowWithoutHiddenColumns[rowKey];
                        if (rowKey === 'actions') return (
                            <td className="align-center" key={i} style={{ height: '100%' }} >
                                {rowValue.map((item, i) => <div onClick={item.action}>{item.element}</div>)}
                            </td>
                        )
                        return (
                            <td className={notHoverable ? "not-hoverable" : ""} onClick={() => rowClick && rowClick(dataClickCallback)} >{rowValue}</td>
                        )
                    })
                }
            </tr>
        </tbody>
    )
}

export default TableRow;