import React, { useState, useEffect, useRef } from 'react';
import IncidencesComponent from '../components/IncidencesComponent';
import { withRouter } from 'react-router-dom';
import { createIncidenceService, updateIncidenceService, getStatisticsService, getIncidencesByStatusService, getIncidencesService } from 'services/incidence';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateUser } from 'redux/actions/userActions';
import _ from 'lodash';
import moment from 'moment';

import { useDateRange } from 'contexts/dateRangeContext';
import { getImageData } from 'services/utils';
import { useIncidence } from 'contexts/incidencesContext';
import { getSpeciesService } from 'services/species';
import { getExtractedIncidenceData } from 'components/utils/incidenceUtils';

const Incidences = (props) => {
    const { history, user } = props;
    const [incidences, setIncidences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingStatistics, setLoadingStatistics] = useState(false)
    const [loadingCsv, setLoadingCsv] = useState()
    const [csvData, setScvData] = useState([]);
    const [loadingBatch, setLoadingBatch] = useState(false);
    const [loadingMoreData, setloadingMoreData] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentReviewedPage, setCurrentReviewedPage] = useState(0);
    const [pestPredictionModal, setPestPredictionModal] = useState(false);
    const [sameBugConfirmation, setSameBugConfirmation] = useState(false);
    const [species, setSpecies] = useState([]);
    const [statistics, setStatistics] = useState({});
    const [totalCount, setTotalCount] = useState()

    const {
        state: {
            dates: { dateStart, dateEnd },
        },
        dispatch,
        updateDateStart,
        updateDateEnd,
    } = useDateRange();
    const {
        state: { incidences: incidencesToReview },
        dispatch: dispatchIncidence,
        clearIncidences,
    } = useIncidence();

    const rawIncidences = useRef([]);
    const rawReviewedIncidences = useRef([]);

    useEffect(() => {
        getLastWeekIncidences();
        getSpecies();
    }, []);

    const getLastWeekIncidences = () => {
        getIncidences(dateStart, dateEnd);
        getStatistics(dateStart, dateEnd);
    };

    const getIncidences = async (startDate, endDate, page = 0, status) => {
        try {
            setLoading(true)
            const data = await getIncidencesByStatusService({ startDate: startDate || dateStart, endDate: endDate || dateEnd, page, status });
            if (status === 'Reviewed') {
                rawReviewedIncidences.current = [...rawReviewedIncidences.current, ...data.myPopulatedIncidence];
                setIncidences(rawReviewedIncidences.current);
            } else {
                rawIncidences.current = [...rawIncidences.current, ...data.myPopulatedIncidence];
                setIncidences(rawIncidences.current);
            }
            const total = data?.totalCount
            setTotalCount(total)
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false)
        }
    };

    const getStatistics = async (startDate, endDate) => {
        try {
            setLoadingStatistics(true)

            const statisticsResponse = await getStatisticsService({ startDate: startDate || dateStart, endDate: endDate || dateEnd });
            setStatistics({
                ...statistics,
                totalReviewed: statistics?.reviewerIncidencesCount,
                ...statisticsResponse,
            })
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoadingStatistics(false)
        }
    }

    const generateCsvData = async () => {
        try {
            setLoadingCsv(true);
            const incidences = await getIncidencesService({ startDate: dateStart, endDate: dateEnd });
            const dataParsedSortedByDate = _.orderBy(incidences?.myPopulatedIncidence, ['date'], ['desc']);
            const speciesReference = _.keyBy(species, (specie) => specie?.specie?.id);
            let csvDataParsed = dataParsedSortedByDate
                .filter((data) => !data.incidence?.isDeleted)
                .map(async (incidenceData) => {
                    const incidence = incidenceData.incidence || {};
                    const { username, os, emailShared, acceptedReview, confidence, weather, location, coordinates } = getExtractedIncidenceData(
                        incidenceData,
                        user
                    );
                    const box = acceptedReview?.review?.multipleGeoPoint?.[0] || acceptedReview?.review?.geoPoint;

                    let scientificName = 'none',
                        subname = 'none';
                    if (acceptedReview?.review?.scientificName) scientificName = acceptedReview?.review?.scientificName;
                    if (acceptedReview?.review?.commonSubmane) subname = acceptedReview?.review?.commonSubmane;
                    const data = {
                        id: incidence.idIncidence,
                        errored: incidence?.errorIA ? 'true' : 'false',
                        requested_review: emailShared ? 'true' : 'false',
                        photo: incidence?.urlImage,
                        souce: incidence?.source || '-',
                        location: location,
                        coordinates: coordinates,
                        pest_prediction: incidence?.bug?.name,
                        '%_confidence': confidence,
                        threat: incidence?.bug?.dangerLevel,
                        user_name: username || '-',
                        os: os || '-',
                        user_email: emailShared || incidence.user?.email || '-',
                        user_zipcode: incidence.user?.zipcode || '-',
                        created_at: moment(incidence.createdAt).format('DD/MM/YYYY HH:mm'),
                        response_time: incidence.responseTime,
                        r_common_name: speciesReference[acceptedReview?.review?.idSpecie]?.specie?.nameEn || 'none',
                        r_common_subname: subname,
                        r_scientific_name: scientificName,
                        r_instar: acceptedReview?.review?.instar || 'none',
                        r_population: acceptedReview?.review?.population || '1',
                        r_box: box
                            ? [
                                [box.x0, box.y0],
                                [box.x1, box.y1],
                            ]
                            : '',
                        r_entomologist_name: acceptedReview?.reviewer?.name || '-',
                        r_date:
                            (acceptedReview?.review?.dateReview && moment(acceptedReview?.review?.dateReview).format('DD/MM/YYYY HH:mm')) ||
                            '-',
                    };

                    const weatherFields = [
                        'main',
                        'description',
                        'temp',
                        'feels_like',
                        'temp_min',
                        'temp_max',
                        'pressure',
                        'humidity',
                        'sea_level',
                        'grnd_level',
                        'country',
                        'city',
                        'wind_speed',
                        'wind_deg',
                    ];
                    if (weather) {
                        weatherFields.forEach((key) => (data[key] = weather[key] || '-'));
                    }
                    return data;
                });

            csvDataParsed = await Promise.all(csvDataParsed);

            const csvFormat = [];
            csvFormat.push(_.keys(csvDataParsed[0]));
            csvDataParsed.forEach((csvRow) => {
                csvFormat.push(_.values(csvRow));
            });
            setScvData(csvFormat);
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoadingCsv(false);
        }

    };

    const getSpecies = async () => {
        const species = await getSpeciesService();
        if (species) setSpecies(species);
    };

    const getIncidencePriority = ({ emailShared, alreadyReviewedByMe, status, isPaid }) => {
        if (alreadyReviewedByMe || status === 'finished' || status === 'Reviewed 2/2' || status === 'Reviewed') return 0;

        if (isPaid) return 10;
        if (emailShared) return 8;
        else return 5;
    };

    const handleStartDateFilter = ({ dateStart, showReviewedIncidences }) => {
        let startDate = dateStart;
        let endDate = dateEnd;
        if (startDate > endDate) endDate = moment(startDate).add(7, 'days').toDate();
        getIncidencesByDateFilter(startDate, endDate, showReviewedIncidences)
    };

    const handleEndDateFilter = ({ dateEnd, showReviewedIncidences }) => {
        let startDate = dateStart;
        let endDate = dateEnd;
        if (startDate > endDate) startDate = moment(endDate).add(-7, 'days').toDate();
        getIncidencesByDateFilter(startDate, endDate, showReviewedIncidences)
    };

    const getIncidencesByDateFilter = (startDate, endDate, showReviewedIncidences) => {
        dispatch(updateDateStart(startDate));
        dispatch(updateDateEnd(endDate));
        getIncidences(startDate, endDate, 0, showReviewedIncidences ? 'Reviewed' : '');
        getStatistics(startDate, endDate)
        showReviewedIncidences ? setCurrentReviewedPage(1) : setCurrentPage(1)
    }

    const uploadImagesBatch = async (event) => {
        setLoadingBatch(true);

        try {
            const incidencePromises = [...event.target.files].map(async (file) => {
                const imageData = await getImageData(file);

                const incidenceData = {
                    // idUser: "",
                    urlImage: imageData.base64,
                };

                return createIncidenceService(incidenceData);
            });

            const results = await Promise.all(incidencePromises);

            await getIncidences(dateStart, dateEnd);
        } catch (err) {
            console.log(err);
        }

        setLoadingBatch(false);
    };

    const deleteOrRestoreIncidence = async (idIncidence, toDelete = true) => {
        try {
            if (window.confirm('Are you sure you want to delete this incidence?')) {
                const res = await updateIncidenceService(idIncidence, { isDeleted: toDelete });
                await getIncidences();
                await getStatistics(dateStart, dateEnd)
            }
        } catch (e) {
            console.log('error deleteIncidence', e);
        }
    };

    const loadMoreData = async (status) => {
        if (loadingStatistics || loadingMoreData) return
        try {
            if (status === 'Reviewed') {
                if (rawReviewedIncidences?.current.length >= statistics?.reviewerIncidencesCount || loadingMoreData) return;
                setloadingMoreData(true);
                getIncidences(dateStart, dateEnd, currentReviewedPage, 'Reviewed');
                setCurrentReviewedPage(currentReviewedPage + 1);
                getStatistics(dateStart, dateEnd)
            } else {
                if (rawIncidences.current.length >= totalCount || loadingMoreData) return;
                setloadingMoreData(true);
                getIncidences(dateStart, dateEnd, currentPage);
                setCurrentPage(currentPage + 1);
                getStatistics(dateStart, dateEnd)
            }
        } catch (e) {
            console.log('error loadMoreData', e);
        } finally {
            setTimeout(() => {
                setloadingMoreData(false);
                setLoading(false);
            }, 7000);
        }
    };

    const getReviewedIncidences = async () => {
        try {
            getIncidences(dateStart, dateEnd, 0, 'Reviewed');
            getStatistics(dateStart, dateEnd)
            setCurrentReviewedPage(1);
        } catch (e) {
            console.log('error Reviewed Incidences', e);
        }
    };
    const getAllIncidences = async () => {
        try {
            getIncidences(dateStart, dateEnd, 0);
            getStatistics(dateStart, dateEnd)
            setCurrentPage(1);
        } catch (e) {
            console.log('error Reviewed Incidences', e);
        }
    };

    const handleResetIncidences = () => {
        setIncidences([]);
        rawReviewedIncidences.current = [];
        rawIncidences.current = [];

    }

    return (
        <IncidencesComponent
            {...props}
            incidences={incidences}
            csvData={csvData}
            loading={loading}
            history={history}
            handleStartDateFilter={handleStartDateFilter}
            handleEndDateFilter={handleEndDateFilter}
            uploadImagesBatch={uploadImagesBatch}
            loadingBatch={loadingBatch}
            generateCsvData={generateCsvData}
            species={species}
            {...{
                getExtractedIncidenceData,
                getIncidencePriority,
                deleteOrRestoreIncidence,
                pestPredictionModal,
                setPestPredictionModal,
                sameBugConfirmation,
                setSameBugConfirmation,
                loadMoreData,
                loadingMoreData,
                statistics,
                getReviewedIncidences,
                setLoading,
                loadingCsv,
                handleResetIncidences,
                loadingStatistics,
                totalCount,
                getAllIncidences,
            }}
        />
    );
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Incidences));
