import React from 'react';

import { ReactComponent as NotePencil } from 'assets/icons/NotePencilGreen.svg';
import { ReactComponent as Trash } from 'assets/icons/TrashRed.svg';

export const DropdownPlane = (props) => {
  const { isDropdownOpen, trap, currentTrap, onDelete, onEdit, coords } = props
  return (
    isDropdownOpen && trap === currentTrap &&
    <div className="canvas-dropdown" style={{left: coords.x - 40, top: coords.y -100}}>
        <div className='canvas-dropdown-header'>
            <h4>{currentTrap.name}</h4>
            <p>{currentTrap.idDevice}</p>
        </div>
        <hr className='canvas-dropdown-line'></hr>
        <div className='canvas-dropdown-buttons'>
            <div
            className="canvas-dropdown-column"
            onClick={onEdit}
            >
                <NotePencil/>
                <span>Edit</span>
            </div>
            <div className="canvas-dropdown-column" onClick={() => onDelete(trap)}>
                <Trash />
                <span>Delete</span>
            </div>
        </div>
    </div>
  )
}

export default DropdownPlane
