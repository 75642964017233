import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken, getUserId } from "./utils";

export const populateSpeciesService = async () => {
    const token = getUserToken();
    const userId = getUserId();


    const species = [
        {
            dev: {
                id: 'ae2691db-f86a-45f1-83b8-03bfbcb63de9',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'no bicho',
            nameEn: 'no bug',
            status: 'accepted',
        },
        {
            dev: {
                id: '2c8099a7-d8e2-4a02-a6e0-5a616049dcc5',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'desconocido',
            nameEn: 'unknown',
            status: 'accepted',
        },
        {
            dev: {
                id: '8509f926-eb51-4c8f-ab6b-f48c8d118864',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'chinche de cama',
            nameEn: 'bedbug',
            status: 'accepted',
        },
        {
            dev: {
                id: '1de5be52-6f71-48b6-96c6-2922e55a3abe',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'pulga',
            nameEn: 'flea',
            status: 'accepted',
        },
        {
            dev: {
                id: 'cd2a5cfd-96b8-433a-ac46-6c065e60c3ba',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'escarabajo de la alfombra',
            nameEn: 'carpet beetle',
            status: 'accepted',
        },
        {
            dev: {
                id: '8ab11068-b6fb-4bd6-b395-efb862ef5908',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'escarabajo araña',
            nameEn: 'spider beetle',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: 'b5c71087-6d41-4727-8a59-77c8fd7d369e',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'garrapata',
            nameEn: 'tick',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '5b8ad311-91bf-4807-b52d-87b4337040ca',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'escarabajo de grano',
            nameEn: 'grain beetle',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: 'ac77d7b3-9c1c-440e-a179-1153ffd663d5',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'cucaracha',
            nameEn: 'cockroach',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '80d5c1c6-e64c-4d39-8211-72a5e9492380',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'chinche marmolada',
            nameEn: 'stink bug',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '7f082792-37e8-4331-84dc-3dfe2f0da6d3',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'tijerilla',
            nameEn: 'earwig',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '1e59187d-39bd-44f7-9319-36fc9b4da951',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: '',
            nameEn: 'moth',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '0123f99a-c7a1-4df1-93b7-3cd50e62f20d',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'piojo',
            nameEn: 'louse',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '0fb1ee4b-0838-49d9-8582-19a06f2dfb15',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: '',
            nameEn: 'fire ant',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '3ddbc502-8866-4aeb-afb0-942fb8b361ba',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'termita',
            nameEn: 'termite',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: 'ccd73273-07f2-4bd5-9fdb-5a05d96828fe',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'pececillo de plata ',
            nameEn: 'silverfish',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: '27ed086a-382e-4227-b345-8acd27d6a787',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'ácaro',
            nameEn: 'mite',
            status: 'accepted',
            registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
        },
        {
            dev: {
                id: 'e20bfb54-9b92-455c-bc0d-91f7f107e996',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'otro',
            nameEn: 'other',
            status: 'accepted',
        },
        {
            delete: true,
            dev: {
                id: 'fde523fb-0b28-46fb-bba2-67630446f774',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'Booklice',
            nameEn: 'Booklice',
            status: 'accepted',
        },
        {
            delete: true,
            dev: {
                id: '63c1d63f-1ff7-498c-8e29-9b8260162fd9',
                registeredBy: "ffd95c38-13ec-4464-9a7d-5527c98ec034"
            },
            pro: {
                id: '',
                registeredBy: "900437f1-4ac8-481c-96d7-3aa4704d811f"
            },
            nameEs: 'Carpet Beetle Larva',
            nameEn: 'Carpet Beetle Larva',
            status: 'accepted',
        },
    ]


    try {
        species.forEach((especie) => {
            const environment = 'dev';
            const id = especie[environment].id;
            if (id) {
                //update
                customFetch(`${endpoints.species}/${id}`, {
                    method: "PATCH",
                    token,
                    bodyReq: {
                        nameEs: especie.nameEs,
                        nameEn: especie.nameEn
                    }
                });
            }
            else if (especie.delete) {
                //delete
                customFetch(`${endpoints.species}/${id}`, {
                    method: "DELETE",
                    token,
                });
            }
            else {
                //create
                const registeredBy = especie[environment].registeredBy;
                customFetch(`${endpoints.species}`, {
                    method: "POST",
                    token,
                    bodyReq: {
                        nameEs: especie.nameEs,
                        nameEn: especie.nameEn,
                        status: especie.status,
                        registeredBy
                    }
                });
            }
        })
    } catch (err) {
        throw err;
    }
};

export const getSpeciesService = async (token) => {
    try {
        let response = await customFetch(endpoints.species, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getSpecieService = async (specieId) => {
    try {
        const token = getUserToken();
        let response = await customFetch(`${endpoints.species}/${specieId}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const addSpecieService = async ({ nameEs, nameEn, status = "accepted" }) => {
    const token = getUserToken();
    const userId = getUserId();
    try {
        let response = customFetch(`${endpoints.species}`, {
            method: "POST",
            token,
            bodyReq: { nameEs, nameEn, status, registeredBy: userId }
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }

};

export const updateSpecieService = async (id, data) => {
    const token = getUserToken();
    try {
        let response = customFetch(`${endpoints.species}/${id}`, {
            method: "PATCH",
            token,
            bodyReq: data
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteSpecieService = async (id) => {
    const token = getUserToken();
    try {
        let response = customFetch(`${endpoints.species}/${id}`, {
            method: "DELETE",
            token
        });
        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }

};