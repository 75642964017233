import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken, getUserId } from "./utils";

export const getCompanyEmployees = async (id) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.facilities}/${id}?filter={"include":[{"relation": "employees"},{"relation": "managers"} ]}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const getCompanyData = async (id) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.facilities}/${id}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const getIncidencesByDateService = async (id, initialDate, finalDate) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.facilities}/quickreport`, {
            method: "POST",
            bodyReq: {
                id,
                initialDate,
                finalDate
            },
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const postDisableFacilityEmployeeService = async (idUser) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.facilities}/disable/employee`, {
            method: "POST",
            bodyReq: {
                idUser,
            },
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }

};

export const getIncidencesBetweenDatesService = async (idFacility, initialDate, finalDate) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.facilities}/incidencesbetweendates`, {
            method: "POST",
            bodyReq: {
                idFacility,
                initialDate,
                finalDate
            },
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getQuickResportService = async (idFacility, initialDate, finalDate) => {
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.facilities}/quickreport`, {
            method: "POST",
            bodyReq: {
                idFacility,
                initialDate,
                finalDate
            },
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getDetailedResportService = async (idFacility, initialDate, finalDate) => {
    const token = getUserToken();
    
    try {
        let response = await customFetch(`${endpoints.facilities}/fullreport`, {
            method: "POST",
            bodyReq: {
                idFacility,
                initialDate,
                finalDate
            },
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
