import React, { useState } from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { BsTrashFill } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";
import "./LibraryCard.scss";
import { BiEdit } from "react-icons/bi";

const LibraryCard = (props) => {
    const {
        data,
        data: {
            createdAt,
            dangerLevel,
            info,
            name,
            new_label,
            preventiveTreatments,
            urlImage
        },
        isLoading,
        deleteSpecie,
        selectSpecie,
        toggleModal,
    } = props;

    return (
        <div className={`pest-library-card`}>

            <div className="review-picture">
                <img src={urlImage} alt="" />
            </div>

            <div className="row name">
                {name}
            </div>

            <div className="row description">
                {info}
            </div>

            <div className="row auto-width dangerLevel">
                {dangerLevel}
            </div>

            <div className="row review-actions auto-width">
                <div className="delete" onClick={() => deleteSpecie(new_label)}>
                    <BsTrashFill />
                </div>
                <div
                    className="delete"
                    onClick={() => {selectSpecie(data); toggleModal()}}
                >
                    <AiFillEdit />
                </div>

            </div>

        </div>
    );
};

export default LibraryCard;
