import PresentationPestID from 'components/elements/PresentationPestID/PresentationPestID'
import React from 'react'
import './ForgotPasswordPestIDComponent.scss'
import Button from "../../elements/Button/Button"
import Input from "../../elements/Input/Input"
import { Formik, Form } from "formik"
import { MdKeyboardBackspace } from 'react-icons/md'
import * as yup from 'yup'

const ForgotPasswordPestIDComponent = (props) => {
    const { loading, submit, history } = props

    return (
        <div className="forgot-password-pestid">
            <PresentationPestID />
            <div className="formContainer">
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={yup.object().shape({
                        email: yup.string().email("Invalid email format").required("Email is a required field"),
                    })}
                    onSubmit={(values) => submit(values)}
                >
                    {(formik) => (
                        <Form>
                            <div className="back-icon" onClick={() => history.push('/login/pestid')} >
                                <MdKeyboardBackspace/>
                                Back
                            </div>
                            <Input
                                id="email"
                                formik={formik}
                                type="text"
                                label="Email*"
                                className="formControl"
                            />

                            <Button
                                loading={loading}
                                type="submit"
                                onClick={formik.handleSubmit}>
                                Reset Password
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ForgotPasswordPestIDComponent