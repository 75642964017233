
import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'; 
import ButtonComponent from 'components/elements/Button/Button';
import { ReactComponent as CameraSVG } from 'assets/icons/camera.svg';
import { ReactComponent as CheckCircleSVG } from 'assets/icons/checkcircle.svg';
import './BuildingRoutesComponent.scss';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import InputComponent from 'components/elements/Input/Input';
import MapPicker from '../../elements/MapPicker/MapPicker';
import * as Yup from 'yup';
import Loading from 'components/elements/Loading/Loading';

export const BuildingForm = (props) => {
    const { submit, modalVisible, setModalVisible, companies, companyUser } = props
    const [numPage, setNumPage] = useState(1)    
    const [loading, setLoading] = useState(false)
    const [selectorCompaniesOpen, setSelectorCompaniesOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const inputRefs = useRef([])
    const formRef = useRef()

    const initialValues = companyUser
    ? {
        nameLocation: '',
        address: '',
        latitude: null,
        longitude: null,
        numberRoutes: '',
        search: '',
        imagesRoutes: [''],
        namesRoutes: [''],
        company: {idCompany: companyUser.idCompany, name: companyUser.name}
    } :
    {
        nameLocation: '',
        address: '',
        latitude: null,
        longitude: null,
        numberRoutes: '',
        search: '',
        imagesRoutes: [''],
        namesRoutes: [''],
        company: {idCompany: '', name: '' }
    } 
    const validationSchema = Yup.object().shape({
        nameLocation: Yup.string().required('This field is required'),
        address: Yup.string().required('This field is required'),
        latitude: Yup.number().required(),
        longitude: Yup.number().required(),
        numberRoutes: Yup.string().required('This field is required'),
        search: Yup.string(),
        imagesRoutes: Yup.array().of(Yup.string()),
        namesRoutes: Yup.array().of(Yup.string()),
        company: Yup.object().shape({
            idCompany: Yup.string().required().min(1),
            name: Yup.string().required().min(1)
        }),
    })

    useEffect(() => {
        setNumPage(1)
    },[modalVisible])

    const handleNext = (formik) => {
        let lengthArray = parseInt(formik.values.numberRoutes)
        formik.setValues({
        ...formik.values,
        imagesRoutes: Array(lengthArray).fill(''),
        namesRoutes: Array(lengthArray).fill('')
        })
        for(let i = 0; i < lengthArray; i++) {
            if(!inputRefs.current[i]) {
            inputRefs.current[i] = React.createRef()
            }
        }
        setNumPage(2)
    }

    const onBackPress = () => {
        if(numPage === 1) setModalVisible(false)
        if(numPage === 2) setNumPage(1)
    }

    const uploadFile = async(event, index, formik) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          const imageDataURL = e.target.result
          formik.setFieldValue(`imagesRoutes[${index}]`, imageDataURL)
        }
        reader.readAsDataURL(file)       
    }

    const checkIsDisabled = (formik) => {
        let isDisabled = false
        formik.values.imagesRoutes.forEach((image) => {
        if(image.length === 0) isDisabled = true
        })
        formik.values.namesRoutes.forEach((name) => {
        if(name.length === 0) isDisabled = true
        })
        return isDisabled
    }

    const onClickSelectorCompany = () => {
        setSelectorCompaniesOpen(!selectorCompaniesOpen)
    }

    const onClickRowCompany = (company, formik) => {
        formik.setFieldValue('company', company)
        setSelectorCompaniesOpen(false)
    }

    return (
        <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
                setLoading(true)
                setNumPage(3)
                await submit(values)
            } catch (error) {
                setErrors({ submit: error.message })
                alert("Something went wrong, contact your administrator")
                setModalVisible(false)
                throw error
            } finally {
                setSubmitting(false)
                setLoading(false)
            }
        }}
        >
        {(formik) => (
            <Form>
                <ModalLayout 
                maxWidth={'783px'}
                isOpen={modalVisible}
                toggleModal={() => setModalVisible(false)}
                footer={
                    numPage === 1 || numPage === 2 ? (
                    <div className='modal-footer-content'>
                        <ButtonComponent className='modal-footer-button back' children={'Back'} onClick={onBackPress}/>
                        {numPage === 1 ? (
                            <ButtonComponent className='modal-footer-button next' children={'Next'} onClick={()=>handleNext(formik)} disabled={!formik.dirty || !formik.isValid} />
                        ) : (   
                            <ButtonComponent
                            className='modal-footer-button next'
                            children={'Save'} onClick={()=> formik.handleSubmit()}
                            disabled={checkIsDisabled(formik)}/>
                        )}
                    </div>
                    ): (
                        <ButtonComponent children='Ok' onClick={() => setModalVisible(false)} />
                    )
                } 
                header={
                    <div className='modal-header-content'>
                        {numPage === 1 ? ( <h2>New building</h2>) : null} 
                        {numPage === 2 ? ( <h2>Routes configuration</h2>) : null}
                        <div className='modal-header-button-close' onClick={() => setModalVisible(false)}>
                            X
                        </div>
                    </div>
                }
                children={
                        <div ref={formRef} className='modal-body-content'>
                            {numPage === 1 ? (
                            <>
                            {!companyUser ? (
                                <>
                                <div className='modal-body-content-selector-companies' onClick={onClickSelectorCompany}>
                                    <h3>{formik.values.company.name || 'Select company'}</h3>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div> 
                                {selectorCompaniesOpen ? (
                                    <div className='modal-body-content-selector-companies-container'>
                                        <InputComponent 
                                            InputProps={{
                                            disableUnderline: true
                                            }}
                                            id='search' 
                                            name='search'
                                            type='text'
                                            onChange={(event) => setSearchTerm(event.target.value)}
                                            placeholder='Search'
                                            />
                                        {companies.filter(company => company.name.toLowerCase().includes(searchTerm.toLowerCase())).map((company,index) => (
                                            <div key={index.toString()}  onClick={ () => onClickRowCompany(company, formik)}  className='modal-users-pestid-body-content-selector-companies-row'>
                                                <p>{company.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) :  null}
                                </>
                            ): null}
                            
                            <h3>Building name</h3>
                            <InputComponent 
                            formik={formik} 
                            id='nameLocation' 
                            name='nameLocation'
                            type='textarea'
                            placeholder='Enter a building name'
                            />
                            <h3>Routes</h3>
                            <InputComponent 
                            formik={formik} 
                            id='numberRoutes' 
                            name='numberRoutes'
                            type='textarea'
                            placeholder='Enter number of routes'
                            />
                            <h3>Address</h3>
                            <InputComponent 
                                InputProps={{ readOnly: true }}
                                formik={formik} 
                                id='address' 
                                name='address'
                                type='textarea'
                                placeholder='Search and address or press the location in map'
                            />
                            <MapPicker formik={formik}/>
                            </>
                            ) : null }
                            {numPage === 2 ? (
                            <div>
                            {formik.values.namesRoutes.map((name, index) => (
                                <>
                                <h3>Route {index + 1} name</h3>
                                <InputComponent 
                                    formik={formik} 
                                    id={`namesRoutes[${index}]`}
                                    name={`namesRoutes[${index}]`}
                                    type='textarea'
                                    placeholder={`Route ${index + 1}`}
                                />
                                <h3>Image</h3>
                                    <div onClick={() => inputRefs.current[index].click()} className='modal-body-content-upload-button'>
                                    <input key={index}  ref={ref => inputRefs.current[index] = ref} type="file" onChange={(event) => uploadFile(event, index, formik)} />
                                    {formik.values.imagesRoutes[index]?.length > 0 ? (
                                        <img src={formik.values.imagesRoutes[index]} alt="Selected route" />
                                    ): (
                                        <>  
                                            <CameraSVG />
                                            <h3>Upload picture</h3>
                                            <p>Please upload a picture of the route</p>
                                        </>
                                    )}               
                                    </div>
                                </>
                                ))}
                            </div>
                            ) : null}
                            {numPage === 3 ? (
                            <div className='modal-body-content-success'>
                                {loading ? (
                                    <Loading />
                                ): (
                                    <>
                                    <CheckCircleSVG />
                                    <h2>Building created</h2>
                                    </>
                                )}
                            </div>
                            ) : null}
                        </div>
                }
                />
            </Form>
        )}
        </Formik>
    )
}

export default BuildingForm