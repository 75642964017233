import React, { useState } from "react";
import Button from "components/elements/Button/Button";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import Quill from "components/elements/ReactQuill/ReactQuill";
import "./SendMessageModal.scss";

const SendMessageModal = ({
    open,
    onDeny,
    confirmButtonText,
    headerText,
    loading,
    denyButtonText,
    submit,
    message,
    setMessage,
    specieData
}) => {
    const customStyles = {
        overlay: {
            background: "#00000060",
        },
        content: {
            top: 0,
            left: 0,
            padding: "0",
            border: "none",
            height: '100%',
            width: '100%',
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            background: "none",
        },
    };

    return (
        <Modal isOpen={open} onRequestClose={onDeny} style={customStyles}>
            <div className="send-message-wrapper">
                <div className="close-button" onClick={onDeny}>
                    <IoClose />
                </div>
                <h3 className="message-header">{specieData?.common_name_english || specieData?.nameEn} default message</h3>
                <div className="message-content">
                    <Quill
                        placeholder="Write the message..."
                        value={message}
                        onChange={(e) => setMessage(e)}
                    />
                </div>
                <div className="button-wreapper">
                    <Button
                        className="secondary auto-width"
                        onClick={onDeny}
                    >
                        {denyButtonText}
                    </Button>
                    <Button
                        loading={loading}
                        className="auto-width"
                        onClick={() => submit(message)}
                    >
                        {confirmButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SendMessageModal;
