import { db, firebaseApp } from 'firebase';
import moment from "moment";

export const messageListenerService = (chatId, callback) => {
    return db
        .collection("chat")
        .doc(chatId)
        .collection("message")
        .orderBy("createdAt", "asc")
        .onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(function (change) {
                if (change.type === "added") {
                    const newMessage = {
                        ...change.doc.data(),
                        id: change.doc.id,
                        chatId
                    };

                    callback(newMessage);
                }

                if (change.type === "modified") {
                }

                if (change.type === "removed") {
                }
            });
        });
};

export const sendMessageService = async (chatId, data, initialRoomData) => {
    console.log('chatId, data, initialRoomData', chatId, data, initialRoomData);
    const now = moment().utc().valueOf();

    if (!(await db.collection("chat").doc(chatId).get()).exists) {
        createChatService(chatId, data, initialRoomData);
    }

    await db
        .collection("chat")
        .doc(chatId)
        .collection("message")
        .add(data);


    updateChatService(chatId, {
        updatedAt: now,
        lastMessage: data,
        lastSeenEntomologist: now
    });
};

export const updateChatService = async (chatId, data) => {
    console.log('update chatId, data', chatId, data);
    await db
        .collection("chat")
        .doc(chatId)
        .update(data);
};

export const createChatService = async (incidenceId, lastMessage, initialRoomData = {}) => {
    const data = {
        lastMessage: lastMessage,
        createdAt: +new Date(),
        ...initialRoomData
    }

    await db
        .collection("chat")
        .doc(incidenceId)
        .set(data);
};

export const getUnreadMessagesCount = async (chatId, lastReadTimestamp) => {
    if (!chatId || lastReadTimestamp === undefined) return 0;
    const unreadMessages = await db
        .collection("chat")
        .doc(chatId)
        .collection("message")
        .where("createdAt", ">", lastReadTimestamp + 100)
        .get();
    return unreadMessages.docs.length;
};

export const listenToRoomsService = async (roomsIds, callback) => {
    let observers = roomsIds.map((roomId) => {
        try {
            return db
                .collection("chat")
                .where(firebaseApp.firestore.FieldPath.documentId(), '==', roomId)
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'added') {
                            callback({
                                ...change.doc.data(),
                                id: change.doc.id
                            })
                        }
                        if (change.type === 'modified') {
                            callback({
                                ...change.doc.data(),
                                id: change.doc.id
                            })
                        }
                        if (change.type === 'removed') {
                            console.log('Removed', change.doc.data());
                        }
                    });
                });
        } catch (e) {
            console.log("error getGroupsService", e);
        }
    });
    return observers;

};