import React, { useState, useEffect } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import IncidenceReviewCard from 'components/elements/IncidenceReviewCard/containers/IncidenceReviewCard';
import Button from 'components/elements/Button/Button';
import _ from 'lodash';
import "./IncidencesReviewsComponent.scss";

const IncidencesReviewsComponent = (props) => {
    const { reviews, getIncidenceAndReviews, acceptAll, loadingAcceptAll } = props;

    return (
        <DashboardLayout >
            <div className="incidence-reviews-wrapper">
                <div className="incidences-header">
                    <h1>Incidence reviews</h1>
                </div>

                <div className="top-buttons">
                    <Button
                        loading={loadingAcceptAll}
                        onClick={() => acceptAll()}
                        style={{ width: 'auto', }}
                        className="">Accept all</Button>

                </div>

                <div className="table-content">
                    {
                        reviews?.length ? reviews.map((review, x) => (
                            <IncidenceReviewCard incidence={review.incidence} review={review} onChangeStatus={getIncidenceAndReviews} />
                        )) : <div className="pests-empty">No pending reviews.</div>
                    }
                </div>

            </div>
        </DashboardLayout>
    );
};

export default IncidencesReviewsComponent;