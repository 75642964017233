import Loading from 'components/elements/Loading/Loading'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryTheme } from 'victory'

export const ChartColumnComponent = (props) => {
  const { legend, data } = props

  const [loading, setLoading] = useState(true)
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    const formatData = () => {
      const specieData = []

      Object.values(data).forEach((item) => {
        const itemData = []

        Object.entries(item).forEach(([key, value]) => {
          itemData.push({ name: key, value })
        })

        const sortedItemData = itemData.toSorted((a, b) => moment(a.name) - moment(b.name))
        specieData.push(sortedItemData)
      })

      return specieData
    }
    setFormattedData(formatData())
    setLoading(false)
  }, [data])

  if (loading) return <Loading />
  return (
    <div className="chart-column">
      <VictoryChart domainPadding={30} theme={{ ...VictoryTheme.material }} width={700} height={400}>
        <VictoryAxis
          style={{
            grid: { stroke: 'transparent', strokeDasharray: '0, 0' },
            axis: { stroke: '#727272', strokeWidth: 0.3, strokeDasharray: '0' },
            tickLabels: {
              fill: '#A0A6A9',
              fontFamily: 'GothamRounded',
            },
            ticks: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(x) => x}
          tickCount={10}
          style={{
            grid: { stroke: '#727272', strokeWidth: 0.3, strokeDasharray: '0' },
            axis: { stroke: '#000', strokeWidth: '1' },
            tickLabels: { fill: '#000', fontFamily: 'GothamRounded', fontWeight: 'bolder', fontSize: 15, textAlign: 'right' },
            ticks: { stroke: 'transparent' },
          }}
        />
        <VictoryStack colorScale={[...Object.values(legend).map((item) => item.color)]}>
          {formattedData.map((data, index) => {
            return (
              <VictoryBar
                key={index}
                barWidth={50}
                data={data}
                x={({ name }) => moment(name).format('ddd DD')}
                y="value"
                animate={{
                  onLoad: { duration: 700 },
                }}
              />
            )
          })}
        </VictoryStack>
      </VictoryChart>
    </div>
  )
}

export default ChartColumnComponent
