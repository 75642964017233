import Loading from 'components/elements/Loading/Loading'
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'

export const ChartPieComponent = (props) => {
  const { legend, data } = props

  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState()

  useEffect(() => {
    setOptions({
      chart: {
        type: 'pie',
      },
      tooltip: {
        enabled: false,
      },
      series: Object.values(data),
      labels: [],
      fill: {
        colors: legend.map((item) => item.color),
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          customScale: 1,
          expandOnClick: false,
          donut: {
            size: '35%',
            labels: {
              show: false,
            },
          },
        },
      },
    })
    setLoading(false)
  }, [data, legend])

  if (loading) return <Loading />

  return (
    <div className="chart-pie">
      <Chart options={options} series={options.series} type="donut" width="100%" />
    </div>
  )
}

export default ChartPieComponent
