import React, { useState } from "react";
import Modal from 'react-modal';
import { Formik, Form } from "formik";
import * as yup from "yup";
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import { BsTrashFill } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';
import ManageSubspecieModal from './ManageSubspecieModal/containers/ManageSubspecieModal';
import { deleteSubspecieService } from 'services/subspecies';
import ClipLoader from "react-spinners/ClipLoader";
import _ from 'lodash';
import "./AddSpecieModal.scss";
import { deleteScientificNameService } from "services/scientificNames";

const AddSpecieModal = (props) => {
    const {
        submit,
        loading,
        isOpen,
        specie,
        setShowSpecieModal,
        getSpecies
    } = props;

    const [isSubspeciesModalOpen, setIsSubspeciesModalOpen] = useState(false);
    const [selectedSubspecie, setSelectedSubspecie] = useState(null);
    const [deletingSubspecies, setDeletingSubspecies] = useState([]);
    const [deletingScNames, setDeletingScNames] = useState([]);

    const initialValues = {
        nameEn: specie?.common_name_english || "",
        nameEs: specie?.common_name_spanish || "",
    }

    const validation = yup.object().shape({
        nameEn: yup.string().required("Name is a required field"),
        nameEs: yup.string().required("Name is a required field"),
    })

    const deleteSubspecie = async (id) => {
        setDeletingSubspecies([...deletingSubspecies, id]);

        try {
            if (window.confirm('Are you sure you want to delete this subspecie?')) {
                await deleteSubspecieService(id);
                const newSubSpecies = _.cloneDeep(specie?.subSpecies).filter(subspecie => {
                    return subspecie.id !== id;
                })

                setShowSpecieModal({
                    ...specie,
                    subSpecies: newSubSpecies
                })

                await getSpecies();
            }
        } catch (err) {
            console.log(err);
        }

        setDeletingSubspecies(deletingSubspecies.filter(subspecie => subspecie !== id));
    }

    const deleteScientificName = async (id) => {
        setDeletingScNames([...deletingScNames, id]);

        try {
            if (window.confirm('Are you sure you want to delete this scientific name?')) {
                await deleteScientificNameService(id);
                const newScNames = _.cloneDeep(specie?.scientificNames).filter(sn => {
                    return sn.id !== id;
                })

                setShowSpecieModal({
                    ...specie,
                    scientificNames: newScNames
                })

                await getSpecies();
            }
        } catch (err) {
            console.log(err);
        }

        setDeletingScNames(deletingScNames.filter(subspecie => subspecie !== id));
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setShowSpecieModal(false)}
                className="extrasense-modal"
                overlayClassName="modal-overlay with-background modal-edit-specie"
                ariaHideApp={false}>
                <div className="edit-profile-modal__wrapper">
                    <div className="modal-header">
                        <div>Add Specie</div>
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={(values) => submit(specie?.id, values)}>
                        {(formik) => (
                            <Form>
                                <div className="modal-body">
                                    <div className="input-wrapper fill-3">
                                        <Input
                                            formik={formik}
                                            type="text"
                                            id="nameEn"
                                            label="Common name in English"
                                            placeholder="Common name in English"
                                            className="formControl" />
                                    </div>
                                    <div className="input-wrapper fill-3">
                                        <Input
                                            formik={formik}
                                            type="text"
                                            id="nameEs"
                                            label="Common name in Spanish"
                                            placeholder="Common name in Spanish"
                                            className="formControl" />
                                    </div>

                                    {/* Subespecie */}
                                    {specie?.id && <div className="subspecies-list">
                                        <div className="header">
                                            <div>Subnames</div>
                                            <Button
                                                style={{ width: 'auto' }}
                                                // loading={loading}
                                                type="button"
                                                onClick={() => {
                                                    setSelectedSubspecie(null);
                                                    setIsSubspeciesModalOpen("subname");
                                                }}>
                                                Add
                                            </Button>
                                        </div>

                                        <div className="content">
                                            {
                                                specie?.subSpecies?.length ?
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Common name in English</th>
                                                                <th>Common name in Spanish</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                specie.subSpecies.map((subSpecie, i) => (
                                                                    <tr key={i}>
                                                                        <td>{subSpecie.nameEng}</td>
                                                                        <td>{subSpecie.nameSpa}</td>
                                                                        <td>
                                                                            <div className="actions">
                                                                                <div className="action" onClick={() => {
                                                                                    setSelectedSubspecie(subSpecie);
                                                                                    setIsSubspeciesModalOpen("subname");
                                                                                }}>
                                                                                    <AiFillEdit />
                                                                                </div>

                                                                                {
                                                                                    deletingSubspecies.includes(subSpecie.id) ?
                                                                                        <ClipLoader
                                                                                            size={15}
                                                                                            color={"#696969"}
                                                                                            loading={true}
                                                                                        /> :
                                                                                        <div className="action" onClick={() => deleteSubspecie(subSpecie.id)}>
                                                                                            <BsTrashFill />
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table> :
                                                    <p>No subnames were registered</p>
                                            }
                                        </div>
                                    </div>}

                                    {/*Scientific name*/}
                                    {specie?.id && <div className="subspecies-list">
                                        <div className="header">
                                            <div>Scientific Names</div>
                                            <Button
                                                style={{ width: 'auto' }}
                                                // loading={loading}
                                                type="button"
                                                onClick={() => {
                                                    setSelectedSubspecie(null);
                                                    setIsSubspeciesModalOpen("scientific name");
                                                }}>
                                                Add
                                            </Button>
                                        </div>

                                        <div className="content">
                                            {
                                                specie?.scientificNames?.length ?
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Scientific name</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                specie.scientificNames.map((sn, i) => (
                                                                    <tr key={i}>
                                                                        <td>{sn.name}</td>
                                                                        <td>
                                                                            <div className="actions">
                                                                                <div className="action" onClick={() => {
                                                                                    setSelectedSubspecie(sn);
                                                                                    setIsSubspeciesModalOpen("scientific name");
                                                                                }}>
                                                                                    <AiFillEdit />
                                                                                </div>

                                                                                {
                                                                                    deletingScNames.includes(sn.id) ?
                                                                                        <ClipLoader
                                                                                            size={15}
                                                                                            color={"#696969"}
                                                                                            loading={true}
                                                                                        /> :
                                                                                        <div className="action" onClick={() => deleteScientificName(sn.id)}>
                                                                                            <BsTrashFill />
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table> :
                                                    <p>No scientific names were registered</p>
                                            }
                                        </div>
                                    </div>}
                                </div>

                                <div className="modal-footer">
                                    <Button className="light" onClick={() => setShowSpecieModal(false)}>Cancel</Button>
                                    <Button loading={loading} type="button" onClick={formik.handleSubmit}>Save</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>

            <ManageSubspecieModal
                isOpen={isSubspeciesModalOpen}
                setIsOpen={setIsSubspeciesModalOpen}
                selectedSubspecie={selectedSubspecie}
                selectedSpecie={specie}
                setSelectedSpecie={setShowSpecieModal}
                speciesId={specie?.id}
                getSpecies={getSpecies}
            />
        </div>
    );
};


export default AddSpecieModal;