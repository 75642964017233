import ButtonComponent from 'components/elements/Button/Button'
import Loading from 'components/elements/Loading/Loading'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import React, { useState } from 'react'


import './TrapsComponent.scss'
import TrapsTable from './TrapsTable'
import TrapForm from './TrapForm'
import TrapImageViewer from './TrapImageViewer'
import AlertDelete from 'components/elements/AlertDelete/AlertDelete'

const TrapsComponent = (props) => {
  const { loading, traps, companies, trapsTypes, companyUser, submit, update, eliminate } = props
  const [modalVisible, setModalVisible] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const [viewerVisible, setViewerVisible] = useState(false)
  const [selectedTrap, setSelectedTrap] = useState(null)
  
  const onPressCreate = () => {
    setSelectedTrap(null)
    setModalVisible(true)
  }

  const onPressEdit = (trap) => {
    setSelectedTrap(trap)
    setModalVisible(true)
  }

  const onPressDelete = (trap) => {
    setSelectedTrap(trap)
    setAlertVisible(true)
  }

  const onPressViewer = (trap) => {
    setSelectedTrap(trap)
    setViewerVisible(true)
  }

  const onDelete = async () => {
    try{
      await eliminate(selectedTrap.trap.idTrap)
    }catch(error){
      console.error(error)
    }
  }

  return (
    <DashboardLayout>
      { loading ? (
        <div className='traps-wrapper-loading'>
          <Loading />
        </div>
      ) :
      (
        <div className="traps-wrapper">
          <div className="traps-header">
            <h1>Traps</h1>
            <ButtonComponent className='traps-header-button' children='+ Create trap' onClick={onPressCreate} />
          </div>
          <div className='traps-content-wrapper'>
            <TrapsTable className='traps-content-table' traps={traps} onDelete={onPressDelete} onEdit={onPressEdit} onPressImage={onPressViewer}/>
          </div>
          <TrapForm modalVisible={modalVisible} setModalVisible={setModalVisible} submit={selectedTrap ? update : submit} selectedTrap={selectedTrap} companies={companies} trapsTypes={trapsTypes} companyUser={companyUser}/>
          <AlertDelete alertVisible={alertVisible} setAlertVisible={setAlertVisible} onDelete={onDelete} title='Delete trap' subtitle='Are you sure you want to delete this trap?' />
          {selectedTrap && <TrapImageViewer viewerVisible={viewerVisible} setViewerVisible={setViewerVisible} trap={selectedTrap} />}

        </div>
      )}
    </DashboardLayout>
  )
}

export default TrapsComponent