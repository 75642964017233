import moment from 'moment';
import * as React from 'react'
const DateRangeContext = React.createContext();

function dateRangeReducer(state, action) {
    switch (action.type) {
        case 'update_start_date': {
            return { ...state, dates: { ...state.dates, dateStart: action.value } }
        }
        case 'update_end_date': {
            return { ...state, dates: { ...state.dates, dateEnd: action.value } }
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function DateRangeProvider({ children }) {
    const dateEnd = moment().add(5, 'hours').toDate();
    const dateStart = moment(dateEnd).add(-4, 'days').toDate();
    const [state, dispatch] = React.useReducer(dateRangeReducer, { dates: { dateEnd, dateStart } })
    const value = { state, dispatch }
    return <DateRangeContext.Provider value={value}>{children}</DateRangeContext.Provider>
}

const updateDateStart = (value) => {
    return { type: "update_start_date", value }
}

const updateDateEnd = (value) => {
    return { type: "update_end_date", value }
}

const actions = { updateDateStart, updateDateEnd };

function useDateRange() {
    const context = React.useContext(DateRangeContext)
    if (context === undefined) {
        throw new Error('useDateRange must be used within a DateRangeProvider')
    }
    return { ...context, ...actions };
}

export { DateRangeProvider, useDateRange, actions }