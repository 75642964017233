import ButtonComponent from 'components/elements/Button/Button';
import LibraryCard from 'components/elements/LibraryCard/LibraryCard';
import SkeletonReviewCard from 'components/elements/SkeletonReviewCard/SkeletonReviewCard';
import TableOptions from 'components/elements/TableOptions/TableOptions';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import React, { useState, useEffect } from 'react';
import ManagePestLibraryModal from './AddPestLibraryModal/containers/ManagePestLibraryModal';
import './PestLibraryComponent.scss';

const PestLibraryComponent = (props) => {
    const { bugs, deleteBug, loading, isFetching, getBugs } = props
    const [filteredData, setFilteredData] = useState([{}]);
    const [selectedBug, setSelectedBug] = useState(null);
    const sortableColumns = [
        { column: 'image', style: { flex: '0 0 70px', padding: "0px 0px 0px 20px" } },
        { column: 'Name', style: { padding: 0, flex: '0 0 100px', } },
        { column: "description", style: { padding: 0 } },
        { column: 'Concern', style: { padding: 0, flex: '0 0 100px', } },
        { column: 'actions', style: { padding: 0, flex: '0 0 80px', } }
    ];

    const [addPestModal, setAddPestModal] = useState(null);

    useEffect(() => {
        const data = bugs.map((bug) => bug);
        setFilteredData(data)
    }, [bugs])

    return (
        <DashboardLayout>
            <div className="pest-library-wrapper">
                <div className="pest-library-header"><h1>Pest Library</h1>
                </div>
                <div className="add-pest-button-wrapper">
                    <ButtonComponent onClick={() => setAddPestModal(true)}>Add new</ButtonComponent>
                </div>
                {isFetching ? (
                    <div className="library-empty padding-top">
                        <SkeletonReviewCard />
                        <SkeletonReviewCard />
                        <SkeletonReviewCard />
                    </div>
                ) : filteredData.length ? (
                    <TableOptions
                        columnsClassName="table-filters-custom"
                        rawData={filteredData}
                        sortableColumns={sortableColumns}
                        rowComponent={
                            <LibraryCard
                                deleteSpecie={(id) => deleteBug(id)}
                                selectSpecie={(bug) => setSelectedBug(bug)}
                                toggleModal={() => setAddPestModal(true)} />
                        } />
                ) : (
                    <div className="species-empty">No pests found.</div>
                )}
                <ManagePestLibraryModal
                    getBugs={getBugs}
                    bugData={selectedBug}
                    isOpen={addPestModal}
                    loading={loading}
                    toggleModal={() => {
                        setAddPestModal(!addPestModal);
                        setSelectedBug(null)
                    }}
                />
            </div>
        </DashboardLayout>
    )
}

export default PestLibraryComponent;