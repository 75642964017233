import React, { useState, useEffect, useRef } from "react";
import MapComponent from "../components/FacilityMapComponent";
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import { getFormularyIncidences } from "services/incidenceFormulary";
import { getFilteredIncidencesService } from "services/incidence";

const FacilityMap = (props) => {
    const { history } = props;
    const incidences = useRef([]);
    const [visibleIncidences, setVisibleIncidences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formularies, setFormularies] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState(new Date());
    const [endDateFilter, setEndDateFilter] = useState(new Date());

    useEffect(() => {
        getFacilitieIncidents()
    }, [])

    useEffect(() => {
        if (formularies) getFilteredIncidences(formularies);
    }, [formularies])

    const getFilteredIncidences = async (formularies) => {
        try {
            setLoading(true)

            const incidencesFilter = {
                where: {
                    or: formularies.map((incidence) => ({ idIncidence: incidence.idIncidence }))
                }
            }

            const newIncidences = await getFilteredIncidencesService(incidencesFilter)
            const ordenedIncidences = []
            newIncidences.forEach((incidence) => {
                const selectedIncidence = formularies.filter((form) => incidence.incidence.idIncidence === form.idIncidence && form.id)

                selectedIncidence
                    .forEach((inc) => ordenedIncidences.push(
                        {
                            ...inc,
                            username: `${incidence.incidence.user.name} ${incidence.incidence.user.surname}`,
                            createdAt: incidence.incidence.createdAt,
                            bug: incidence.incidence.bug.name
                        }
                    ))
            })

            setVisibleIncidences(ordenedIncidences);
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false)
        }
    }

    const getFacilitieIncidents = async () => {
        try {
            setLoading(true)
            const filter = {
                where: {
                    or: [{ incidenceType: 'pest' }, { incidenceType: 'facility' }]
                }
            }
            const res = await getFormularyIncidences(filter);

            setFormularies(res);

        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoading(false)
        }
    }

    return <MapComponent
        visibleIncidences={visibleIncidences}
        history={history}
        startDateFilter={startDateFilter}
        endDateFilter={endDateFilter}
        loading={loading} />;
};

export default withRouter(FacilityMap);