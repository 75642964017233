import React, { useEffect, useMemo, useRef, useState } from "react";
import GoogleMapReact, { Polygon } from 'google-map-react';
import Button from 'components/elements/Button/Button';
import _ from "lodash";
import './GoogleMap.scss';


export default function SimpleMap(props) {
    const { formik, whenDragable } = props
    const [isDragable, setIsDragable] = useState(true);
    const [drawedPoligon, setDrawedPoligon] = useState(null);
    const [poligon, setPoligon] = useState(null);
    const mapView = useRef(null);
    const defaultProps = {
        center: {
            lat: 40.730610,
            lng: -73.935242
        },
        zoom: 7,
        fullscreenControl: false,
    };

    // const propsPoligon = useMemo(() => { //poligon coords
    //     if (formik.values.coverageArea.coords) setDrawedPoligon(formik.values.coverageArea.coords);
    // }, [formik.values.coverageArea.coords])

    const enableDrawableHelper = () => {
        // Clear the old Listeners if exits and attach a new 'mousedown' listener on Map
        setIsDragable(false);
        if (mapView.current) {
            mapView.current.maps_.event.clearListeners(
                mapView.current.googleMapDom_,
                'mousedown',
            );
            mapView.current.maps_.event.addDomListener(
                mapView.current.googleMapDom_,
                'mousedown',
                () => {
                    if (poligon) poligon.setMap(null);
                    drawNewPoligon()
                },
            );
        }
    };

    const polyComplete = (paths) => {
        let bounds = '';
        let coordsArray = [];

        paths.forEach((path) => {
            const lat = path.lat();
            const lng = path.lng();
            coordsArray.push({ lat, lng })
            bounds += `${lng} ${lat},`;
            // Appending the first coords as last to make a complete polygon
        });
        bounds += `${paths[0].lng()} ${paths[0].lat()}`;
        // Send the bounds to state 
        setDrawedPoligon(bounds);
        formik.setFieldValue('coverageArea', coordsArray);
    };

    const drawNewPoligon = () => {
        const poly = new mapView.current.maps_.Polyline({
            clickable: false,
            map: mapView.current.map_,
            strokeColor: '#080c22',
            strokeWeight: 3,
        });

        const move = mapView.current.maps_.event.addListener(
            mapView.current.map_,
            'mousemove',
            (e) => {
                console.log('e.latLng', e.latLng);
                poly.getPath().push(e.latLng);
            },
        );

        mapView.current.maps_.event.addListenerOnce(
            mapView.current.map_,
            'mouseup',
            (e) => {
                mapView.current.maps_.event.removeListener(move);
                const path = poly.getPath();
                poly.setMap(null);
                const newPolygon = new mapView.current.maps_.Polygon({
                    clickable: false,
                    fillColor: '#080c22',
                    fillOpacity: 0.25,
                    geodesic: true,
                    map: mapView.current.map_,
                    path,
                    strokeColor: '#080c22',
                    strokeWeight: 3,
                });

                mapView.current.maps_.event.clearListeners(
                    mapView.current.googleMapDom_,
                    'mousedown',
                );
                setPoligon(newPolygon);
            },
        );
    }

    const render = () => {
        return <div className="google-map-drawable">
            {isDragable ?
                <Button
                    onClick={() => { enableDrawableHelper(); setIsDragable(false); whenDragable(true) }}
                    className="draw-button-wrapper"
                >Draw</Button> :
                <Button
                    onClick={() => { polyComplete(poligon.getPath().getArray()); setIsDragable(true); whenDragable(false) }}
                    className="draw-button-wrapper accent"
                >Apply</Button>
            }

            <GoogleMapReact
                ref={(ref) => mapView.current = ref}
                bootstrapURLKeys={{
                    key: config.googleMaps.apiKey,
                    libraries: config.googleMaps.libraries,
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                draggable={isDragable}
                options={defaultProps}
                yesIWantToUseGoogleMapApiInternals
            />
        </div>
    }

    return render();
}

const config = {
    googleMaps: {
        apiKey: 'AIzaSyBTBtSShkECf6YgHMLDgiJv1zMFiVtOE5U',
        libraries: ['geometry', 'drawing'],
    },
};