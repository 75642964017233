import React from 'react';
import logo from '../../../logo.png';
import './Presentation.scss';

const Presentation = () => {

    return (
        <div className="claim">
            <div className="header">
                <img src={logo} width="100" alt="main-logo" />
            </div>
            <div className="claimBody">
                <h1>Qualified leads to grow your business powered by A.I.</h1>
                <h2>Most pest control companies fight over the same unqualified leads. We’ve got thousands of people using our app to identify pests at their home and looking to contact a professional when they need it most.</h2>
            </div>
            <div className="footer">
                <span className="powerby">powered by EXTRASENSE</span>
            </div>
        </div>
    )
}

export default Presentation;