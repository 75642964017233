import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import './TrapRoutesComponent.scss'

import SkeletonBuildingView from 'components/elements/BuildingElements/SkeletonBuildingView';
import Loading from 'components/elements/Loading/Loading';
import AlertDelete from 'components/elements/AlertDelete/AlertDelete';
import ModalTraps from 'components/elements/ModalTraps/ModalTraps';

export const TrapRoutesComponent = (props) => {
  const {
    loading,
    locations,
    availableTraps,
    deleteTrap,
    editTrap,
    selectedRoute,
    selectedTrap,
    selectedLocation,
    setSelectedTrap,
    setSelectedRoute,
    setSelectedLocation,
  } = props

  const [modalVisible, setModalVisible] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setSelectedLocation(locations[0])
  }, [locations, setSelectedLocation])

  useEffect(() => {
    if (selectedLocation?.routes?.length > 0) {
      setSelectedRoute(selectedLocation?.routes[0])
    }else{
      setSelectedRoute(null)
    }
  }, [selectedLocation, setSelectedRoute])


  const handleButtonClick = () => {
    history.push(`/admin/routes/add-traps/${selectedRoute.idRoute}`)
  }

  const handleEditTrap = async(values)=> {
    await editTrap(selectedTrap, values)
  }

  const handleDelete = async() => {
    await deleteTrap(selectedTrap)
  }


  return (
    <DashboardLayout>
      {loading ? (
        <div className='trap-routes-wrapper-loading'>
          <Loading />
        </div>
      ): (
        <>
        <SkeletonBuildingView
        selectedLocation={selectedLocation}
        selectedRoute={selectedRoute}
        setSelectedLocation={setSelectedLocation}
        setSelectedRoute={setSelectedRoute}
        selectedTrap={selectedTrap}
        setSelectedTrap={setSelectedTrap}
        locations={locations}
        title='Routes'
        onPressAdd={{title: '+Add traps', action: handleButtonClick}}
        onEditCanvas={() => setModalVisible(true)}
        onDeleteCanvas={() => setAlertVisible(true)}
        canvas
        />
        <ModalTraps
        submit={handleEditTrap}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedRoute={selectedRoute}
        selectedTrap={selectedTrap}
        availableTraps={availableTraps}
        />
        <AlertDelete
        alertVisible={alertVisible}
        setAlertVisible={setAlertVisible}
        onDelete={handleDelete}
        title='Remove trap'
        subtitle='Are you sure you want to remove this trap?'
        />
        </>
      )}
      
    </DashboardLayout>
  )
}

export default TrapRoutesComponent