import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Button from "../../elements/Button/Button";
import Input from "../../elements/Input/Input";
import Presentation from "../../elements/Presentation/Presentation";
import './Login.scss';
import ButtonComponent from "../../elements/Button/Button";

const LoginComponent = (props) => {
    const { loading, submit, error, history } = props;

    return (
        <div className="loginComponent">
            <Presentation />
            <div className="formContainer">
                <div className="wrapper-tabs">
                <ButtonComponent 
                    onClick={() => history.push('/login')} 
                    children={ <div className='button-access-bedbug'><p>BedBug ID</p> </div>}
                    style={{
                        backgroundColor: ['/','/login','/login/'].includes(history.location.pathname) ? '#178068' : 'transparent',
                        color: ['/','/login','/login/'].includes(history.location.pathname) ? 'white' : 'black',
                        boxShadow: ['/','/login','/login/'].includes(history.location.pathname) ? '0px 4px 10px #17806854' : 'none'
                      }}
                    />
                <ButtonComponent 
                    onClick={() => history.push('/login/pestid')} 
                    children={ <div className='button-access-pestid'><p>PestID</p></div>}
                    style={{ backgroundColor: history.location.pathname === '/login/pestid' ? '#178068' : 'transparent',
                    color: history.location.pathname === '/login/pestid' ? 'white' : 'black'
                 }}
                    />
                </div>
                <div className="formContainer-content">
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={yup.object().shape({
                            email: yup.string().email("Invalid email format").required("Email is a required field"),
                            password: yup
                                .string()
                                .required("Password is a required field"),
                        })}
                        onSubmit={(values) => submit(values)}
                    >
                        {(formik) => (
                            <>
                                <Form>
                                    <Input
                                        id="email"
                                        formik={formik}
                                        type="text"
                                        label="Email*"
                                        className="formControl"
                                    />
                                    <Input
                                        id="password"
                                        formik={formik}
                                        type="password"
                                        label="Password*"
                                        className="formControl"
                                    />

                                    {/* <a href="#" className="forgotPassword">Forgot password?</a> */}

                                    {
                                        error ?
                                            <div className="error">Wrong credentials. Please try again.</div> :
                                            null
                                    }

                                    <Button
                                        loading={loading}
                                        type="submit"
                                        onClick={formik.handleSubmit}>
                                        Login
                                    </Button>

                                    <span className="separator">or</span>

                                    <Button
                                        className="secondary"
                                        type="button"
                                        onClick={() => history.push('/register')}>
                                        Register
                                    </Button>
                                    <span
                                        className="forgotPassword"
                                        onClick={() => history.push('/forgot-password')}
                                    >Forgot password ?</span>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;
