import { endpoints } from "./endpoints";
import customFetch from "./customFetch";

export const getFeedbacksService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.feedbacks}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};