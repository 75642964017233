import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import ResetPasswordPestIDComponent from "../components/ResetPasswordPestIDComponet"
import { resetPasswordPestIDService } from "services/usersPestID"
import { useParams } from 'react-router-dom';

const ResetPasswordPestID = (props) => {
    const { history } = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const { id } = useParams();

    console.log(id)

    const submit = async (values) => {
        try {
            setLoading(true)
            const body = {
                resetKey: id,
                password: values.password,
                confirmPassword: values.confirmPassword,
            }
            console.log(body)
            await resetPasswordPestIDService(body)
            setSuccess(true)
        } catch ({ error }) {
            console.error(error)
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    return (<ResetPasswordPestIDComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        history={history}
        success={success}
    />)
}

export default withRouter(ResetPasswordPestID)