
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getUsersPestIDAdmin = async () => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/users`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getUsersPestIDCmsManager = async (idCompany) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/users/${idCompany}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const createUserPestID =  async (bodyReq) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/iam/crateUser`, {
            method: "POST",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const updateUserPestID =  async (bodyReq, idUser) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/user/${idUser}`, {
            method: "PATCH",
            bodyReq,
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const deleteUserPestID= async (idUser) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/user/${idUser}`, {
            method: "DELETE",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const getUserById = async (idUser) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/user/${idUser}`, {
            method: "GET",
            token: getUserToken()
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

export const loginServicePestID = async (credentials) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/iam/login`, {
            method: 'POST',
            bodyReq: credentials,
        });

        if (response.error) throw response;
        console.log(response)
        const filter = { include: [{ relation: 'user' }] }
        const userData = await getUserServiceComplete(response.token, filter)
        console.log(userData)
        const {user , ...rest} = userData
        if(user.role === "Professional"){
            throw new Error('Wrong credentials. Please try again.')
        }else {
            return { token: response.token, ...user, ...rest}
        }
    } catch (err) {
        throw err;
    }
};


export const getUserServiceComplete = async (token, filter = {}) => {
	try {
		let userCompleteData = await customFetch(
			`https://pestid.extrasense.pitagorinesgroup.com/user/me?filter=${JSON.stringify(filter)}`,
			{
				method: 'GET',
				token,
			}
		)

		const { userSettings, ...rest } = userCompleteData

		userCompleteData = {
			name: userCompleteData?.name,
			nickname: userCompleteData?.nickname,
			id: userCompleteData?.idUser,
			telephone: userCompleteData?.telephone,
			email: userCompleteData?.user?.email,
			token: token,
			onboardingShow: userSettings?.onboardingShown ?? false,
			pushToken: userSettings?.pushToken ?? '',
			...rest,
		}
		return userCompleteData
	} catch (e) {
		console.error('error', e)
		throw new Error(`Error fetching user data`)
	}
};

export const sendResetEmailService = async (email) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/iam/reset-password/init`, {
            method: 'POST',
            bodyReq: { email },
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const resetPasswordPestIDService = async (body) => {
    try {
        let response = await customFetch(`https://pestid.extrasense.pitagorinesgroup.com/iam/reset-password/finish`, {
            method: 'PUT',
            bodyReq: body,
           // token: data.resetPasswordToken ??,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};