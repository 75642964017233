import React, { useEffect, useState } from "react";
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Button from 'components/elements/Button/Button';
import ModalEditProfile from 'components/elements/ModalEditProfile/ModalEditProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faStar, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-modal';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import ClipLoader from "react-spinners/ClipLoader";
import { HiOutlineMail } from 'react-icons/hi';
import { AiOutlinePhone, AiOutlineInbox, AiOutlineMessage } from 'react-icons/ai';
import { toast } from "react-toastify";
import "./ManagePestControllerComponent.scss";
import ToastrContent from "components/elements/Toastr/Toastr";

const ReviewSkeletonLoading = () => {
    return (
        <div className="review">
            <div className="review-picture">
                <Skeleton width={40} height={40} circle={true} />
            </div>
            <div className="review-name">
                <Skeleton width={90} height={15} />
            </div>
            <div className="review-message">
                <Skeleton width={150} height={15} />
            </div>
            <div className="review-stars">
                {
                    _.times(5, (i) => (
                        <FontAwesomeIcon key={i} icon={faStar} />
                    ))
                }
            </div>
        </div>
    )
}

const ManagePestControllerComponent = (props) => {
    const {
        id,
        currentPestController,
        submit,
        loading,
        fetchingPestController,
        isEditModalOpen,
        setIsEditModalOpen,
        isDeleteModalOpen,
        setIsDeleteModalOpen,
        deletePestController,
        loadingDelete,
        loadingPhoto,
        updatePestControllerPhoto,
        setVisibility
    } = props;

    const [allReviewsVisible, setAllReviewsVisible] = useState(false);
    const [isActive, setIsActive] = useState(currentPestController.active);

    useEffect(() => setIsActive(currentPestController.active), [currentPestController.active]);

    const username = `${currentPestController.name || ''} ${currentPestController.lastName || ''}`;
    const reviewsSorted = _.orderBy(currentPestController.reviews, ['dateReview'], ['desc']);

    let userDescription = currentPestController.specialitiesDescription || currentPestController.historyDescription || currentPestController.ownerDescription;
    userDescription = userDescription ? `${currentPestController.specialitiesDescription} ${currentPestController.historyDescription} ${currentPestController.ownerDescription}` : null;


    const handlePictureChange = async (event) => {
        const imageFile = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);

        reader.onload = () => {
            let fileInfo = {
                name: imageFile.name,
                type: imageFile.type,
                size: Math.round(imageFile.size / 1000), // kb
                base64: reader.result,
                file: imageFile,
            };

            updatePestControllerPhoto(fileInfo);
        }
    }

    const toggleVisibility = () => {
        if (!currentPestController.phone || !currentPestController.postalCode || !currentPestController.licenseNumber) {

            toast(
                <ToastrContent
                    type="danger"
                    title={"Missing information"}
                    message={"Check your phone number, postal code and license number!"}
                />,
                {
                    progressClassName: "toastr-progress-bar danger",
                }
            );
            return;
        }
        setVisibility({ active: !isActive });
        setIsActive(!isActive);
    }

    return (
        <DashboardLayout>
            <div className="profile-wrapper">
                <div className="profile-header">
                    <h1>{id ? "Manage Pest Pro" : "Profile"}</h1>
                </div>
                <div className="profile-content">
                    <div className="profile-name">
                        <label htmlFor="pictureInput">
                            <img src={currentPestController.urlProfileImage || require('assets/images/user-placeholder.jpg')} alt="" />

                            <div className={loadingPhoto ? "picture-overlay loading" : "picture-overlay"}>
                                <FontAwesomeIcon icon={faUpload} />

                                <div className="loading-spinner">
                                    <ClipLoader
                                        size={25}
                                        color={"#fff"}
                                        loading={true}
                                    />
                                </div>
                            </div>
                        </label>

                        <input
                            type="file"
                            id="pictureInput"
                            onChange={handlePictureChange}
                            multiple={false}
                            style={{ display: "none" }}
                            accept="image/png, image/jpg"
                        />

                        {
                            fetchingPestController ?
                                <Skeleton width={100} height={20} /> :
                                <div>
                                    <h2>{username}</h2>
                                    <div className="contact"><HiOutlineMail /> <span className="user-data"> {currentPestController.email}</span></div>
                                    <div className="contact"><AiOutlineMessage />  <span className="user-data">{currentPestController.notificationsEmail || currentPestController.email}</span></div>
                                    <div className="contact"><AiOutlinePhone />  <span className="user-data">{currentPestController.phone || "-"}</span></div>
                                </div>
                        }
                    </div>

                    <div className="profile-actions">
                        <div className="delete-icon" onClick={() => setIsDeleteModalOpen(true)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </div>
                        <div className="edit-icon" onClick={() => setIsEditModalOpen(true)}>
                            <FontAwesomeIcon icon={faPen} />
                            <div className="edit-icon__title">Edit</div>
                        </div>
                    </div>

                    <div className="profile-description">
                        <div className="profile-description__title">Description</div>
                        <div className="profile-description__body">
                            {
                                fetchingPestController ?
                                    <Skeleton width={100} height={15} /> :
                                    userDescription || "No description provided."
                            }
                        </div>
                    </div>

                    <div className="profile-info">
                        <div className="info-item">
                            <div className="info-item__icon">
                                <img src={require('assets/icons/location-pin.png')} alt="" />
                            </div>
                            <div className="info-item__text">Location</div>
                            <div className="info-item__value">
                                {
                                    fetchingPestController ?
                                        <Skeleton width={100} height={15} /> :
                                        currentPestController.city ? `${currentPestController.city} ${currentPestController.country ? `(${currentPestController.country})` : ''}` : "No location"
                                }
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="info-item__icon">
                                <img src={require('assets/icons/bug.png')} alt="" />
                            </div>
                            <div className="info-item__text">Pest coverage</div>
                            <div className="info-item__value">
                                {
                                    fetchingPestController ?
                                        <Skeleton width={100} height={15} /> :
                                        currentPestController.pestCoverage?.length ? currentPestController.pestCoverage.join(", ") : "No pest coverage selected"
                                }
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="info-item__icon">
                                <img src={require('assets/icons/pesticide.png')} alt="" />
                            </div>
                            <div className="info-item__text">Service options</div>
                            <div className="info-item__value">
                                {
                                    fetchingPestController ?
                                        <Skeleton width={100} height={15} /> :
                                        currentPestController.serviceOptions?.length ? currentPestController.serviceOptions.join(", ") : "No service options selected"
                                }
                            </div>
                        </div>
                    </div>

                    {
                        id &&
                        <div className="profile-reviews">
                            <div className="profile-reviews__title">{reviewsSorted?.length || 0} Reviews</div>
                            <div className="profile-reviews__body">
                                {
                                    fetchingPestController ?
                                        _.times(3, (i) => (
                                            <ReviewSkeletonLoading key={i} />
                                        )) :
                                        reviewsSorted && reviewsSorted.length ?
                                            reviewsSorted.slice(0, allReviewsVisible ? reviewsSorted.length : 4).map((review, i) => (
                                                <div className="review" key={i}>
                                                    <div className="review-picture">
                                                        <img src={review.user.urlProfileImage || require('assets/images/user-placeholder.jpg')} alt="" />
                                                    </div>
                                                    <div className="review-name">{`${review.user.name || ''} ${review.user.surname || ''}`}</div>
                                                    <div className="review-message">{review.content}</div>
                                                    <div className="review-stars">
                                                        {
                                                            _.times(review.stars, (i) => (
                                                                <FontAwesomeIcon key={i} className={review.stars >= 3 ? 'green' : 'yellow'} icon={faStar} />
                                                            ))
                                                        }
                                                        {
                                                            _.times(5 - review.stars, (i) => (
                                                                <FontAwesomeIcon key={i} icon={faStar} />
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            )) :
                                            <div className="reviews-empty">No reviews provided yet.</div>
                                }
                                {
                                    reviewsSorted && reviewsSorted.length > 4 && !allReviewsVisible &&
                                    <div className="view-all" onClick={() => setAllReviewsVisible(true)}>
                                        <div>View all</div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="button-wrapper">
                        <Button className={isActive ? 'danger' : ''} onClick={() => toggleVisibility()}>{isActive ? 'Unpublish' : 'Publish'}</Button>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className="extrasense-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="delete-modal__wrapper">
                    <div className="modal-body">
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <h3>Delete your account?</h3>
                        <p>Are you sure you want to delete your Pest Pro account?</p>
                    </div>
                    <div className="modal-footer">
                        <Button className="light" onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
                        <Button className="danger" loading={loadingDelete} onClick={() => deletePestController()}>Delete</Button>
                    </div>
                </div>
            </Modal>

            <ModalEditProfile
                currentPestController={currentPestController}
                isOpen={isEditModalOpen}
                onRequestClose={() => setIsEditModalOpen(false)}
                changeIsOpenValue={(value) => setIsEditModalOpen(value)}
                submit={submit}
                loading={loading}
            />

        </DashboardLayout >
    );
};

export default ManagePestControllerComponent;