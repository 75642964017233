import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getZipcodesService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.zipcodes}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const updateZipcodeService = async (id, data) => {
    console.log('id, data', id, data);
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.zipcodes}/${id}`, {
            method: "PATCH",
            bodyReq: data,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createZipcodeService = async ({list}) => {
    console.log('list', list);
    const token = getUserToken();

    try {
        let response = await customFetch(`${endpoints.zipcodes}`, {
            method: "POST",
            bodyReq: {
                list,
                id: 'zipcodes',
            },
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};